import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { LEFT_NAV_ITEMS } from '../../../../../constants/index';
import { StyledLink } from '../Header.styled';

export default function HeaderLeft() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentPath = location.pathname.toLowerCase();
  return LEFT_NAV_ITEMS.map(item => {
    const isActive =
      item.name === 'index'
        ? currentPath === '/'
        : currentPath.startsWith(item.value);
    return (
      <StyledLink
        aria-label={
          isActive
            ? `${t(`nav.${item.name}`)}. ${t('nav.currentPageMessage')}`
            : t(`nav.${item.name}`)
        }
        className="w-1/4"
        key={item.name}
        to={item.name === 'index' ? `/` : item.value}
      >
        <img
          alt={item.name}
          height={40}
          src={`/icons/${item.name}.svg`}
          width={40}
        />

        {t(`nav.${item.name}`)}
        {isActive && (
          <div
            style={{
              backgroundColor: '#7F68FF',
              bottom: 0,
              height: '3px',
              left: 0,
              margin: '-4px 10px',
              position: 'absolute',
              right: 0,
            }}
          />
        )}
      </StyledLink>
    );
  });
}
