/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const MathConceptsPage = lazyLoad(
  () => import('./index'),
  module => module.MathConceptsPage,
);
