import { StrategyCommentData } from 'schemas/student-dashboard';

export const generateStrategyComment = (
  data: StrategyCommentData | undefined,
  studentName: string | undefined,
): string => {
  if (!data) return '';

  // 학습단계
  let studyLevelComment = '';
  if (data.achievement_level === 0) {
    studyLevelComment =
      '아직 이번 소단원을 완료하지 않아 학습단계가 산출되지 않았습니다.';
  } else {
    studyLevelComment = `${data.achievement_level}단계의 학습을 수행중이며`;
  }

  // 학습시간
  let studyTimeComment = '';
  if (data.percentage_study_duration >= 81) {
    studyTimeComment = '아주 많은 편입니다';
  } else if (data.percentage_study_duration >= 61) {
    studyTimeComment = '비교적 많은 편입니다';
  } else if (data.percentage_study_duration >= 41) {
    studyTimeComment = '평균적인 편입니다';
  } else if (data.percentage_study_duration >= 21) {
    studyTimeComment = '비교적 부족한 편입니다';
  } else {
    studyTimeComment = '매우 부족한 편입니다';
  }

  // 문제풀이수
  let studyPerformsComment = '';
  if (data.percentage_study_performs >= 81) {
    studyPerformsComment = '아주 많은 편입니다';
  } else if (data.percentage_study_performs >= 61) {
    studyPerformsComment = '비교적 많은 편입니다';
  } else if (data.percentage_study_performs >= 41) {
    studyPerformsComment = '평이한 편입니다';
  } else if (data.percentage_study_performs >= 21) {
    studyPerformsComment = '비교적 적은 편입니다';
  } else {
    studyPerformsComment = '아주 적은 편입니다';
  }

  // 정답률
  let correctRateComment = '';
  if (data.percentage_correct_rate >= 81) {
    correctRateComment = '아주 높은 편입니다';
  } else if (data.percentage_correct_rate >= 61) {
    correctRateComment = '비교적 높은 편입니다';
  } else if (data.percentage_correct_rate >= 41) {
    correctRateComment = '평이한 편입니다';
  } else if (data.percentage_correct_rate >= 21) {
    correctRateComment = '비교적 낮은 편입니다';
  } else {
    correctRateComment = '아주 낮은 편입니다';
  }

  // 난이도
  let difficultyComment = '';
  const { HIGH, HIGHEST, LOW, MIDDLE } = data.performs_by_difficulty;
  const maxDifficulty = Math.max(HIGHEST, HIGH, MIDDLE, LOW);

  if (maxDifficulty === HIGHEST) {
    difficultyComment = '최상 난이도의 문제를 많이 풀고 있습니다';
  } else if (maxDifficulty === HIGH) {
    difficultyComment = '상 난이도의 문제를 많이 풀고 있습니다';
  } else if (maxDifficulty === MIDDLE) {
    difficultyComment = '중 난이도의 문제를 많이 풀고 있습니다';
  } else {
    difficultyComment = '하 난이도의 문제를 많이 풀고 있습니다';
  }

  // 최종 문구 생성
  return `${studentName} 학생은 ${studyLevelComment} 학습 시간은 ${studyTimeComment}. 문제 풀이 수는 ${studyPerformsComment}. 정답률은 ${correctRateComment}. 그리고 ${difficultyComment}. 다양한 분석 데이터를 참조하여 실력 향상에 디딤돌로 삼기 바랍니다.`;
};
