import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { Box, Button, Container, Typography } from '@mui/material';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TOAST_MESSAGE, ToastMessage } from 'types/WhiteboardState';

import CustomSVGIcon from './CustomSVGIcon';
import styles from './Whiteboard.module.css';

const ffmpeg = new FFmpeg();
export interface RecordingResultData {
  gridThumbnailUrl: string;
  mainThumbnailUrl: string;
  videoBlob: Blob;
  videoUrl: string;
}

interface AudioVideoRecorderProps {
  canvasRef: React.RefObject<HTMLCanvasElement>;
  isAssessment: boolean;
  onRecordingComplete: (result: RecordingResultData) => void;
  onRecordingStart: () => void;
  showToastMessage: (message: ToastMessage) => void;
  updatePauseState: (paused: boolean) => void;
}
export interface AudioVideoRecorderRef {
  handleRestartRecording: () => void;
  startCapture: () => void;
}

type PermissionState = 'denied' | 'error' | 'granted' | 'prompt' | 'unknown';
const FPS = 15;
const MAX_RECORDING_TIME = 20 * 60; //20분

const GRID_THUMB_ITEM_WIDTH = 320;
const GRID_THUMB_ITEM_HEIGHT = 180;
const GRID_COLS = 5; // 그리드의 열 수
const GRID_THUMB_INTERVAL = 5 * 1000; //5초마다 그리드 썸네일 캡쳐

const TOOLTIP_INTERVAL = 3000;

const ButtonStyle = {
  '&.Mui-disabled': {
    '& > *': {
      color: 'gray',
    },
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
};

const AudioVideoRecorder = forwardRef<
  AudioVideoRecorderRef,
  AudioVideoRecorderProps
>((props, ref) => {
  const { t } = useTranslation();
  const {
    canvasRef,
    isAssessment,
    onRecordingComplete,
    onRecordingStart,
    showToastMessage,
    updatePauseState,
  }: AudioVideoRecorderProps = props;
  // 녹화 관련
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const [permissionStatus, setPermissionStatus] =
    useState<PermissionState>('unknown');
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [endRecording, setEndRecording] = useState<boolean>(false);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const audioSourceRef = useRef<MediaStreamAudioSourceNode | null>(null);
  const audioStreamRef = useRef<MediaStream | null>(null);
  const finalAudioStreamRef = useRef<MediaStream | null>(null);
  const [recordingTime, setRecordingTime] =
    useState<number>(MAX_RECORDING_TIME);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const fakeDrawIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // thumbnail 관련
  const mainThumbnailRef = useRef<string>('');
  const gridThumbnailsRef = useRef<string[]>([]);
  const captureIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // tooltip 관련
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<string>('');
  const [tooltipIconId, setTooltipIconId] = useState<string>('');
  const [uesdWhiteboard, setUsedWhiteboard] = useState<boolean>(false);
  const tooltipTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const tooltipDivRef = useRef<HTMLDivElement>(null);
  const buttonBoxRef = useRef<HTMLDivElement>(null);
  const stateDivRef = useRef<HTMLDivElement>(null);
  const timerDivRef = useRef<HTMLDivElement>(null);
  const startButtonRef = useRef<HTMLButtonElement>(null);
  const endButtonRef = useRef<HTMLButtonElement>(null);

  // test 관련
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);

  const recordingGuideElements = [
    {
      message: t('white_board.the_electronic_whiteboard_recording_has'),
      ref: stateDivRef,
    },
    { message: t('white_board.remaining_recording_time'), ref: timerDivRef },
    {
      icon: 'pause',
      message: t('white_board.recording_is_paused'),
      ref: startButtonRef,
    },
    {
      message: t('white_board.if_you_end_the_recording'),
      ref: endButtonRef,
    },
  ];

  const recordingStartElement = {
    message: '녹화 시작 버튼을 눌러 영상을 제작해보세요',
    ref: startButtonRef,
  };

  const recordingResumeElement = {
    icon: 'recording-restart',
    message: t('white_board.you_can_continue'),
    ref: startButtonRef,
  };

  const remainingTimeElement = {
    message: t('white_board.there_is_1_minute'),
    ref: endButtonRef,
  };

  const initRecorder = () => {
    mediaRecorderRef.current = null;
    chunksRef.current = [];
    setIsRecording(false);
    setIsPaused(false);
    setEndRecording(false);
    setDevices([]);
    setSelectedDeviceId('');
    setIsMuted(false);
    audioSourceRef.current = null;
    audioStreamRef.current = null;
    finalAudioStreamRef.current = null;
    setRecordingTime(MAX_RECORDING_TIME);
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = null;
    }
  };
  useImperativeHandle(ref, () => ({
    handleRestartRecording() {
      initRecorder();
      startCapture();
    },
    startCapture() {
      startCapture();
      handleTooltipMessage('start');
    },
  }));
  const showMessage = (
    element: HTMLElement,
    message: string,
    icon?: string,
  ) => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    setTooltipOpen(true);
    setTooltip(message);
    const tooltipDiv = tooltipDivRef.current as HTMLDivElement;
    const buttonBox = buttonBoxRef.current as HTMLDivElement;
    const rect = element.getBoundingClientRect();
    const wrapRect = buttonBox.getBoundingClientRect();
    const left = rect.left - wrapRect.left + rect.width / 2 - 14;
    const tooltipArrow = tooltipDiv.querySelector(
      '#tooltipArrow',
    ) as HTMLDivElement;
    tooltipArrow.style.left = `${left}px`;
    if (icon) {
      setTooltipIconId(icon);
    } else {
      setTooltipIconId('');
    }
  };

  useEffect(() => {
    const storedValue = localStorage.getItem('usedWhiteboard');
    if (storedValue === 'true') {
      setUsedWhiteboard(true);
    }
    // 시나리오가 전자칠판 뜨자마자 녹화가 시작되는걸로 바뀌어서 녹화 시작 전 안내 툴팁 주석처리
    // if (!uesdWhiteboard && recordingStartElement.ref.current) {
    //   showMessage(
    //     recordingStartElement.ref.current,
    //     recordingStartElement.message,
    //   );
    //   tooltipTimeoutRef.current = setTimeout(() => {
    //     setTooltipOpen(false);
    //   }, TOOLTIP_INTERVAL);
    // }
    return () => {
      initRecorder();
      if (fakeDrawIntervalRef.current) {
        clearInterval(fakeDrawIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const loadFFmpeg = async () => {
      await ffmpeg.load();
    };
    loadFFmpeg();
  }, []);

  useEffect(() => {
    updatePauseState(isPaused);
  }, [isPaused]);

  const checkMicrophonePermission = async () => {
    try {
      const permission = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });

      if (permission.state === 'granted') {
        setPermissionStatus('granted');
        getDevices();
      } else if (permission.state === 'denied') {
        setPermissionStatus('denied');
      } else {
        setPermissionStatus('prompt');
      }

      permission.onchange = () => {
        const changedState = permission.state as PermissionState;
        setPermissionStatus(changedState);
        if (changedState === 'granted') {
          getDevices();
        }
      };
    } catch (error) {
      alert('Failed to get microphone permission');
      setPermissionStatus('error');
    }
  };

  const getDevices = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      // console.warn('MediaDevices API is not supported in this browser.');
      return;
    }
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(
        device => device.kind === 'audioinput',
      );
      setDevices(audioDevices);
      if (audioDevices.length) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      }
      // console.log('Available audio devices:', audioDevices);
    } catch (err) {
      console.warn('Failed to get audio devices:', err);
    }
  };

  useEffect(() => {
    if (!isAssessment) {
      checkMicrophonePermission();
    }
  }, []);

  useEffect(() => {
    if (isRecording && recordingTime === 0) {
      stopCapture();
    }
  }, [recordingTime, isRecording]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  };

  const formatTimeToString = (sec: number) => {
    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    return t('white_board.minutes_seconds', { min: minutes, sec: seconds });
  };

  const updateRecordingTime = () => {
    // setRecordingTime(prevTime => (prevTime - 1 < 0 ? 0 : prevTime - 1));
    setRecordingTime(prevTime => {
      if (prevTime < 61 && remainingTimeElement.ref.current) {
        showMessage(
          remainingTimeElement.ref.current,
          remainingTimeElement.message,
        );
        tooltipTimeoutRef.current = setTimeout(() => {
          setTooltipOpen(false);
        }, TOOLTIP_INTERVAL);
      }
      return prevTime - 1 < 0 ? 0 : prevTime - 1;
    });
  };

  const startCapture = async () => {
    setDownloadUrl(null);
    setIsRecording(true);
    onRecordingStart();
    chunksRef.current = [];
    setRecordingTime(MAX_RECORDING_TIME);
    recordingIntervalRef.current = setInterval(updateRecordingTime, 1000);

    try {
      // 선택된 디바이스로부터 오디오 스트림 가져오기
      let audioStream;
      if (!isAssessment) {
        try {
          audioStream = await navigator.mediaDevices.getUserMedia({
            audio: {
              deviceId: selectedDeviceId
                ? { exact: selectedDeviceId }
                : undefined,
            },
          });
        } catch (err) {
          // console.warn('Failed to access audio input:', err);
          audioStream = null;
        }

        if (audioStream) {
          audioStreamRef.current = audioStream;
          const audioContext = new AudioContext();
          audioSourceRef.current =
            audioContext.createMediaStreamSource(audioStream);
          const destination = audioContext.createMediaStreamDestination();
          audioSourceRef.current.connect(destination);
          const finalAudioStream = destination.stream;
          finalAudioStreamRef.current = finalAudioStream;

          finalAudioStream
            .getAudioTracks()
            .forEach(track => (track.enabled = !isMuted));
        } else {
          finalAudioStreamRef.current = null;
        }
      } else {
        finalAudioStreamRef.current = null;
      }

      if (canvasRef.current) {
        const videoStream = canvasRef.current.captureStream(FPS);

        // 비디오와 오디오 스트림 합성
        const tracks = [...videoStream.getTracks()];
        if (finalAudioStreamRef.current) {
          tracks.push(...finalAudioStreamRef.current.getTracks());
        }
        const mixedStream = new MediaStream(tracks);

        const newMediaRecorder = new MediaRecorder(mixedStream, {
          mimeType: 'video/webm; codecs=vp9',
        });
        mediaRecorderRef.current = newMediaRecorder;

        newMediaRecorder.ondataavailable = (event: BlobEvent) => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        };

        newMediaRecorder.onstop = async () => {
          setEndRecording(true);
          if (chunksRef.current.length === 0) {
            // console.warn('No audio data available on stop');
            return;
          }

          const blob = new Blob(chunksRef.current, { type: 'video/webm' });
          // duration metadata 추가를 위해 ffmpeg으로 파일 복사. 복사 과정에서 metadata 자동 생성됨
          const fixedBlob = await fixWebmMetadata(blob);
          const videoUrl = URL.createObjectURL(fixedBlob);
          setDownloadUrl(videoUrl);

          chunksRef.current = [];
          if (audioSourceRef.current) audioSourceRef.current.disconnect();
          const gridThumbnailUrl = await generateGridThumbnail();
          onRecordingComplete({
            gridThumbnailUrl: gridThumbnailUrl,
            mainThumbnailUrl: mainThumbnailRef.current as string,
            videoBlob: fixedBlob,
            videoUrl: videoUrl,
          });

          if (tooltipTimeoutRef.current) {
            setTooltipOpen(false);
            clearTimeout(tooltipTimeoutRef.current);
          }
        };

        // 100ms 간격으로 chunks 생성
        newMediaRecorder.start(100);
        captureGridThumbnails();
        captureIntervalRef.current = setInterval(
          captureGridThumbnails,
          GRID_THUMB_INTERVAL,
        );

        // 캔버스에 입력이 없을 때 캡쳐가 시작되지 않아 1초 간격으로 입력 유지
        const updateCanvas = () => {
          if (canvasRef.current) {
            const context = canvasRef.current.getContext('2d');
            if (context) {
              context.fillStyle = 'rgba(0, 0, 0, 0.001)';
              context.fillRect(0, 0, 1, 1);
            }
          }
        };
        fakeDrawIntervalRef.current = setInterval(updateCanvas, 1000);
      }
    } catch (err) {
      // console.warn('An error occurred:', err);
      setIsRecording(false);
    }
  };

  const fixWebmMetadata = async (blob: Blob): Promise<Blob> => {
    await ffmpeg.writeFile('input.webm', await fetchFile(blob));
    await ffmpeg.exec(['-i', 'input.webm', '-c', 'copy', 'output.webm']);
    const data = await ffmpeg.readFile('output.webm');
    return new Blob([data], { type: 'video/webm' });
  };

  const stopCapture = () => {
    setIsRecording(false);
    setIsPaused(false);
    setIsMuted(false);
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = null;
    }
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    if (audioStreamRef.current) {
      audioStreamRef.current.getTracks().forEach(track => track.stop());
    }
    if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
      mediaRecorderRef.current.stream
        .getTracks()
        .forEach(track => track.stop());
    }
    if (captureIntervalRef.current) {
      clearInterval(captureIntervalRef.current);
    }
  };

  const pauseCapture = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'recording'
    ) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      if (recordingIntervalRef.current) {
        clearInterval(recordingIntervalRef.current);
      }
      if (captureIntervalRef.current) {
        clearInterval(captureIntervalRef.current);
      }
    }
  };

  const resumeCapture = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === 'paused'
    ) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);

      recordingIntervalRef.current = setInterval(updateRecordingTime, 1000);
      captureGridThumbnails();
      captureIntervalRef.current = setInterval(
        captureGridThumbnails,
        GRID_THUMB_INTERVAL,
      );
    }
  };

  const toggleMute = () => {
    if (permissionStatus !== 'granted') {
      showToastMessage(TOAST_MESSAGE.MIC_PERMISSION_DENIED);
      return;
    } else if (devices.length === 0) {
      showToastMessage(TOAST_MESSAGE.MIC_NOT_FOUND);
    }
    if (finalAudioStreamRef.current) {
      finalAudioStreamRef.current.getAudioTracks().forEach(track => {
        track.enabled = isMuted;
      });
      setIsMuted(!isMuted);
    }
  };

  const captureGridThumbnails = () => {
    if (!canvasRef.current) return;
    const tempCanvas = document.createElement('canvas');
    const tempContext = tempCanvas.getContext('2d');
    if (!tempContext) return;

    tempCanvas.width = GRID_THUMB_ITEM_WIDTH;
    tempCanvas.height = GRID_THUMB_ITEM_HEIGHT;
    tempContext.drawImage(
      canvasRef.current,
      0,
      0,
      GRID_THUMB_ITEM_WIDTH,
      GRID_THUMB_ITEM_HEIGHT,
    );
    gridThumbnailsRef.current.push(tempCanvas.toDataURL('image/png'));

    if (!mainThumbnailRef.current) {
      mainThumbnailRef.current = canvasRef.current.toDataURL('image/png');
    }
  };

  const generateGridThumbnail = async () => {
    const rows = Math.ceil(gridThumbnailsRef.current.length / GRID_COLS);
    const gridCanvas = document.createElement('canvas');
    const gridContext = gridCanvas.getContext('2d');
    if (!gridContext) return '';

    gridCanvas.width = GRID_THUMB_ITEM_WIDTH * GRID_COLS;
    gridCanvas.height = GRID_THUMB_ITEM_HEIGHT * rows;

    const imageLoadPromises = gridThumbnailsRef.current.map(
      (dataURL, index) => {
        return new Promise<void>(resolve => {
          const img = new Image();
          img.src = dataURL;
          const x = (index % GRID_COLS) * GRID_THUMB_ITEM_WIDTH;
          const y = Math.floor(index / GRID_COLS) * GRID_THUMB_ITEM_HEIGHT;

          img.onload = () => {
            gridContext.drawImage(
              img,
              x,
              y,
              GRID_THUMB_ITEM_WIDTH,
              GRID_THUMB_ITEM_HEIGHT,
            );
            resolve();
          };

          img.onerror = () => {
            // console.warn(`Failed to load image at index ${index}`);
            resolve();
          };
        });
      },
    );

    await Promise.all(imageLoadPromises);
    return gridCanvas.toDataURL('image/png');
  };

  const showGuideMessage = (index: number) => {
    if (index < recordingGuideElements.length) {
      const elementRef = recordingGuideElements[index].ref.current;
      if (elementRef) {
        showMessage(
          elementRef,
          recordingGuideElements[index].message,
          recordingGuideElements[index].icon,
        );
        tooltipTimeoutRef.current = setTimeout(() => {
          setTooltipOpen(false);
          showGuideMessage(++index);
        }, TOOLTIP_INTERVAL);
      }
    }
  };

  const downloadGridThumbnail = (dataUrl: string) => {
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = 'gridThumbnail.png';
    a.click();
  };

  const handleTooltipMessage = (recordingState: string) => {
    if (uesdWhiteboard) return;
    if (recordingState === 'start') {
      showGuideMessage(0);
    } else if (recordingState === 'pause') {
      if (recordingResumeElement.ref.current) {
        showMessage(
          recordingResumeElement.ref.current,
          recordingResumeElement.message,
          recordingResumeElement.icon,
        );
        tooltipTimeoutRef.current = setTimeout(
          () => setTooltipOpen(false),
          TOOLTIP_INTERVAL,
        );
      }
    }
  };

  return (
    <Container
      sx={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        left: 0,
        marginBottom: '12px',
        position: 'fixed',
        width: 'auto',
      }}
    >
      <Box
        ref={buttonBoxRef}
        sx={{
          backgroundColor: '#E0E0E0',
          borderRadius: '20px',
          display: 'flex',
          height: '54px',
          justifyContent: 'center',
          padding: '4px',
        }}
      >
        <Box
          ref={stateDivRef}
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            marginLeft: '20px',
            padding: '4px',
            width: '62px',
          }}
          tabIndex={0}
        >
          <CustomSVGIcon
            id={
              endRecording
                ? 'recording-done'
                : isRecording
                ? isPaused
                  ? 'recording-pause'
                  : 'recording-active'
                : 'recording-waiting'
            }
            sx={{ height: '10px', width: '10px' }}
          />
          <Typography
            sx={{ color: 'black', fontSize: '14px', marginLeft: '5px' }}
          >
            {endRecording
              ? t('white_board.standby')
              : isRecording
              ? isPaused
                ? t('white_board.standby')
                : t('white_board.recording')
              : t('white_board.waiting')}
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '8px 5px',
          }}
          tabIndex={0}
        >
          <Box
            ref={timerDivRef}
            sx={{
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
              borderRadius: '4px',
              display: 'flex',
              flexDirection: 'row',
              height: '24px',
              marginTop: '10px',
              width: '78px',
            }}
          >
            <CustomSVGIcon
              id="timer"
              sx={{ height: '18px', margin: '2px 4px', width: '18px' }}
            />
            <Typography
              aria-label={formatTimeToString(recordingTime)}
              sx={{
                color: 'black',
                fontSize: '14px',
                marginLeft: '2px',
              }}
            >
              {formatTime(recordingTime)}
            </Typography>
          </Box>
          <Typography sx={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.available_recording_time')}
          </Typography>
        </Box>
        <Button
          disabled={endRecording}
          onClick={() => {
            if (!isRecording) {
              startCapture();
              handleTooltipMessage('start');
            } else if (!isPaused) {
              pauseCapture();
              handleTooltipMessage('pause');
            } else {
              resumeCapture();
              handleTooltipMessage('resume');
            }
          }}
          ref={startButtonRef}
          sx={ButtonStyle}
        >
          <CustomSVGIcon
            id={
              endRecording
                ? 'recording-disable'
                : isRecording
                ? isPaused
                  ? 'recording-restart'
                  : 'pause'
                : 'recording-start'
            }
            sx={{
              height: '24px',
              width: '24px',
              ...(!endRecording && { marginTop: '4px' }),
            }}
          />
          <Typography sx={{ color: 'black', fontSize: '14px' }}>
            {endRecording
              ? ''
              : isRecording
              ? isPaused
                ? t('white_board.resume_recording')
                : t('white_board.pause')
              : t('white_board.start_recording')}
          </Typography>
        </Button>
        <Box
          sx={{ alignItems: 'center', display: 'flex', marginRight: '18px' }}
        >
          <Button
            disabled={endRecording}
            onClick={stopCapture}
            ref={endButtonRef}
            sx={{
              alignItems: 'center',
              backgroundColor: '#585F9C',
              border: '2px solid white',
              borderRadius: '2px',
              color: '#585F9C',
              display: 'flex',
              height: '24px',
              width: '74px',
            }}
          >
            <Typography sx={{ color: 'white', fontSize: '14px' }}>
              {t('white_board.stop_recording')}
            </Typography>
          </Button>
        </Box>
      </Box>

      {isRecording && !isAssessment && (
        <Box
          sx={{
            backgroundColor: '#E0E0E0',
            borderRadius: '20px',
            display: 'flex',
            height: '54px',
            justifyContent: 'center',
            marginLeft: '13px',
            width: '68px',
          }}
        >
          <Button onClick={toggleMute} sx={ButtonStyle}>
            <CustomSVGIcon
              id={isMuted ? 'mic-off' : 'mic-on'}
              sx={{
                color: selectedDeviceId ? 'black' : 'gray',
                height: '22px',
                width: '20px',
              }}
            />
            <Typography style={{ color: 'black', fontSize: '14px' }}>
              {t('white_board.voice_recording')}
            </Typography>
          </Button>
        </Box>
      )}
      <div
        className={styles.tooltip}
        id="tooltipDiv"
        ref={tooltipDivRef}
        style={{ display: tooltipOpen ? 'flex' : 'none' }}
      >
        <div
          style={{
            justifyContent: 'center',
          }}
        >
          {tooltipIconId && (
            <CustomSVGIcon
              id={tooltipIconId}
              sx={{
                height: '20px',
                marginBottom: '2px',
                marginRight: '2px',
                width: '20px',
              }}
            />
          )}
          <span style={{ fontSize: '14px' }}>{tooltip}</span>
        </div>
        <div className={styles.tooltipArrow} id="tooltipArrow"></div>
      </div>
      {/* 영상 다운로드가 필요할 때 주석 해제 */}
      {/* {downloadUrl && (
        <div style={{ marginTop: '10px' }}>
          <a href={downloadUrl} download="capture.webm">
            Download Video
          </a>
        </div>
      )} */}
    </Container>
  );
});

AudioVideoRecorder.displayName = 'AudioVideoRecorder';

export default AudioVideoRecorder;
