import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { configureAppStore } from 'store/configureStore';
import { RootState } from 'types/RootState';

import dummyBaseQuery from './dummyBaseQuery';

export const mockApiService = createApi({
  // }),
  baseQuery: dummyBaseQuery,
  // baseQuery: fetchBaseQuery({
  //   baseUrl: process.env.REACT_APP_API_URL,
  //   prepareHeaders: headers => {
  //     const store = configureAppStore();
  //     const state = store.getState() as RootState;
  //     const userId = state.user.user_id;
  //     const token = state.user.access_token.token;
  //     if (token) {
  //       headers.set('Authorization', `${token}`);
  //     }
  //     if (userId) {
  //       headers.set('uuid', userId);
  //     }
  //     headers.set('Content-Type', 'application/json');
  //     return headers;
  //   },
  endpoints: () => ({}),
  reducerPath: 'mock_api',
});
