import { useQuery } from '@tanstack/react-query';
import { getTeacher } from 'apis/aidt-teacher';
import { useSelector } from 'react-redux';
import { GetTeacherResponse } from 'schemas/aidt-teacher';
import { BaseAidtDto } from 'schemas/base';
import { RootState } from 'types';

export function useTeacher({ data, headers }: BaseAidtDto) {
  const user = useSelector((state: RootState) => state.user);

  return useQuery<GetTeacherResponse, Error>({
    enabled:
      !!data.user_id &&
      !!data.access_token.access_id &&
      !!data.access_token.token &&
      !!headers['Partner-ID'] &&
      user.user_type === 'T',
    queryFn: () => getTeacher({ data, headers }),
    queryKey: ['teacher', { data, headers }],
  });
}
