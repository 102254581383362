import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ContentTable } from 'schemas/learning';
import { ContentTableState } from 'types/ContentTableState';
const initialState: ContentTableState = {
  contentTables: [],
};

const contentTableSlice = createSlice({
  initialState,
  name: 'contentTable',
  reducers: {
    setContentTables: (
      state: ContentTableState,
      action: PayloadAction<ContentTable[]>,
    ) => {
      state.contentTables = action.payload;
    },
  },
});

export const { setContentTables } = contentTableSlice.actions;

export default contentTableSlice.reducer;
