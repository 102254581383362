import {
  StoreEnhancer,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import evaluationReducer from 'app/pages/AssessmentDiagnosis/evaluationSlice';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import { aidtService } from 'services/aidt';
import { apiService } from 'services/api';
import { assignmentService } from 'services/assignment';
import { mockApiService } from 'services/mockApi';
import { problemService } from 'services/problem';
import { teacherAssessmentService } from 'services/teacher-assessment';
import { userService } from 'services/user';
import loadingReducer from 'store/slices/loadingSlice';
import noticeReducer from 'store/slices/noticeSlice';

import { createReducer } from './reducers';
import ContentTableReducer from './slices/contentTableSlice';
import notificationSlice from './slices/notificationSlice';
import StudyReducer from './slices/studySlice';
import userSlice from './slices/userSlice';
import whiteboardReducer from './slices/whiteboardSlice';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [
    sagaMiddleware,
    apiService.middleware,
    aidtService.middleware,
    teacherAssessmentService.middleware,
    mockApiService.middleware,
    userService.middleware,
    assignmentService.middleware,
    problemService.middleware,
  ];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    devTools: process.env.REACT_APP_NODE_ENV !== 'production',
    enhancers,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middlewares),
    ],
    reducer: combineReducers({
      // add reducers here
      [aidtService.reducerPath]: aidtService.reducer,
      [apiService.reducerPath]: apiService.reducer,
      [assignmentService.reducerPath]: assignmentService.reducer,
      contentTable: ContentTableReducer,
      evaluation: evaluationReducer,
      // api: apiReducer,
      loading: loadingReducer,
      [mockApiService.reducerPath]: mockApiService.reducer,
      notice: noticeReducer,
      notification: notificationSlice,
      [problemService.reducerPath]: problemService.reducer,
      study: StudyReducer,
      [teacherAssessmentService.reducerPath]: teacherAssessmentService.reducer,
      user: userSlice,
      [userService.reducerPath]: userService.reducer,
      whiteboard: whiteboardReducer,
    }),
  });

  return store;
}
