import { Box, Stack, Typography } from '@mui/material';
import { useDescendingStudents } from 'queries/use-descending-students';
import { useStudentNames } from 'queries/use-student-names';
import { useTeacher } from 'queries/use-teacher';
import { useTeacherClassMembers } from 'queries/use-teacher-class-members';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetStudentNameQuery } from 'services/aidt-student';
import { RootState } from 'types';

export default function DescendingStudentsList() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 선생님 학급 구성원 조회
  const teacherClassMembersQuery = useTeacherClassMembers({
    data: {
      access_token: user.access_token,
      class_code: teacherQuery.data?.class_info?.[0].class_code,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 최근에 학습한 2개 소단원에서 학습 단계가 계속 하락하는 학생 목록 조회
  const descendingStudentsQuery = useDescendingStudents({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      fetchingParts: [
        'LEARNING_LEVEL',
        'PROBLEM_SOLVING_COUNT',
        'CORRECT_RATE',
        'LEARNING_TIME',
      ],
      learningSysId: parseInt(subsectionId as string),
      studentIds: teacherClassMembersQuery.data?.map(member => member.user_id),
    },
  });

  // 학습단계 학생 성명 리스트 조회
  const learningLevelStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: descendingStudentsQuery.data?.learningLevel,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 문제풀이수 학생 성명 리스트 조회
  const problemSolvingCountStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: descendingStudentsQuery.data?.problemSolvingCount,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 정답률 학생 성명 리스트 조회
  const correctRateStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: descendingStudentsQuery.data?.correctRate,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학습시간 학생 성명 리스트 조회
  const learningTimeStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: descendingStudentsQuery.data?.learningTime,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  const students = [
    {
      list:
        learningLevelStudentNamesQuery.data?.map(user => user.user_name) || [],
      title: 'common.learning_stage',
    },
    {
      list: problemSolvingCountStudentNamesQuery.data?.map(
        user => user.user_name,
      ),
      title: 'teacher_dashboard.number_of_problems',
    },
    {
      list:
        correctRateStudentNamesQuery.data?.map(user => user.user_name) || [],
      title: 'common.accuracy_rate',
    },
    {
      list:
        learningTimeStudentNamesQuery.data?.map(user => user.user_name) || [],
      title: 'teacher_dashboard.study_time',
    },
  ];

  return (
    <Stack
      aria-label="2개 소단원 연속하락 학생 목록입니다. 학습단계, 문제풀이수, 정답률, 학습시간의 각 항목별로 해당하는 학생의 이름이 총 3명까지 나열됩니다."
      borderRadius=".75rem"
      gridColumn="span 2"
      sx={{
        backgroundColor: '#FFF5D7',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".25rem"
        paddingBottom=".375rem"
        paddingTop=".125rem"
      >
        <Box component="img" height={32} src="/icons/siren.svg" width={36} />
        <Typography
          color="#FF0000"
          fontSize=".9375rem"
          fontWeight={700}
          variant="body1"
        >
          {t('teacher_dashboard.list_of_consecutive')}
        </Typography>
      </Stack>
      <Stack
        alignItems="stretch"
        borderRadius=".75rem"
        direction="column"
        flex={1}
        gap={1}
        justifyContent="space-between"
        padding=".625rem"
        sx={{
          backgroundColor: '#E7E3FC',
          border: '3px solid #BFC7E3',
        }}
      >
        {students.map((item, index) => {
          return (
            <Stack
              alignItems="center"
              aria-label={`${t(item.title)}: ${item.list?.join(', ')}`}
              direction="row"
              gap=".375rem"
              justifyContent="stretch"
              key={index}
              tabIndex={0}
            >
              <Box sx={{ fontSize: '.75rem', fontWeight: 700 }} width={56}>
                {t(item.title)}
              </Box>
              <Stack
                alignItems="center"
                borderRadius=".375rem"
                direction="row"
                flex={1}
                gap={1}
                height={24}
                paddingX=".25rem"
                sx={{
                  backgroundColor: '#FFF',
                  color: item.list && item.list.length > 0 ? '#000' : '#999',
                  fontSize: '.75rem',
                  fontWeight: 700,
                }}
              >
                {item.list && item.list.length > 0
                  ? item.list?.map((data, dataIndex) => {
                      return (
                        <Box key={`list_${dataIndex}`}>{data ? data : '-'}</Box>
                      );
                    })
                  : '아직 데이터가 부족합니다.'}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
