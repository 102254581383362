import { useQuery } from '@tanstack/react-query';
import { getMVP } from 'apis/dashboard';
import {
  GetCumulativeAccuracyDataDto,
  GetMvpDataResponse,
  MvpData,
} from 'schemas/student-dashboard';

export function useMvp({ headers, params }: GetCumulativeAccuracyDataDto) {
  return useQuery<GetMvpDataResponse, Error, MvpData>({
    enabled: params.classUuids.length > 0,
    queryFn: () => getMVP({ headers, params }),
    queryKey: ['mvp_data'],
    select: data => data.data,
  });
}
