import { useQuery } from '@tanstack/react-query';
import { getLearningSystem } from 'apis/learning';
import {
  GetLearningSystemDto,
  GetLearningSystemResponse,
  LearningSystem,
} from 'schemas/learning';

export function useLearningSystem({ headers, params }: GetLearningSystemDto) {
  return useQuery<GetLearningSystemResponse, Error, LearningSystem>({
    enabled: !!params.learningSysId,
    queryFn: () => getLearningSystem({ headers, params }),
    queryKey: ['learning_system', { headers, params }],
    retry: 2,
    retryDelay: 1000,
    select: data => data.data,
  });
}
