import { useQuery } from '@tanstack/react-query';
import { getClassLearningHistory } from 'apis/dashboard';
import {
  ClassLearningHistory,
  GetClassLeaningHistoryResponse,
  GetClassLearningHistoryDto,
} from 'schemas/teacher-dashboard';

export function useClassLearningHistory({
  headers,
  params,
}: GetClassLearningHistoryDto) {
  return useQuery<
    GetClassLeaningHistoryResponse,
    Error,
    ClassLearningHistory[]
  >({
    enabled: params.classUuids.length > 0 && !!params.learningSysId,
    queryFn: () => getClassLearningHistory({ headers, params }),
    queryKey: ['class_learning_history', { headers, params }],
    select: data => data.data,
  });
}
