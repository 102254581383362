import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { StudyState } from 'types/StudyState';
const initialState: StudyState = {
  curriculumId: '',
};

const studySlice = createSlice({
  initialState,
  name: 'study',
  reducers: {
    setCurriculumId: (state: StudyState, action: PayloadAction<string>) => {
      state.curriculumId = action.payload;
    },
  },
});

export const { setCurriculumId } = studySlice.actions;

export default studySlice.reducer;
