/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const ChapterPage = lazyLoad(
  () => import('./index'),
  module => module.ChapterPage,
);
