import { useQuery } from '@tanstack/react-query';
import { getTeacherWeakChapters } from 'apis/dashboard';
import {
  GetTeacherWeakChaptersDto,
  GetTeacherWeakChaptersResponse,
  TeacherWeakChapter,
} from 'schemas/teacher-dashboard';

export function useTeacherWeakChapters({
  data,
  headers,
}: GetTeacherWeakChaptersDto) {
  return useQuery<GetTeacherWeakChaptersResponse, Error, TeacherWeakChapter[]>({
    enabled:
      !!headers.uuid &&
      !!data.learning_sys_id &&
      !!data.learning_sys_id &&
      !!data.uuids,
    queryFn: () => getTeacherWeakChapters({ data, headers }),
    queryKey: ['teacher_weak_chapters', { data, headers }],
    retry: false,
    select: data => data.data,
  });
}
