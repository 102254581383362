/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const NoticePage = lazyLoad(
  () => import('./index'),
  module => module.NoticePage,
);
