export const NOTIFICATION_MESSAGES_KOR_CONSTANTS = [
  '', //0번째 인덱스는 사용하지 않습니다.
  '질문에 답변이 달렸습니다.',
  '새로운 공지사항이 올라왔습니다.',
  '아직 기본 문제학습을 풀지 않았습니다.',
  '아직 확인 문제학습을 풀지 않았습니다.',
  '아직 피드백 문제를 풀지 않았습니다.',
  '과제를 풀지 않았습니다. 과제를 완료해주세요.',
  '새로운 평가가 등록되었습니다.',
  '5분 뒤에 평가가 시작됩니다.',
  '새로운 평가가 시작되었습니다.',
  '선생님이 과제를 출제했습니다.',
];

export const NOTIFICATION_AVATAR_CONSTANTS = [
  '', //0번째 인덱스는 사용하지 않습니다.
  '/images/profile/female_student.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
  '/icons/assessment.svg',
  '/images/profile/female_teacher.svg',
  '/images/profile/female_teacher.svg',
];

export const generateNotificationUrl = (
  info1: null | number,
  info2: null | number,
  info3: null | number,
  infoString: null | string,
  action: number,
) => {
  switch (action) {
    case 1:
      return `/question/${info1}`;
    case 2:
      return `/notice/${info1}`;
    case 3:
      return `/questions/${info1}/basic`;
    case 4:
      return `/questions/${info1}/confirm`;
    case 5:
      return `/questions/${info1}/feedback`;
    case 6:
      return `/questions/${info1}/assignment`;
    case 7:
      if (infoString === '학력진단평가') {
        return `/assessment/diagnostic`;
      } else {
        return `/`;
      }
    case 8:
      if (infoString === '학력진단평가') {
        return `/assessment/diagnostic`;
      } else {
        return `/`;
      }
    case 9:
      if (infoString === '학력진단평가') {
        return `/assessment/diagnostic`;
      } else {
        return `/`;
      }
    case 10:
      return `/questions/${info2}/assignment`;
    default:
      return '/';
  }
};

export const generateNotificationInfoString = (
  infoString: null | string,
  action: number,
) => {
  switch (action) {
    case 3:
      return `${infoString} 단원의 기본문제를 풀어주세요.`;
    case 4:
      return `${infoString} 단원의 확인문제를 풀어주세요.`;
    case 5:
      return `${infoString} 단원의 피드백문제를 풀어주세요.`;
    case 6:
      return `${infoString} 단원의 과제를 풀어주세요.`;
    case 10:
      return `${infoString} 단원에서 과제가 출제되었습니다.`;
    default:
      return infoString;
  }
};
