import { useQuery } from '@tanstack/react-query';
import { getAchievementStandard } from 'apis/dashboard';
import {
  GetAchievementStandardDto,
  GetAchievementStandardResponse,
} from 'schemas/student-dashboard';

export function useAchievementStandard({
  headers,
  params,
}: GetAchievementStandardDto) {
  return useQuery<GetAchievementStandardResponse, Error>({
    enabled: !!params.learning_sys_id && !!headers.role && !!headers.uuid,
    queryFn: () => getAchievementStandard({ headers, params }),
    queryKey: ['achievement_standard', { headers, params }],
  });
}
