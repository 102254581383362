import { useQuery } from '@tanstack/react-query';
import { getTeacherStatistics } from 'apis/dashboard';
import {} from 'schemas/assignment';
import { StatisticItem } from 'schemas/teacher-dashboard';
import {
  GetTeacherStatisticsDto,
  GetTeacherStatisticsResponse,
} from 'schemas/teacher-dashboard';

export function useTeacherStatistics({
  headers,
  params,
}: GetTeacherStatisticsDto) {
  return useQuery<GetTeacherStatisticsResponse, Error, StatisticItem[]>({
    enabled:
      !!params.learningSysId &&
      !!params.classUuids &&
      !!headers.role &&
      !!headers.uuid,
    queryFn: () => getTeacherStatistics({ headers, params }),
    queryKey: ['teacher_statistics', { headers, params }],
    retry: false,
    select: data => data.data.statisticItems,
  });
}
