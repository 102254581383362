import { Box, Stack, Typography } from '@mui/material';
import { useTeacher } from 'queries/use-teacher';
import { useTeacherClassMembers } from 'queries/use-teacher-class-members';
import { useTeacherWeakChapters } from 'queries/use-teacher-weak-chapters';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useValidateUuidQuery } from 'services/user';
import { RootState } from 'types';
import { calculateCorrectRate } from 'utils/calculate';

export default function TeacherWeakChaptersTable() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);
  const { subsectionId } = useParams();

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 선생님 학급 구성원 조회
  const teacherClassMembersQuery = useTeacherClassMembers({
    data: {
      access_token: user.access_token,
      class_code: teacherQuery.data?.class_info?.[0].class_code,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학급 취약단원순 난이도별 분석 조회
  const teacherWeakChaptersQuery = useTeacherWeakChapters({
    data: {
      learning_sys_id: Number(subsectionId),
      uuids: teacherClassMembersQuery.data?.map(member => member.user_id) || [],
    },
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
  });

  // 테이블 데이터
  const headers = [
    'common.highest',
    'common.high',
    'common.medium',
    'common.low',
    'common.sum',
  ];
  const subHeaders = [
    'common.problem',
    'teacher_dashboard.incorrect',
    'common.accuracy_rate',
  ];
  const rowData = teacherWeakChaptersQuery.data
    ? [
        ...teacherWeakChaptersQuery.data.map(chapter => {
          return {
            award: [
              ...chapter.HIGH,
              calculateCorrectRate(chapter.HIGH[0], chapter.HIGH[1]),
            ],
            best: [
              ...chapter.HIGHEST,
              calculateCorrectRate(chapter.HIGHEST[0], chapter.HIGHEST[1]),
            ],
            middle: [
              ...chapter.MIDDLE,
              calculateCorrectRate(chapter.MIDDLE[0], chapter.MIDDLE[1]),
            ],
            sum: [
              ...chapter.SUM,
              calculateCorrectRate(chapter.SUM[0], chapter.SUM[1]),
            ],
            title: chapter.chapterName,
            under: [
              ...chapter.LOW,
              calculateCorrectRate(chapter.LOW[0], chapter.LOW[1]),
            ],
          };
        }),

        {
          award: [
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.HIGH[0], 0),
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.HIGH[1], 0),
            calculateCorrectRate(
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.HIGH[0], 0),
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.HIGH[1], 0),
            ),
          ],
          best: [
            teacherWeakChaptersQuery.data?.reduce(
              (a, b) => a + b.HIGHEST[0],
              0,
            ),
            teacherWeakChaptersQuery.data?.reduce(
              (a, b) => a + b.HIGHEST[1],
              0,
            ),
            calculateCorrectRate(
              teacherWeakChaptersQuery.data?.reduce(
                (a, b) => a + b.HIGHEST[0],
                0,
              ),
              teacherWeakChaptersQuery.data?.reduce(
                (a, b) => a + b.HIGHEST[1],
                0,
              ),
            ),
          ],
          middle: [
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.MIDDLE[0], 0),
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.MIDDLE[1], 0),
            calculateCorrectRate(
              teacherWeakChaptersQuery.data?.reduce(
                (a, b) => a + b.MIDDLE[0],
                0,
              ),
              teacherWeakChaptersQuery.data?.reduce(
                (a, b) => a + b.MIDDLE[1],
                0,
              ),
            ),
          ],
          sum: [
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.SUM[0], 0),
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.SUM[1], 0),
            calculateCorrectRate(
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.SUM[0], 0),
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.SUM[1], 0),
            ),
          ],
          title: '현재까지 학습한 전체 누적데이터',
          under: [
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.LOW[0], 0),
            teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.LOW[1], 0),
            calculateCorrectRate(
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.LOW[0], 0),
              teacherWeakChaptersQuery.data?.reduce((a, b) => a + b.LOW[1], 0),
            ),
          ],
        },
      ]
    : [
        {
          award: [0, 0, 0],
          best: [0, 0, 0],
          middle: [0, 0, 0],
          sum: [0, 0, 0],
          title: '현재까지 학습한 전체 누적데이터',
          under: [0, 0, 0],
        },
      ];

  return (
    <Stack
      aria-label="취약단원순 난이도별 분석 테이블입니다."
      sx={{
        backgroundColor: '#F9F3FF',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '.75rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        취약단원순 난이도별 분석
      </Typography>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".375rem" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              취약단원순 난이도별 분석 테이블
            </caption>
            <tbody style={{ borderRadius: '10px' }}>
              <tr
                style={{
                  backgroundColor: '#E7E3FC',
                }}
              >
                <Box
                  colSpan={1}
                  component="th"
                  rowSpan={2}
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 600,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  취약
                  <br />
                  단원순
                </Box>
                <Box
                  colSpan={4}
                  component="th"
                  rowSpan={2}
                  scope="colgroup"
                  sx={theme => ({
                    fontSize: '.75rem',
                    fontWeight: 600,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {t('teacher_dashboard.subunit_name')}
                </Box>
                {headers.map((header, index) => (
                  <Box
                    colSpan={3}
                    component="th"
                    key={index}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 600,
                      height: '1.5rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                    })}
                    tabIndex={0}
                  >
                    {t(header)}
                  </Box>
                ))}
              </tr>

              <tr>
                {headers.map((data, index) => (
                  <React.Fragment key={index}>
                    {subHeaders.map((subHeader, i) => (
                      <Box
                        component="th"
                        key={i}
                        scope="col"
                        sx={theme => ({
                          backgroundColor: '#F9F3FF',
                          border: '1px solid #BFC7E3',
                          fontSize: '.75rem',
                          fontWeight: 600,
                          height: '1.5rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                      >
                        {t(subHeader)}
                      </Box>
                    ))}
                  </React.Fragment>
                ))}
              </tr>

              {rowData?.map((data, index) => (
                <tr key={index} tabIndex={0}>
                  <Box
                    colSpan={1}
                    component="td"
                    sx={theme => ({
                      backgroundColor: '#F9F3FF',
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 700,
                      height: '1.75rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.5625rem',
                      },
                    })}
                    tabIndex={0}
                  >
                    {rowData.length === index + 1
                      ? t('teacher_dashboard.cumulation')
                      : index + 1}
                  </Box>
                  <Box
                    colSpan={4}
                    component="td"
                    paddingX=".25rem"
                    sx={theme => ({
                      backgroundColor: '#FFF',
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 700,
                      height: '1.75rem',
                      textAlign: 'left',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.5625rem',
                      },
                    })}
                    tabIndex={0}
                  >
                    {data.title}
                  </Box>
                  {Object.keys(data).map((key, index) => {
                    if (key === 'title') {
                      return null;
                    }

                    return (
                      <React.Fragment key={index}>
                        {data[key].map((item, i) => (
                          <Box
                            colSpan={1}
                            component="td"
                            key={i}
                            sx={theme => ({
                              backgroundColor: '#FFF',
                              border: '1px solid #BFC7E3',
                              fontSize: '.75rem',
                              fontWeight: 700,
                              height: '1.75rem',
                              [theme.breakpoints.down('md')]: {
                                fontSize: '.625rem',
                                height: '1.5625rem',
                              },
                            })}
                            tabIndex={0}
                          >
                            {item}
                          </Box>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </Box>
    </Stack>
  );
}
