import { Alert, Box, Snackbar, Stack } from '@mui/material';
import ClassAchievementLevelTable from 'app/components/Dashboard/ClassAchievementLevelTable';
import ClassLearningHistoryTable from 'app/components/Dashboard/ClassLearningHistoryTable';
import DescendingStudentsList from 'app/components/Dashboard/DescendingStudentsList';
import DueAssignmentDialog from 'app/components/Dashboard/DueAssignmentDialog';
import Profile from 'app/components/Dashboard/Profile';
import TeacherStatisticsChart from 'app/components/Dashboard/TeacherStatisticsChart';
import TeacherStatisticsComparisonChart from 'app/components/Dashboard/TeacherStatisticsComparisonChart';
import TeacherWeakChaptersTable from 'app/components/Dashboard/TeacherWeakChaptersTable';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useLearningSystem } from 'queries/use-learning-system';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';

import { DialogData } from '../../../types/DialogData';
import CustomDialog from '../Common/CustomDialog';
import SpeedDialTooltipOpen from '../Common/SpeedDialTooltipOpen';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// 차트 설정
export const chartOptions = {
  barThickness: 20,
  elements: {
    bar: {
      borderColor: 'white',
      borderWidth: 3,
    },
  },
  indexAxis: 'y' as const,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      max: 10,
      min: 0,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

const TeacherDashboard = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<DialogData>({
    contents: '',
    title: '',
  });
  const [maxWidth, setMaxWidth] = useState<
    'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  >('md');

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState<
    'error' | 'info' | 'success' | 'warning' | undefined
  >('success');

  const user = useSelector((state: RootState) => state.user);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleSnackBarClose = reason => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  return (
    <Box component="main">
      <Stack
        alignItems="stretch"
        component="section"
        gap=".875rem"
        sx={{ height: '100%' }}
      >
        <Box
          display="grid"
          gap={1}
          gridTemplateColumns="repeat(9, minmax(0, 1fr))"
        >
          {/* 프로필 */}
          <Profile />

          <Stack
            borderRadius=".75rem"
            display="grid"
            gridColumn="span 8"
            gridTemplateColumns="repeat(8, minmax(0, 1fr))"
            sx={{
              backgroundColor: '#F8F8F8',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            }}
          >
            {/* 학급 목표 결과 분석 */}
            <TeacherStatisticsChart />

            {/* 2개 소단원 연속하락 리스트 */}
            <DescendingStudentsList />
          </Stack>
        </Box>

        {/* 학급 현재 소단원별 학습결과 내역 */}
        <ClassLearningHistoryTable />

        {/* 학급 소단원별 학습결과 비교 그래프 */}
        <TeacherStatisticsComparisonChart />

        {/* 취약단원순 난이도별 분석 */}
        <TeacherWeakChaptersTable />

        {/* 학급 성취기준별 성취수준 */}
        {learningSystemQuery.data?.type === 'SECTION' &&
          user.user_school.includes('고등학교') && (
            <ClassAchievementLevelTable />
          )}
      </Stack>
      <CustomDialog
        customContentStyle={{
          padding: maxWidth === 'md' ? '0 !important' : 'initial',
        }}
        dialogData={dialogData}
        handleClose={handleDialogClose}
        maxWidth={maxWidth}
        open={dialogOpen}
      />
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={6000}
        onClose={handleSnackBarClose}
        open={snackBarOpen}
      >
        <Alert
          onClose={handleSnackBarClose}
          severity={severity}
          variant="filled"
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TeacherDashboard;
