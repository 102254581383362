import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { CircleCheck } from 'lucide-react';
import * as React from 'react';
import useReminderDialogStore from 'stores/use-assignment-reminder-dialog-store';

export default function ReminderDialog() {
  const { message, open, toggleDialog } = useReminderDialogStore(state => ({
    message: state.message,
    open: state.open,
    toggleDialog: state.toggleDialog,
  }));

  return (
    <Dialog onOpenChange={(open: boolean) => toggleDialog(open)()} open={open}>
      <DialogContent className="p-0 overflow-hidden border-none max-w-[30.625rem] pt-20 pb-28 flex items-center justify-center">
        <DialogHeader>
          <DialogTitle
            aria-label={message}
            className="flex flex-col items-center justify-center text-xl font-medium gap-1.5"
            tabIndex={0}
          >
            <CircleCheck className="size-16 text-[#77E665]" />
            {message}
          </DialogTitle>
          <DialogDescription className="sr-only">
            <CircleCheck className="size-16 text-[#77E665]" />
            {message}
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
