// katex style
import { Alert } from '@mui/material';
import 'katex/dist/katex.min.css';
import { LucideX } from 'lucide-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import AppRoutes from 'routes';
import { getSubdomain } from 'services/apiService';
import { getStudentInfo, getTeacherInfo } from 'services/apiServiceAidt';
import { MockData } from 'services/dummyData';
import { useLazyGetContentTableQuery } from 'services/learning';
import { setContentTables } from 'store/slices/contentTableSlice';
import {
  getGradeFromSubdomain,
  getSemesterFromSubdomain,
} from 'utils/studyUtils';

import { LOGIN_DOMAIN } from '../constants';
import authService from '../services/authService';
import {
  setAccessToken,
  setLectureCode,
  setPartnerId,
  setStudentInfo,
  setTeacherInfo,
  setUserId,
  setUserSemester,
  setUserStatus,
  setUserType,
} from '../store/slices/userSlice';
import { RootState } from '../types';
import { Header } from './components/Common/header/Header';
import WhiteboardModal from './components/Whiteboard/WhiteboardModal';

export function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const [authorized, setAuthorized] = useState(false);
  const [authError, setAuthError] = useState(false);
  const access_token = useSelector(
    (state: RootState) => state.user.access_token,
  );
  const user_id = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const userSchool = useSelector((state: RootState) => state.user.user_school);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);
  const studentInfo = useSelector((state: RootState) => state.user.studentInfo);
  const teacherInfo = useSelector((state: RootState) => state.user.teacherInfo);

  const [triggerGetContentTable, { data: contentTableResponse }] =
    useLazyGetContentTableQuery();

  //첫번째 실행
  const kerisAuth = async () => {
    if (userType === 'S') {
      const studentInfo = await getStudentInfo(
        access_token.token,
        access_token.access_id,
        user_id,
        partnerId,
      );
      if (studentInfo.school_id) {
        dispatch(setStudentInfo(studentInfo));
        dispatch(setLectureCode(studentInfo.schedule_info[0].lecture_code));
      } else {
        const pathname = window.location.pathname;
        if (pathname !== '/login') {
          alert('해당 사용자 정보를 찾을 수 없습니다.');
          window.location.href = LOGIN_DOMAIN;
        }
      }
    } else {
      const teacherInfo = await getTeacherInfo(
        access_token.token,
        access_token.access_id,
        user_id,
        partnerId,
      );
      if (teacherInfo.school_id) {
        dispatch(setTeacherInfo(teacherInfo));
        dispatch(setLectureCode(teacherInfo.schedule_info[0].lecture_code));
      } else {
        const pathname = window.location.pathname;
        if (pathname !== '/login') {
          alert('해당 사용자 정보를 찾을 수 없습니다.');
          window.location.href = LOGIN_DOMAIN;
        }
      }
    }
  };

  useEffect(() => {
    if (
      access_token &&
      user_id &&
      partnerId &&
      userType &&
      access_token.token !== 'MOCK-PENETRATION'
    ) {
      kerisAuth();
    }
  }, [access_token, user_id, partnerId, userType]);

  useEffect(() => {
    // Load from local storage
    const token = JSON.parse(localStorage.getItem('access_token') || '{}');
    const userId = localStorage.getItem('user_id');
    const userType = localStorage.getItem('user_type');
    const userStatus = localStorage.getItem('user_status');

    const subDomain = getSubdomain();
    if (subDomain === 'm-1-1') {
      dispatch(setPartnerId(process.env.REACT_APP_M11_PARTNER_ID ?? ''));
    } else if (subDomain === 'h-1-1') {
      dispatch(setPartnerId(process.env.REACT_APP_H11_PARTNER_ID ?? ''));
    } else if (subDomain === 'h-1-2') {
      dispatch(setPartnerId(process.env.REACT_APP_H12_PARTNER_ID ?? ''));
    }

    if (token) {
      dispatch(setAccessToken(token));
    }

    if (userId) {
      dispatch(setUserId(userId));
    }

    if (userType) {
      dispatch(setUserType(userType as 'S' | 'T'));
    }

    if (userStatus) {
      dispatch(setUserStatus(userStatus as 'E' | 'I' | 'O'));
    }

    if (
      token.token === 'MOCK-PENETRATION' &&
      token.access_id === 'MOCK-PENETRATION'
    ) {
      if (userId && userId.startsWith('mock-penetration-id')) {
        if (userType === 'S') {
          dispatch(setStudentInfo(MockData.STUDENT_ALL));
        } else if (userType === 'T') {
          dispatch(setTeacherInfo(MockData.TEACHER_ALL));
        }
        setAuthorized(true);
      }
    }
  }, [dispatch]);

  //두번째 실행, 우리쪽 /user/authorize에 요청한다.
  const authUser = async () => {
    if (!user_id || !userType || !classInfo || !userSchool || !partnerId)
      return;
    const info = userType === 'S' ? studentInfo : teacherInfo;
    if (!info?.schedule_info) return;
    const authResponse = await authService.authorize({
      accessToken: access_token,
      classInfo,
      partnerId,
      role: userType,
      schedule_info: info.schedule_info,
      school_name: userSchool,
      semester: getSemesterFromSubdomain(getSubdomain()),
      uuid: user_id,
    });
    if (authResponse?.data) {
      dispatch(setUserSemester(authResponse.data.learning_map.semester_id));
      setAuthorized(true);
    } else {
      setAuthError(true);
    }
  };

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname !== '/login') {
      authUser();
    }
  }, [user_id, userType, classInfo, userSchool, partnerId]);

  //세번째 실행
  useEffect(() => {
    if (!authorized) return;
    const grade = getGradeFromSubdomain(getSubdomain());
    if (!grade) return;
    triggerGetContentTable({
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: user_id,
      },
      params: {
        grade,
      },
    });
  }, [user_id, classInfo, authorized]);

  useEffect(() => {
    if (contentTableResponse) {
      dispatch(setContentTables(contentTableResponse.data));
    }
  }, [contentTableResponse]);

  // const EvaluationRoutePage = () => {
  //   const isStart = useSelector((state: RootState) => state.evaluation.isStart); // evaluationSlice의 isStart 상태를 가져옵니다.

  //   useBlocker('정말로 나가시겠습니까?', isStart);

  //   // TODO : Blocker 처리 후 어떻게 해야 할지?

  //   return <AssessmentDiagnosisPage />;
  // };
  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="매튜 - AI 디지털 교과서"
        htmlAttributes={{ lang: i18n.language }}
        titleTemplate="%s"
      >
        <meta content="매튜 AI디지털교과서" name="description" />
      </Helmet>
      {authError ? (
        <>
          <Alert icon={<LucideX />} severity="error">
            {userType === 'S'
              ? `아직 ${studentInfo?.user_class} 학급 정보가 구성되지 않았습니다. 해당 반의 선생님이 먼저 로그인 해주셔야합니다.`
              : `현재 접속한 계정으로 ${teacherInfo?.schedule_info[0]?.classroom_name} 학급 정보를 자동생성 할 수 없습니다. 계속
            문제가 발생하면 관리자에게 문의하세요.`}
          </Alert>
          {process.env.NODE_ENV === 'development' && (
            <>
              <Header />
              <div className="p-4 text-center">
                development 모드에선 로그아웃 및 디버깅을 위해 상단 헤더를
                띄워드렸습니다.
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <WhiteboardModal />
          <AppRoutes />
        </>
      )}
    </BrowserRouter>
  );
}
