import { Box, Button, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';

import CustomSVGIcon from '../Whiteboard/CustomSVGIcon';

interface SuccessModalProps {
  onClose: () => void;
  text: string;
}
const SuccessModal = (props: SuccessModalProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const handleModalClose = () => {
    setIsOpen(false);
    props.onClose();
  };

  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          height: '300px',
          left: '50%',
          position: 'absolute',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          width: '400px',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '10px',
            display: 'flex',
            height: '50px',
            justifyContent: 'flex-end',
            zIndex: 3,
          }}
        >
          <Button onClick={handleModalClose}>
            <img
              alt="close"
              height={24}
              src="/icons/modal_close.svg"
              width={24}
            />
          </Button>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginTop: '24px',
            zIndex: 5,
          }}
        >
          <CustomSVGIcon
            id="upload-done"
            sx={{ height: '100px', width: '100px' }}
          />
          <Typography
            style={{
              color: 'black',
              fontSize: '24px',
              fontWeight: '500',
              marginTop: '20px',
              padding: '0 20px',
              textAlign: 'center',
            }}
          >
            {props.text}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default SuccessModal;
