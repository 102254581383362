import { Alert, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AIDTErrorBoundary({
  backLink,
  desc,
  title,
}: {
  backLink: string;
  desc: string;
  title: string;
}) {
  const navigate = useNavigate();
  return (
    <div className="container mx-auto p-4 pt-10 items-center justify-center flex flex-col gap-10">
      <Alert aria-label={title + desc} className="w-full" severity="error">
        <h1 className="font-black text-lg">{title}</h1>
        <p>{desc}</p>
      </Alert>
      <button
        aria-label="엔터키를 누르면 돌아갑니다."
        className="bg-red-500 ring-red-300 hover:ring-2 transition-all font-bold text-lg px-4 py-3 rounded-xl text-white"
        onClick={() => navigate(backLink)}
      >
        돌아가기
      </button>
    </div>
  );
}
