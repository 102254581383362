import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';
const SpriteSvg = process.env.PUBLIC_URL + '/icons/whiteboard/sprite.svg';

interface IconProps extends SvgIconProps {
  id: string;
}

const CustomSVGIcon: React.FC<IconProps> = ({ id, ...props }) => (
  <SvgIcon {...props}>
    <use href={`${SpriteSvg}#${id}`} />
  </SvgIcon>
);

export default CustomSVGIcon;
