import { Box, Container, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BookMarker } from 'schemas/learning';
import { useGetLearningBookMarkerQuery } from 'services/learning';
import { RootState } from 'types';
import { UnitTable, findUnitById } from 'utils/studyUtils';

import { Layout } from '../../components/Layout';

export function DevPage() {
  const navigate = useNavigate();
  const { id: unitId } = useParams();
  const { t } = useTranslation();

  const [unitData, setUnitData] = useState<UnitTable | null>(null);

  const userId = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );
  const { data: bookMarkRes } = useGetLearningBookMarkerQuery(
    {
      headers: {
        role: userType,
        uuid: userId,
      },
      params: {
        learningSysId: unitId ? parseInt(unitId, 10) : 0,
      },
    },
    {
      skip: !unitId && !userType && !userId,
    },
  );

  useEffect(() => {
    if (unitId === undefined) return;
    const unitRes = findUnitById(contentTables, unitId);
    if (unitRes) {
      setUnitData(unitRes);
    }
  }, [contentTables, unitId]);

  useEffect(() => {
    if (!bookMarkRes || !bookMarkRes.data || !unitData) return;
    const updatedData = updateCheckedStatus(unitData, bookMarkRes.data);
    if (JSON.stringify(updatedData) !== JSON.stringify(unitData)) {
      setUnitData(updatedData);
    }
  }, [bookMarkRes, unitData]);

  const updateCheckedStatus = (
    unit: UnitTable,
    statusData: BookMarker[],
  ): UnitTable => {
    const updatedUnit = {
      ...unit,
      chapter: unit.chapter.map(chapter => ({
        ...chapter,
        section: chapter.section.map(section => ({
          ...section,
          subsection: section.subsection.map(subsection => {
            const status = statusData.find(
              s => s.learningSysId === subsection.learning_sys_id,
            );
            return status
              ? { ...subsection, checked: status.status === 'DONE' }
              : subsection;
          }),
        })),
      })),
    };
    return updatedUnit;
  };

  // const updateCheckedStatus = (
  //   unit: UnitTable,
  //   statusData: BookMarker[],
  // ): UnitTable => {
  //   const updatedUnit = { ...unit };

  //   statusData.forEach(status => {
  //     updatedUnit.chapter.forEach(chapter => {
  //       chapter.section.forEach(section => {
  //         section.subsection.forEach(subsection => {
  //           if (subsection.learning_sys_id === status.learningSysId) {
  //             subsection.checked = status.status === 'DONE';
  //           }
  //         });
  //       });
  //     });
  //   });

  //   return updatedUnit;
  // };

  return (
    <Layout isDisableGutters={true}>
      <Container
        disableGutters={true}
        sx={{ height: 'auto', minHeight: 'calc(100vh - 69px)' }}
      >
        <Stack
          alignItems={'stretch'}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            height: '100%',
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(/images/denouement/${unitId}.svg)`,
              backgroundPosition: 'center',

              backgroundSize: 'cover',
              flex: 1,
              padding: '5vw',
            }}
          >
            <Stack
              direction={'column'}
              justifyContent={'space-between'}
              sx={{ height: '100%' }}
            >
              <Stack
                alignItems={'flex-end'}
                aria-label={unitData?.unit_name}
                direction={'row'}
                tabIndex={0}
              >
                <img src={`/images/denouement/num_${unitId}.svg`} />
                <Typography
                  component={'h1'}
                  sx={{
                    color: '#FFCC32',
                    fontFamily: 'Jua, sans-serif',
                    fontSize: '3rem',
                    lineHeight: '40px',
                    marginLeft: '1rem',
                  }}
                  variant={'h4'}
                >
                  {unitData?.unit_name}
                </Typography>
              </Stack>
              <Typography
                component={'div'}
                sx={{ color: 'white' }}
                variant={'body1'}
              >
                {/* 소개글 데이터 준비되기 전까지 숨김 */}
                {/* <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                  <li>
                    소인수분해의 뜻을 알고, 자연수를 소인수분해 할 수 있다.
                  </li>
                  <li>
                    소인수분해를 이용하여 최대공약수와 최소공배수를 구할 수
                    있다.
                  </li>
                  <li>
                    다양한 상황을 이용하여 음수의 필요성을 인식하고, 양수와
                    음수, 정수와 유리수의 개념을 이해한다.
                  </li>
                  <li>정수와 유리수의 대소 관계를 판단할 수 있다.</li>
                  <li>
                    정수와 유리수의 사칙계산의 원리를 이해하고, 그 계산을 할 수
                    있다.
                  </li>
                </ul> */}
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{ backgroundColor: '#F5F5F5', flex: 1.5, padding: '4vw 2vw' }}
          >
            {unitData?.chapter.map((chapter, chapterIdx) => {
              return (
                <Stack
                  direction={'column'}
                  justifyContent={'space-between'}
                  key={chapterIdx}
                  sx={{ padding: '1rem' }}
                >
                  <Typography
                    component={'h2'}
                    sx={{
                      color: '#07756E',
                      fontFamily: 'Jua, sans-serif',
                      padding: '1rem',
                    }}
                    tabIndex={0}
                    variant={'h4'}
                  >
                    {unitData.unitOrder}-{chapterIdx + 1}.{' '}
                    {chapter.chapter_name}
                  </Typography>
                  {chapter.section.map((section, sectionIdx) => (
                    <Stack key={sectionIdx}>
                      {section.subsection.map((subsection, subsectionIdx) => (
                        <Stack
                          alignItems={'center'}
                          aria-label={t(
                            'denouement.navigate_to_subsection_page',
                            {
                              subsectionName: subsection.sub_section_name,
                            },
                          )}
                          direction={'row'}
                          justifyContent={'space-between'}
                          key={subsectionIdx}
                          onClick={() => {
                            navigate(`/chapter/${subsection.learning_sys_id}`);
                          }}
                          sx={{
                            cursor: 'pointer',
                            marginLeft: '50px',
                            padding: '10px 0',
                          }}
                          tabIndex={0}
                        >
                          {userType === 'S' && (
                            <>
                              <Box
                                sx={{
                                  border: '1px dotted #CCCCCC',
                                  flex: 1,
                                  margin: '0 5px',
                                }}
                              />
                              <Box
                                aria-label={
                                  subsection.checked
                                    ? t('home.learning_completed')
                                    : t('home.learning_pending')
                                }
                                sx={{
                                  backgroundColor: subsection.checked
                                    ? '#4F73C0'
                                    : 'initial',
                                  // check icon for css
                                  backgroundImage: subsection.checked
                                    ? 'url(/icons/check.svg)'
                                    : 'initial',
                                  backgroundPosition: 'center',
                                  border: subsection.checked
                                    ? 'none'
                                    : '1px solid #CCCCCC',
                                  borderRadius: '5px',
                                  height: '24px',
                                  width: '24px',
                                }}
                                tabIndex={0}
                              />
                            </>
                          )}
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              );
            })}
          </Box>
        </Stack>
      </Container>
    </Layout>
  );
}
