import axios, { AxiosInstance } from 'axios';

export const getSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  return subdomain != 'localhost' ? subdomain : 'm-1-1';
};

const instance: AxiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? `/api`
      : `https://api.${getSubdomain()}.${process.env.REACT_APP_MY_DOMAIN}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        // const navigate = useNavigate();
        // navigate('/expired');
        console.error('Axios Instance Error', error.response);
      } else {
        console.error(
          (error.response.data?.path || error.default.baseURL) +
            ' 에서 Internal Server Error 발생',
        );
      }
    } else {
      console.error('Internal Axios Instance Error', error);
    }
  },
);

export default instance;
