import {
  GetLearningSystemDto,
  GetLearningSystemResponse,
  UpdateLearningLevelDto,
} from 'schemas/learning';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/learning';

// 단원 정보 조회
export const getLearningSystem = async ({
  headers,
  params,
}: GetLearningSystemDto): Promise<GetLearningSystemResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/learning-system`, { headers, params })
  ).data;
};

// 학생 학습단계 수정
export const updateLearningLevel = async ({
  data,
  headers,
}: UpdateLearningLevelDto) => {
  return (await axiosInstance.patch(`${ENDPOINT}/level`, data, { headers }))
    .data;
};
