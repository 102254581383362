import { Box } from '@mui/material';
import { type RefObject, forwardRef } from 'react';
import * as React from 'react';
import Markdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkMath from 'remark-math';
import { visit } from 'unist-util-visit';

import '../styles/markdown.css';

interface MarkdownContentProps {
  content: string;
  marking?: string;
  ref?: RefObject<HTMLElement>;
}

const replaceURLToPath = (text: string) => {
  if (!text) return '';
  if (
    process.env.REACT_APP_NODE_ENV !== 'development' ||
    !process.env.REACT_APP_LATEX_ORG_IMAGE_URL
  )
    return text;

  const oldURL = process.env.REACT_APP_LATEX_ORG_IMAGE_URL;
  const newURL = '/latex-content-image';

  const updatedText = text.replace(new RegExp(oldURL, 'g'), newURL);

  return updatedText;
};

const addCrossOriginToImages = () => (tree: any) => {
  visit(tree, 'element', (node: any) => {
    if (node.tagName === 'img') {
      node.properties = {
        ...node.properties,
        crossOrigin: 'Anonymous',
      };
    }
  });
};

const MarkdownContent = forwardRef<HTMLElement, MarkdownContentProps>(
  ({ content, marking }, ref) => {
    const updatedContent = replaceURLToPath(content);
    return (
      <Box
        component={'div'}
        ref={ref || null}
        style={{ whiteSpace: 'pre-wrap' }}
        tabIndex={0}
        width={'fit-content'}
      >
        {marking && (
          <img
            alt={marking}
            src={`/icons/marking/mk_${marking}.svg`}
            style={{ left: '0px', position: 'absolute', top: '0px' }}
          />
        )}
        <Markdown
          rehypePlugins={[rehypeKatex, rehypeRaw, addCrossOriginToImages]}
          remarkPlugins={[remarkMath]}
        >
          {updatedContent}
        </Markdown>
      </Box>
    );
  },
);

export default MarkdownContent;
