import React, { useCallback, useEffect, useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
interface ColorPickerProps {
  color: string;
  setColor: (color: string) => void;
}

const ColorPicker = (props: ColorPickerProps) => {
  const useDebounce = (callback: (color: string) => void, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedCallback = useCallback(
      color => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          callback(color);
        }, delay);
      },
      [callback, delay],
    );

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    return debouncedCallback;
  };

  const handleChangeColor = useDebounce(color => {
    props.setColor(color);
  }, 100);
  return (
    <div className="color-picker">
      <HexColorPicker color={props.color} onChange={handleChangeColor} />
    </div>
  );
};

export default ColorPicker;
