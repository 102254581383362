import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import axiosInstance from 'utils/axiosInstance';

export const axiosBaseQuery =
  (
    { baseURL } = { baseURL: '' },
  ): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
  async ({ data, headers, method, params, transformResponse, url }) => {
    try {
      const result = await axiosInstance({
        data,
        headers,
        method,
        params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'comma', encode: false });
        },
        transformResponse,
        url: baseURL + url,
      });
      return { data: result.data };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error,
      };
    }
  };

export const apiService = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: [],
});
