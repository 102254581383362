import { useMutation } from '@tanstack/react-query';
import { createAssignment } from 'apis/assignment';
import { queryClient } from 'utils/tanstack-query';

export function useCreateAssignment() {
  return useMutation({
    mutationFn: createAssignment,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['can_create_assignment'],
      });
    },
  });
}
