// src/api/dummyData.ts

export const Dummy = {
  ANALYSIS_TABLE: [
    {
      correction_rate: 85,
      problems: [
        {
          added: 1,
          confidence: 4.5,
          difficulty: 'MIDDLE',
          is_correct: 1,
          problem_id: 101,
          study_perform_id: 201,
          study_problem_id: 301,
        },
      ],
      progress_rate: 90,
      user_uuid: '123e4567-e89b-12d3-a456-426614174000',
    },
  ],
  // Basic Search 샘플 데이터
  BASIC_SEARCH: {
    answer_data: 'z = √(x^2 + y^2)',
    answer_type: 'numeric',
    average_correct_rate: 80,
    cls_id: 'class_01',
    difficulty: 'MIDDLE',
    latex_data: 'x^2 + y^2 = z^2',
    problem_id: 101,
    problem_solving: {},
    problem_solving_meta: {},
    study_perform: {},
  },
  // Comment 샘플 데이터
  COMMENT: {
    content: '이 비디오 정말 유익했어요!',
    created_at: '2023-06-01T12:34:56Z',
    id: 1,
    updated_at: '2023-06-02T12:34:56Z',
  },
  // Concept 샘플 데이터
  CONCEPT: {
    cls_id: 'class_01',
    content_status: 'active',
    created_by: 123,
    id: 1,
    is_algeomath: 1,
    latex_data: 'E = mc^2',
    order_no: 1,
    type: 'BASIC',
    type_name: '기본 개념',
    updated_at: '2023-06-01T12:34:56Z',
  },
  CONTENT_TABLES: [
    {
      chapter: [
        {
          chapter_name: '소인수분해',
          learning_sys_doc_id: 1,
          learning_sys_id: 2,
          section: [
            {
              checked: true,
              cls_id: '',
              learning_sys_doc_id: 1,
              learning_sys_id: 3,
              section_name: '최대공약수와 최소공배수',
              subsection: [
                {
                  checked: true,
                  cls_id: 'M3MATA01B01C01',
                  learning_sys_doc_id: 1,
                  learning_sys_id: 4,
                  sub_section_name: '최대공약수',
                },
                {
                  checked: false,
                  cls_id: 'M3MATA01B01C01',
                  learning_sys_doc_id: 1,
                  learning_sys_id: 5,
                  sub_section_name: '최소공배수',
                },
              ],
            },
            {
              checked: false,
              cls_id: 'cls104',
              learning_sys_doc_id: 2,
              learning_sys_id: 6,
              section_name: '소단원 2',
              subsection: [
                {
                  checked: true,
                  cls_id: 'cls105',
                  learning_sys_doc_id: 2,
                  learning_sys_id: 7,
                  sub_section_name: '소단원 2',
                },
              ],
            },
          ],
        },
      ],
      learning_sys_doc_id: 1,
      learning_sys_id: 1,
      unit_name: '수와 연산',
    },
  ],
  // Shared Video 샘플 데이터
  LECTURE_VIDEO: {
    totalPage: 1,
    videos: [
      {
        comment_count: 10,
        created_at: '2023-06-01T12:34:56Z',
        deleted_at: '2023-06-02T12:34:56Z',
        haveLiked: false,
        id: 1,
        learning_sys_id: 101,
        like_count: 42,
        pinned: false,
        scope: 'CLASS',
        status: 'DONE',
        title: '비디오 1',
        user_uuid: '123e4567-e89b-12d3-a456-426614174000',
        video_path: '/path/to/video',
      },
    ],
  },
  // Like 샘플 데이터
  LIKE: {
    concept_video_id: 1,
    like_count: 42,
  },
  MY_REPLY_DATA: {
    createdAt: '2024-05-20T16:50:00Z',
    id: 1,
    isChoiceQuestion: false,
    peerId: '1',
    question: `<math xmlns="http://www.w3.org/1998/Math/MathML">
              <mrow>
                <mi>x</mi>
                <mo>=</mo>
                <mfrac>
                  <mrow>
                    <mo>-</mo>
                    <mi>b</mi>
                    <mo>&PlusMinus;</mo>
                    <msqrt>
                      <mrow>
                        <msup>
                          <mi>b</mi>
                          <mn>2</mn>
                        </msup>
                        <mo>-</mo>
                        <mrow>
                          <mn>4</mn>
                          <mo>&InvisibleTimes;</mo>
                          <mi>a</mi>
                          <mo>&InvisibleTimes;</mo>
                          <mi>c</mi>
                        </mrow>
                      </mrow>
                    </msqrt>
                  </mrow>
                  <mrow>
                    <mn>2</mn>
                    <mo>&InvisibleTimes;</mo>
                    <mi>a</mi>
                  </mrow>
                </mfrac>
              </mrow>
            </math>`,
    title: '제목: 친구들아 이것좀 풀어줘.. 나는 틀렸어',
    username: '김퉁퉁',
  },
  OTHER_REPLY_DATA: {
    createdAt: '2024-05-20T16:50:00Z',
    id: 1,
    isChoiceQuestion: false,
    peerId: '1',
    question: `<math xmlns="http://www.w3.org/1998/Math/MathML">
              <mrow>
                <mi>x</mi>
                <mo>=</mo>
                <mfrac>
                  <mrow>
                    <mo>-</mo>
                    <mi>b</mi>
                    <mo>&PlusMinus;</mo>
                    <msqrt>
                      <mrow>
                        <msup>
                          <mi>b</mi>
                          <mn>2</mn>
                        </msup>
                        <mo>-</mo>
                        <mrow>
                          <mn>4</mn>
                          <mo>&InvisibleTimes;</mo>
                          <mi>a</mi>
                          <mo>&InvisibleTimes;</mo>
                          <mi>c</mi>
                        </mrow>
                      </mrow>
                    </msqrt>
                  </mrow>
                  <mrow>
                    <mn>2</mn>
                    <mo>&InvisibleTimes;</mo>
                    <mi>a</mi>
                  </mrow>
                </mfrac>
              </mrow>
            </math>`,
    title: '제목: 친구들아 이것좀 풀어줘.. 나는 틀렸어',
    username: '김퉁퉁',
  },
  PROBLEMS: [
    {
      answer_data: 'z = sqrt(x^2 + y^2)',
      answer_type: 'TEXT',
      cls_id: 'cls_1',
      created_at: '2024-01-01 10:00:00',
      curriculum: 'MATH',
      deleted_at: null,
      difficulty: 'LOW',
      id: 101,
      latex_data: `1. 두 다항식 $A, B$ 에 대하여
              $\\left\\{\\begin{array}{l}A=x^{2}+x y-y^{2} \\\\ B=2 x^{2}-3 x y+4 y^{2}\\end{array}\\right.$
              일 때 $A * B=3 A-2 B, A \\diamond B=2 A-7 B$ 로 약속
              할 때, $\\{A *(A-3 B)\\} \\diamond B$ 를 간단히 한 식에서 $y^{2}$ 의
              계수는?
              (1) 12
              (2) 13
              (3) 15
              (4) 17
              (5) 18`,
      type: 'GENERAL',
    },
    {
      answer_data: 'E = mc^2',
      answer_type: 'TEXT',
      cls_id: 'cls_2',
      created_at: '2024-01-02 11:00:00',
      curriculum: 'PHYSICS',
      deleted_at: null,
      difficulty: 'MEDIUM',
      id: 102,
      latex_data: 'E = mc^2',
      type: 'GENERAL',
    },
    {
      answer_data: '\\frac{ad + bc}{bd}',
      answer_type: 'TEXT',
      cls_id: 'cls_3',
      created_at: '2024-01-03 12:00:00',
      curriculum: 'MATH',
      deleted_at: null,
      difficulty: 'HIGH',
      id: 103,
      latex_data: '\\frac{a}{b} + \\frac{c}{d}',
      type: 'DIAGNOSTIC',
    },
    {
      answer_data: 'H_2O',
      answer_type: 'TEXT',
      cls_id: 'cls_4',
      created_at: '2024-01-04 13:00:00',
      curriculum: 'CHEMISTRY',
      deleted_at: null,
      difficulty: 'LOW',
      id: 104,
      latex_data: 'H_2 + O_2 = H_2O',
      type: 'GENERAL',
    },
  ],
  // Question Bank 샘플 데이터
  QUESTION_BANK: {
    questions: [
      {
        answer: '2',
        explanation: '기초적인 산수 문제입니다.',
        id: 1,
        options: ['1', '2', '3', '4'],
        question: '1 + 1은?',
      },
    ],
    totalPage: 1,
  },
  QUESTIONS: [
    {
      created_at: '2024-06-21T04:23:11.792Z',
      id: 1,
      learning_sys_id: 1,
      problem_id: 101,
      problem_solving: {
        created_at: '2024-06-21T04:23:11.792Z',
        id: 1,
        problem_id: 101,
        scope: 'CLASS',
        status: 'IDLE',
        user_uuid: '8774cf15-3e84-5888-93b0-b59a33ae4d6a',
      },
      question_user_uuid: '0a745198-c99a-5a29-84a9-9f51b7591a08',
      solving_user_uuid: '8774cf15-3e84-5888-93b0-b59a33ae4d6a',
      title: 'Question 1',
    },
    {
      created_at: '2024-06-21T04:23:11.792Z',
      id: 2,
      learning_sys_id: 1,
      problem_id: 102,
      problem_solving: {
        created_at: '2024-06-21T04:23:11.792Z',
        id: 2,
        problem_id: 102,
        scope: 'CLASS',
        status: 'IDLE',
        user_uuid: '41bd0631-d7ab-5c1b-94f1-bada19639597',
      },
      question_user_uuid: '8774cf15-3e84-5888-93b0-b59a33ae4d6a',
      solving_user_uuid: '41bd0631-d7ab-5c1b-94f1-bada19639597',
      title: 'Question 2',
    },
    {
      created_at: '2024-06-21T04:23:11.792Z',
      id: 3,
      learning_sys_id: 1,
      problem_id: 103,
      problem_solving: {
        created_at: '2024-06-21T04:23:11.792Z',
        id: 3,
        problem_id: 103,
        scope: 'CLASS',
        status: 'IDLE',
        user_uuid: '394e7a4e-0dee-590d-815a-7ad97426af1c',
      },
      question_user_uuid: '8774cf15-3e84-5888-93b0-b59a33ae4d6a',
      solving_user_uuid: '394e7a4e-0dee-590d-815a-7ad97426af1c',
      title: 'Question 3',
    },
  ],
  REFERENCE: {
    currentPage: 1,
    data: [
      {
        content: 'content1',
        createdAt: '2024-06-24T20:51:56.754Z',
        id: 1,
        scope: 'CLASS',
        title: '타이틀1',
        viewCount: 2,
      },
      {
        content: 'content2',
        createdAt: '2024-06-25T20:51:56.754Z',
        id: 2,
        scope: 'ALL',
        title: '타이틀2',
        viewCount: 3,
      },
      {
        content: 'content3',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 3,
        scope: 'CLASS',
        title: '타이틀3',
        viewCount: 4,
      },
      {
        content: 'content4',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 4,
        scope: 'CLASS',
        title: '타이틀4',
        viewCount: 5,
      },
      {
        content: 'content5',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 5,
        scope: 'ALL',
        title: '타이틀5',
        viewCount: 6,
      },
      {
        content: 'content6',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 6,
        scope: 'ALL',
        title: '타이틀6',
        viewCount: 7,
      },
      {
        content: 'content7',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 7,
        scope: 'ALL',
        title: '타이틀7',
        viewCount: 8,
      },
      {
        content: 'content8',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 8,
        scope: 'CLASS',
        title: '타이틀8',
        viewCount: 9,
      },
      {
        content: 'content9',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 9,
        scope: 'CLASS',
        title: '타이틀9',
        viewCount: 10,
      },
      {
        content: 'content10',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 10,
        scope: 'ALL',
        title: '타이틀10',
        viewCount: 11,
      },
      {
        content: 'content11',
        createdAt: '2024-06-26T20:51:56.754Z',
        id: 11,
        scope: 'CLASS',
        title: '타이틀11',
        viewCount: 12,
      },
    ],
    totalPage: 2,
  },
  SEARCH_RESULT: {},
  // 개발계용
  // SEMESTER_DATA: [
  //   {
  //     id: 1,
  //     year: 2024,
  //     grade: 1,
  //     semester: 1,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-1-1',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  //   {
  //     id: 2,
  //     year: 2024,
  //     grade: 1,
  //     semester: 2,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-1-2',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  //   {
  //     id: 3,
  //     year: 2024,
  //     grade: 2,
  //     semester: 1,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-2-1',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  //   {
  //     id: 4,
  //     year: 2024,
  //     grade: 2,
  //     semester: 2,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-2-2',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  //   {
  //     id: 5,
  //     year: 2024,
  //     grade: 3,
  //     semester: 1,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-3-1',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  //   {
  //     id: 6,
  //     year: 2024,
  //     grade: 3,
  //     semester: 2,
  //     begin_date: '2024-06-27 15:23:21',
  //     end_date: '2024-06-27 15:23:21',
  //     desc: '2024-3-2',
  //     created_at: '2024-06-27 15:23:21',
  //   },
  // ],
  // 운영계용
  SEMESTER_DATA: [
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:15',
      desc: '2025-8-1',
      end_date: '2025-12-31 00:00:00',
      grade: '8',
      id: 11,
      semester: '1',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:20',
      desc: '2025-8-2',
      end_date: '2025-12-31 00:00:00',
      grade: '8',
      id: 12,
      semester: '2',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:25',
      desc: '2025-8-1',
      end_date: '2025-12-31 00:00:00',
      grade: '9',
      id: 13,
      semester: '1',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:29',
      desc: '2025-8-2',
      end_date: '2025-12-31 00:00:00',
      grade: '9',
      id: 14,
      semester: '2',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:41',
      desc: '2025-10-1',
      end_date: '2025-12-31 00:00:00',
      grade: '10',
      id: 15,
      semester: '1',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:46',
      desc: '2025-10-2',
      end_date: '2025-12-31 00:00:00',
      grade: '10',
      id: 16,
      semester: '2',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:31:59',
      desc: '2025-11-1',
      end_date: '2025-12-31 00:00:00',
      grade: '11',
      id: 17,
      semester: '1',
      year: '2025',
    },
    {
      begin_date: '2024-07-01 00:00:00',
      created_at: '2024-07-02 06:32:02',
      desc: '2025-11-2',
      end_date: '2025-12-31 00:00:00',
      grade: '11',
      id: 18,
      semester: '2',
      year: '2025',
    },
  ],
  // Shared Solution Video 샘플 데이터
  SHARED_SOLUTION_VIDEO: {
    page: 1,
    totalPage: 1,
    videos: [
      {
        commentCount: 10,
        createdAt: '2023-06-01T12:34:56Z',
        haveILiked: false,
        id: 1,
        likeCount: 42,
        problemId: 101,
        userUuid: '123e4567-e89b-12d3-a456-426614174000',
        videoPath: '/path/to/video',
      },
    ],
  },
  SHARED_VIDEO: [
    {
      comment_count: 5,
      created_at: '2024-06-25T03:13:41.110Z',
      deleted_at: '2024-06-25T03:13:41.110Z',
      haveLiked: true,
      id: 1,
      learning_sys_id: 1,
      like_count: 10,
      pinned: true,
      scope: 'ALL',
      status: 'DONE',
      user_uuid: 'string',
      video_path: '',
    },
    {
      comment_count: 10,
      created_at: '2024-06-25T03:13:41.110Z',
      deleted_at: '2024-06-25T03:13:41.110Z',
      haveLiked: false,
      id: 2,
      learning_sys_id: 1,
      like_count: 20,
      pinned: false,
      scope: 'CLASS',
      status: 'DONE',
      user_uuid: 'string',
      video_path: '',
    },
  ],
  // Solution Video Comments 샘플 데이터
  SOLUTION_VIDEO_COMMENTS: {
    comments: [
      {
        content: '이 비디오 정말 유익했어요!',
        created_at: '2023-06-01T12:34:56Z',
        id: 1,
        updated_at: '2023-06-02T12:34:56Z',
      },
    ],
    page: 1,
    totalPage: 1,
  },
  SUBMIT_RESULT: {},
};

export const MockData = {
  STUDENT_ALL: {
    code: '00000',
    message: '성공',
    schedule_info: [
      {
        class_period: '1',
        classroom_name: '1학년 1반 교실',
        day_week: '1',
        lecture_code: 'K100000001_20241_00610001',
        subject_name: '수학',
      },
      {
        class_period: '2',
        classroom_name: '1학년 1반 교실',
        day_week: '2',
        lecture_code: 'K100000001_20241_00610001',
        subject_name: '수학',
      },
      {
        class_period: '3',
        classroom_name: '1학년 1반 교실',
        day_week: '3',
        lecture_code: 'K100000001_20241_00610001',
        subject_name: '수학',
      },
      {
        class_period: '4',
        classroom_name: '1학년 1반 교실',
        day_week: '4',
        lecture_code: 'K100000001_20241_00610001',
        subject_name: '수학',
      },
      {
        class_period: '5',
        classroom_name: '1학년 1반 교실',
        day_week: '5',
        lecture_code: 'K100000001_20241_00610001',
        subject_name: '수학',
      },
    ],
    school_id: 'K100000001',
    school_name: '한국 중학교',
    user_class: '1학년 1반 교실',
    user_division: '3',
    user_gender: '1',
    user_grade: '1',
    user_name: '해피중학생1',
    user_number: '1',
  },
  STUDENT_NAME: {
    code: '00000',
    message: '성공',
    user_list: [],
    user_name: '김중등해피에듀테크학생',
  },
  STUDENT_NAME_LIST: {
    code: '00000',
    message: '성공',
    path: 'user_name',
    user_list: [
      { user_id: 'mock-penetration-id', user_name: '해피중학생1' },
      { user_id: 'mock-penetration-id-s', user_name: '해피중학생2' },
      { user_id: 'mock-penetration-id-s-2', user_name: '해피중학생3' },
    ],
    user_name: '해피중학생1',
  },
  TEACHER_ALL: {
    class_info: [
      {
        class_code: 'K100000001_2024_00000000_1001',
        school_name: '한국 중학교',
        user_class: '1학년 1반',
        user_grade: '1',
      },
    ],
    code: '00000',
    message: '성공',
    schedule_info: [
      {
        class_period: '1',
        classroom_name: '1학년 1반 교실',
        day_week: '1',
        lecture_code: 'K100000001_20241_00610001',
        school_name: '한국 중학교',
        subject_name: '수학',
      },
      {
        class_period: '2',
        classroom_name: '1학년 1반 교실',
        day_week: '2',
        lecture_code: 'K100000001_20241_00610001',
        school_name: '한국 중학교',
        subject_name: '수학',
      },
      {
        class_period: '3',
        classroom_name: '1학년 1반 교실',
        day_week: '3',
        lecture_code: 'K100000001_20241_00610001',
        school_name: '한국 중학교',
        subject_name: '수학',
      },
      {
        class_period: '4',
        classroom_name: '1학년 1반 교실',
        day_week: '4',
        lecture_code: 'K100000001_20241_00610001',
        school_name: '한국 중학교',
        subject_name: '수학',
      },
      {
        class_period: '5',
        classroom_name: '1학년 1반 교실',
        day_week: '5',
        lecture_code: 'K100000001_20241_00610001',
        school_name: '한국 중학교',
        subject_name: '수학',
      },
    ],
    school_id: 'K100000001',
    school_name: '한국 중학교',
    user_name: '한국중 선생님',
  },
  TEACHER_CLASS_MEMBER: {
    code: '00000',
    member_info: [
      {
        user_id: 'mock-penetration-id',
        user_name: '해피중학생1',
        user_number: '1',
      },
      {
        user_id: 'mock-penetration-id-s',
        user_name: '해피중학생2',
        user_number: '2',
      },
      {
        user_id: 'mock-penetration-id-s-2',
        user_name: '해피중학생3',
        user_number: '3',
      },
      // {
      //   user_id: 'mock-penetration-id-1',
      //   user_name: '해피중학생2',
      //   user_number: '2',
      // },
      // {
      //   user_id: 'mock-penetration-id-2',
      //   user_name: '해피중학생3',
      //   user_number: '3',
      // },
      // {
      //   user_id: 'mock-penetration-id-3',
      //   user_name: '해피중학생4',
      //   user_number: '4',
      // },
      // {
      //   user_id: 'mock-penetration-id-4',
      //   user_name: '해피중학생5',
      //   user_number: '5',
      // },
      // {
      //   user_id: 'mock-penetration-id-5',
      //   user_name: '해피중학생6',
      //   user_number: '6',
      // },
    ],
    message: '성공',
  },
  TEACHER_INFO: {
    class_info: [
      {
        class_code: 'K300000038101',
        school_name: '해피에듀중',
        user_class: '2',
        user_grade: '1',
      },
    ],
    code: '00000',
    message: '성공',
    schedule_info: [
      {
        class_period: '2',
        classroom_name: '1(1학년1반교실)',
        day_week: '1',
        lecture_code: 'LCTR380000231001',
        school_name: '해피에듀중',
        subject_name: '중등수학1',
      },
    ],
    school_id: 'K100000001',
    school_name: '해피에듀중',
    user_name: '해피에듀테크중1수학교사',
  },
  TEACHER_NAMES: {
    code: '00000',
    message: '성공',
    user_list: [
      {
        user_id: 'mock-penetration-id-t',
        user_name: '한국중 선생님',
      },
    ],
    user_name: '한국중 선생님',
  },
};

export const MOCK_CLASS_INFO = 'K100000001-1-1';
