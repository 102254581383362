import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export enum DialogKeys {
  CreateAssignment = 'createAssignment',
  DueAssignment = 'dueAssignment',
  MvpList = 'mvpList',
  SubmitAssignment = 'submitAssignment',
  SubmitDiagnosis = 'submitDiagnosis',
  UpdateLearningLevel = 'updateLearningLevel',
}

interface DialogState {
  [DialogKeys.CreateAssignment]: boolean;
  [DialogKeys.DueAssignment]: boolean;
  [DialogKeys.MvpList]: boolean;
  [DialogKeys.SubmitAssignment]: boolean;
  [DialogKeys.SubmitDiagnosis]: boolean;
  [DialogKeys.UpdateLearningLevel]: boolean;
}

interface DialogAction {
  toggleDialog: (name: string, open: boolean) => () => void;
}

const useDialogStore = create<DialogAction & DialogState>()(
  devtools(
    persist(
      set => ({
        [DialogKeys.CreateAssignment]: false,
        [DialogKeys.DueAssignment]: false,
        [DialogKeys.MvpList]: false,
        [DialogKeys.SubmitAssignment]: false,
        [DialogKeys.SubmitDiagnosis]: false,
        [DialogKeys.UpdateLearningLevel]: false,
        toggleDialog: (name, open) => () =>
          set(state => ({ ...state, [name]: open })),
      }),
      { name: 'dialog' },
    ),
  ),
);

export default useDialogStore;
