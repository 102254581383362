export function getGradeLabel(value: number): string {
  if (value < 1 || value > 12) {
    throw new Error('Value must be between 1 and 12');
  }

  if (value <= 6) {
    return `초${value}`;
  } else if (value <= 9) {
    return `중${value - 6}`;
  } else {
    return `고${value - 9}`;
  }
}
