import { useQuery } from '@tanstack/react-query';
import { getTeacherStatisticsComparison } from 'apis/dashboard';
import {} from 'schemas/assignment';
import {
  GetTeacherStatisticsComparisonDto,
  GetTeacherStatisticsComparisonResponse,
  TeacherStatisticComparison,
} from 'schemas/teacher-dashboard';

export function useTeacherStatisticsComparison({
  headers,
  params,
}: GetTeacherStatisticsComparisonDto) {
  return useQuery<
    GetTeacherStatisticsComparisonResponse,
    Error,
    TeacherStatisticComparison[]
  >({
    enabled:
      !!params.learningSysId &&
      !!params.classUuids &&
      params.classUuids?.length > 0 &&
      !!headers.role &&
      !!headers.uuid,
    queryFn: () => getTeacherStatisticsComparison({ headers, params }),
    queryKey: ['teacher_statistics_comparison', { headers, params }],
    retry: false,
    select: data => data.data,
  });
}
