import qs from 'qs';
import {
  CanCreateAssignmentDto,
  CanCreateAssignmentResponse,
  CheckAssignmentDto,
  CheckAssignmentResponse,
  CheckAssignmentSubmissionDto,
  CheckAssignmentSubmissionResponse,
  CreateAssignmentDto,
  CreateAssignmentResponse,
  GetAssignmentsDto,
  GetAssignmentsResponse,
} from 'schemas/assignment';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/assignment';

// 과제 유무 조회
export const checkAssignment = async ({
  headers,
  params,
}: CheckAssignmentDto): Promise<CheckAssignmentResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/exist`, {
      headers,
      params,
    })
  ).data;
};

// 과제 출제
export const createAssignment = async ({
  data,
  headers,
}: CreateAssignmentDto): Promise<CreateAssignmentResponse> => {
  return (await axiosInstance.post(`${ENDPOINT}/gave`, data, { headers })).data;
};

// 과제 출제 가능 여부 조회
export const canCreateAssignment = async ({
  headers,
  params,
}: CanCreateAssignmentDto): Promise<CanCreateAssignmentResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/gave/check`, { headers, params })
  ).data;
};

// 과제 문제 조회
export const getAssignments = async ({
  headers,
  params,
}: GetAssignmentsDto): Promise<GetAssignmentsResponse> => {
  return (await axiosInstance.get(`${ENDPOINT}/solve`, { headers, params }))
    .data;
};

// 과제 제출 여부 조회
export const checkAssignmentSubmission = async ({
  headers,
  params,
}: CheckAssignmentSubmissionDto): Promise<CheckAssignmentSubmissionResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/finish/check`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};
