import { ConceptVideo } from 'schemas/teacher-dashboard';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ConceptVideoDialogState {
  open: boolean;
  videos: ConceptVideo[];
}

interface ConceptVideoDialogAction {
  setVideos: (videos: ConceptVideo[]) => void;
  toggleDialog: (open: boolean) => () => void;
}

const useConceptVideoDialogStore = create<
  ConceptVideoDialogAction & ConceptVideoDialogState
>()(
  devtools(
    persist(
      set => ({
        open: false,
        setVideos: videos => set(state => ({ ...state, videos })),
        toggleDialog: (open: boolean) => () =>
          set(state => ({ ...state, open })),
        videos: [],
      }),

      { name: 'conceptVideoDialog' },
    ),
  ),
);

export default useConceptVideoDialogStore;
