import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { setId, setType } from 'app/pages/AssessmentDiagnosis/evaluationSlice';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import assessmentService from 'services/assessmentService';

import {
  DRAWER_WIDTH,
  LEFT_NAV_ITEMS,
  RIGHT_NAV_ITEMS,
} from '../../../../constants';
import { RootState } from '../../../../types';
import AlertDrawer from '../../Alert/AlertDrawer';
import SettingDrawer from '../../Setting/SettingDrawer';
import { StyledLink } from './Header.styled';
import HeaderLeft from './sub/HeaderLeft';
import HeaderMid from './sub/HeaderMid';

export function Header() {
  const { i18n, t } = useTranslation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useSelector((state: RootState) => state.user);
  const isStart = useSelector((state: RootState) => state.evaluation.isStart);
  const isOver = useSelector((state: RootState) => state.evaluation.isOver);

  const [currentAssessment, setCurrentAssessment] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography sx={{ my: 2 }} variant="h6">
        {t('title')}
      </Typography>
      <Divider />
      <List>
        {RIGHT_NAV_ITEMS.map(item => (
          <ListItem disablePadding key={item.name}>
            <ListItemButton
              component={Link}
              sx={{ textAlign: 'center' }}
              to={item.name === 'index' ? `/` : item.value}
            >
              <ListItemText primary={t(`nav.${item.name}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const checkAssessmentStatus = () => {
    const params = {
      learning_sys_id: 0,
      type: 'DIAGNOSTIC',
    };
    const headers = { role: currentUser.user_type, uuid: currentUser.user_id };

    assessmentService
      .getAssessmentStatus(headers, currentUser.classInfo, params)
      .then(response => {
        if (response.data) {
          //이건 모든 assessment에 대한 정보를 가져옵니다.
          const currentAssessment = response.data.find(
            v => v.status === 'IN_PROGRESS',
          );
          if (!currentAssessment) return;
          setCurrentAssessment(currentAssessment);
        }
      });
  };

  React.useEffect(() => {
    if (currentUser.user_id && currentUser.classInfo) {
      checkAssessmentStatus();
    }
  }, [currentUser]);

  return (
    <Box
      component={'header'}
      sx={{
        backgroundColor: 'white',
        height: '80px',
        minHeight: '80px',
        pointerEvents: isStart && !isOver ? 'none' : 'auto',
      }}
    >
      <Container
        disableGutters={!isSmallScreen}
        maxWidth={false}
        sx={{
          backgroundColor: 'white',
          boxShadow: '0 1px 7px 0 rgba(0, 0, 0, 0.1)',
          margin: '0 auto',
          maxWidth: '100vw',
          position: 'fixed',
          width: '100vw',
          zIndex: 20,
        }}
      >
        <div className="container px-0">
          <AppBar
            color={'inherit'}
            component="nav"
            position={'relative'}
            sx={{
              boxShadow: 'inherit',
              boxSizing: 'border-box',
              width: '100%',
            }}
          >
            <Toolbar
              disableGutters={true}
              sx={{ justifyContent: 'space-between' }}
            >
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: 'none', xs: 'block' } }}
              >
                <img alt="menu" height={40} src="/icons/index.svg" width={40} />
              </IconButton>
              {/**LEFT SECTION */}
              <Box
                sx={{
                  alignItems: 'center',
                  display: { sm: 'flex', xs: 'none' },
                  flex: 1,
                }}
              >
                <HeaderLeft />
              </Box>
              {/**MID SECTION */}
              <HeaderMid
                currentAssessment={currentAssessment}
                subTitle={`${currentUser.user_school || '한국 중학교'} ${
                  currentUser.studentInfo?.user_class || '선생님'
                }`}
                title={`수학 ${t('title')}`}
              />
              {/**RIGHT SECTION */}
              <Box
                sx={{
                  alignItems: 'center',
                  display: { sm: 'flex', xs: 'none' },
                  flex: 1,
                }}
              >
                {RIGHT_NAV_ITEMS.map(item => {
                  if (item.name === 'alert') {
                    return (
                      <div className="w-1/4" key={item.name} tabIndex={0}>
                        <AlertDrawer item={item} />
                      </div>
                    );
                  }
                  if (item.name === 'option') {
                    return (
                      <div className="w-1/4" key={item.name} tabIndex={0}>
                        <SettingDrawer item={item} key={item.name} />
                      </div>
                    );
                  } else {
                    const isActive = currentPath.startsWith(item.value);
                    return (
                      <StyledLink
                        aria-label={
                          isActive
                            ? `${t(`nav.${item.name}`)}. ${t(
                                'nav.currentPageMessage',
                              )}`
                            : t(`nav.${item.name}`)
                        }
                        className="w-1/4"
                        key={item.name}
                        tabIndex={0}
                        to={item.name === 'index' ? `/` : item.value}
                      >
                        <img
                          alt={item.name}
                          height={40}
                          src={`/icons/${item.name}.svg`}
                          width={40}
                        />
                        {t(`nav.${item.name}`)}
                        {isActive && (
                          <div
                            style={{
                              backgroundColor: '#7F68FF',
                              bottom: 0,
                              height: '3px',
                              left: 0,
                              margin: '-4px 10px',
                              position: 'absolute',
                              right: 0,
                            }}
                          />
                        )}
                      </StyledLink>
                    );
                  }
                })}
              </Box>
            </Toolbar>
          </AppBar>
        </div>
        <nav>
          <Drawer
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            onClose={handleDrawerToggle}
            open={mobileOpen}
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: DRAWER_WIDTH,
              },
              display: { sm: 'none', xs: 'block' },
            }}
            variant="temporary"
          >
            {drawer}
          </Drawer>
        </nav>
      </Container>
    </Box>
  );
}
