import React from 'react';
import { ImageObj, ValueOfUnion } from 'types/WhiteboardState';

interface ResizeHandlesProps {
  image: ImageObj;
  startResizing: (
    x: number,
    y: number,
    handle: HandlePositionsType,
    rect?: DOMRect,
  ) => void;
}

const HANDLE_SIZE = 10;
export const HANDLE_POSITIONS = {
  BOTTOM_LEFT: 'bl',
  BOTTOM_RIGHT: 'br',
  TOP_LEFT: 'tl',
  TOP_RIGHT: 'tr',
} as const;

export type HandlePositionsType = ValueOfUnion<typeof HANDLE_POSITIONS>;

const ResizeHandles = ({ image, startResizing }: ResizeHandlesProps) => {
  const handleMouseDown = (
    e: React.MouseEvent,
    handle: HandlePositionsType,
  ) => {
    const x = e.clientX;
    const y = e.clientY;
    startResizing(x, y, handle);
  };
  const handleTouchStart = (
    e: React.TouchEvent,
    handle: HandlePositionsType,
  ) => {
    const touch = e.touches[0];
    const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    startResizing(x, y, handle, rect);
  };

  return (
    <div>
      <div
        onMouseDown={(e: React.MouseEvent) =>
          handleMouseDown(e, HANDLE_POSITIONS.TOP_LEFT)
        }
        onTouchStart={(e: React.TouchEvent) =>
          handleTouchStart(e, HANDLE_POSITIONS.TOP_LEFT)
        }
        style={{
          ...resizeHandleStyle,
          left: image.x - HANDLE_SIZE / 2,
          top: image.y - HANDLE_SIZE / 2,
        }}
      ></div>
      <div
        onMouseDown={(e: React.MouseEvent) =>
          handleMouseDown(e, HANDLE_POSITIONS.TOP_RIGHT)
        }
        onTouchStart={(e: React.TouchEvent) =>
          handleTouchStart(e, HANDLE_POSITIONS.TOP_RIGHT)
        }
        style={{
          ...resizeHandleStyle,
          left: image.x + image.width - HANDLE_SIZE / 2,
          top: image.y - HANDLE_SIZE / 2,
        }}
      ></div>
      <div
        onMouseDown={(e: React.MouseEvent) =>
          handleMouseDown(e, HANDLE_POSITIONS.BOTTOM_LEFT)
        }
        onTouchStart={(e: React.TouchEvent) =>
          handleTouchStart(e, HANDLE_POSITIONS.BOTTOM_LEFT)
        }
        style={{
          ...resizeHandleStyle,
          left: image.x - HANDLE_SIZE / 2,
          top: image.y + image.height - HANDLE_SIZE / 2,
        }}
      ></div>
      <div
        onMouseDown={(e: React.MouseEvent) =>
          handleMouseDown(e, HANDLE_POSITIONS.BOTTOM_RIGHT)
        }
        onTouchStart={(e: React.TouchEvent) =>
          handleTouchStart(e, HANDLE_POSITIONS.BOTTOM_RIGHT)
        }
        style={{
          ...resizeHandleStyle,
          left: image.x + image.width - HANDLE_SIZE / 2,
          top: image.y + image.height - HANDLE_SIZE / 2,
        }}
      ></div>
    </div>
  );
};

const resizeHandleStyle: React.CSSProperties = {
  background: 'blue',
  cursor: 'pointer',
  height: `${HANDLE_SIZE}px`,
  position: 'absolute',
  width: `${HANDLE_SIZE}px`,
};
export default ResizeHandles;
