import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { createAnnouncement, patchAnnouncement } from 'apis/announcement';
import { S3FilePair } from 'app/board/board.types';
import AIDTErrorBoundary from 'app/components/Common/AIDTErrorBoundary';
import SpeedDialTooltipOpen from 'app/components/Common/SpeedDialTooltipOpen';
import { TextButton } from 'app/components/Common/TextButton';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import { Layout } from 'app/components/Layout';
import NoticeSubHeader from 'app/components/Notice/NoticeSubHeader';
import { LucideArchive, LucideFile, LucideTrash } from 'lucide-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { VIDEO_BUCKETS } from 'schemas/file';
import {
  useGetReferenceDataByIdQuery,
  usePatchReferenceDataMutation,
  usePostReferenceDataMutation,
} from 'services/studyService';
import { RootState } from 'types';
import { CurrentSubSection } from 'types/StudyState';
import { aidtGetPath, aidtUploadAndGetPath } from 'utils/aidtUpload';
import { findSubsectionByClsId } from 'utils/studyUtils';

export default function ReferenceNewPage() {
  const { sectionId, subsectionId } = useParams();
  const { t } = useTranslation();
  const currentUser = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [sectionData, setSectionData] = useState<CurrentSubSection | null>(
    null,
  );
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );
  const id = searchParams.get('id');
  const [payload, setPayload] = useState({
    content: '',
    filePaths: [] as string[],
    scope: 'ALL',
    title: '',
  });
  useEffect(() => {
    if (subsectionId === undefined) return;
    const section = findSubsectionByClsId(contentTables, subsectionId);
    if (section) {
      setSectionData(section);
    }
  }, [contentTables, subsectionId]);
  const [postReferenceData, { isSuccess }] = usePostReferenceDataMutation();
  const [patchReferenceData, patchReferenceDataRes] =
    usePatchReferenceDataMutation();

  const { data: referenceRes } = useGetReferenceDataByIdQuery(
    {
      headers: {
        'Class-Info': currentUser.classInfo,
        role: currentUser?.user_type,
        uuid: currentUser?.user_id,
      },
      referenceDataId: id ? parseInt(id, 10) : 0,
    },
    {
      skip:
        !currentUser || !currentUser.user_type || !currentUser.user_id || !id,
    },
  );
  const article = referenceRes?.data;

  useEffect(() => {
    if (article) {
      setPayload({
        content: article.content,
        filePaths: article.filePaths as unknown as string[],
        scope: 'ALL',
        title: article.title,
      });
    }
  }, [article]);

  const [downloadPaths, setDownloadPaths] = useState<
    Array<{
      downlodablePath: string;
      filePath: string;
    }>
  >([]);
  const [loading, setLoading] = useState(false);
  const handlePayload = (key: string, value: string | string[]) => {
    setPayload({
      ...payload,
      [key]: value,
    });
  };
  const submit = async () => {
    setLoading(true);
    if (id) {
      const reuslt = await patchReferenceData({
        data: {
          content: payload.content,
          filePaths: payload.filePaths,
          title: payload.title,
        },
        headers: {
          'Class-Info': currentUser.classInfo,
          role: currentUser.user_type,
          uuid: currentUser.user_id,
        },
        params: {
          id: Number(id),
        },
      });
      navigate(`/reference/${sectionId}/${subsectionId}/${id}`);
    } else {
      await postReferenceData({
        data: {
          clsId: sectionData?.cls_id || '',
          ...payload,
          scope: payload.scope === 'ALL' ? 'ALL' : 'CLASS',
        },
        headers: {
          'Class-Info': currentUser.classInfo,
          role: currentUser.user_type,
          uuid: currentUser.user_id,
        },
      });
      navigate(`/reference/${sectionId}/${subsectionId}`);
    }
  };

  const getDownloadablePaths = async () => {
    setLoading(true);
    const paths = article?.filePaths;
    const tmp: Array<S3FilePair> = [];
    if (!paths) return;
    for await (const path of paths) {
      const tmpPathObj = {
        downlodablePath: '',
        filePath: path,
      };
      const downlodablePath = await aidtGetPath({
        bucketName: VIDEO_BUCKETS.REFERENCE,
        currentUser,
        path,
      });
      tmpPathObj.downlodablePath = downlodablePath;
      tmp.push(tmpPathObj);
    }
    setDownloadPaths(tmp);
    setLoading(false);
  };

  useEffect(() => {
    if (referenceRes) getDownloadablePaths();
  }, [referenceRes]);

  const initUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const uploadResult = await aidtUploadAndGetPath({
      bucketName: VIDEO_BUCKETS.REFERENCE,
      currentUser,
      e,
    });
    handlePayload('filePaths', [...payload.filePaths, uploadResult.filePath]);
    setDownloadPaths([
      ...downloadPaths,
      {
        ...uploadResult,
      },
    ]);
    setLoading(false);
  };

  const deleteFile = (filePath: string) => {
    const newPayload = payload.filePaths.filter(v => v !== filePath);
    handlePayload('filePath', newPayload);
    setDownloadPaths(downloadPaths.filter(v => v.filePath !== filePath));
  };

  const UploadField = () => {
    return (
      <Stack direction={'row'} gap={1.5} py={1}>
        <TextButton
          buttonStyle={{ gap: 0 }}
          imageStyle={{ height: '1.625rem', width: '1.625rem' }}
          name={t('common.attach_file')}
          onClick={() => {
            document.getElementById('AnnouncementFileUploadForm')?.click();
          }}
          src={'file'}
          textStyle={{
            color: '#818181',
            fontSize: '.625rem',
            fontWeight: 'medium',
          }}
        />
        <input
          accept=".pdf,.pptx,.hwp,.docx"
          className="hidden"
          id="AnnouncementFileUploadForm"
          name="AnnouncementFileUploadForm"
          onChange={e => {
            initUpload(e);
          }}
          type="file"
        />
        <TextButton
          buttonStyle={{ gap: 0 }}
          imageStyle={{ height: '1.625rem', width: '1.625rem' }}
          name={t('common.attach_image')}
          onClick={() => {
            document.getElementById('AnnouncementImageUploadForm')?.click();
          }}
          src={'image'}
          textStyle={{
            color: '#818181',
            fontSize: '.625rem',
            fontWeight: 'medium',
          }}
        />
        <input
          accept="image/*"
          className="hidden"
          id="AnnouncementImageUploadForm"
          name="AnnouncementImageUploadForm"
          onChange={e => {
            initUpload(e);
          }}
          type="file"
        />
      </Stack>
    );
  };

  const imagePahts = downloadPaths.filter(
    v =>
      (v.filePath.endsWith('jpg') ||
        v.filePath.endsWith('png') ||
        v.filePath.endsWith('jpeg')) &&
      v.filePath,
  );

  const filePahts = downloadPaths.filter(
    v =>
      !(
        v.filePath.endsWith('jpg') ||
        v.filePath.endsWith('png') ||
        v.filePath.endsWith('jpeg')
      ) && v.filePath,
  );

  if (currentUser.user_type !== 'T') {
    return (
      <AIDTErrorBoundary
        backLink="/"
        desc="이 페이지에 접근 권한이 없습니다."
        title="잘못된 접근입니다."
      />
    );
  } else {
    return (
      <Layout
        customContainerStyle={{
          backgroundColor: '#fafafa',
          display: 'flex',
          flexDirection: 'column',
        }}
        isDisableGutters={true}
        width={false}
      >
        <Backdrop
          open={loading}
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <SecondaryHeader
          backgroundColor="#4F73C0"
          handleRightButtonClicked={() =>
            navigate(`/reference/${sectionId}/${subsectionId}`)
          }
          rightButtonLabel="참고자료 목록으로 돌아가기"
          rightButtonText="목록으로"
          title={t('reference.reference_material') + ' 등록'}
          titleColor="white"
          titleIcon={<LucideArchive />}
        />
        <Container
          sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pb: 3 }}
        >
          <Box
            component={'form'}
            sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
          >
            <Stack direction={'column'} sx={{ flexGrow: 1, gap: 3, pt: 3 }}>
              <FormControl
                disabled={loading}
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <FormLabel
                  htmlFor="title"
                  sx={{
                    color: 'black',
                    flexGrow: 0,
                    fontSize: '.9375rem',
                    fontWeight: 'medium',
                    minWidth: '4rem',
                    mr: 1,
                  }}
                >
                  {t('common.title')}:
                </FormLabel>
                <TextField
                  id="title"
                  onChange={e => {
                    handlePayload('title', e.target.value);
                  }}
                  placeholder={t('common.please_enter_the_title')}
                  sx={{
                    '& input': {
                      border: '1px solid #DCDCDC',
                      fontSize: '.9375rem',
                      fontWeight: 'medium',
                      padding: '10px 12px',
                    },

                    flexGrow: 1,
                  }}
                  type="text"
                  value={payload.title}
                />
              </FormControl>
              {imagePahts.length > 0 && (
                <div className="flex flex-col items-start justify-start gap-4">
                  <div className="text-sm font-bold">첨부된 이미지</div>
                  <div className="flex items-center justify-start w-full gap-4 p-4 border rounded border-slate-300">
                    {downloadPaths
                      .filter(
                        v =>
                          v.filePath.endsWith('jpg') ||
                          v.filePath.endsWith('png') ||
                          v.filePath.endsWith('jpeg'),
                      )
                      .map((v, i) => (
                        <div className="relative" key={`UPLOADED_IMAGE_${i}`}>
                          <img
                            alt={`첨부된 이미지 ${i + 1}`}
                            className="w-16 h-16 aspect-square"
                            src={v.downlodablePath}
                          />
                          <button
                            className="py-2 absolute -top-2 -right-2 px-2.5 bg-white text-sm -top-4 shadow rounded-full text-red-500"
                            onClick={e => {
                              e.preventDefault();
                              deleteFile(v.filePath);
                            }}
                            type="button"
                          >
                            <LucideTrash size={15} />
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <FormControl
                disabled={loading}
                sx={{
                  alignItems: 'start',
                  display: 'flex',
                  flexDirection: 'row',
                  flexGrow: 1,
                }}
              >
                <Box sx={{ display: 'flex', height: '100%', minWidth: '4rem' }}>
                  <FormLabel
                    htmlFor="title"
                    sx={{
                      color: 'black',

                      fontSize: '.9375rem',
                      fontWeight: 'medium',
                      mr: 1,
                    }}
                  >
                    {t('common.content')}:
                  </FormLabel>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: '100%',
                  }}
                >
                  <Stack sx={{ height: '100%', position: 'relative' }}>
                    <TextField
                      InputProps={{
                        sx: {
                          alignItems: 'start',
                          border: '1px solid #DCDCDC',
                          fontSize: '.9375rem',
                          fontWeight: 'medium',
                          minHeight: '100%',
                          padding: '10px 12px',
                          paddingBottom: '4.25rem',
                        },
                      }}
                      disabled={loading}
                      id="content"
                      multiline
                      onChange={e => {
                        handlePayload('content', e.target.value);
                      }}
                      placeholder={t('common.please_enter_the_content')}
                      sx={{ display: 'flex', flexGrow: 1 }}
                      value={payload.content}
                    />
                  </Stack>
                  <UploadField />
                </Box>
              </FormControl>
              {filePahts.length > 0 && (
                <div className="flex flex-col items-start justify-start gap-4">
                  <div className="text-sm font-bold">첨부된 파일</div>
                  <div className="flex items-center justify-start w-full gap-8 p-4 border rounded border-slate-300">
                    {downloadPaths
                      .filter(
                        v =>
                          !(
                            v.filePath.endsWith('jpg') ||
                            v.filePath.endsWith('png') ||
                            v.filePath.endsWith('jpeg')
                          ),
                      )
                      .map((v, i) => (
                        <div
                          className="relative flex items-center justify-start gap-2"
                          key={`UPLOADED_FILE_${i}`}
                        >
                          <LucideFile size={24} />
                          {v.filePath.split('/').pop()}
                          <button
                            className="py-2 px-2.5 bg-white text-sm -top-4 shadow rounded-full text-red-500"
                            onClick={e => {
                              e.preventDefault();
                              deleteFile(v.filePath);
                            }}
                            type="button"
                          >
                            <LucideTrash size={15} />
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              )}
              <Stack direction={'row'} justifyContent="end">
                <Button
                  disabled={loading}
                  onClick={() => {
                    submit();
                  }}
                  size="large"
                  sx={{
                    backgroundColor: '#4F73C0',
                    borderRadius: '.25rem',
                    fontSize: '.9375rem',
                    padding: '.5rem 1.5rem',
                  }}
                  variant="contained"
                >
                  {id ? '수정' : t('reference.register_material')}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Container>
      </Layout>
    );
  }
}
