import { useQuery } from '@tanstack/react-query';
import { getStudentName } from 'apis/aidt-student';
import { GetStudentNameResponse } from 'schemas/aidt-student';
import { BaseAidtDto } from 'schemas/base';

export function useStudentName({ data, headers }: BaseAidtDto) {
  return useQuery<GetStudentNameResponse, Error, string | undefined>({
    queryFn: () => getStudentName({ data, headers }),
    queryKey: ['student_name', { data, headers }],
    select: data => data.user_name,
  });
}
