import { Box, Button, Stack, Typography } from '@mui/material';
import { useTeacher } from 'queries/use-teacher';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { replaceSchoolName } from 'utils/replace-school-name';

export default function ClassAchievementLevelTable() {
  const user = useSelector((state: RootState) => state.user);

  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  return (
    <Stack
      aria-label="성취기준별 성취수준 테이블입니다."
      sx={{
        backgroundColor: '#F8F8F8',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack alignItems="center" direction="row" justifyContent="center">
        <Typography
          fontSize="1.25rem"
          fontWeight="500"
          paddingY=".5rem"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '1.125rem',
              paddingY: '.375rem',
            },
          })}
          variant="h6"
        >
          {replaceSchoolName(
            teacherQuery.data?.class_info[0].school_name ?? '',
          )}{' '}
          {teacherQuery.data?.class_info[0].user_grade}-
          {Number(teacherQuery.data?.class_info[0].user_class.slice(0, 2))}반
          성취기준별 성취수준
        </Typography>
      </Stack>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        mx=".3125rem"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".5rem .5rem 0 0" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              성취기준별 성취수준 테이블
            </caption>
            <tbody>
              <tr
                style={{
                  backgroundColor: '#E7E3FC',
                }}
              >
                <Box
                  colSpan={3}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 700,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.75rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  학교급 : 고등학교
                </Box>
                <Box
                  colSpan={3}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 700,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.75rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  과목 : 공통수학1
                </Box>
                <Box
                  colSpan={3}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 700,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.75rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  학년군 : 10
                </Box>
                <Box
                  colSpan={3}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 700,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.75rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  영역 : 다항식
                </Box>
              </tr>
              <tr
                style={{
                  backgroundColor: '#E7E3FC',
                }}
              >
                <Box
                  colSpan={2}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.875rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '2.25rem',
                    },
                  })}
                >
                  성취기준
                </Box>
                <Box
                  colSpan={10}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#F9F3FF',
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.875rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '2.25rem',
                    },
                  })}
                >
                  [10공수1-01-01] 다항식의 사칙연산의 원리를 설명하고 그 계산을
                  할 수 있다.
                </Box>
              </tr>
              <tr
                style={{
                  backgroundColor: '#E7E3FC',
                }}
              >
                <Box
                  colSpan={2}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.25rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  평가요소
                </Box>
                <Box
                  colSpan={4}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#FFF',
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    borderRight: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  다항식의 사칙연산을 수행하기
                </Box>
                <Box
                  colSpan={2}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.25rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  평가도구 유형
                </Box>
                <Box
                  colSpan={4}
                  component="th"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#FFF',
                    border: '1px solid #BFC7E3',
                    borderBottom: '2px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.25rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.75rem',
                    },
                  })}
                >
                  선다형, 단답형
                </Box>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
      <Stack alignItems="center" direction="row">
        <Typography
          fontSize="1rem"
          fontWeight="500"
          paddingX="1.5rem"
          paddingY=".5rem"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.875rem',
              paddingY: '.375rem',
            },
          })}
          variant="h6"
        >
          학생별 성취수준 결과
        </Typography>
      </Stack>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        mb=".3125rem"
        mx=".3125rem"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".5rem .5rem 0 0" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              성취기준별 성취수준 테이블
            </caption>
            <tbody>
              <tr
                style={{
                  backgroundColor: '#F9F3FF',
                }}
              >
                <Box
                  colSpan={2}
                  component="th"
                  paddingX=".875rem"
                  rowSpan={2}
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '2.875rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '2.25rem',
                    },
                  })}
                >
                  김나현
                </Box>
                <Box
                  colSpan={2}
                  component="td"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#E7E3FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '1.875rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  평가문항 수 : 50
                </Box>
                <Box
                  colSpan={2}
                  component="td"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#E7E3FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '1.875rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  평가점수 : 90
                </Box>
                <Box
                  colSpan={2}
                  component="td"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#E7E3FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '1.875rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    gap={0.5}
                    justifyContent="space-between"
                  >
                    피드백문제
                    <Button
                      sx={theme => ({
                        '&:hover': {
                          backgroundColor: '#4F73C0',
                        },
                        backgroundColor: '#4F73C0',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        color: 'white',
                        fontSize: '.75rem',
                        fontWeight: 500,
                        height: '1.375rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.25rem',
                        },
                        width: '3.75rem',
                      })}
                      variant="contained"
                    >
                      보기
                    </Button>
                  </Stack>
                </Box>
                <Box
                  colSpan={4}
                  component="td"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#E7E3FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.875rem',
                    fontWeight: 500,
                    height: '1.875rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  성취기준별 성취 수준: A
                </Box>
              </tr>
              <tr>
                <Box
                  colSpan={10}
                  component="td"
                  paddingX=".875rem"
                  scope="colgroup"
                  sx={theme => ({
                    backgroundColor: '#FFF',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 500,
                    height: '2rem',
                    textAlign: 'left',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  다항식의 사칙연산의 원리를 이해하여 설명할 수 있으며, 그
                  계산을 수학적 절차에 따라 체계적으로 수행할 수 있다.
                </Box>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Stack>
  );
}
