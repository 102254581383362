import { useQuery } from '@tanstack/react-query';
import { getStudentStatistics } from 'apis/dashboard';
import {} from 'schemas/assignment';
import {
  GetStudentStatisticsDto,
  GetStudentStatisticsResponse,
  StatisticItem,
} from 'schemas/student-dashboard';

export function useStudentStatistics({
  headers,
  params,
}: GetStudentStatisticsDto) {
  return useQuery<GetStudentStatisticsResponse, Error, StatisticItem[]>({
    enabled: !!params.learningSysId && !!params.classUuids,
    queryFn: () => getStudentStatistics({ headers, params }),
    queryKey: ['student_statistics', { headers, params }],
    retry: false,
    select: data => data.data.statisticItems,
  });
}
