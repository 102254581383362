import { useQuery } from '@tanstack/react-query';
import { getRecentStatus } from 'apis/user';
import {
  GetRecentStatusDto,
  GetRecentStatusResponse,
  RecentStatus,
} from 'schemas/user';

export function useRecentStatus({ headers }: GetRecentStatusDto) {
  return useQuery<GetRecentStatusResponse, Error, RecentStatus>({
    queryFn: () => getRecentStatus({ headers }),
    queryKey: ['recent_status'],
    select: data => data.data,
  });
}
