import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { toast } from 'components/ui/use-toast';
import { useNavigate } from 'react-router-dom';

interface ErrorResponse {
  message: {
    message: string;
  };
  statusCode: number;
}

const getSubdomain = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split('.')[0];
  return subdomain;
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_NODE_ENV === 'development'
      ? `/api`
      : `https://api.${getSubdomain()}.${process.env.REACT_APP_MY_DOMAIN}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const { method, url } = config;
  const token = localStorage.getItem('token');

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const onResponse = (res: AxiosResponse): AxiosResponse => {
  const { method, url } = res.config;
  const { code, message } = res.data;

  return res;
};

const onError = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    if (error.response) {
      const { message, statusCode } = error.response.data as ErrorResponse;
      if (error.response.status === 401 || error.response.status === 403) {
        const navigate = useNavigate();
        navigate('/expired');
      } else if (error.response.status === 400) {
        if (error.response?.data?.message?.message)
          toast({
            description: error.response.data.message.message,
            variant: 'destructive',
          });
      } else if (error.response.status === 500) {
        toast({
          description: `서버에 500 ERROR가 발생했습니다. 잠시 후 다시 시도해주세요.`,
          variant: 'destructive',
        });
      }
    }
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.request.use(onRequest);
axiosInstance.interceptors.response.use(onResponse, onError);

export default axiosInstance;
