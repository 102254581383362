import qs from 'qs';
import {
  GetAchievementStandardDto,
  GetAchievementStandardResponse,
  GetCumulativeAccuracyDataDto,
  GetCumulativeAccuracyDataResponse,
  GetCumulativeDataDto,
  GetCumulativeDataResponse,
  GetLearningHistoryDto,
  GetLearningHistoryResponse,
  GetMvpDataResponse,
  GetStrategyCommentDataDto,
  GetStrategyCommentDataResponse,
  GetStudentStatisticsComparisonDto,
  GetStudentStatisticsComparisonResponse,
  GetStudentStatisticsDto,
  GetStudentStatisticsResponse,
  GetStudentWeakChaptersDto,
  GetStudentWeakChaptersResponse,
  UpdateDashboardDto,
  UpdateDashboardResponse,
} from 'schemas/student-dashboard';
import {
  GetClassLeaningHistoryResponse,
  GetClassLearningHistoryDto,
  GetConceptVideosDto,
  GetConceptVideosResponse,
  GetDescendingStudentsDto,
  GetDescendingStudentsResponse,
  GetLearningProblemsDto,
  GetLearningProblemsResponse,
  GetTeacherStatisticsComparisonDto,
  GetTeacherStatisticsComparisonResponse,
  GetTeacherStatisticsDto,
  GetTeacherStatisticsResponse,
  GetTeacherWeakChaptersDto,
  GetTeacherWeakChaptersResponse,
  GetUserPlanNotesDto,
  GetUserPlanNotesResponse,
} from 'schemas/teacher-dashboard';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/dashboard';

// 학습전략추천 데이터 조회
export const getStrategyComment = async ({
  headers,
  params,
}: GetStrategyCommentDataDto): Promise<GetStrategyCommentDataResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/strategy-comment-data`, {
      headers,
      params,
    })
  ).data;
};

// 누적 데이터 조회
export const getCumulativeData = async ({
  headers,
  params,
}: GetCumulativeDataDto): Promise<GetCumulativeDataResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/accumulated-data`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학생 현재 소단원별 학습결과 내역 조회
export const getLearningHistory = async ({
  headers,
  params,
}: GetLearningHistoryDto): Promise<GetLearningHistoryResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/learning-history`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 현재 소단원 목표 결과 분석 조회
export const getStudentStatistics = async ({
  headers,
  params,
}: GetStudentStatisticsDto): Promise<GetStudentStatisticsResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/student-statistics`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학생 취약단원순 난이도별 분석 조회
export const getStudentWeakChapters = async ({
  headers,
  params,
}: GetStudentWeakChaptersDto): Promise<GetStudentWeakChaptersResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/student-weak-chapters`, {
      headers,
      params,
    })
  ).data;
};

// 소단원별 학습결과 비교
export const getStudentStatisticsComparison = async ({
  headers,
  params,
}: GetStudentStatisticsComparisonDto): Promise<GetStudentStatisticsComparisonResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/student-statistics-recent`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 반 목표 결과 분석 조회
export const getTeacherStatistics = async ({
  headers,
  params,
}: GetTeacherStatisticsDto): Promise<GetTeacherStatisticsResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/teacher-statistics`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 2개 소단원 연속하락 리스트 조회
export const getDescendingStudents = async ({
  headers,
  params,
}: GetDescendingStudentsDto): Promise<GetDescendingStudentsResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/descending-students`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학급 현재 소단원별 학습결과 내역 조회
export const getClassLearningHistory = async ({
  headers,
  params,
}: GetClassLearningHistoryDto): Promise<GetClassLeaningHistoryResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/class-learning-history`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학급 소단원별 학습결과 비교 그래프 조회
export const getTeacherStatisticsComparison = async ({
  headers,
  params,
}: GetTeacherStatisticsComparisonDto): Promise<GetTeacherStatisticsComparisonResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/teacher-statistics-recent`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학급 취약단원순 난이도별 분석 조회
export const getTeacherWeakChapters = async ({
  data,
  headers,
}: GetTeacherWeakChaptersDto): Promise<GetTeacherWeakChaptersResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/teacher-weak-chapters`, data, {
      headers,
    })
  ).data;
};

// 교사 희망노트 조회
export const getUserPlanNotes = async ({
  data,
  headers,
}: GetUserPlanNotesDto): Promise<GetUserPlanNotesResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/teacher-users-plan-notes`, data, {
      headers,
    })
  ).data;
};

// 난이도별 문제풀이 및 정답률 누적데이터 조회
export const getCumulativeAccuracyData = async ({
  headers,
  params,
}: GetCumulativeAccuracyDataDto): Promise<GetCumulativeAccuracyDataResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/accumulated-data-2`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 각 부분별 MVP 조회 (왕정희)
export const getMVP = async ({
  headers,
  params,
}: GetCumulativeAccuracyDataDto): Promise<GetMvpDataResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/mvp`, {
      headers,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'comma', encode: false });
      },
    })
  ).data;
};

// 학습결과내역 문제 조회
export const getLearningProblems = async ({
  data,
  headers,
}: GetLearningProblemsDto): Promise<GetLearningProblemsResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/teacher/student-performs`, data, {
      headers,
    })
  ).data;
};

// 공유 영상 조회
export const getConceptVideos = async ({
  data,
  headers,
}: GetConceptVideosDto): Promise<GetConceptVideosResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/teacher/concept-videos`, data, {
      headers,
    })
  ).data;
};

// 성취수준 조회
export const getAchievementStandard = async ({
  headers,
  params,
}: GetAchievementStandardDto): Promise<GetAchievementStandardResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/achievement-standard`, {
      headers,
      params,
    })
  ).data;
};

// 유저 학습현황 업데이트
export const updateDashboard = async ({
  headers,
}: UpdateDashboardDto): Promise<UpdateDashboardResponse> => {
  return (
    await axiosInstance.patch(
      `${ENDPOINT}`,
      {},
      {
        headers,
      },
    )
  ).data;
};
