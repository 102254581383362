import { generateMock } from '@anatine/zod-mock';
import { Box, Stack, Typography } from '@mui/material';
import { chartOptions } from 'app/components/Dashboard/StudentDashboard';
import { useLearningSystem } from 'queries/use-learning-system';
import { useStudentClassMembers } from 'queries/use-student-class-members';
import { useStudentName } from 'queries/use-student-name';
import { useStudentStatisticsComparison } from 'queries/use-student-statistics-comparison';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { GetStudentStatisticsComparisonResponseSchema } from 'schemas/student-dashboard';
import { RootState } from 'types';

export default function StudentStatisticsRecentChart() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 학생 성명 조회
  const studentNameQuery = useStudentName({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학급 구성원 조회
  const classMembersQuery = useStudentClassMembers({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 소단원별 학습결과 비교 조회
  const studentStatisticsComparisonQuery = useStudentStatisticsComparison({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      // classUuids: classMembersQuery.data?.map(member => member.user_id) || [
      //   user.user_id,
      // ],
      classUuids: [user.user_id],
      learningSysId: Number(subsectionId as string),
    },
  });

  // const studentStatisticsComparisonQuery = generateMock(
  //   GetStudentStatisticsComparisonResponseSchema,
  //   {
  //     stringMap: {
  //       learningSysName: () => '소수와 합성수',
  //     },
  //   },
  // );

  // 차트 데이터 설정
  const chartDataSet = chartData => {
    return {
      datasets: [
        {
          backgroundColor: '#5B9BD5',
          data: chartData[0],
          label:
            studentStatisticsComparisonQuery.data?.[0]?.learningSysName || '',
        },
        {
          backgroundColor: '#9E74F2',
          data: chartData[1] && chartData[1],
          label:
            studentStatisticsComparisonQuery.data?.[1]?.learningSysName || '',
        },
        {
          backgroundColor: '#FFC000',
          data: chartData[2] && chartData[2],
          label:
            studentStatisticsComparisonQuery.data?.[2]?.learningSysName || '',
        },
      ],
      labels: [''],
    };
  };

  // 차트 데이터
  const chartData = [
    {
      data: chartDataSet([
        [
          studentStatisticsComparisonQuery.data?.[0]?.statisticItems?.find(
            i => i.itemName === 'PROGRESS' || 0,
          )?.userAchievement,
        ],
        [
          studentStatisticsComparisonQuery.data?.[1]?.statisticItems?.find(
            i => i.itemName === 'PROGRESS',
          )?.userAchievement || 0,
        ],
        [
          studentStatisticsComparisonQuery.data?.[2]?.statisticItems?.find(
            i => i.itemName === 'PROGRESS',
          )?.userAchievement || 0,
        ],
      ]),
      options: chartOptions,
      title: 'common.progress_rate',
    },
    {
      data: chartDataSet([
        [
          studentStatisticsComparisonQuery.data?.[0]?.statisticItems?.find(
            i => i.itemName === 'CORRECT_RATE',
          )?.userAchievement || 0,
        ],
        [
          studentStatisticsComparisonQuery.data?.[1]?.statisticItems?.find(
            i => i.itemName === 'CORRECT_RATE',
          )?.userAchievement || 0,
        ],
        [
          studentStatisticsComparisonQuery.data?.[2]?.statisticItems?.find(
            i => i.itemName === 'CORRECT_RATE',
          )?.userAchievement || 0,
        ],
      ]),
      options: chartOptions,
      title: 'common.accuracy_rate',
    },
    {
      data: chartDataSet([
        [
          studentStatisticsComparisonQuery.data?.[0]?.statisticItems?.find(
            i => i.itemName === 'METACOGNITION' || 0,
          )?.userAchievement,
        ],
        [
          studentStatisticsComparisonQuery.data?.[1]?.statisticItems?.find(
            i => i.itemName === 'METACOGNITION' || 0,
          )?.userAchievement,
        ],
        [
          studentStatisticsComparisonQuery.data?.[2]?.statisticItems?.find(
            i => i.itemName === 'METACOGNITION' || 0,
          )?.userAchievement,
        ],
      ]),
      options: chartOptions,
      title: 'common.metacognition',
    },
    {
      data: chartDataSet([
        [
          studentStatisticsComparisonQuery.data?.[0]?.statisticItems?.find(
            i => i.itemName === 'LEVEL' || 0,
          )?.userAchievement,
        ],
        [
          studentStatisticsComparisonQuery.data?.[1]?.statisticItems?.find(
            i => i.itemName === 'LEVEL' || 0,
          )?.userAchievement,
        ],
        [
          studentStatisticsComparisonQuery.data?.[2]?.statisticItems?.find(
            i => i.itemName === 'LEVEL' || 0,
          )?.userAchievement,
        ],
      ]),
      options: chartOptions,
      title: 'common.learning_stage',
    },
  ];

  return (
    <Box
      aria-label={`${
        learningSystemQuery.data?.type === 'SECTION' ? '나의 ' : '대단원 내 '
      }소단원별 학습결과 비교를 나타내는 차트입니다. 진도율, 정답률, 메타인지, 학습단계의 총 4개의 차트가 포함되어 있고, 각 항목별로 해당하는 단원의 데이터를 나타냅니다.`}
      sx={{
        backgroundColor: '#EBF2FE',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '.75rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        {learningSystemQuery.data?.type === 'SECTION'
          ? t('student_dashboard.comparison_of_learning_outcomes', {
              name: studentNameQuery.data,
            })
          : '대단원 내 소단원 학습결과 비교'}
      </Typography>
      <Box paddingBottom=".3125rem" paddingX=".3125rem">
        <Stack
          alignItems="center"
          component="article"
          direction="row"
          gap=".625rem"
          justifyContent="space-between"
          paddingX=".625rem"
          paddingY=".75rem"
          sx={{
            backgroundColor: '#FFF',
            borderBottomLeftRadius: '.625rem',
            borderBottomRightRadius: '.625rem',
          }}
        >
          <Stack
            alignItems="start"
            direction="column"
            gap=".625rem"
            justifyContent="center"
            minWidth="9.9256rem"
            paddingLeft="1.25rem"
            paddingRight="2.25rem"
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                minWidth: '6.515rem',
                paddingLeft: '.625rem',
                paddingRight: '.625rem',
              },
            })}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#5B9BD5',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {studentStatisticsComparisonQuery.data?.[0]?.learningSysName ||
                  ''}
              </Typography>
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#9E74F2',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {studentStatisticsComparisonQuery.data?.[1]?.learningSysName ||
                  ''}
              </Typography>
            </Stack>

            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#FFC000',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {studentStatisticsComparisonQuery.data?.[2]?.learningSysName ||
                  ''}
              </Typography>
            </Stack>
          </Stack>
          {chartData.map((chart, index) => (
            <Box
              aria-label={`${t(chart.title)} 차트입니다.${
                chart.data.datasets[0]?.label
                  ? ` ${chart.data.datasets[0]?.label}: ${chart.data.datasets[0]?.data[0]}, `
                  : ''
              }${
                chart.data.datasets[1]?.label
                  ? `${chart.data.datasets[1]?.label}: ${chart.data.datasets[1]?.data[0]}, `
                  : ''
              }${
                chart.data.datasets[2]?.label
                  ? `${chart.data.datasets[2]?.label}: ${chart.data.datasets[2]?.data[0]}`
                  : ''
              }`}
              border="1px solid #E0E0E0"
              borderRadius=".5rem"
              flex={1}
              key={index}
              sx={{
                backgroundColor: 'white',
              }}
              tabIndex={0}
              width="20%"
            >
              <Box paddingTop=".25rem" paddingX=".25rem">
                <Box
                  borderRadius=".375rem"
                  paddingX="10px"
                  paddingY="2px"
                  sx={{
                    backgroundColor: '#F8F8F8',
                  }}
                >
                  <Typography
                    fontSize="15px"
                    fontWeight="500"
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.75rem',
                      },
                    })}
                    variant="h6"
                  >
                    {t(chart.title)}
                  </Typography>
                </Box>
              </Box>
              <Box height="6rem">
                <Bar
                  data={chart.data}
                  fallbackContent={<p>차트가 표시하는 데이터의 대체 텍스트</p>}
                  options={chart.options}
                />
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
