import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
export const StyledLink = styled(Link)({
  alignItems: 'center',
  color: 'inherit',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '.75rem',
  justifyContent: 'center',
  margin: '0 7px',
  position: 'relative',
  textDecoration: 'none',
});
