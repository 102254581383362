import { Box, Typography } from '@mui/material';
import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { X } from 'lucide-react';
import { useMvp } from 'queries/dashboard/use-mvp';
import { useStudentNames } from 'queries/use-student-names';
import * as React from 'react';
import { useSelector } from 'react-redux';
import useDialogStore from 'stores/use-dialog-store';
import { RootState } from 'types';

export function MVPListDialog() {
  const open = useDialogStore(state => state.mvpList);
  const toggleDialog = useDialogStore(state => state.toggleDialog);
  const user = useSelector((state: RootState) => state.user);

  //mvp 데이터 조회
  const mvpQuery = useMvp({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      // classUuids: classMembersQuery.data?.map(member => member.user_id) || [
      //   user.user_id,
      // ],
      classUuids: [user.user_id],
    },
  });

  // 질문답변 학생 성명 리스트 조회
  const qnaStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: mvpQuery.data?.QnA.map(item => item) || [],
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // SNS활동 학생 성명 리스트 조회
  const snsStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: mvpQuery.data?.SNS.map(item => item) || [],
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // 정답률 학생 성명 리스트 조회
  const correctRateStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: mvpQuery.data?.CorrectRate.map(item => item) || [],
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // 정답률 학생 성명 리스트 조회
  const numSolveStudentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: mvpQuery.data?.NumSolve.map(item => item) || [],
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  const MVPNamesList = () => {
    if (mvpQuery.isLoading) {
      return <div className="p-2 text-center">불러오는 중...</div>;
    }
    if (mvpQuery.isError) {
      return (
        <div className="p-2 text-center">
          에러가 발생하여 불러올 수 없습니다.
        </div>
      );
    }
    if (mvpQuery.isSuccess) {
      return (
        <div className="flex flex-col gap-8 p-8 text-sm font-normal">
          <p className="flex items-center gap-2">
            <span className="font-semibold">질문답변 :</span>
            {qnaStudentNamesQuery.data?.map((item, index) => (
              <Typography
                fontSize=".75rem"
                key={index}
                noWrap
                sx={theme => ({
                  fontWeight: item.user_id === user.user_id ? '600' : '400',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
              >
                {item.user_name}
              </Typography>
            ))}
          </p>
          <p className="flex items-center gap-2">
            <span className="font-semibold">SNS활동 :</span>
            {snsStudentNamesQuery.data?.map((item, index) => (
              <Typography
                fontSize=".75rem"
                key={index}
                noWrap
                sx={theme => ({
                  fontWeight: item.user_id === user.user_id ? '600' : '400',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
              >
                {item.user_name}
              </Typography>
            ))}
          </p>
          <p className="flex items-center gap-2">
            <span className="font-semibold">정답률 :</span>
            {correctRateStudentNamesQuery.data?.map((item, index) => (
              <Typography
                fontSize=".75rem"
                key={index}
                noWrap
                sx={theme => ({
                  fontWeight: item.user_id === user.user_id ? '600' : '400',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
              >
                {item.user_name}
              </Typography>
            ))}
          </p>
          <p className="flex items-center gap-2">
            <span className="font-semibold">문제풀이수 :</span>
            {numSolveStudentNamesQuery.data?.map((item, index) => (
              <Typography
                fontSize=".75rem"
                key={index}
                noWrap
                sx={theme => ({
                  fontWeight: item.user_id === user.user_id ? '600' : '400',
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
              >
                {item.user_name}
              </Typography>
            ))}
          </p>
        </div>
      );
    }
  };

  return (
    <Dialog
      onOpenChange={(open: boolean) => toggleDialog('mvpList', open)()}
      open={open}
    >
      <DialogContent className="gap-0 p-0 sm:max-w-xl">
        <DialogHeader className="relative flex-row h-10 space-y-0">
          <DialogTitle className="flex flex-1 justify-center gap-2 items-center border-b border-[#DCDCDC] text-lg font-medium">
            <Box component="img" height={26} src={'/icons/mvp.svg'} />
            <h3>각 부분별 MVP</h3>
          </DialogTitle>
          <Button
            className="absolute p-0 -translate-y-1/2 right-2 size-6 top-1/2"
            onClick={() => toggleDialog('mvpList', false)()}
            onKeyDown={e =>
              e.key === 'Enter' && toggleDialog('mvpList', false)()
            }
            variant="ghost"
          >
            <X className="size-6" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <MVPNamesList />
      </DialogContent>
    </Dialog>
  );
}
