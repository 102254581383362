import { Box, CircularProgress, Stack } from '@mui/material';
import { t } from 'i18next';
import { LucideLayoutDashboard, LucidePencilLine } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SectionItem from './SectionItem';

export default function UnitContents({
  indexList,
  setIndexList,
  unit,
  unitIdx,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  if ((unit?.chapter?.length || 0) === 0)
    return (
      <div className="flex items-center justify-center p-4">
        <CircularProgress />
      </div>
    );
  return (
    <>
      {unit.chapter.map((chapter, chapterIdx) => {
        const chaptertitle = `${unitIdx + 1}-${chapterIdx + 1} ${
          chapter.chapter_name
        }`;
        return (
          <div key={`CHAPTER_${chapter.learning_sys_id}`}>
            {/**중단원 */}
            <h3 className="pb-4 pt-2 text-xl font-black">{chaptertitle}</h3>
            {/**소단원 루프 */}
            {chapter.section.map((section, index) => (
              <SectionItem
                chapterIdx={chapterIdx}
                index={index}
                indexList={indexList}
                key={`SECTION_ITEM_${section.learning_sys_id}`}
                section={section}
                setIndexList={setIndexList}
                unitIdx={unitIdx}
              />
            ))}
          </div>
        );
      })}
      {/* 대단원 대시보드 */}
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        pt={4}
      >
        <h3 className="text-xl font-black" tabIndex={0}>
          {`${unit.unit_name} `}
          {t('chapter.dashboard')}
        </h3>
        <Box
          sx={{
            border: '1px dotted #CCCCCC',
            flex: 1,
          }}
        />
        <button
          aria-label={`${unit.unit_name} ` + t('chapter.dashboard')}
          className="border border-slate-300 px-3 text-lg flex items-center justify-center gap-1 h-[36px] ml-4 rounded-md"
          onClick={() => {
            navigate(`/dashboard/${unit.learning_sys_id}`);
          }}
          tabIndex={0}
        >
          <LucideLayoutDashboard size={20} strokeWidth={1.3} />
          <span>대단원 {`${t('chapter.dashboard')}`}</span>
        </button>
      </Stack>

      {/**대단원 형성평가 */}
      <Stack
        alignItems={'center'}
        direction={'row'}
        gap={1}
        justifyContent={'space-between'}
        pt={4}
      >
        <h3
          className="text-xl font-black flex items-end justify-start gap-2"
          tabIndex={0}
        >
          {`${unit.unit_name} `}
          {t('evaluation.formative')}
          <LucidePencilLine size={24} strokeWidth={1.7} />
        </h3>
        <Box
          sx={{
            border: '1px dotted #CCCCCC',
            flex: 1,
          }}
        />
        <button
          aria-label={`${unit.unit_name} ` + t('chapter.dashboard')}
          className="border border-slate-300 px-3 text-lg flex items-center justify-center gap-1 h-[36px] ml-4 rounded-md"
          onClick={() => {
            navigate(`/assessment/unit-${unit.learning_sys_id}`);
          }}
          tabIndex={0}
        >
          <LucidePencilLine size={20} strokeWidth={1.3} />
          <span>{`${t('chapter.go_to')}`}</span>
        </button>
      </Stack>

      {/**학기 총괄평가 */}
      {indexList.length === unitIdx + 1 && (
        <Stack
          alignItems={'center'}
          className="pt-10"
          direction={'row'}
          gap={1}
          justifyContent={'space-between'}
        >
          <div
            className="text-2xl font-black flex items-end justify-start gap-2"
            tabIndex={0}
          >
            {t('evaluation.summative')}
            <LucidePencilLine size={28} strokeWidth={1.7} />
          </div>
          <Box
            sx={{
              border: '1px dotted #CCCCCC',
              flex: 1,
            }}
          />
          <button
            aria-label={`${unit.unit_name} ` + t('chapter.dashboard')}
            className="border border-slate-300 px-3 text-lg flex items-center justify-center gap-1 h-[36px] ml-4 rounded-md"
            onClick={() => {
              navigate(`/assessment/comprehensive`);
            }}
            tabIndex={0}
          >
            <LucidePencilLine size={20} strokeWidth={1.3} />
            <span>{`${t('chapter.go_to')}`}</span>
          </button>
        </Stack>
      )}
    </>
  );
}
