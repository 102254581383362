import { Box, Stack, Typography } from '@mui/material';
import { useStrategyComment } from 'queries/use-strategy-comment';
import { useStudentName } from 'queries/use-student-name';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';

export default function StrategyComment() {
  const { t } = useTranslation();

  // 단원 아이디
  const { subsectionId } = useParams();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 학생 성명 조회
  const studentNameQuery = useStudentName({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학습전략추천 데이터 조회
  const strategyCommentQuery = useStrategyComment({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learning_sys_id: subsectionId ? Number(subsectionId) : undefined,
    },
    user_name: studentNameQuery.data ? studentNameQuery.data : undefined,
  });

  return (
    <Stack
      aria-label={`담임 선생님의 학습전략추천: ${
        strategyCommentQuery.data ?? '비어 있습니다.'
      }`}
      border="3px solid #83A3E9"
      borderRadius=".5rem"
      component="article"
      gridColumn="span 1"
      height="8rem"
      padding=".125rem"
      sx={theme => ({
        backgroundColor: '#EBF2FE',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',

        [theme.breakpoints.down('md')]: {
          height: '6.5rem',
        },
      })}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".375rem"
        height="2rem"
        justifyContent="center"
        sx={theme => ({
          backgroundColor: '#CEE2FA',
          borderTopLeftRadius: '.5rem',
          borderTopRightRadius: '.5rem',
          [theme.breakpoints.down('md')]: {
            height: '1.5rem',
          },
        })}
      >
        <Box
          alt="말풍선 아이콘"
          component="img"
          height={18}
          src="/icons/ic_message.svg"
          sx={theme => ({
            [theme.breakpoints.down('md')]: { height: 14, width: 14 },
          })}
          width={16}
        />
        <Typography
          fontSize=".9375rem"
          fontWeight="500"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.625rem',
            },
          })}
          variant="subtitle1"
        >
          {t('student_dashboard.recommended_learning_strategy', {
            name: '담임',
          })}
        </Typography>
      </Stack>
      <Stack
        alignItems="center"
        flex={1}
        justifyContent="center"
        px={1}
        sx={{
          backgroundColor: '#fff',
          borderBottomLeftRadius: '.5rem',
          borderBottomRightRadius: '.5rem',
        }}
      >
        <Typography
          color="#1A48A9"
          fontSize=".75rem"
          fontWeight="500"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.5625rem',
            },
            wordBreak: 'keep-all',
            wordWrap: 'break-word',
          })}
          textAlign="center"
        >
          {strategyCommentQuery.data ?? '-'}
        </Typography>
      </Stack>
    </Stack>
  );
}
