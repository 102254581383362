import { Box, Typography } from '@mui/material';
import { LucideArrowLeft, LucideMegaphone } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function NoticeSubHeader({
  backButton,
}: {
  backButton: boolean;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  //157C69
  return (
    <section className="bg-[#157C69] h-[64px] flex items-center justify-center">
      <div className="container justify-between items-center flex">
        <div className="w-1/5">
          {backButton && (
            <button
              className="text-white p-4 flex items-center justify-start gap-1"
              onClick={() => {
                navigate('/notice');
              }}
            >
              <LucideArrowLeft size={24} />
              <span>뒤로가기</span>
            </button>
          )}
        </div>
        <div className="flex-grow text-center flex items-center justify-center gap-4">
          <div className="flex items-center justify-center p-2 text-xl bg-white rounded-full">
            <LucideMegaphone />
          </div>
          <Typography
            color={'white'}
            component={'h2'}
            fontSize="1.5rem"
            fontWeight="700"
            variant={'h6'}
          >
            {t('announcement.announcement')}
          </Typography>
        </div>
        <div className="w-1/5"></div>
      </div>
    </section>
  );
}
