import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import {
  HeartFillIcon,
  HeartIcon,
  MessageIcon,
  PencilIcon,
  ProfileIcon,
  XIcon,
} from 'app/components/icons';
import { Form, FormControl, FormField, FormItem } from 'components/ui/form';
import { Input } from 'components/ui/input';
import { useRecentStatus } from 'queries/user/use-recent-status';
import { useUpdateStatus } from 'queries/user/use-update-status';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateStatusFormSchema, UpdateStatusFormValues } from 'schemas/form';
import useDrawerStore from 'stores/use-drawer-store';
import { RootState } from 'types';

import { MENTAL_ICONS } from '../../../constants';

export default function ProfileSettingDrawer() {
  const { open, toggleDrawer } = useDrawerStore(state => ({
    open: state.profileSetting,
    toggleDrawer: state.toggleDrawer,
  }));

  const { subsectionId } = useParams();

  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  // 학생 최근 상태 조회
  const recentStatusQuery = useRecentStatus({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
  });

  // 학생 상태 변경
  const updateStatusMutation = useUpdateStatus();

  const defaultValues: Partial<UpdateStatusFormValues> = {
    mentalState: recentStatusQuery.data?.mentalStates[0] || 1,
    physicalState: recentStatusQuery.data?.physicalStates[0] || 1,
    statusMessage: recentStatusQuery.data?.statusMessage || '',
  };

  const form = useForm<UpdateStatusFormValues>({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(UpdateStatusFormSchema),
  });

  function onSubmit(values: UpdateStatusFormValues) {
    updateStatusMutation.mutate({
      data: values,
      headers: {
        role: user.user_type,
        uuid: user.user_id,
      },
    });
  }

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '60%',
        },
      }}
      onClose={toggleDrawer('profileSetting', false)}
      open={open}
    >
      <Form {...form}>
        <Stack
          component="form"
          flex={1}
          onSubmit={form.handleSubmit(onSubmit)}
          role="presentation"
        >
          <Stack direction="row" height="6rem">
            <Stack sx={{ backgroundColor: '#CEE2FA' }} width="11.25rem">
              <Typography
                fontSize="1.125rem"
                fontWeight={700}
                noWrap
                pl="1.5rem"
                pt=".75rem"
              >
                프로필 설정
              </Typography>
            </Stack>
            <Stack alignItems="end" flex={1} p="1rem">
              <IconButton
                onClick={toggleDrawer('profileSetting', false)}
                sx={{ height: '1.5rem', width: '1.5rem' }}
              >
                <XIcon sx={{ height: '1.5rem', width: '1.5rem' }} />
              </IconButton>
            </Stack>
          </Stack>

          <Stack direction="row" height="5.625rem">
            <Stack
              justifyContent="center"
              sx={{ backgroundColor: '#CEE2FA' }}
              width="11.25rem"
            >
              <Stack
                alignItems="center"
                direction="row"
                gap=".3125rem"
                gridRow="span 1"
                paddingLeft="1rem"
              >
                <Stack
                  alignItems="center"
                  height={24}
                  justifyContent="center"
                  width={24}
                >
                  <ProfileIcon
                    sx={{
                      color: '#2A343D',
                      height: '1.125rem',
                      width: '1.125rem',
                    }}
                  />
                </Stack>
                <Typography fontSize=".875rem" fontWeight={500}>
                  장래희망
                </Typography>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              gap=".5rem"
              paddingLeft="1.5625rem"
            >
              <Button
                onClick={() => {
                  toggleDrawer('profileSetting', false)();
                  navigate('/note', { state: { subsectionId } });
                }}
                sx={{ color: '#1A48A9', fontSize: '1rem', fontWeight: 500 }}
                variant="text"
              >
                수정하기
              </Button>
            </Stack>
          </Stack>
          <Stack direction="row" height="5.625rem">
            <Stack
              justifyContent="center"
              sx={{ backgroundColor: '#CEE2FA' }}
              width="11.25rem"
            >
              <Stack
                alignItems="center"
                direction="row"
                gap=".3125rem"
                gridRow="span 1"
                paddingLeft="1rem"
              >
                <Stack
                  alignItems="center"
                  height={24}
                  justifyContent="center"
                  width={24}
                >
                  <MessageIcon
                    sx={{
                      color: '#2A343D',
                      height: '1.125rem',
                      width: '1.125rem',
                    }}
                  />
                </Stack>
                <Typography fontSize=".875rem" fontWeight={500}>
                  한줄평
                </Typography>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              gap=".5rem"
              paddingLeft="1.5625rem"
            >
              <FormField
                control={form.control}
                name="statusMessage"
                render={({ field }) => (
                  <FormItem className="w-full flex items-center pr-2 space-y-0 max-w-72 border border-[#B4B4B4] bg-[#FBFBFB] rounded-md overflow-hidden">
                    <FormControl>
                      <Input
                        {...field}
                        className="bg-transparent border-0 rounded-none focus-visible:ring-0"
                      />
                    </FormControl>
                    <PencilIcon
                      sx={{
                        color: '#B4B4B4',
                        height: '1rem',
                        width: '1rem',
                      }}
                    />
                  </FormItem>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" height="5.625rem">
            <Stack
              justifyContent="center"
              sx={{ backgroundColor: '#CEE2FA' }}
              width="11.25rem"
            >
              <Stack
                alignItems="center"
                direction="row"
                gap=".3125rem"
                gridRow="span 1"
                paddingLeft="1rem"
              >
                <Stack
                  alignItems="center"
                  height={24}
                  justifyContent="center"
                  width={24}
                >
                  <HeartFillIcon
                    sx={{
                      color: '#2A343D',
                      height: 'auto',
                      width: '1.25rem',
                    }}
                  />
                </Stack>
                <Typography fontSize=".875rem" fontWeight={500}>
                  오늘의 멘탈
                </Typography>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              paddingLeft="1.5625rem"
            >
              <FormField
                control={form.control}
                name="mentalState"
                render={({ field }) => (
                  <FormItem>
                    <StyledIconSelect
                      IconComponent={() => null}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            paddingBottom: 0,
                            paddingTop: 0,
                          },
                        },
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'center',
                        },
                        transformOrigin: {
                          horizontal: 'left',
                          vertical: 'center',
                        },
                      }}
                      displayEmpty
                      onChange={field.onChange}
                      renderValue={() => {
                        return (
                          <Box
                            alt={''}
                            component="img"
                            height={46}
                            src={`/${
                              MENTAL_ICONS.find(
                                icon => icon.type === field.value,
                              )?.itemSrc
                            }`}
                            sx={theme => ({
                              [theme.breakpoints.down('md')]: {
                                height: 36,
                                width: 36,
                              },
                            })}
                            width={46}
                          />
                        );
                      }}
                      sx={{
                        backgroundColor: '#EBF2FE',
                        borderRadius: '50%',
                      }}
                      value={field.value}
                    >
                      {MENTAL_ICONS.map(mentalIcon => (
                        <MenuItem key={mentalIcon.type} value={mentalIcon.type}>
                          <img
                            // alt={mentalIcon.type}
                            height={46}
                            src={`/${mentalIcon.itemSrc}`}
                            width={46}
                          />
                        </MenuItem>
                      ))}
                    </StyledIconSelect>
                  </FormItem>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" height="5.625rem">
            <Stack
              justifyContent="center"
              sx={{ backgroundColor: '#CEE2FA' }}
              width="11.25rem"
            >
              <Stack
                alignItems="center"
                direction="row"
                gap=".3125rem"
                gridRow="span 1"
                paddingLeft="1rem"
              >
                <Stack
                  alignItems="center"
                  height={24}
                  justifyContent="center"
                  width={24}
                >
                  <HeartIcon
                    sx={{
                      color: '#2A343D',
                      height: 'auto',
                      width: '1.25rem',
                    }}
                  />
                </Stack>
                <Typography fontSize=".875rem" fontWeight={500}>
                  오늘의 피지컬
                </Typography>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              flex={1}
              paddingLeft="1.5625rem"
            >
              <FormField
                control={form.control}
                name="physicalState"
                render={({ field }) => (
                  <FormItem>
                    <StyledIconSelect
                      IconComponent={() => null}
                      MenuProps={{
                        MenuListProps: {
                          sx: {
                            display: 'flex',
                            paddingBottom: 0,
                            paddingTop: 0,
                          },
                        },
                        anchorOrigin: {
                          horizontal: 'right',
                          vertical: 'center',
                        },
                        transformOrigin: {
                          horizontal: 'left',
                          vertical: 'center',
                        },
                      }}
                      displayEmpty
                      onChange={field.onChange}
                      renderValue={() => {
                        return (
                          <Box
                            alt={''}
                            component="img"
                            height={46}
                            src={`/${
                              MENTAL_ICONS.find(
                                icon => icon.type === field.value,
                              )?.itemSrc
                            }`}
                            sx={theme => ({
                              [theme.breakpoints.down('md')]: {
                                height: 36,
                                width: 36,
                              },
                            })}
                            width={46}
                          />
                        );
                      }}
                      sx={{
                        backgroundColor: '#EBF2FE',
                        borderRadius: '50%',
                      }}
                      value={field.value}
                    >
                      {MENTAL_ICONS.map(mentalIcon => (
                        <MenuItem key={mentalIcon.type} value={mentalIcon.type}>
                          <img
                            // alt={mentalIcon.type}
                            height={46}
                            src={`/${mentalIcon.itemSrc}`}
                            width={46}
                          />
                        </MenuItem>
                      ))}
                    </StyledIconSelect>
                  </FormItem>
                )}
              />
            </Stack>
          </Stack>
          <Stack direction="row" flexGrow={1}>
            <Stack
              justifyContent="center"
              sx={{ backgroundColor: '#CEE2FA' }}
              width="11.25rem"
            ></Stack>
            <Stack
              alignItems="end"
              direction="row"
              flex={1}
              gap=".5rem"
              justifyContent="end"
              px="1.125rem"
              py="1.125rem"
            >
              <Button
                sx={{
                  '&:hover': {
                    backgroundColor: '#fff',
                    border: '1px solid #1A48A9',
                    color: '#1A48A9',
                  },
                  backgroundColor: '#fff',
                  border: '1px solid #1A48A9',
                  borderRadius: '.125rem',
                  boxShadow: 'none',
                  color: '#1A48A9',
                  fontSize: '.875rem',
                  fontWeight: 400,
                  height: '2rem',
                  px: '.75rem',
                }}
                type="submit"
                variant="contained"
              >
                저장하기
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </Drawer>
  );
}

const StyledIconSelect = styled(Select)({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '& .MuiSelect-select': {
    minWidth: '24px',
    padding: '0 !important',
  },
  '& fieldset': {
    border: '1px solid #EBF2FE',
  },
});
