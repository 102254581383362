import { z } from 'zod';

import { BaseHeadersSchema, BaseResponseSchema } from './base';
import { difficultyTypeEnum, scopeTypeEnum } from './common';

const problemBankTypeEnum = z.enum([
  'INFERIOR',
  'BASIC',
  'SIMILAR',
  'ADVANCED',
]);
export type ProblemBankType = z.infer<typeof problemBankTypeEnum>;

const videoStatusEnum = z.enum(['IDLE', 'PROCESSING', 'DONE', 'ERROR']);
export type VideoStatus = z.infer<typeof videoStatusEnum>;

export const answerTypeEnum = z.enum(['SHORT', 'SELECT', 'MULTISELECT']);
export type AnswerType = z.infer<typeof answerTypeEnum>;

const VideoSchema = z.object({
  comment_count: z.number(),
  created_at: z.string(),
  deleted_at: z.string(),
  haveLiked: z.boolean(),
  id: z.number(),
  learning_sys_id: z.number(),
  like_count: z.number(),
  pinned: z.boolean(),
  scope: scopeTypeEnum,
  status: videoStatusEnum,
  title: z.string(),
  user_uuid: z.string().uuid(),
  video_path: z.string(),
});

export type VideoSchema = z.infer<typeof VideoSchema>;

const CommentSchema = z.object({
  content: z.string(),
  created_at: z.string(),
  id: z.number(),
  updated_at: z.string(),
  uuid: z.string(),
});
export type CommentRes = z.infer<typeof CommentSchema>;
export type Comment = z.infer<typeof CommentSchema>;

// GetSharedVideo 스키마
const GetSharedVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  learningSysId: z.number(),
  onlyMine: z.boolean(),
  page: z.number(),
  pageSize: z.number(),
  userId: z.string(),
});

const SharedVideoResSchema = z
  .object({
    data: z.object({
      totalPage: z.number(),
      videos: z.array(VideoSchema),
    }),
  })
  .merge(BaseResponseSchema);

export type GetSharedVideoReqDto = z.infer<typeof GetSharedVideoReqDtoSchema>;
export type SharedVideoRes = z.infer<typeof SharedVideoResSchema>;

const PostSharedVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  learningSysId: z.number(),
  role: z.string(),
  scope: scopeTypeEnum,
  userId: z.string(),
  videoPath: z.string(),
});

export type PostSharedVideoReqDto = z.infer<typeof PostSharedVideoReqDtoSchema>;

const PinSharedVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  pin: z.boolean(),
  userId: z.string(),
});

export type PinSharedVideoReqDto = z.infer<typeof PinSharedVideoReqDtoSchema>;

const ViewSharedVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  userId: z.string(),
});

export type ViewSharedVideoReqDto = z.infer<typeof ViewSharedVideoReqDtoSchema>;

const DeleteSharedVideoReqDtoSchema = z.object({
  userId: z.string(),
});

export type DeleteSharedVideoReqDto = z.infer<
  typeof DeleteSharedVideoReqDtoSchema
>;

const PostSharedVideoLikeReqDtoSchema = z.object({
  data: z.object({
    concept_video_id: z.number(),
    like: z.boolean(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
});

const PostSharedVideoLikeResSchema = z
  .object({
    data: z.object({
      concept_video_id: z.number(),
      like_count: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type PostSharedVideoLikeReqDto = z.infer<
  typeof PostSharedVideoLikeReqDtoSchema
>;
export type PostSharedVideoLikeRes = z.infer<
  typeof PostSharedVideoLikeResSchema
>;

const GetSharedVideoCommentsReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    page: z.number().default(1),
    pageSize: z.number().default(10),
  }),
  videoId: z.number(),
});

const GetSharedVideoCommentsResSchema = z
  .object({
    data: z.object({
      comments: z.array(CommentSchema),
      currentPage: z.number(),
      totalPage: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type GetSharedVideoCommentsReqDto = z.infer<
  typeof GetSharedVideoCommentsReqDtoSchema
>;
export type GetSharedVideoCommentsRes = z.infer<
  typeof GetSharedVideoCommentsResSchema
>;
const GetConceptReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    learning_sys_id: z.number(),
  }),
});

const CommonConceptVideoSchema = z.object({
  commentary: z.string(),
  common_concept_id: z.number(),
  common_concept_video_data: z
    .object({
      _count: z.object({
        common_concept_video_comment: z.number(),
      }),
      common_concept_video_id: z.number(),
      id: z.number(),
      like_count: z.number(),
      view_count: z.number(),
    })
    .nullable(),
  created_at: z.string(),
  created_by: z.number(),
  haveILiked: z.boolean(),
  id: z.number(),
  sign_video_path: z.string(),
  status: videoStatusEnum,
  subtitle_path: z.string(),
  title: z.string(),
  video_path: z.string(),
});

const ConceptSchema = z.object({
  cls_id: z.string(),
  common_concept_video: z.array(CommonConceptVideoSchema),
  content_status: z.string(),
  created_by: z.number(),
  id: z.number(),
  is_algeomath: z.number(),
  latex_data: z.string(),
  learning_sys_id: z.number(),
  order_no: z.number(),
  type: z.string(),
  type_name: z.string(),
  updated_at: z.string(),
});

const ConceptResSchema = z
  .object({
    data: z.array(ConceptSchema),
  })
  .merge(BaseResponseSchema);

export type GetConceptReqDto = z.infer<typeof GetConceptReqDtoSchema>;
export type CommonConceptVideo = z.infer<typeof CommonConceptVideoSchema>;
export type Concept = z.infer<typeof ConceptSchema>;
export type GetConceptRes = z.infer<typeof ConceptResSchema>;

const QuestionBankSchema = z.object({
  ai_hint: z.string(),
  answer_type: answerTypeEnum,
  cls_id: z.string(),
  difficulty: difficultyTypeEnum,
  latex_data: z.string(),
  learning_sys: z
    .object({
      achievement_desc: z.string(),
      achievement_id: z.string(),
      cls_id: z.string(),
      full_name: z.string(),
      id: z.number(),
    })
    .nullable(),
  problem_id: z.number(),
  study_id: z.number(),
});

const QuestionBankResSchema = z
  .object({
    data: z.array(QuestionBankSchema),
  })
  .merge(BaseResponseSchema);

export type QuestionBankRes = z.infer<typeof QuestionBankResSchema>;

const GetQuestionBankReqDtoSchema = z.object({
  Uuid: z.string(),
  classInfo: z.string(),
  difficulty: difficultyTypeEnum,
  learningSysId: z.number(),
  problemId: z.number(),
  problemType: problemBankTypeEnum,
  role: z.string(),
});

export type GetQuestionBankReqDto = z.infer<typeof GetQuestionBankReqDtoSchema>;

const QuestionBankVideoSchema = z.object({
  commentCount: z.number(),
  commentary: z.string(),
  haveILiked: z.boolean(),
  id: z.number(),
  likeCount: z.number(),
  subtitlePath: z.string(),
  videoPath: z.string(),
});

const GetQuestionBankVideoReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    page: z.number(),
    pageSize: z.number(),
    problemId: z.number(),
    studyId: z.number(),
  }),
});

const GetQuestionBankVideoResSchema = z
  .object({
    data: z.object({
      currentPage: z.number(),
      totalPage: z.number(),
      videos: z.array(QuestionBankVideoSchema),
    }),
  })
  .merge(BaseResponseSchema);

export type GetQuestionBankVideoReqDto = z.infer<
  typeof GetQuestionBankVideoReqDtoSchema
>;
export type GetQuestionBankVideoRes = z.infer<
  typeof GetQuestionBankVideoResSchema
>;

// Analysis 스키마
const AnalysisProblemSchema = z.object({
  confidence: z.number(),
  difficulty: difficultyTypeEnum,
  is_correct: z.number(),
  problem_id: z.number(),
  problem_type: z.string(),
  study_perform_id: z.number(),
  study_problem_id: z.number(),
});

const AnalysisSchema = z.object({
  correction_rate: z.number(),
  problems: z.array(AnalysisProblemSchema),
  progress_rate: z.number(),
  uuid: z.string(),
});

const AnalysisTableResSchema = z
  .object({
    data: z.array(AnalysisSchema),
  })
  .merge(BaseResponseSchema);

export type AnalysisProblem = z.infer<typeof AnalysisProblemSchema>;
export type Analysis = z.infer<typeof AnalysisSchema>;
export type AnalysisTableRes = z.infer<typeof AnalysisTableResSchema>;

const GetAnalysisTableReqDtoSchema = z.object({
  classInfo: z.string(),
  learningSysId: z.number(),
  role: z.string(),
  type: z.string(),
  userId: z.string(),
  uuids: z.array(z.string()),
});

export type GetAnalysisTableReqDto = z.infer<
  typeof GetAnalysisTableReqDtoSchema
>;

// GET 요청 스키마
const ReferenceMaterialSchema = z.object({
  content: z.string(),
  createdAt: z.string(),
  id: z.number(),
  scope: scopeTypeEnum,
  title: z.string(),
  userUuid: z.string().uuid(),
  viewCount: z.number(),
});

const ReferenceDataListResSchema = z
  .object({
    data: z.object({
      currentPage: z.number(),
      data: z.array(ReferenceMaterialSchema),
      totalPage: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type ReferenceMaterial = z.infer<typeof ReferenceMaterialSchema>;
export type ReferenceDataListRes = z.infer<typeof ReferenceDataListResSchema>;

const GetReferenceDataReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    clsId: z.string(),
    page: z.number().default(1),
    pageSize: z.number().default(10),
  }),
});

export type GetReferenceDataReqDto = z.infer<
  typeof GetReferenceDataReqDtoSchema
>;

const ReferenceDataReqDtoSchema = z.object({
  data: z.object({
    clsId: z.string(),
    content: z.string(),
    filePaths: z.array(z.string()),
    scope: scopeTypeEnum,
    title: z.string(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
});
const ReferenceDataPatchReqDtoSchema = z.object({
  data: z.object({
    content: z.string(),
    filePaths: z.array(z.string()),
    title: z.string(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    id: z.number(),
  }),
});
const ReferenceDataResSchema = z
  .object({
    data: z.object({
      content: z.string(),
      filePaths: z.string(),
      id: z.number(),
      scope: scopeTypeEnum,
      title: z.string(),
    }),
  })
  .merge(BaseResponseSchema);

export type ReferenceDataReqDto = z.infer<typeof ReferenceDataReqDtoSchema>;
export type ReferenceDataPatchReqDto = z.infer<
  typeof ReferenceDataPatchReqDtoSchema
>;
export type ReferenceDataRes = z.infer<typeof ReferenceDataResSchema>;

const GetReferenceDetailDataReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  referenceDataId: z.number(),
});
export type GetReferenceDetailDataReqDto = z.infer<
  typeof GetReferenceDetailDataReqDtoSchema
>;

export type PatchReferenceDataViewReqDto = z.infer<
  typeof GetReferenceDetailDataReqDtoSchema
>;

const PatchReferenceDetailDataLikeReqDtoSchema = z.object({
  data: z.object({
    like: z.boolean(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  referenceDataId: z.number(),
});
export type PatchReferenceDetailDataLikeReqDto = z.infer<
  typeof PatchReferenceDetailDataLikeReqDtoSchema
>;
const ReferenceDetailSchema = z.object({
  commentCount: z.number(),
  content: z.string(),
  createdAt: z.string().datetime(),
  filePaths: z.array(z.string()),
  haveILiked: z.boolean(),
  id: z.number(),
  likeCount: z.number(),
  scope: scopeTypeEnum,
  title: z.string(),
  userUuid: z.string().uuid(),
  viewCount: z.number(),
});

const ReferenceDetailResSchema = z
  .object({
    data: ReferenceDetailSchema,
  })
  .merge(BaseResponseSchema);

export type ReferenceDetail = z.infer<typeof ReferenceDetailSchema>;
export type ReferenceDetailRes = z.infer<typeof ReferenceDetailResSchema>;

const BasicSearchSchema = z.object({
  ai_hint: z.string(),
  answer_data: z.string(),
  answer_type: answerTypeEnum,
  average_correct_rate: z.number(),
  cls_id: z.string(),
  difficulty: difficultyTypeEnum,
  latex_data: z.string(),
  learning_sys: z
    .object({
      achievement_desc: z.string(),
      achievement_id: z.string(),
      cls_id: z.string(),
      full_name: z.string(),
      id: z.number(),
    })
    .nullable(),
  problem_id: z.number(),
  problem_solving: z.record(z.unknown()),
  problem_solving_meta: z.record(z.unknown()),
  study_perform: z.record(z.unknown()),
});

const BasicSearchResSchema = z
  .object({
    data: z.array(BasicSearchSchema),
  })
  .merge(BaseResponseSchema);

export type BasicSearchRes = z.infer<typeof BasicSearchResSchema>;

const GetBasicSearchReqDtoSchema = z.object({
  classInfo: z.string(),
  learning_sys_id: z.number(),
  role: z.string(),
  user_uuid: z.string(),
});

export type GetBasicSearchReqDto = z.infer<typeof GetBasicSearchReqDtoSchema>;

const GetConfirmSearchReqDtoSchema = z.object({
  classInfo: z.string(),
  learning_sys_id: z.number(),
  role: z.string(),
  user_uuid: z.string(),
});

export type GetConfirmSearchReqDto = z.infer<
  typeof GetConfirmSearchReqDtoSchema
>;

const GetFeedbackSearchReqDtoSchema = z.object({
  classInfo: z.string(),
  learning_sys_id: z.number(),
  role: z.string(),
  user_uuid: z.string(),
});

export type GetFeedbackSearchReqDto = z.infer<
  typeof GetFeedbackSearchReqDtoSchema
>;

const PostStudySubmitReqDtoSchema = z.object({
  answer: z.string(),
  classInfo: z.string(),
  confidence: z.number(),
  problemId: z.number(),
  role: z.string(),
  studyId: z.number(),
  userId: z.string(),
});

const PostStudySubmitResSchema = z
  .object({
    data: z.object({
      confidence: z.number(),
      created_at: z.string(),
      id: z.number(),
      is_correct: z.number(),
      solving_end: z.string().nullable(),
      solving_start: z.string(),
      study_problem_id: z.number(),
      submission_answer: z.string(),
      user_uuid: z.string(),
    }),
  })
  .merge(BaseResponseSchema);

export type PostStudySubmitReqDto = z.infer<typeof PostStudySubmitReqDtoSchema>;
export type PostStudySubmitRes = z.infer<typeof PostStudySubmitResSchema>;

const PostSharedVideoCommentReqDtoSchema = z.object({
  data: z.object({
    content: z.string(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});

const PostSharedVideoCommentResSchema = z
  .object({
    data: CommentSchema,
  })
  .merge(BaseResponseSchema);

export type PostSharedVideoCommentReqDto = z.infer<
  typeof PostSharedVideoCommentReqDtoSchema
>;

export type PostSharedVideoCommentRes = z.infer<
  typeof PostSharedVideoCommentResSchema
>;

const DeleteSharedVideoCommentReqSchema = z.object({
  commentId: z.number(),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
});

const DeleteSharedVideoCommentresSchema = z
  .object({
    data: z.object({
      id: z.number(),
    }),
  })
  .merge(BaseResponseSchema);
export type DeleteSharedVideoCommentReq = z.infer<
  typeof DeleteSharedVideoCommentReqSchema
>;
export type DeleteSharedVideoCommentres = z.infer<
  typeof DeleteSharedVideoCommentresSchema
>;

const PatchCommentReqDtoSchema = z.object({
  content: z.string(),
  userId: z.string(),
});

export type PatchCommentReqDto = z.infer<typeof PatchCommentReqDtoSchema>;

const PostSharedSolutionVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  problemId: z.number(),
  role: z.string(),
  scope: scopeTypeEnum,
  userId: z.string(),
  videoPath: z.string(),
});

export type PostSharedSolutionVideoReqDto = z.infer<
  typeof PostSharedSolutionVideoReqDtoSchema
>;

const GetSharedSolutionVideoReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    onlyMine: z.boolean().optional(),
    problemId: z.number(),
  }),
});

const SharedSolutionVideoSchema = z.object({
  commentCount: z.number(),
  createdAt: z.string(),
  haveILiked: z.boolean(),
  id: z.number(),
  likeCount: z.number(),
  problemId: z.number(),
  userUuid: z.string(),
  videoPath: z.string(),
});

const GetSharedSolutionVideoResSchema = z
  .object({
    data: z.object({
      page: z.number(),
      totalPage: z.number(),
      videos: z.array(SharedSolutionVideoSchema),
    }),
  })
  .merge(BaseResponseSchema);

export type SharedSolutionVideo = z.infer<typeof SharedSolutionVideoSchema>;

export type GetSharedSolutionVideoReqDto = z.infer<
  typeof GetSharedSolutionVideoReqDtoSchema
>;
export type SharedSolutionVideoRes = z.infer<
  typeof GetSharedSolutionVideoResSchema
>;

const PatchSharedSolutionVideoReqDtoSchema = z.object({
  classInfo: z.string(),
  userId: z.string(),
});

export type PatchSharedSolutionVideoReqDto = z.infer<
  typeof PatchSharedSolutionVideoReqDtoSchema
>;

const DeleteSharedSolutionVideoReqDtoSchema = z.object({
  userId: z.string(),
});

export type DeleteSharedSolutionVideoReqDto = z.infer<
  typeof DeleteSharedSolutionVideoReqDtoSchema
>;

const PostSolutionVideoCommentReqDtoSchema = z.object({
  data: z.object({
    content: z.string(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});

const PostSolutionVideoCommentResSchema = z
  .object({
    data: CommentSchema,
  })
  .merge(BaseResponseSchema);

export type PostSolutionVideoCommentReqDto = z.infer<
  typeof PostSolutionVideoCommentReqDtoSchema
>;

export type PostSolutionVideoCommentRes = z.infer<
  typeof PostSolutionVideoCommentResSchema
>;

const GetSolutionVideoCommentsReqDtoSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    page: z.number().default(1),
    pageSize: z.number().default(10),
  }),
  videoId: z.number(),
});

const GetSolutionVideoCommentsResSchema = z
  .object({
    data: z.object({
      comments: z.array(CommentSchema),
      page: z.number(),
      totalPage: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type GetSolutionVideoCommentsReqDto = z.infer<
  typeof GetSolutionVideoCommentsReqDtoSchema
>;
export type GetSolutionVideoCommentsRes = z.infer<
  typeof GetSolutionVideoCommentsResSchema
>;

const PatchSolutionVideoCommentReqDtoSchema = z.object({
  content: z.string(),
  userId: z.string(),
});

export type PatchSolutionVideoCommentReqDto = z.infer<
  typeof PatchSolutionVideoCommentReqDtoSchema
>;

const DeleteSolutionVideoCommentReqDtoSchema = z.object({
  commentId: z.number(),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});

export type DeleteSolutionVideoCommentReqDto = z.infer<
  typeof DeleteSolutionVideoCommentReqDtoSchema
>;

export type DeleteSolutionVideoCommentRes = z.infer<typeof BaseResponseSchema>;

const PostSolutionVideoLikeReqDtoSchema = z.object({
  data: z.object({
    like: z.boolean(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});

export type PostSolutionVideoLikeReqDto = z.infer<
  typeof PostSolutionVideoLikeReqDtoSchema
>;

export type PostSolutionVideoLikeRes = z.infer<typeof BaseResponseSchema>;

const GetSemesterReqDtoSchema = z.object({
  classInfo: z.string(),
  cls_id: z.string(),
  userId: z.string(),
});

const SemesterSchema = z.object({
  begin_date: z.string(),
  created_at: z.string(),
  desc: z.string(),
  end_date: z.string(),
  grade: z.number(),
  id: z.number(),
  semester: z.number(),
  year: z.number(),
});

const SemestersSchema = z.array(SemesterSchema);

export type Semester = z.infer<typeof SemesterSchema>;
export type Semesters = z.infer<typeof SemestersSchema>;
export type GetSemesterReqDto = z.infer<typeof GetSemesterReqDtoSchema>;

const StudyChapterPlanReqSchema = z.object({
  achievement_level: z.number().optional(),
  correct_rate: z.number().optional(),
  metarecognition_rate: z.number().optional(),
  progress_rate: z.number().optional(),
  semester_id: z.number(),
});

const StudyChapterPlan = z.object({
  achievement_level: z.number(),
  correct_rate: z.number(),
  id: z.number(),
  learning_sys_id: z.number(),
  metarecognition_rate: z.number(),
  progress_rate: z.number(),
  semester_id: z.number(),
  uuid: z.string(),
});

const StudyChapterPlanResSchema = z
  .object({
    data: z.object({
      current: StudyChapterPlan,
      previous: StudyChapterPlan,
    }),
  })
  .merge(BaseResponseSchema);

export type StudyChapterPlanReq = z.infer<typeof StudyChapterPlanReqSchema>;
export type StudyChapterPlan = z.infer<typeof StudyChapterPlan>;
export type StudyChapterPlanRes = z.infer<typeof StudyChapterPlanResSchema>;

const GetConceptVideoCommentsReqSchema = z.object({
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  params: z.object({
    page: z.number(),
    pageSize: z.number(),
  }),
  videoId: z.number(),
});

const GetConceptVideoCommentsResSchema = z
  .object({
    data: z.object({
      comments: z.array(CommentSchema),
      currentPage: z.number(),
      totalPage: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type GetConceptVideoCommentsReq = z.infer<
  typeof GetConceptVideoCommentsReqSchema
>;
export type GetConceptVideoCommentsRes = z.infer<
  typeof GetConceptVideoCommentsResSchema
>;

const DeleteConceptVideoCommentReqSchema = z.object({
  commentId: z.number(),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
});

export type DeleteConceptVideoCommentReq = z.infer<
  typeof DeleteConceptVideoCommentReqSchema
>;
export type DeleteConceptVideoCommentres = z.infer<typeof BaseResponseSchema>;

const PostConceptVideoCommentReqSchema = z.object({
  data: z.object({
    comment: z.string(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});

const PostConceptVideoCommentResSchema = z
  .object({
    data: CommentSchema,
  })
  .merge(BaseResponseSchema);

export type PostConceptVideoCommentReq = z.infer<
  typeof PostConceptVideoCommentReqSchema
>;
export type PostConceptVideoCommentRes = z.infer<
  typeof PostConceptVideoCommentResSchema
>;

const PatchConceptVideoLikeReqDtoSchema = z.object({
  data: z.object({
    like: z.boolean(),
  }),
  headers: BaseHeadersSchema.extend({
    'Class-Info': z.string(),
  }),
  videoId: z.number(),
});
export type PatchConceptVideoLikeReqDto = z.infer<
  typeof PatchConceptVideoLikeReqDtoSchema
>;

const PatchConceptVideoLikeResSchema = z
  .object({
    data: z.number(),
  })
  .merge(BaseResponseSchema);
export type PatchConceptVideoLikeRes = z.infer<
  typeof PatchConceptVideoLikeResSchema
>;

const GetConceptVideoLikeReqDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    concept_video_id: z.number(),
  }),
});

const GetConceptVideoLikeResSchema = z
  .object({
    data: z.object({
      like: z.boolean(),
    }),
  })
  .merge(BaseResponseSchema);
export type GetConceptVideoLikeReqDto = z.infer<
  typeof GetConceptVideoLikeReqDtoSchema
>;

export type GetConceptVideoLikeRes = z.infer<
  typeof GetConceptVideoLikeResSchema
>;
