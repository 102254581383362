import { Avatar, Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

interface SpeechBubbleProps {
  children: React.ReactNode;
  customBoxStyle?: React.CSSProperties;
  customLayoutStyle?: React.CSSProperties;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  children,
  customBoxStyle,
  customLayoutStyle,
}) => {
  return (
    <Stack direction={'row'} sx={{ ...customLayoutStyle }}>
      <Avatar
        alt="matue"
        src="/icons/ai_profile.svg"
        sx={{ height: 72, width: 72 }}
      />
      <Stack direction={'column'} sx={{ gap: 1, ml: 2, width: '100%' }}>
        <Typography>매쓰튜터 AI</Typography>
        <Box
          sx={{
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            height: 'auto',
            minHeight: 500,
            overflowY: 'auto',
            p: 2,
            ...customBoxStyle,
          }}
        >
          {children}
        </Box>
      </Stack>
    </Stack>
  );
};

export default SpeechBubble;
