import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { LucidePencil } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CurrentSection, CurrentSubSection } from 'types/StudyState';

export default function ReferenceBreadcrumbs({
  section,
  subSection,
}: {
  section: CurrentSection | null;
  subSection: CurrentSubSection | null;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [subTitle, setSubTitle] = useState('');
  useEffect(() => {
    if (subSection && section) {
      setSubTitle(
        `${section.unitName} > ${section.chapterName} > ${section.sectionName}`,
      );
    }
  }, [subSection, section]);
  if (!section || !subSection) return <></>;
  const breadcrumbs = [
    <Typography
      color="text.primary"
      key="3"
      sx={{ color: 'black', fontSize: '.9rem', fontWeight: 500 }}
    >
      {subTitle}
    </Typography>,
  ];

  return (
    <Box sx={{ background: '#EBF2FE', p: 1.5, textAlign: 'center' }}>
      <Container>
        <Stack
          alignItems={'center'}
          direction={'row'}
          justifyContent={'space-between'}
          spacing={1}
          sx={{ px: 2 }}
        >
          <Breadcrumbs
            aria-label="breadcrumb"
            separator="›"
            sx={{
              color: 'black',
              fontSize: '.8rem',
              fontWeight: 500,
              width: '33%',
            }}
          >
            {breadcrumbs}
          </Breadcrumbs>
          <div className="flex flex-grow items-center justify-center gap-2 font-bold text-xl">
            {subSection && subSection.subSectionName}
          </div>
          <div className="w-1/3 flex items-center justify-end">
            <Button
              onClick={() =>
                navigate(
                  `/reference/${section.learningSysId}/${subSection.cls_id}/new`,
                )
              }
              size="large"
              startIcon={<LucidePencil className="pl-1" size={20} />}
              sx={{
                backgroundColor: '#4F73C0',
                borderRadius: '.25rem',
                fontSize: '.9375rem',
              }}
              variant="contained"
            >
              {t('reference.register_material')}
            </Button>
          </div>
        </Stack>
      </Container>
    </Box>
  );
}
