import { AiSearchPage } from 'app/pages/AiSearch';
import AnalysisResultPage from 'app/pages/AnalysisResult';
import { AssessmentComprehensivePage } from 'app/pages/AssessmentComprehensive/Loadable';
import { AssessmentComprehensiveResultPage } from 'app/pages/AssessmentComprehensiveResult';
import { AssessmentDiagnosisPage } from 'app/pages/AssessmentDiagnosis/Loadable';
import { AssessmentDiagnosisResultPage } from 'app/pages/AssessmentDiagnosisResult/Loadable';
import { AssessmentUnitPage } from 'app/pages/AssessmentUnit/Loadable';
import { AssessmentUnitResultPage } from 'app/pages/AssessmentUnitResult/Loadable';
import { AssignmentResultPage } from 'app/pages/AssignmentResult';
import { ChapterPage } from 'app/pages/Chapter/Loadable';
import { ChapterResultPage } from 'app/pages/ChapterResult/Loadable';
import { DashboardPage } from 'app/pages/Dashboard';
import { DenouementPage } from 'app/pages/Denouement/Loadable';
import { DevPage } from 'app/pages/Dev';
import EvaluationManagePage from 'app/pages/EvaluationManage';
import { EvaluationResultPage } from 'app/pages/EvaluationResult/Loadable';
import { ExpiredPage } from 'app/pages/Expired';
import { HomePage } from 'app/pages/Home';
import LoginPage from 'app/pages/Login';
import { MathConceptsPage } from 'app/pages/MathConcepts/Loadable';
import { NotFoundPage } from 'app/pages/NotFound/Loadable';
import { NotePage } from 'app/pages/Note/Loadable';
import { NoticePage } from 'app/pages/Notice/Loadable';
import { NoticeDetailPage } from 'app/pages/NoticeDetail/Loadable';
import NoticeNewPage from 'app/pages/NoticeNew';
import { QuestionsPage } from 'app/pages/Questions/Loadable';
import { ReferencePage } from 'app/pages/Reference';
import ReferenceDetailPage from 'app/pages/ReferenceDetail';
import ReferenceNewPage from 'app/pages/ReferenceNew';
import { ReplyPage } from 'app/pages/Reply/Loadable';
import { StepPage } from 'app/pages/Step';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'types';

import SSORequiredRoute from './SSORequiredRoute';

const AppRoutes = () => {
  const access_token = useSelector(
    (state: RootState) => state.user.access_token,
  );
  const user_id = useSelector((state: RootState) => state.user.user_id);

  const NotFoundRoutePage = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
      navigate('/error');
    }, [navigate]);

    return null;
  };

  const DashboardRoutePage = () => {
    const { subsectionId } = useParams();

    if (!subsectionId) {
      return <NotFoundPage />;
    }

    return <DashboardPage />;
  };

  const QuestionRoutePage = () => {
    const { learningType } = useParams();

    if (!learningType) {
      return <NotFoundPage />;
    }

    return <QuestionsPage />;
  };

  return (
    <Routes>
      {/* 홈 */}
      <Route
        element={
          <SSORequiredRoute>
            <HomePage />
          </SSORequiredRoute>
        }
        path="/"
      />
      <Route element={<LoginPage />} path="/login" />
      {/** DEV 전용 페이지 */}
      <Route element={<DevPage />} path="/dev" />
      {/* 학습단계 설명 */}
      <Route
        element={
          <SSORequiredRoute>
            <StepPage />
          </SSORequiredRoute>
        }
        path="/step"
      />
      {/* 대시보드 */}
      <Route
        element={
          <SSORequiredRoute>
            <DashboardRoutePage />
          </SSORequiredRoute>
        }
        path="/dashboard/:subsectionId"
      />
      {/* 질문/답변하기 */}
      <Route
        element={
          <SSORequiredRoute>
            <ReplyPage />
          </SSORequiredRoute>
        }
        path="/reply"
      />
      {/* 공지사항 */}
      <Route
        element={
          <SSORequiredRoute>
            <NoticePage />
          </SSORequiredRoute>
        }
        path="/notice"
      />
      <Route
        element={
          <SSORequiredRoute>
            <NoticeDetailPage />
          </SSORequiredRoute>
        }
        path="/notice/:id"
      />
      <Route
        element={
          <SSORequiredRoute>
            <NoticeNewPage />
          </SSORequiredRoute>
        }
        path="/notice/new"
      />
      {/* AI 검색 */}
      <Route
        element={
          <SSORequiredRoute>
            <AiSearchPage />
          </SSORequiredRoute>
        }
        path="/search"
      />
      {/* 희망노트 */}
      <Route
        element={
          <SSORequiredRoute>
            <NotePage />
          </SSORequiredRoute>
        }
        path="/note"
      />
      {/* 참고자료 */}
      <Route
        element={
          <SSORequiredRoute>
            <ReferencePage />
          </SSORequiredRoute>
        }
        path="/reference/:sectionId/:subsectionId"
      />
      <Route
        element={
          <SSORequiredRoute>
            <ReferenceDetailPage />
          </SSORequiredRoute>
        }
        path="/reference/:sectionId/:subsectionId/:id"
      />
      <Route
        element={
          <SSORequiredRoute>
            <ReferenceNewPage />
          </SSORequiredRoute>
        }
        path="/reference/:sectionId/:subsectionId/new"
      />
      {/* 평가 */}
      <Route
        element={
          <SSORequiredRoute>
            <AssessmentDiagnosisPage />
          </SSORequiredRoute>
        }
        path="/assessment/:type"
      />
      <Route
        element={
          <SSORequiredRoute>
            <AssessmentDiagnosisResultPage />
          </SSORequiredRoute>
        }
        path="/assessment/:type/:id"
      />
      {/* <Route
        element={
          <SSORequiredRoute>
            <AssessmentUnitPage />
          </SSORequiredRoute>
        }
        path="/assessment/unit/:id"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <AssessmentUnitResultPage />
          </SSORequiredRoute>
        }
        path="/assessment/unit/result/:id"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <AssessmentComprehensivePage />
          </SSORequiredRoute>
        }
        path="/assessment/comprehensive"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <AssessmentComprehensiveResultPage />
          </SSORequiredRoute>
        }
        path="/assessment/comprehensive/:id"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <EvaluationResultPage />
          </SSORequiredRoute>
        }
        path="/evaluation/result/:assessmentId"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <AssignmentResultPage />
          </SSORequiredRoute>
        }
        path="/evaluation/result/:assignmentId"
      /> */}
      {/* <Route
        element={
          <SSORequiredRoute>
            <EvaluationManagePage />
          </SSORequiredRoute>
        }
        path="/evaluation/manage"
      /> */}
      {/* 기본,확인,피드백,통합 오답 문제 */}
      <Route
        element={
          <SSORequiredRoute>
            <QuestionRoutePage />
          </SSORequiredRoute>
        }
        path="/questions/:sectionId/:learningType"
      />
      {/* subType(basic,confirm,feedback)에 대한 오답 문제 */}
      <Route
        element={
          <SSORequiredRoute>
            <QuestionRoutePage />
          </SSORequiredRoute>
        }
        path="/questions/:sectionId/:learningType/:subType"
      />
      {/* 개념 */}
      <Route
        element={
          <SSORequiredRoute>
            <MathConceptsPage />
          </SSORequiredRoute>
        }
        path="/math-concept/:id"
      />
      {/* 대단원 */}
      <Route
        element={
          <SSORequiredRoute>
            <DenouementPage />
          </SSORequiredRoute>
        }
        path="/denouement/:id"
      />
      {/* 소단원 */}
      <Route
        element={
          <SSORequiredRoute>
            <ChapterPage />
          </SSORequiredRoute>
        }
        path="/chapter/:id"
      />
      <Route
        element={
          <SSORequiredRoute>
            <ChapterResultPage />
          </SSORequiredRoute>
        }
        path="/chapter/:id/result"
      />
      {/* 분석결과표(교사) */}
      <Route
        element={
          <SSORequiredRoute>
            <AnalysisResultPage />
          </SSORequiredRoute>
        }
        path="/analysis/result/:subsectionId/:unitType/:questionType"
      />
      {/* 로그인 만료 */}
      <Route element={<ExpiredPage />} path="/expired" />
      {/* 찾을 수 없는 페이지 404 */}
      <Route element={<NotFoundPage />} path="/error" />
      <Route element={<NotFoundRoutePage />} path="*" />
    </Routes>
  );
};

export default AppRoutes;
