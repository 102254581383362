import React from 'react';
export const Student_HelpTotalChapter = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    좌측에 나열되어있는 학기의 대단원목차입니다.
    <br />
    좌측에서 학습해야 하는 대단원을 클릭해보세요.
    <br />
    선택한 대단원은 연한 파란색으로 표시가됩니다.
    <br />
    대단원을 클릭했다면 우측보드에 대단원에 속해있는 중단원과 소단원이 나옵니다.
    <br />
    소단원명을 클릭하여 해당 소단원의 학습을 시작할 수 있습니다.
    <br />
    학습이 완료된 단원은 우측 대시보드 버튼을 클릭하여 대시보드로 이동할 수
    있습니다.
    <br />
    아직 학습을 시작하지않은 단원의 대시보드는 아래와같이 연한 회색으로
    비활성화되어있습니다.
    <img alt="대단원목차" src={`/images/help/help_12201.png`} width={400} />
  </div>
);

export const Teacher_HelpTotalChapter = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    좌측에 나열되어있는 학기의 대단원목차입니다.
    <br />
    좌측에서 학습해야 하는 대단원을 클릭해보세요.
    <br />
    선택한 대단원은 연한 파란색으로 표시가됩니다.
    <br />
    대단원을 클릭했다면 우측보드에 대단원에 속해있는 중단원과 소단원이 나옵니다.
    <br />
    소단원명을 클릭하여 해당 소단원의 학습을 시작할 수 있습니다.
    <br />
    학습이 완료된 단원은 우측 대시보드 버튼을 클릭하여 대시보드로 이동할 수
    있습니다.
    <br />
    아직 학습을 시작하지않은 단원의 대시보드는 아래와같이 연한 회색으로
    비활성화되어 있습니다.
    <br />
    교사는 현재 진도상태에 대한 판단을 하여 체크표시를 할 수 있습니다.
    <img alt="대단원목차" src={`/images/help/help_12201.png`} width={400} />
  </div>
);

export const Student_HelpChapter = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    학습에 시작하기에 앞서 학습목표를 설정하세요.
    진도율,학습단계,정답률,메타인지
    <br />총 4가지로 구성된 학습목표는 본인의 목표와 성취율 비교에 도움을
    줍니다. <br />
    학습목표의 연한 파란색은 내가 설정한 이전 소단원은 정보이며, 진한 파란색은
    <br />
    지금 내가 선택한 목표이며, ‘저장하기’를 클릭하면 저장이되고 다음 소단원
    목차에서
    <br />
    반영되어 보여집니다.
    <br />
    <img alt="목차" src={`/images/help/help_12214.png`} width={700} />
  </div>
);

export const Teacher_HelpChapter = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    소단원명을 클릭하면 소단원목차에 진입됩니다. 해당 단원의 학습순서가
    나옵니다.
    <br />
    개념학습, 기본학습,확인학습,피드백학습,대시보드로 구성되어 있습니다.
    <br />
    개념학습은 학생들이 풀이학습에 들어가기에 앞서 해당 풀이의 개념을 설명하는
    <br />
    기능입니다. 대시보드를 클릭하면 학생들의 해당단원에 대한 대시보드를 조회할
    수 있습니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12270.png`} width={700} />
  </div>
);

export const Student_HelpPlanNote = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    상단메뉴를 통해 학생희망노트에 접속할 수 있습니다. <br />
    본인의 장래희망과 해당학기 학습목표를 자유롭게 수정하고 저장할 수 있습니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12226.png`} width={400} />
  </div>
);

export const Teacher_HelpPlanNote = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    학생들이 등록한 학생희망노트 리스트를 볼 수 있습니다. <br />
    상단 메뉴를 통해 바로가기가 가능합니다.
  </div>
);

export const Student_HelpConcept = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    개념학습이란 문제풀이에 앞서 선생님과 함께 해당단원의 개념 및 본문을
    학습하는
    <br />
    페이지입니다. 다음 개념을 클릭하여 이어서 학습할 수 있습니다.
    <br />
    <br />
    (1)공통인강 : 해당 개념과 관련된 EBS영상 시청가능
    <br />
    (2)공유영상 : 해당 개념학습을 앞서 학습한 사용자들의 공유영상 시청가능
    <br />
    (3)참고자료 : 해당 개념학습의 참고자료를 등록하거나 열람할 수 있습니다.
    <br />
    (4)전자칠판 : 해당 개념학습의 본문을 학습한 영상을 녹화 및 공유할 수
    있습니다. <br />
    <img alt="대단원목차" src={`/images/help/help_12237.png`} width={600} />
  </div>
);

export const Teacher_HelpConcept = Student_HelpConcept;

export const Student_HelpStudy = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    풀이학습이란 기본학습,확인학습,피드백학습 총 세가지로 이루어진
    학습순서입니다.
    <br />
    기본학습과 확인학습의 결과를 기반으로 사용자에게 맞춤 피드백학습이
    생성됩니다.
    <br />
    우측기능에는 직전 소단원목차로 이동할수 있는 버튼과 자신있음,없음을 체크해
    메타인지를
    <br />
    측정하는 기능, 해당 문제의 힌트, 풀이를 하고 공유할 수 있는 전자칠판과
    정답선택 <br />
    및 제출버튼이 있습니다.
    <br />
    문제풀이 후 정답을 제출하면 채점화면이 나옵니다.
    <br />
    <br />
    (1)AI튜터 : 통합개념영상, 공유개념영상, 정답해설을 볼 수 있는 기능입니다.
    <br />
    (2)문제은행 : 해당문제 보충, 기초,유사,심화문제를 선택해서 풀이할 수 있는
    기능입니다.
    <br />
    (3)공유풀이영상 : 해당문제를 먼저 학습한 사용자들이 공유한 풀이영상을 볼 수
    있는 기능입니다.
    <br />
    (4)질문하기 : 해당 문제를 질문해서 다른 사용자들의 답변을 볼 수있습니다.
    <br />
  </div>
);

export const Teacher_HelpStudy = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    풀이학습 학습상황을 클릭하면 해당 학급 학생들의 결과표를 조회할 수 있습니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12270.png`} width={700} />
  </div>
);

export const Student_HelpDashboard = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <strong>(1) 프로필설정</strong>
    본인의 프로필 설정을 할 수있습니다. 장래희망, 한줄평, 오늘의 멘탈, 피지컬로
    구성되어있으며
    <br />
    장래희망과 오늘의멘탈,피지컬은 누적기록됩니다.
    <br />
    <br />
    <strong>(2) 선생님의 학습전략추천</strong>
    본인의 풀이상태에 따른 맞춤 학습전략추천이 적용되어 보여집니다.
    <br />
    <br />
    <strong>(3) 누적 데이터</strong>
    본인의 풀이 수와 정답률, 작성한 질의응답과 좋아요,댓글등과 학급의 평균을
    비교할 수 있습니다. <br />
    <br />
    <strong>(4) 현재 소단원별 학습결과내역</strong>
    본인이 학습한 개념학습 및 풀이학습의 내역 및 오답을 확인하고 재확인 할 수
    있으며
    <br />
    과제가 있을 시 과제하기를 통해 과제를 풀고 결과를 볼 수 있습니다.
    <br />
    <br />
    <strong>(5) 현재 소단원 목표 결과 분석</strong>
    내가 설정한 목표와 실제 성취수준, 학급평균을 비교해서 볼 수 있는
    그래프입니다.
    <br />
    <br />
    <strong>
      (6) 장래희망, 부문별 MVP, 난이도별 문제풀이 및 정답률 누적데이터
    </strong>
    내가 설정한 장래희망의 누적횟수를 나타내는 그래프와 각 부문별 순위, 본인과
    학급의 난이도별
    <br />
    문제풀이 및 정답률을 비교해볼 수 있는 데이터입니다.
    <br />
    <br />
    <strong>(7) 취약 단원순 난이도별 분석</strong>
    본인이 취약했던 단원과 난이도별 분석표를 확인할 수 있습니다.
    <br />
    <br />
    <strong>(8) 소단원별 학습결과 비교</strong>
    본인의 소단원별 학습결과를 진도율,정답률,메타인지,학습단계 총 네가지
    항목으로 비교하여 <br />볼 수있습니다. 단, 본인이 학습을 하지 않았을경우는
    그래프가 나타나지않습니다.
  </div>
);

export const Teacher_HelpDashboard = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <strong>(1) 목표 결과 분석</strong>
    학급이 설정한 목표와 달성한 평균을
    <br />
    비교하여 볼 수 있는 그래프입니다. <br />
    <strong>(2) 소단원 연속 하락리스트</strong>
    2개 소단원 연속으로 하락한 학생들의 리스트를 보여주는 영역입니다.
    <br />
    학습단계, 문제풀이 수, 정답률, 학습시간, 4가지 항목으로 구성되어있습니다.
    <br />
    <strong>(3) 현재 소단원별 학습결과 내역</strong>
    학급의 학생들의 풀이학습 결과표입니다.
    학습단계,개념학습,기본학습,확인학습,피드백학습,
    <br />
    메타인지,학습참여, 과제결과를 조회할 수 있으며 과제를 제출하거나 알람을
    전송할 수 있습니다.
    <br />
    <strong>(4) 학급 소단원별 학습결과 비교 그래프</strong>
    소단원별 학습결과를 진도율,정답률,메타인지,학습단계 4가지항목으로 확인할 수
    있습니다.
    <br />
    <strong>(5) 취약 소단원순 난이도별 분석</strong>
    학급의 취약소단원순 난이도별 분석을 확인할 수 있는 차트입니다.
    <br />
    <strong>(6) 학급 성취기준별 성취수준</strong>
    학급의 성취기준별 성취수준을 조회할 수 있는 영역입니다.
    <br />
    공통수학에만 해당하며 중등수학에는 반영되지않습니다.
  </div>
);

export const Student_HelpAssessment = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">1.진단평가</h2>
    시험은 학력진단평가, 대단원 형성평가, 학기말 총괄평가 총 세가지로
    구성되어있습니다.
    <br />
    학력진단평가 : 학기 진도에 나가기에 앞서 최초에 학습자의 수준을 평가하기
    위한 시험.
    <br />
    대단원 형성평가 : 1개 대단원을 모두 학습한 후 평가하는 시험.
    <br />
    학기말 총괄평가 : 한 학년이 끝나고 모든 단원이 마무리되었을때 전체 결산 및
    평가
    <br />
    <br />
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">2.평가시작</h2>
    학력진단평가의 경우 상단메뉴에 학력진단평가 버튼을 클릭하여 접속할 수
    있습니다.
    <br />
    우측상단에서 선생님이 설정해놓은 시험 제한시간을 확인할 수 있습니다.
    <br />
    선생님이 정해놓은 시간이 되면 시험을 시작할 수 있습니다.
    <br />
    <br />
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">3.평가풀이</h2>
    시험이 시작되면 평가 풀이 페이지로 접속됩니다. 모든 시험은 주관식 형태로
    출제됩니다.
    <br />
    우측에는 남은 시간이 실시간으로 나타나니 시간 분배를 잘해서 풀이를
    해야합니다.
    <br />
    우측의 전자칠판으로 풀이 및 공유가 가능합니다.
    <br />
    정답작성후 ‘다음문제’ 버튼을 클릭하여 다음 문제로 넘어갈 수 있지만, 우측
    최하단의 ‘제출’
    <br />
    버튼을 클릭하지 않는 한 이전문제로 돌아가서 다시 풀이하고 정답를 수정할 수
    있습니다.
    <br />
    중앙 하단 페이지네이션을 통해 문제페이지를 편하게 이동할 수 있습니다.
    <br />
    <br />
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">
      4.평가 마지막 페이지
    </h2>
    20번문제에서 제출을 클릭하면 해당 문구의 팝업창이 뜹니다. 제출을 클릭하면
    <br />
    절대 수정이 불가하니 신중히 선택해야합니다.
  </div>
);

export const Teacher_HelpAssessment = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <h2 className="text-aidt-teacherAccentBg text-xl font-bold">1.진단평가</h2>
    시험은 학력진단평가, 대단원 형성평가, 학기말 총괄평가 총 세가지로
    구성되어있습니다.
    <br />
    학력진단평가 : 학기 진도에 나가기에 앞서 최초에 학습자의 수준을 평가하기
    위한 시험.
    <br />
    대단원 형성평가 : 1개 대단원을 모두 학습한 후 평가하는 시험.
    <br />
    학기말 총괄평가 : 한 학년이 끝나고 모든 단원이 끝났을때 전체 결산 및 평가를
    하기위한 시험.
    <br />
    <br />
    <h2 className="text-aidt-teacherAccentBg text-xl font-bold">
      2.시험세팅 및 시작
    </h2>
    교사는 평가의 시간설정, 시작의 권한이 있습니다. 시험시간을 설정하고
    지금시작을 <br />
    클릭하면 시험이 시작됩니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12275.png`} width={700} />
  </div>
);

export const Student_HelpNotice = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">
      1.공지사항 페이지
    </h2>
    선생님 또는 시스템관리자의 공지를 확인 할 수있는 페이지입니다.
    <br />
    상단메뉴에서 ‘공지사항’버튼을 클릭하여 이동가능합니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12269.png`} width={400} />
  </div>
);

export const Teacher_HelpNotice = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    공지사항을 보거나 등록할 수 있는 페이지입니다. <br />
    교사는 공지사항을 등록하거나 수정,삭제할수 있는 권한이 있습니다.
  </div>
);

export const Student_HelpQA = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">1.질문하기</h2>
    본인이 풀이학습 채점 후 문제에 대한 질문을 하고싶다면 ‘질문하기’ 를
    클릭합니다. <br />
    클릭 시 우측화면이 생성되며 제목과 공유범위 설정 후 등록할 수 있습니다.
    <br />
    <br />
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">2.답변하기</h2>
    상단메뉴에서 ‘답변하기’ 클릭 후 ‘나의 질문’ 탭을 클릭하면 나의 질문목록을
    확인할 수 있습니다.
    <br />
    나의 질문에 대한 답변이 있다면 ‘답변확인’을 클릭하여 확인할 수 있고 아직
    답변이 없다면
    <br />
    아래의 이미지와 같이 ‘답변대기’ 버튼으로 비활성화 되어있습니다.
    <br />
    <br />
    <h2 className="text-aidt-studentAccentBg text-xl font-bold">3.질문목록</h2>
    해당페이지에서 ‘질문목록’ 탭을 클릭하면 다른 사용자들이 올린 질문 목록을 볼
    수 있습니다. <br />
    답변하기를 클릭하면 우측화면과 같이 질문에 대한 답변을 할 수있으며
    공유범위를 설정할 수 있습니다.
  </div>
);

export const Teacher_HelpQA = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    해당페이지에서 ‘질문목록’ 탭을 클릭하면 다른 사용자들이 올린 질문 목록을 볼
    수 있습니다. <br />
    답변하기를 클릭하면 우측화면과 답변을 할 수있으며 공유범위를 설정할 수
    있습니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12186.png`} width={700} />
  </div>
);

export const Student_HelpNotiAndSettings = (
  <div className="leading-7 flex flex-col items-start justify-start gap-2">
    <h2 className="text-xl font-bold">1.알람</h2>
    중요한 이벤트나 정보에 대해 실시간으로 인지할 수 있도록 알림을 제공하는
    기능입니다. <br />
    내가 질문한 문제의 답변, 공지사항,평가시험 알림 등의 정보를 알람으로 받을 수
    있습니다.
    <br />
    <img alt="대단원목차" src={`/images/help/help_12267.png`} width={700} />
    <br />
    <h2 className="text-xl font-bold">2.설정</h2>
    교과서 내에서 기본적으로 제공하는 시스템기능입니다. <br />
    <strong>(1) 다국어기능</strong>
    한국어,영어를 지원하여 사용자들이 선호하는 언어로 서비스를 이용할 수
    있습니다.
    <br />
    <strong>(2) 글자크기조정</strong>
    글자크기를 보통(100%),크게(150%),제일크게(200%)로 조정할 수 있습니다.
    <br />
    <strong>(3) 수업시작/종료</strong>
    수업을 시작하고 종료했음을 알려주는 기능입니다.
    <br />
    <strong>(4) 로그아웃</strong>
    사용자가 현재 세션을 종료하는 기능입니다.
  </div>
);

export const Teacher_HelpNotiAndSettings = Student_HelpNotiAndSettings;
