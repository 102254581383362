import { Box, Stack, Typography } from '@mui/material';
import { chartOptions } from 'app/components/Dashboard/StudentDashboard';
import { useLearningSystem } from 'queries/use-learning-system';
import { useStudentClassMembers } from 'queries/use-student-class-members';
import { useStudentStatistics } from 'queries/use-student-statistics';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';

export default function StudentStatisticsChart() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 학급 구성원 조회
  const classMembersQuery = useStudentClassMembers({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 현재 소단원 목표 결과 분석 조회
  const studentStatisticsQuery = useStudentStatistics({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      // classUuids: classMembersQuery.data
      //   ? classMembersQuery.data?.map(member => member.user_id)
      //   : [user.user_id],
      classUuids: [user.user_id],
      learningSysId: Number(subsectionId as string),
    },
  });

  // 차트 데이터 설정
  const chartDataSet = chartData => {
    return {
      datasets: [
        {
          backgroundColor: '#FFC000',
          data: chartData[0],
          label: t('common.student_set_goals'),
        },
        {
          backgroundColor: '#00B050',
          data: chartData[1],
          label: t('student_dashboard.achievement_indicators'),
        },
        {
          backgroundColor: '#4472C4',
          data: chartData[2],
          label: t('common.class_average'),
        },
      ],
      labels: [''],
    };
  };

  // 차트 데이터
  const levelStatisticsItem = studentStatisticsQuery.data?.find(
    item => item.itemName === 'LEVEL',
  );
  const correctRateStatisticsItem = studentStatisticsQuery.data?.find(
    item => item.itemName === 'CORRECT_RATE',
  );
  const metacognitionStatisticsItem = studentStatisticsQuery.data?.find(
    item => item.itemName === 'METACOGNITION',
  );
  const progressStatisticsItem = studentStatisticsQuery.data?.find(
    item => item.itemName === 'PROGRESS',
  );

  const chartData = [
    {
      data: chartDataSet([
        [levelStatisticsItem?.goal ?? 0],
        [levelStatisticsItem?.userAchievement ?? 0],
        [levelStatisticsItem?.classAchievement ?? 0],
      ]),
      options: chartOptions,
      title: 'common.learning_stage',
    },
    {
      data: chartDataSet([
        [correctRateStatisticsItem?.goal ?? 0],
        [correctRateStatisticsItem?.userAchievement ?? 0],
        [correctRateStatisticsItem?.classAchievement ?? 0],
      ]),
      options: chartOptions,
      title: 'common.accuracy_rate',
    },
    {
      data: chartDataSet([
        [metacognitionStatisticsItem?.goal ?? 0],
        [metacognitionStatisticsItem?.userAchievement ?? 0],
        [metacognitionStatisticsItem?.classAchievement ?? 0],
      ]),
      options: chartOptions,
      title: 'common.metacognition',
    },
    {
      data: chartDataSet([
        [progressStatisticsItem?.goal ?? 0],
        [progressStatisticsItem?.userAchievement ?? 0],
        [progressStatisticsItem?.classAchievement ?? 0],
      ]),
      options: chartOptions,
      title: 'common.progress_rate',
    },
  ];

  return (
    <Box
      aria-label={`${
        learningSystemQuery.data?.type === 'SECTION' ? '현재' : '대단원 내'
      } 소단원 목표 결과 분석을 나타내는 차트 목록입니다. 학습단계, 정답률, 메타인지, 진도율의 총 4개의 차트가 포함되어 있고, 각 항목별로 학생설정목표, 성취지표, 학급평균 데이터를 나타냅니다.`}
      sx={{
        backgroundColor: '#EBF2FE',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '.75rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        {learningSystemQuery.data?.type === 'SECTION'
          ? t('student_dashboard.current_subunit_goal_outcome')
          : '대단원 전체 평균 결과 분석'}
      </Typography>
      <Box paddingBottom=".3125rem" paddingX=".3125rem">
        <Stack
          alignItems="center"
          component="article"
          direction="row"
          gap=".625rem"
          justifyContent="space-between"
          paddingX=".625rem"
          paddingY=".75rem"
          sx={{
            backgroundColor: '#FFF',
            borderBottomLeftRadius: '.625rem',
            borderBottomRightRadius: '.625rem',
          }}
        >
          <Stack
            alignItems="start"
            direction="column"
            gap=".625rem"
            justifyContent="center"
            paddingLeft="1.25rem"
            paddingRight="2.25rem"
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                paddingLeft: '.625rem',
                paddingRight: '.625rem',
              },
            })}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#FFC000',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {t('common.student_set_goals')}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#00B050',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {t('student_dashboard.achievement_indicators')}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              gap={1}
              justifyContent="center"
            >
              <Box
                height={12}
                sx={theme => ({
                  backgroundColor: '#4472C4',
                  [theme.breakpoints.down('md')]: {
                    height: '.625rem',
                    width: '.625rem',
                  },
                })}
                width={12}
              />
              <Typography
                fontSize=".9375rem"
                fontWeight="400"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                })}
                variant="body1"
              >
                {t('common.class_average')}
              </Typography>
            </Stack>
          </Stack>
          {chartData.map((chart, index) => (
            <Box
              aria-label={`${t(chart.title)} 차트입니다. 학생설정목표: ${
                chart.data.datasets[0]?.data[0]
              }, 성취지표: ${chart.data.datasets[1]?.data[0]}, 학급평균: ${
                chart.data.datasets[2]?.data[0]
              }`}
              border="1px solid #E0E0E0"
              borderRadius=".5rem"
              flex={1}
              key={index}
              sx={{
                backgroundColor: 'white',
              }}
              tabIndex={0}
              width="20%"
            >
              <Box paddingTop=".25rem" paddingX=".25rem">
                <Box
                  borderRadius=".375rem"
                  paddingX="10px"
                  paddingY="2px"
                  sx={{
                    backgroundColor: '#F8F8F8',
                  }}
                >
                  <Typography
                    fontSize="15px"
                    fontWeight="500"
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                    })}
                    variant="h6"
                  >
                    {t(chart.title)}
                  </Typography>
                </Box>
              </Box>
              <Box height="6rem">
                <Bar
                  data={chart.data}
                  fallbackContent={<p>차트가 표시하는 데이터의 대체 텍스트</p>}
                  options={chart.options}
                />
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
}
