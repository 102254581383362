import { Avatar, Chip, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ScopeType } from 'schemas/common';
import { useGetStudentNameQuery } from 'services/aidt-student';
import { RootState } from 'types';

interface SubHeaderProps {
  createdAt?: string;
  customStyles?: object;
  scope?: ScopeType;
  title: string;
  userUuid?: string;

  viewCount?: number;
}

export function SubHeader({
  createdAt,
  customStyles,
  scope,
  title,
  userUuid,
  viewCount,
}: SubHeaderProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  const { data: nameResponse } = useGetStudentNameQuery(
    {
      access_token: user.access_token,
      partner_id: partnerId,
      user_id: userUuid ?? '',
    },
    {
      skip: !userUuid || !user.access_token,
    },
  );

  const convertCreatedAt = (dateStr: string) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const getScopeText = (scope: ScopeType | undefined) => {
    if (scope === 'ALL') {
      return t('common.public_to_all');
    } else if (scope === 'CLASS') {
      return t('common.public_to_class');
    }
    return '';
  };
  return (
    <Stack
      direction={'column'}
      gap={2}
      sx={{
        borderBottom: '1px solid #DCDCDC',
        pb: 1.5,
        pt: 2.5,
        ...customStyles,
      }}
    >
      <Stack alignItems={'center'} direction={'row'} gap={1.5}>
        <Chip
          label={getScopeText(scope)}
          size="small"
          sx={{
            backgroundColor: '#555',
            color: 'white',
            fontSize: '.625rem',
            fontWeight: 'medium',
          }}
        />
        <Typography
          component={'h2'}
          sx={{ fontSize: '1.25rem', fontWeight: 'medium' }}
          variant={'h6'}
        >
          {title}
        </Typography>
      </Stack>
      <Stack alignItems={'center'} direction={'row'} gap={1.5}>
        <Avatar
          alt="avatar"
          src="/images/profile/female_student.svg"
          sx={{ height: 46, width: 46 }}
        />
        <Stack direction={'column'}>
          <Typography
            component={'p'}
            sx={{ fontSize: '.875rem', fontWeight: 'medium' }}
          >
            {userUuid}
          </Typography>

          <Typography
            component={'p'}
            sx={{ fontSize: '.625rem', fontWeight: 'medium' }}
          >
            {convertCreatedAt(createdAt ?? '')}
            {` `}
            {t('common.views')} {viewCount}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
