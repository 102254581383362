import { ReqDeleteReference, RespDeleteReference } from 'schemas/reference';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/study/reference-data';

// 참고자료 삭제
export const deleteReference = async ({
  headers,
  params,
}: ReqDeleteReference): Promise<RespDeleteReference> => {
  return (
    await axiosInstance.delete(`${ENDPOINT}/${params.id}`, {
      headers,
    })
  ).data;
};
