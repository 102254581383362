import { z } from 'zod';

export const BaseHeadersSchema = z.object({
  role: z.enum(['T', 'S', 'P']),
  uuid: z.string(),
});

export const BaseResponseSchema = z.object({
  path: z.string(),
  statusCode: z.number(),
  timestamp: z.string(),
});

export const BaseAidtDtoSchema = z.object({
  data: z.object({
    access_token: z.object({
      access_id: z.string(),
      token: z.string(),
    }),
    user_id: z.string(),
  }),
  headers: z.object({
    'Partner-ID': z.string(),
  }),
});

export const BaseUuidOnlyHeader = z.object({
  uuid: z.string(),
});

export type BaseHeaders = z.infer<typeof BaseHeadersSchema>;
export type BaseAidtDto = z.infer<typeof BaseAidtDtoSchema>;
