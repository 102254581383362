import apiService from './apiService';

const assessmentService = {
  createPlatform: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
    body: {
      assessment_id: number;
      problem_id: number;
    },
  ) => {
    const response = await apiService.post('/assessment/create-perform', body, {
      headers: {
        ...headers,
        'Class-Info': classInfo || '',
      },
    });
    return response.data;
  },

  done: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
    params: {
      assessment_id: number;
    },
  ) => {
    const response = await apiService.get('/assessment/done', {
      headers: {
        ...headers,
        'Class-Info': classInfo || '',
      },
      params: params,
    });
    return response.data;
  },

  getAssessmentStatus: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
    params: {
      learning_sys_id: number | string;
      type: string;
    },
  ) => {
    const response = await apiService.get('/assessment/status', {
      headers: {
        ...headers,
        'Class-Info': classInfo || '',
      },
      params,
    });

    return response?.data || {};
  },

  getDiagnostic: async (
    params: { containingProblemsPreview: boolean },
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
  ) => {
    const response = await apiService.get('/assessment/diagnostic', {
      headers: { ...headers, 'Class-Info': classInfo },
      params,
    });

    return response.data;
  },

  getDiagnosticProblems: async (
    id: number,
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
  ) => {
    const response = await apiService.get(
      `/assessment/diagnostic/${id}/problems`,
      {
        headers: {
          ...headers,
          'Class-Info': classInfo,
        },
      },
    );
    return response.data;
  },

  // teacher
  getDiagnosticTeacher: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
    body: { uuids: string[]; year: string },
  ) => {
    const response = await apiService.post(
      '/dashboard/teacher-users-plan-notes',
      body,
      {
        headers: {
          ...headers,
          'Class-Info': classInfo || '',
        },
      },
    );

    return response.data;
  },

  // 학력진단평가 유무 확인
  getIsDiagnostic: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
  ) => {
    const response = await apiService.get('/assessment/assessment-exist', {
      headers: {
        ...headers,
        'Class-Info': classInfo || '',
      },
    });

    return response.data;
  },

  submitPerform: async (
    headers: { role: string; uuid: string },
    classInfo: string | undefined,
    body: {
      submissions: {
        answer: string;
        assessment_id: number;
        problem_id: number;
      }[];
    },
  ) => {
    const response = await apiService.post(
      '/assessment/submit-performs',
      body,
      {
        headers: {
          ...headers,
          'Class-Info': classInfo || '',
        },
      },
    );
    return response.data;
  },
};

export default assessmentService;
