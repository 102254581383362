import { ScheduleInfo } from 'schemas/aidt-teacher';

import apiService from './apiService';

const authService = {
  authorize: async (credentials: {
    accessToken;
    classInfo: string;
    partnerId: string;
    role: string;
    schedule_info: ScheduleInfo[];
    school_name: string;
    semester: number;
    uuid: string;
  }) => {
    const response = await apiService.post(
      '/user/authorize',
      {
        accessToken: credentials.accessToken,
        partnerId: credentials.partnerId,
        schedule_info: credentials.schedule_info,
        school_name: credentials.school_name,
        semester: credentials.semester,
      },
      {
        headers: {
          'Class-Info': credentials.classInfo,
          role: credentials.role,
          uuid: credentials.uuid,
        },
      },
    );
    if (response?.data) {
      return response.data;
    } else {
      console.error('Not a proper response from the server');
    }
  },

  init: async () => {
    const response = await apiService.get('/user/init');
    return response.data;
  },
};

export default authService;
