import { useQuery } from '@tanstack/react-query';
import { getStrategyComment } from 'apis/dashboard';
import {
  GetStrategyCommentDataResponse,
  UseStrategyCommentDto,
} from 'schemas/student-dashboard';
import { generateStrategyComment } from 'utils/generate-strategy-comment';

export function useStrategyComment({
  headers,
  params,
  user_name,
}: UseStrategyCommentDto) {
  return useQuery<GetStrategyCommentDataResponse, Error, string>({
    enabled: !!user_name && !!params.learning_sys_id,
    queryFn: () => getStrategyComment({ headers, params }),
    queryKey: ['strategy_comment', { headers, params }],
    select: data => generateStrategyComment(data.data, user_name),
  });
}
