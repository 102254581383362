import { Box, Stack, Typography } from '@mui/material';
import { chartOptions } from 'app/components/Dashboard/StudentDashboard';
import { useLearningSystem } from 'queries/use-learning-system';
import { useTeacher } from 'queries/use-teacher';
import { useTeacherClassMembers } from 'queries/use-teacher-class-members';
import { useTeacherStatistics } from 'queries/use-teacher-statistics';
import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';

export default function TeacherStatisticsChart() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 선생님 학급 구성원 조회
  const teacherClassMembersQuery = useTeacherClassMembers({
    data: {
      access_token: user.access_token,
      class_code: teacherQuery.data?.class_info?.[0].class_code,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 1번 그래프 데이터 조회
  const teacherStaticsQuery = useTeacherStatistics({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      classUuids: teacherClassMembersQuery.data?.map(member => member.user_id),
      learningSysId: Number(subsectionId),
    },
  });

  const levelItem = teacherStaticsQuery.data?.find(
    item => item.itemName === 'LEVEL',
  );
  const correctRateItem = teacherStaticsQuery.data?.find(
    item => item.itemName === 'CORRECT_RATE',
  );
  const metacognitionItem = teacherStaticsQuery.data?.find(
    item => item.itemName === 'METACOGNITION',
  );
  const progressItem = teacherStaticsQuery.data?.find(
    item => item.itemName === 'PROGRESS',
  );

  // 차트 데이터 설정
  const chartDataSet = chartData => {
    return {
      datasets: [
        {
          backgroundColor: '#FFC000',
          data: chartData[0],
          label: '학급 목표 평균',
        },
        {
          backgroundColor: '#4472C4',
          data: chartData[1],
          label: '학급 달성 평균',
        },
      ],
      labels: [''],
    };
  };

  // 차트 데이터
  const chartData = [
    {
      data: chartDataSet([[levelItem?.goal], [levelItem?.classAchievement]]),
      options: chartOptions,
      title: 'common.learning_stage',
    },
    {
      data: chartDataSet([
        [correctRateItem?.goal],
        [correctRateItem?.classAchievement],
      ]),
      options: chartOptions,
      title: 'common.accuracy_rate',
    },
    {
      data: chartDataSet([
        [metacognitionItem?.goal],
        [metacognitionItem?.classAchievement],
      ]),
      options: chartOptions,
      title: 'common.metacognition',
    },
    {
      data: chartDataSet([
        [progressItem?.goal],
        [progressItem?.classAchievement],
      ]),
      options: chartOptions,
      title: 'common.progress_rate',
    },
  ];

  return (
    <Box
      aria-label={`${Number(
        teacherQuery.data?.class_info[0].user_grade,
      )}학년 ${teacherQuery.data?.class_info[0].user_class.slice(
        0,
        2,
      )}반 목표 결과 분석을 나타내는 차트 목록입니다. 학습단계, 정답률, 메타인지, 진도율의 총 4개의 차트가 포함되어 있고, 각 항목별로 학생설정목표, 학급평균 데이터를 나타냅니다.`}
      gridColumn="span 6"
      tabIndex={0}
    >
      <Stack alignItems="stretch" direction="column" sx={{ height: '100%' }}>
        <Stack
          alignItems="center"
          direction="row"
          paddingX=".9375rem"
          paddingY=".5rem"
        >
          <Typography
            fontSize="1.125rem"
            fontWeight="500"
            marginRight="2.875rem"
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                fontSize: '.9375rem',
                paddingX: '.75rem',
                paddingY: '.375rem',
              },
            })}
            variant="h6"
          >
            {learningSystemQuery.data?.type === 'SECTION'
              ? t('teacher_dashboard.goal_and_result_analysis', {
                  class: teacherQuery.data?.class_info[0].user_class
                    .split(' ')[1]
                    .replace(/[^0-9]/g, ''),
                  grade: teacherQuery.data?.class_info[0].user_grade,
                })
              : '대단원 내 소단원 목표 결과 분석'}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            gap=".5rem"
            marginRight="1.25rem"
          >
            <Box
              height={12}
              sx={theme => ({
                backgroundColor: '#FFC000',
                [theme.breakpoints.down('md')]: {
                  height: '.625rem',
                  width: '.625rem',
                },
              })}
              width={12}
            />
            <Typography
              fontSize=".9375rem"
              fontWeight="400"
              sx={theme => ({
                [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
              })}
              variant="body1"
            >
              학급 목표 평균
            </Typography>
          </Stack>

          <Stack alignItems="center" direction="row" gap=".5rem">
            <Box
              height={12}
              sx={theme => ({
                backgroundColor: '#4472C4',
                [theme.breakpoints.down('md')]: {
                  height: '.625rem',
                  width: '.625rem',
                },
              })}
              width={12}
            />
            <Typography
              fontSize=".9375rem"
              fontWeight="400"
              sx={theme => ({
                [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
              })}
              variant="body1"
            >
              학급 달성 평균
            </Typography>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          component="section"
          direction="row"
          gap={0.5}
          paddingBottom=".5rem"
          paddingX=".5rem"
        >
          {chartData.map((chart, index) => (
            <Box
              aria-label={`${t(chart.title)} 차트입니다. 학급목표평균: ${
                chart.data.datasets[0]?.data[0]
              }, 학급달성평균: ${chart.data.datasets[1]?.data[0]}`}
              border="1px solid #E0E0E0"
              borderRadius=".5rem"
              flex={1}
              key={index}
              sx={{
                backgroundColor: 'white',
              }}
              tabIndex={0}
              width="20%"
            >
              <Box paddingTop=".25rem" paddingX=".25rem">
                <Box
                  borderRadius=".375rem"
                  paddingX="10px"
                  paddingY="2px"
                  sx={{
                    backgroundColor: '#F8F8F8',
                  }}
                >
                  <Typography
                    fontSize="15px"
                    fontWeight="500"
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: { fontSize: '.75rem' },
                    })}
                    variant="h6"
                  >
                    {t(chart.title)}
                  </Typography>
                </Box>
                <Box height="6rem">
                  <Bar
                    data={chart.data}
                    fallbackContent={
                      <p>차트가 표시하는 데이터의 대체 텍스트</p>
                    }
                    options={chart.options}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
