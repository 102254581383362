import { useQuery } from '@tanstack/react-query';
import { checkAssignment } from 'apis/assignment';
import {
  Assignment,
  CheckAssignmentDto,
  CheckAssignmentResponse,
} from 'schemas/assignment';

export function useCheckAssignment({ headers, params }: CheckAssignmentDto) {
  return useQuery<CheckAssignmentResponse, Error, Assignment[]>({
    enabled: !!params.learningSysId,
    queryFn: () => checkAssignment({ headers, params }),
    queryKey: ['check_assignment', { headers, params }],
    select: data => data.data,
  });
}
