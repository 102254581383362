import { useQuery } from '@tanstack/react-query';
import { canCreateAssignment } from 'apis/assignment';
import {
  CanCreateAssignmentDto,
  CanCreateAssignmentResponse,
} from 'schemas/assignment';

export function useCanCreateAssignment({
  headers,
  params,
}: CanCreateAssignmentDto) {
  return useQuery<CanCreateAssignmentResponse, Error, boolean>({
    enabled: !!params.learningSysId && headers.role === 'T' && !!headers.uuid,
    queryFn: () => canCreateAssignment({ headers, params }),
    queryKey: ['can_create_assignment'],
    select: data => data.data,
  });
}
