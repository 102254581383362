import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Pagination,
  PaginationItem,
  Stack,
} from '@mui/material';
import { styled } from '@mui/system';
import { display } from 'html2canvas/dist/types/css/property-descriptors/display';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { CustomDialogProps } from '../../../types/DialogData';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiDialogContent-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  '& .MuiPaper-root': { minHeight: '36rem' },
}));

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  // 커스텀 스타일 적용
  '&.Mui-selected': {
    '&:hover': {
      backgroundColor: '#CEE2FA',
    },
    backgroundColor: '#CEE2FA',
    color: 'black',
    fontWeight: 'bold',
  },
  '&:hover': {
    backgroundColor: '#CEE2FA',
  },
}));

const CustomDialog = ({
  customContentStyle,
  customHeaderStyle,
  dialogData,
  handleClose,
  isPagination,
  maxWidth = 'md',
  open,
}: CustomDialogProps) => {
  const [videoList, setVideoList] = useState([1, 2, 3, 4, 5]);
  const [videoPage, setVideoPage] = useState(1);

  useEffect(() => {
    if (isPagination) {
      setVideoList([1, 2, 3, 4, 5]);
    }
  }, []);

  const handleVideoChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setVideoPage(value);
  };

  return (
    <BootstrapDialog
      aria-labelledby="customized-dialog-title"
      fullWidth={true}
      maxWidth={maxWidth}
      onClose={handleClose}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: 'absolute', right: 5, top: 12 }}
      >
        <CloseIcon sx={{ color: '#333333' }} />
      </IconButton>
      <DialogTitle
        id="customized-dialog-title"
        sx={{ ...customHeaderStyle, m: 0, p: 2, textAlign: 'center' }}
      >
        {dialogData.title}
      </DialogTitle>

      <DialogContent dividers sx={{ ...customContentStyle }}>
        {dialogData.contents}

        {isPagination && (
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            spacing={2}
            sx={{ padding: '10px 0' }}
          >
            <Pagination
              boundaryCount={0}
              count={videoList.length}
              getItemAriaLabel={(type, page, selected) => {
                if (type === 'page') {
                  return `${page}번 영상로 이동`;
                }
                return `${type} 문제`;
              }}
              onChange={handleVideoChange}
              page={videoPage}
              renderItem={item => (
                <CustomPaginationItem
                  {...item}
                  components={{
                    next: () => (
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          border: '1px solid #4782CA',
                          borderRadius: '5px',
                          color: '#1A48A9',
                          padding: '4px 12px',
                        }}
                      >
                        다음영상
                      </Box>
                    ),
                    previous: () => (
                      <Box
                        sx={{
                          backgroundColor: 'white',
                          border: '1px solid #4782CA',
                          borderRadius: '5px',
                          color: '#1A48A9',
                          padding: '4px 10px',
                        }}
                      >
                        이전영상
                      </Box>
                    ),
                  }}
                />
              )}
              shape="rounded"
              siblingCount={1}
              variant="text"
            />
          </Stack>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default CustomDialog;
