import { z } from 'zod';

export const VIDEO_BUCKETS = {
  ANNOUNCEMENT: 'announcement-data', //공지사항
  ANSWER: 'answers', //답변영상
  COMMON_CONCEPT: 'concept-videos', //공통인강
  CONCEPT_SOLUTION: 'concept-solution-videos', //공유풀이영상
  REFERENCE: 'reference-data', //참고자료
  SHARED_CONCEPT: 'shared-concept-videos', //개념공유영상
} as const;

export type ValueOfUnion<T extends { [k: string]: unknown }> = T[keyof T];

const PresignedPostInfoSchema = z.object({
  fields: z.record(z.string(), z.string()),
  url: z.string().url(),
});

const GetPreSignedPostInfoReqSchema = z.object({
  bucketName: z.enum(Object.values(VIDEO_BUCKETS) as [string, ...string[]]),
  curriculumId: z.string(),
  fileName: z.string(),
  problemId: z.string(),
  userId: z.string(),
});

const UploadVideoReqSchema = z.object({
  file: z.instanceof(Blob),
  presignedPostInfo: PresignedPostInfoSchema,
});

const UploadVideoResSchema = z.string().url();

export type PresignedPostInfo = z.infer<typeof PresignedPostInfoSchema>;
export type GetPreSignedPostInfoReq = z.infer<
  typeof GetPreSignedPostInfoReqSchema
>;
export type UploadVideoReq = z.infer<typeof UploadVideoReqSchema>;
export type UploadVideoRes = z.infer<typeof UploadVideoResSchema>;
