import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function LearningStageTable() {
  const { t } = useTranslation();

  const headers = [
    'step.item/learning_stage',
    'step.1st_stage',
    'step.2nd_stage',
    'step.3rd_stage',
    'step.4th_stage',
    'step.5th_stage',
    'step.6th_stage',
    'step.7th_stage',
    'step.8th_stage',
    'step.9th_stage',
    'step.10th_stage',
  ];

  const diagnosticAssessmentScores = [
    '0~10',
    '11~20',
    '21~30',
    '31~40',
    '41~50',
    '51~60',
    '61~70',
    '71~80',
    '81~90',
    '91~100',
  ];

  const questions = [
    ['step.low_with_count,4'],
    ['step.low_with_count,4'],
    ['step.low_with_count,4'],
    ['step.low_with_count,4'],
    ['step.medium_with_count,2', 'step.low_with_count,2'],
    [
      'step.high_with_count,2',
      'step.medium_with_count,1',
      'step.low_with_count,1',
    ],
    ['step.high_with_count,2', 'step.medium_with_count,2'],
    [
      'step.top_with_count,1',
      'step.high_with_count,2',
      'step.medium_with_count,1',
    ],
    ['step.top_with_count,2', 'step.high_with_count,2'],
    ['step.top_with_count,4'],
  ];

  const learningLevels = [
    'step.remedial_learning_needed',
    'step.low',
    'step.medium',
    'step.high',
  ];

  const lessons = [
    [
      'step.pre_pre_previous_with_count,1',
      'step.pre_previous_with_count,1',
      'step.pre_unit_with_count,1',
      'step.main_unit_with_count,1',
    ],
    [
      'step.pre_previous_with_count,1',
      'step.pre_unit_with_count,1',
      'step.main_unit_with_count,2',
    ],
    ['step.pre_unit_with_count,1', 'step.main_unit_with_count,3'],
    ['step.main_unit'],
    ['step.main_unit'],
    ['step.main_unit'],
    ['step.main_unit'],
    ['step.main_unit'],
    ['step.main_unit'],
    ['step.main_unit'],
  ];

  return (
    <Stack
      aria-label="학습단계 및 학습단계에 따른 문항의 단원, 난이도, 문항수 테이블입니다."
      borderRadius=".5rem"
      sx={{
        backgroundColor: '#E0FAEC',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".3125rem"
        px="1.25rem"
        py=".375rem"
      >
        <Box
          alt="학습단계 및 학습단계에 따른 문항의 단원, 난이도, 문항수 아이콘"
          component="img"
          height={36}
          src={'/icons/system_icon.svg'}
          width={36}
        />
        <Typography
          component="h3"
          fontSize="1.3125rem"
          fontWeight={500}
          variant="h3"
        >
          {t('step.table_title')}
        </Typography>
      </Stack>

      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".375rem" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              학습단계 및 학습단계에 따른 문항의 단원, 난이도, 문항 수 테이블
            </caption>
            <tbody>
              <tr
                style={{
                  backgroundColor: '#D0E3FA',
                  borderTopLeftRadius: '10px',
                  borderTopRightRadius: '10px',
                }}
              >
                {headers.map((header, index) => (
                  <Box
                    colSpan={1}
                    component="th"
                    key={index}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 700,
                      height: '1.5rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                    })}
                  >
                    {t(header)}
                  </Box>
                ))}
              </tr>

              {/* 진단평가점수 */}
              <tr aria-label="학습단계에 따른 진단평가점수입니다." tabIndex={0}>
                <Box
                  component="th"
                  scope="col"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 700,
                    height: '1.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.25rem',
                    },
                  })}
                >
                  {t('step.diagnostic_assessment_score')}
                </Box>

                {diagnosticAssessmentScores.map((item, index) => {
                  return (
                    <Box
                      aria-label={`${index + 1}단계는 ${item}점입니다.`}
                      component="td"
                      key={`item_${index}`}
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                      tabIndex={0}
                    >
                      {item}
                    </Box>
                  );
                })}
              </tr>

              {/* 난이도(문항수) */}
              <tr
                aria-label="학습단계에 따른 난이도 문항수입니다."
                tabIndex={0}
              >
                <Box
                  component="th"
                  scope="col"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 700,
                    height: '1.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.25rem',
                    },
                  })}
                >
                  {t('step.difficulty')}
                </Box>

                {questions.map((item, index) => {
                  return (
                    <Box
                      aria-label={`${index + 1}단계는 ${item.map(subItem =>
                        t(subItem?.split(',')[0], {
                          count: Number(subItem?.split(',')[1]),
                        }),
                      )}입니다.`}
                      component="td"
                      key={`item_${index}`}
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                      tabIndex={0}
                    >
                      <Stack
                        alignItems="center"
                        direction="column"
                        justifyContent="center"
                      >
                        {item.map((subItem, subIndex) => {
                          const key = subItem.split(',')[0];
                          const count = Number(subItem.split(',')[1]);

                          if (subIndex === 0) {
                            return (
                              <span key={subIndex}>{t(key, { count })}</span>
                            );
                          }

                          return t(key, { count });
                        })}
                      </Stack>
                    </Box>
                  );
                })}
              </tr>

              {/* 단원 */}
              <tr aria-label="학습단계에 따른 단원입니다." tabIndex={0}>
                <Box
                  component="th"
                  scope="col"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 700,
                    height: '1.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.25rem',
                    },
                  })}
                >
                  {t('step.unit')}
                </Box>

                {lessons.map((item, index) => {
                  return (
                    <Box
                      aria-label={`${index + 1}단계는 ${item}점입니다.`}
                      component="td"
                      key={`item_${index}`}
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 700,
                        height: '5.375rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                      tabIndex={0}
                    >
                      <Stack
                        alignItems="center"
                        direction="column"
                        gap=".125rem"
                        justifyContent="center"
                      >
                        {item.map((subItem, subIdx) => (
                          <Box
                            borderRadius=".125rem"
                            height="1rem"
                            key={subIdx}
                            style={{
                              backgroundColor: '#DEF6E9',
                              border: '1px solid #0D9388',
                              color: '#076C64',
                              fontSize: '.625rem',
                              fontWeight: 500,
                            }}
                            width="3.125rem"
                          >
                            {subItem.split(',').length > 1
                              ? t(subItem.split(',')[0], {
                                  count: Number(subItem.split(',')[1]),
                                })
                              : t(subItem)}
                          </Box>
                        ))}
                      </Stack>
                    </Box>
                  );
                })}
              </tr>

              {/* 학습수준 */}
              <tr aria-label="학습단계에 따른 학습수준입니다." tabIndex={0}>
                <Box
                  component="th"
                  scope="col"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 700,
                    height: '1.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.25rem',
                    },
                  })}
                >
                  {t('step.learning_level')}
                </Box>

                {learningLevels.map((item, index) => {
                  const start = [1, 4, 6, 9];
                  return (
                    <Box
                      aria-label={`${
                        index % 2 === 0
                          ? `${start[index]}~${start[index] + 2}`
                          : `${start[index]}~${start[index] + 1}`
                      }단계는 ${t(item)}입니다.`}
                      colSpan={index % 2 === 0 ? 3 : 2}
                      component="td"
                      key={`item_${index}`}
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                      tabIndex={0}
                    >
                      {t(item)}
                    </Box>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>

      <Stack
        alignItems="center"
        direction="row"
        gap=".875rem"
        px="1.5rem"
        py=".5rem"
      >
        <Stack alignItems="center" direction="row" gap=".1875rem">
          <Box
            borderRadius=".125rem"
            height="1rem"
            sx={{
              border: '1px solid #0D9388',
              color: '#076C64',
              fontSize: '.625rem',
              fontWeight: 500,
              padding: '0 .25rem',
            }}
          >
            {t('step.pre_pre_previous')}
          </Box>
          <Typography color="#076C64" fontSize=".75rem" fontWeight={400}>
            : {t('step.pre_pre_previous_desc')}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap=".1875rem">
          <Box
            borderRadius=".125rem"
            height="1rem"
            sx={{
              border: '1px solid #0D9388',
              color: '#076C64',
              fontSize: '.625rem',
              fontWeight: 500,
              padding: '0 .25rem',
            }}
          >
            {t('step.pre_previous')}
          </Box>
          <Typography color="#076C64" fontSize=".75rem" fontWeight={400}>
            : {t('step.pre_previous_desc')}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap=".1875rem">
          <Box
            borderRadius=".125rem"
            height="1rem"
            sx={{
              border: '1px solid #0D9388',
              color: '#076C64',
              fontSize: '.625rem',
              fontWeight: 500,
              padding: '0 .25rem',
            }}
          >
            {t('step.pre_unit')}
          </Box>
          <Typography color="#076C64" fontSize=".75rem" fontWeight={400}>
            : {t('step.pre_unit_desc')}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" gap=".1875rem">
          <Box
            borderRadius=".125rem"
            height="1rem"
            sx={{
              border: '1px solid #0D9388',
              color: '#076C64',
              fontSize: '.625rem',
              fontWeight: 500,
              padding: '0 .25rem',
            }}
          >
            {t('step.main_unit')}
          </Box>
          <Typography color="#076C64" fontSize=".75rem" fontWeight={400}>
            : {t('step.main_unit_desc')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
