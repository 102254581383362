import { CircularProgress } from '@mui/material';
import React from 'react';

export const LoadingDOM = () => {
  return (
    <div className="w-full items-center justify-center p-10 flex flex-col gap-4">
      <CircularProgress thickness={4} />
      <div className="text-lg font-bold animate-pulse text-blue-700">
        로드 중..
      </div>
    </div>
  );
};
