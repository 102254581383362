import { LucideCheck } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function UnitItem({
  currentUnitId,
  setCurrentUnitId,
  unit,
  unitIdx,
}: {
  currentUnitId: number;
  setCurrentUnitId: (id: number) => void;
  unit;
  unitIdx: number;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSelected = unitIdx === currentUnitId;
  return (
    <button
      aria-label={`${unitIdx + 1}. ${unit.unit_name}`}
      className={
        (isSelected
          ? 'border-[#4F73C0] bg-[#CEE2FA] '
          : 'shadow-slate-300 border-[#CDCDCD]') +
        ' border-2 shadow w-full rounded-xl p-5 flex items-center justify-start gap-4 relative'
      }
      onClick={() => {
        setCurrentUnitId(unitIdx);
      }}
    >
      <div
        className={
          (isSelected ? 'border-[#4F73C0] ' : 'border-[#CDCDCD] ') +
          ' rounded-xl border-2 bg-white text-2xl font-black p-2 px-4'
        }
      >
        {unitIdx + 1}
      </div>
      {isSelected && (
        <span className="bg-[#4F73C0] rounded-full p-2 absolute -top-3 -right-3 flex items-center justify-center">
          <LucideCheck color="white" size={16} strokeWidth={4} />
        </span>
      )}
      <h2 className="text-2xl font-black">{`${unit.unit_name}`}</h2>
    </button>
  );
}
