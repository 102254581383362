import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  GetDiagnosticDoneDto,
  GetDiagnosticDoneResponse,
} from 'schemas/assessment';
import {
  AssessmentDiagnostic,
  CreateAssessmentDiagnosticDto,
  CreateAssessmentDiagnosticResponse,
  GetAssessmentDiagnosticDto,
  GetAssessmentResult,
  GetAssessmentResultDto,
} from 'schemas/teacher-assessment';
import { axiosBaseQuery } from 'services/api';

export const teacherAssessmentService = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: '/assessment',
  }),
  endpoints: build => ({
    // 진단평가 생성
    createAssessmentDiagnostic: build.mutation<
      CreateAssessmentDiagnosticResponse,
      CreateAssessmentDiagnosticDto
    >({
      invalidatesTags: ['AssessmentDiagnostic'],
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/diagnostic',
      }),
    }),
    // 진단평가 조회
    getAssessmentDiagnostic: build.query<
      AssessmentDiagnostic,
      GetAssessmentDiagnosticDto
    >({
      providesTags: ['AssessmentDiagnostic'],

      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/diagnostic',
      }),
    }),
    // 학생 학력진단평과 결과조회
    getAssessmentDiagnosticDone: build.query<
      GetDiagnosticDoneResponse,
      GetDiagnosticDoneDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/done',
      }),
    }),
    // 진단평가 결과 조회
    getAssessmentResult: build.query<
      GetAssessmentResult,
      GetAssessmentResultDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/result-table',
      }),
    }),
  }),
  reducerPath: 'teacherAssessmentApi',
  tagTypes: ['AssessmentDiagnostic'],
});

export const {
  useCreateAssessmentDiagnosticMutation,
  useGetAssessmentDiagnosticDoneQuery,
  useGetAssessmentDiagnosticQuery,
  useGetAssessmentResultQuery,
} = teacherAssessmentService;
