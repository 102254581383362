import {
  AnalysisTableRes,
  BasicSearchRes,
  CommentRes,
  DeleteConceptVideoCommentReq,
  DeleteConceptVideoCommentres,
  DeleteSharedSolutionVideoReqDto,
  DeleteSharedVideoCommentReq,
  DeleteSharedVideoCommentres,
  DeleteSharedVideoReqDto,
  DeleteSolutionVideoCommentReqDto,
  DeleteSolutionVideoCommentRes,
  GetAnalysisTableReqDto,
  GetBasicSearchReqDto,
  GetConceptReqDto,
  GetConceptRes,
  GetConceptVideoCommentsReq,
  GetConceptVideoCommentsRes,
  GetConceptVideoLikeReqDto,
  GetConceptVideoLikeRes,
  GetConfirmSearchReqDto,
  GetFeedbackSearchReqDto,
  GetQuestionBankReqDto,
  GetQuestionBankVideoReqDto,
  GetQuestionBankVideoRes,
  GetReferenceDataReqDto,
  GetReferenceDetailDataReqDto,
  GetSemesterReqDto,
  GetSharedSolutionVideoReqDto,
  GetSharedVideoCommentsReqDto,
  GetSharedVideoCommentsRes,
  GetSharedVideoReqDto,
  GetSolutionVideoCommentsReqDto,
  GetSolutionVideoCommentsRes,
  PatchCommentReqDto,
  PatchConceptVideoLikeReqDto,
  PatchConceptVideoLikeRes,
  PatchReferenceDataViewReqDto,
  PatchReferenceDetailDataLikeReqDto,
  PatchSharedSolutionVideoReqDto,
  PatchSolutionVideoCommentReqDto,
  PinSharedVideoReqDto,
  PostConceptVideoCommentReq,
  PostConceptVideoCommentRes,
  PostSharedSolutionVideoReqDto,
  PostSharedVideoCommentReqDto,
  PostSharedVideoCommentRes,
  PostSharedVideoLikeReqDto,
  PostSharedVideoLikeRes,
  PostSharedVideoReqDto,
  PostSolutionVideoCommentReqDto,
  PostSolutionVideoCommentRes,
  PostSolutionVideoLikeReqDto,
  PostSolutionVideoLikeRes,
  PostStudySubmitReqDto,
  PostStudySubmitRes,
  QuestionBankRes,
  ReferenceDataListRes,
  ReferenceDataPatchReqDto,
  ReferenceDataReqDto,
  ReferenceDataRes,
  ReferenceDetailRes,
  SharedSolutionVideoRes,
  SharedVideoRes,
  StudyChapterPlanReq,
  StudyChapterPlanRes,
  ViewSharedVideoReqDto,
} from 'schemas/study';

import { apiService } from './api';
import { mockApiService } from './mockApi';

const mockStudyService = mockApiService.injectEndpoints({
  endpoints: build => ({
    getSemester: build.query<GetConceptRes, GetSemesterReqDto>({
      query: ({ classInfo, cls_id, userId }) => {
        const params = new URLSearchParams();
        params.append('cls_id', cls_id);

        return {
          headers: {
            'Class-Info': classInfo,
            Uuid: userId,
          },
          method: 'GET',
          url: `/semesters`,
        };
      },
    }),
  }),
});

const studyService = apiService.injectEndpoints({
  endpoints: build => ({
    deleteConceptVideoComment: build.mutation<
      DeleteConceptVideoCommentres,
      DeleteConceptVideoCommentReq
    >({
      query: ({ commentId, headers }) => ({
        headers,
        method: 'DELETE',
        url: `/study/question-bank/video/comments/${commentId}`,
      }),
    }),
    deleteSharedSolutionVideo: build.mutation<
      void,
      { headers: DeleteSharedSolutionVideoReqDto; videoId: number }
    >({
      query: ({ headers, videoId }) => ({
        headers: {
          Uuid: headers.userId,
        },
        method: 'DELETE',
        url: `/shared-solution-video/${videoId}`,
      }),
    }),
    deleteSharedVideo: build.mutation<
      void,
      { headers: DeleteSharedVideoReqDto; videoId: number }
    >({
      query: ({ headers, videoId }) => ({
        headers: {
          Uuid: headers.userId,
        },
        method: 'DELETE',
        url: `/shared-video/${videoId}`,
      }),
    }),
    deleteSharedVideoComment: build.mutation<
      DeleteSharedVideoCommentres,
      DeleteSharedVideoCommentReq
    >({
      query: ({ commentId, headers }) => ({
        headers,
        method: 'DELETE',
        url: `/shared-video/comments/${commentId}`,
      }),
    }),

    deleteSolutionVideoComment: build.mutation<
      DeleteSolutionVideoCommentRes,
      DeleteSolutionVideoCommentReqDto
    >({
      query: ({ commentId, headers, videoId }) => ({
        headers,
        method: 'DELETE',
        url: `/shared-solution-video/${videoId}/comment/${commentId}`,
      }),
    }),
    getAnalysisTable: build.query<AnalysisTableRes, GetAnalysisTableReqDto>({
      query: ({ classInfo, learningSysId, role, type, userId, uuids }) => {
        return {
          data: {
            learning_sys_id: learningSysId,
            type,
            uuids,
          },
          headers: {
            'Class-Info': classInfo,
            Role: role,
            Uuid: userId,
          },
          method: 'POST',
          url: '/study/analysis-table',
        };
      },
    }),
    getBasicSearch: build.query<BasicSearchRes, GetBasicSearchReqDto>({
      query: ({ classInfo, learning_sys_id, role, user_uuid }) => ({
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: user_uuid,
        },
        method: 'GET',
        params: {
          learning_sys_id,
          user_uuid,
        },
        url: '/study/basic-search',
      }),
    }),
    getConcept: build.query<GetConceptRes, GetConceptReqDto>({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/concept/common-concept',
      }),
    }),
    getConceptVideoComments: build.query<
      GetConceptVideoCommentsRes,
      GetConceptVideoCommentsReq
    >({
      query: ({ headers, params, videoId }) => ({
        headers,
        method: 'GET',
        params,
        url: `/study/question-bank/video/${videoId}/comments`,
      }),
    }),
    getConceptVideoLike: build.query<
      GetConceptVideoLikeRes,
      GetConceptVideoLikeReqDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/concept/common-concept-like-mine',
      }),
    }),
    getConfirmSearch: build.query<BasicSearchRes, GetConfirmSearchReqDto>({
      query: ({ classInfo, learning_sys_id, role, user_uuid }) => ({
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: user_uuid,
        },
        method: 'GET',
        params: {
          learning_sys_id,
          user_uuid,
        },
        url: '/study/confirm-search',
      }),
    }),
    getFeedbackSearch: build.query<BasicSearchRes, GetFeedbackSearchReqDto>({
      query: ({ classInfo, learning_sys_id, role, user_uuid }) => ({
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: user_uuid,
        },
        method: 'GET',
        params: {
          learning_sys_id,
          user_uuid,
        },
        url: '/study/feedback-search',
      }),
    }),
    getQuestionBank: build.query<QuestionBankRes, GetQuestionBankReqDto>({
      query: ({
        Uuid,
        classInfo,
        difficulty,
        learningSysId,
        problemId,
        problemType,
        role,
      }) => ({
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid,
        },
        method: 'GET',
        params: {
          difficulty,
          learningSysId,
          problemId,
          problemType,
        },
        url: '/study/question-bank',
      }),
    }),
    getQuestionBankVideo: build.query<
      GetQuestionBankVideoRes,
      GetQuestionBankVideoReqDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/study/question-bank/video',
      }),
    }),
    getReferenceData: build.query<ReferenceDataListRes, GetReferenceDataReqDto>(
      {
        query: ({ headers, params }) => ({
          headers,
          method: 'GET',
          params,
          url: '/study/reference-data',
        }),
      },
    ),
    getReferenceDataById: build.query<
      ReferenceDetailRes,
      GetReferenceDetailDataReqDto
    >({
      query: ({ headers, referenceDataId }) => ({
        headers,
        method: 'GET',
        url: `/study/reference-data/${referenceDataId}`,
      }),
    }),
    getSharedSolutionVideo: build.query<
      SharedSolutionVideoRes,
      GetSharedSolutionVideoReqDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/shared-solution-video',
      }),
    }),
    getSharedVideo: build.query<SharedVideoRes, GetSharedVideoReqDto>({
      query: ({
        classInfo,
        learningSysId,
        onlyMine,
        page,
        pageSize,
        userId,
      }) => {
        const params = new URLSearchParams({
          learningSysId: learningSysId.toString(),
          onlyMine: onlyMine.toString(),
          page: page.toString(),
          pageSize: pageSize.toString(),
        });

        return {
          headers: {
            'Class-Info': classInfo,
            Uuid: userId,
          },
          method: 'GET',
          url: `/shared-video?${params.toString()}`,
        };
      },
    }),
    getSharedVideoComments: build.query<
      GetSharedVideoCommentsRes,
      GetSharedVideoCommentsReqDto
    >({
      query: ({ headers, params, videoId }) => ({
        headers,
        method: 'GET',
        params,
        url: `/shared-video/${videoId}/comments`,
      }),
    }),
    getSolutionVideoComments: build.query<
      GetSolutionVideoCommentsRes,
      GetSolutionVideoCommentsReqDto
    >({
      query: ({ headers, params, videoId }) => ({
        headers,
        method: 'GET',
        params,
        url: `/shared-solution-video/${videoId}/comment`,
      }),
    }),
    getStudyChapterPlan: build.query<
      StudyChapterPlanRes,
      {
        classInfo: string;
        learning_sys_id: number;
        role: string;
        semester_id: number;
        userId: string;
      }
    >({
      extraOptions: {
        retry: false,
      },
      query: ({ classInfo, learning_sys_id, role, semester_id, userId }) => {
        return {
          headers: {
            'Class-Info': classInfo,
            Role: role,
            Uuid: userId,
          },
          method: 'GET',
          params: {
            learning_sys_id,
            semester_id,
          },
          url: '/study/study-chapter-plan',
        };
      },
    }),
    patchComment: build.mutation<
      CommentRes,
      { body: PatchCommentReqDto; commentId: number }
    >({
      query: ({ body, commentId }) => ({
        data: { content: body.content },
        headers: {
          Uuid: body.userId,
        },
        method: 'PATCH',
        url: `/shared-video/comments/${commentId}`,
      }),
    }),
    patchConceptVideoLike: build.mutation<
      PatchConceptVideoLikeRes,
      PatchConceptVideoLikeReqDto
    >({
      query: ({ data, headers, videoId }) => ({
        data,
        headers,
        method: 'PATCH',
        url: `/study/question-bank/video/${videoId}/like`,
      }),
    }),
    patchReferenceData: build.mutation<
      ReferenceDataRes,
      ReferenceDataPatchReqDto
    >({
      query: ({ data, headers, params }) => ({
        data,
        headers,
        method: 'PATCH',
        url: `/study/reference-data/${params.id}`,
      }),
    }),
    patchReferenceDataLike: build.mutation<
      void,
      PatchReferenceDetailDataLikeReqDto
    >({
      query: ({ data, headers, referenceDataId }) => ({
        data,
        headers,
        method: 'PATCH',
        url: `/study/reference-data/${referenceDataId}/like`,
      }),
    }),
    patchReferenceDataView: build.mutation<void, PatchReferenceDataViewReqDto>({
      query: ({ headers, referenceDataId }) => ({
        headers,
        method: 'PATCH',
        url: `/study/reference-data/${referenceDataId}/view`,
      }),
    }),
    patchSharedSolutionVideo: build.mutation<
      void,
      { body: PatchSharedSolutionVideoReqDto; videoId: number }
    >({
      query: ({ body, videoId }) => ({
        headers: {
          'Class-Info': body.classInfo,
          Uuid: body.userId,
        },
        method: 'PATCH',
        url: `/shared-solution-video/${videoId}`,
      }),
    }),
    patchSolutionVideoComment: build.mutation<
      void,
      {
        body: PatchSolutionVideoCommentReqDto;
        commentId: number;
        videoId: number;
      }
    >({
      query: ({ body, commentId, videoId }) => ({
        data: { content: body.content },
        headers: {
          Uuid: body.userId,
        },
        method: 'PATCH',
        url: `/shared-solution-video/${videoId}/comment/${commentId}`,
      }),
    }),
    patchStudyChapterPlan: build.mutation<
      StudyChapterPlanRes,
      {
        body: StudyChapterPlanReq;
        classInfo: string;
        role: string;
        userId: string;
      }
    >({
      query: ({ body, classInfo, role, userId }) => ({
        data: body,
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: userId,
        },
        method: 'PATCH',
        url: '/study/study-chapter-plan',
      }),
    }),
    pinSharedVideo: build.mutation<
      void,
      { body: PinSharedVideoReqDto; videoId: number }
    >({
      query: ({ body, videoId }) => ({
        data: { pin: body.pin },
        headers: {
          'Class-Info': body.classInfo,
          Uuid: body.userId,
        },
        method: 'PATCH',
        url: `/shared-video/${videoId}/pin`,
      }),
    }),
    postConceptVideoComment: build.mutation<
      PostConceptVideoCommentRes,
      PostConceptVideoCommentReq
    >({
      query: ({ data, headers, videoId }) => ({
        data,
        headers,
        method: 'POST',
        url: `/study/question-bank/video/${videoId}/comments`,
      }),
    }),
    postReferenceData: build.mutation<ReferenceDataRes, ReferenceDataReqDto>({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: `/study/reference-data`,
      }),
    }),
    postSharedSolutionVideo: build.mutation<
      void,
      { body: PostSharedSolutionVideoReqDto }
    >({
      query: ({ body }) => ({
        data: {
          problemId: body.problemId,
          scope: body.scope,
          videoPath: body.videoPath,
        },
        headers: {
          'Class-Info': body.classInfo,
          Role: body.role,
          Uuid: body.userId,
        },
        method: 'POST',
        url: `/shared-solution-video`,
      }),
    }),
    postSharedVideo: build.mutation<SharedVideoRes, PostSharedVideoReqDto>({
      query: ({
        classInfo,
        learningSysId,
        role,
        scope,
        userId,
        videoPath,
      }) => ({
        data: { learningSysId, scope, videoPath },
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: userId,
        },
        method: 'POST',
        url: `/shared-video`,
      }),
    }),
    postSharedVideoComment: build.mutation<
      PostSharedVideoCommentRes,
      PostSharedVideoCommentReqDto
    >({
      query: ({ data, headers, videoId }) => ({
        data,
        headers,
        method: 'POST',
        url: `/shared-video/${videoId}/comment`,
      }),
    }),
    postSharedVideoLike: build.mutation<
      PostSharedVideoLikeRes,
      PostSharedVideoLikeReqDto
    >({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: `/shared-video/like`,
      }),
    }),
    postSolutionVideoComment: build.mutation<
      PostSolutionVideoCommentRes,
      PostSolutionVideoCommentReqDto
    >({
      query: ({ data, headers, videoId }) => ({
        data,
        headers,
        method: 'POST',
        url: `/shared-solution-video/${videoId}/comment`,
      }),
    }),
    postSolutionVideoLike: build.mutation<
      PostSolutionVideoLikeRes,
      PostSolutionVideoLikeReqDto
    >({
      query: ({ data, headers, videoId }) => ({
        data,
        headers,
        method: 'POST',
        url: `/shared-solution-video/${videoId}/like`,
      }),
    }),
    postStudySubmit: build.mutation<PostStudySubmitRes, PostStudySubmitReqDto>({
      query: ({
        answer,
        classInfo,
        confidence,
        problemId,
        role,
        studyId,
        userId,
      }) => ({
        data: {
          answer,
          confidence: confidence,
          problemId: problemId,
          studyId: studyId,
        },
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: userId,
        },
        method: 'POST',
        url: `/study/submit`,
      }),
    }),
    viewSharedVideo: build.mutation<
      void,
      { body: ViewSharedVideoReqDto; videoId: number }
    >({
      query: ({ body, videoId }) => ({
        headers: {
          'Class-Info': body.classInfo,
          Uuid: body.userId,
        },
        method: 'PATCH',
        url: `/shared-video/${videoId}/view`,
      }),
    }),
  }),
});

export const {
  useDeleteConceptVideoCommentMutation,
  useDeleteSharedSolutionVideoMutation,
  useDeleteSharedVideoCommentMutation,
  useDeleteSharedVideoMutation,
  useDeleteSolutionVideoCommentMutation,
  useGetAnalysisTableQuery,
  useGetBasicSearchQuery,
  useGetConceptQuery,
  useGetConceptVideoCommentsQuery,
  useGetConceptVideoLikeQuery,
  useGetConfirmSearchQuery,
  useGetFeedbackSearchQuery,
  useGetQuestionBankQuery,
  useGetQuestionBankVideoQuery,
  useGetReferenceDataByIdQuery,
  useGetReferenceDataQuery,
  useGetSharedSolutionVideoQuery,
  useGetSharedVideoCommentsQuery,
  useGetSharedVideoQuery,
  useGetSolutionVideoCommentsQuery,
  useGetStudyChapterPlanQuery,
  useLazyGetAnalysisTableQuery,
  useLazyGetBasicSearchQuery,
  useLazyGetConceptQuery,
  useLazyGetConfirmSearchQuery,
  useLazyGetFeedbackSearchQuery,
  useLazyGetQuestionBankQuery,
  useLazyGetReferenceDataQuery,
  useLazyGetSharedSolutionVideoQuery,
  useLazyGetSharedVideoQuery,
  useLazyGetSolutionVideoCommentsQuery,
  usePatchCommentMutation,
  usePatchConceptVideoLikeMutation,
  usePatchReferenceDataLikeMutation,
  usePatchReferenceDataMutation,
  usePatchReferenceDataViewMutation,
  usePatchSharedSolutionVideoMutation,
  usePatchSolutionVideoCommentMutation,
  usePatchStudyChapterPlanMutation,
  usePinSharedVideoMutation,
  usePostConceptVideoCommentMutation,
  usePostReferenceDataMutation,
  usePostSharedSolutionVideoMutation,
  usePostSharedVideoCommentMutation,
  usePostSharedVideoLikeMutation,
  usePostSharedVideoMutation,
  usePostSolutionVideoCommentMutation,
  usePostSolutionVideoLikeMutation,
  usePostStudySubmitMutation,
  useViewSharedVideoMutation,
} = studyService;

export const { useLazyGetSemesterQuery: useMockLazyGetSemesterQuery } =
  mockStudyService;
