import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import * as React from 'react';
import useDialogStore, { DialogKeys } from 'stores/use-dialog-store';

interface Props {
  onSubmit: () => void;
}

export default function CreateAssignmentDialog({ onSubmit }: Props) {
  const { open, toggleDialog } = useDialogStore(state => ({
    open: state.createAssignment,
    toggleDialog: state.toggleDialog,
  }));

  return (
    <Dialog
      onOpenChange={(open: boolean) =>
        toggleDialog(DialogKeys.CreateAssignment, open)()
      }
      open={open}
    >
      <DialogContent className="p-0 overflow-hidden border-none max-w-[40rem] ">
        <DialogHeader className="pb-24 pt-14 px-11">
          <DialogTitle className="text-xl font-medium" tabIndex={0}>
            정말 과제를 출제하시겠습니까?
            <br />이 소단원에 한번 출제한 과제는 다시 출제할 수 없습니다.
          </DialogTitle>
          <DialogDescription className="sr-only">
            정말 과제를 출제하시겠습니까?
            <br />이 소단원에 한번 출제한 과제는 다시 출제할 수 없습니다.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center border-t border-[#DCDCDC] h-[3.875rem]">
          <Button
            className="flex-1 h-full text-black transition-all duration-300 bg-white rounded-none hover:bg-white hover:text-black hover:opacity-80"
            onClick={() => toggleDialog(DialogKeys.CreateAssignment, false)()}
            onKeyDown={e =>
              e.key === 'Enter' &&
              toggleDialog(DialogKeys.CreateAssignment, false)()
            }
          >
            취소
          </Button>
          <Button
            className="flex-1 h-full mx-0 bg-white rounded-none hover:bg-white hover:text-destructive hover:opacity-80 duration-300 transition-all text-destructive border-l border-[#DCDCDC]"
            onClick={onSubmit}
            onKeyDown={e => e.key === 'Enter' && onSubmit()}
            type="submit"
          >
            출제하기
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
