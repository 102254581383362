import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  ImageObj,
  Line,
  Point,
  TOOL_LIST,
  ToolsType,
  WhiteboardCanvasState,
} from 'types/WhiteboardState';

import { HANDLE_POSITIONS, HandlePositionsType } from './ResizeHandles';

interface WhiteboardCanvasProps {
  boxSize: { height: number; width: number };
  canvasRef: React.RefObject<HTMLCanvasElement>;
  color: string;
  images: ImageObj[];
  isPaused: boolean;
  isRecording: boolean;
  lineWidth: number;
  lines: Line[];
  redoStack: WhiteboardCanvasState[];
  setImages: React.Dispatch<React.SetStateAction<ImageObj[]>>;
  setLines: React.Dispatch<React.SetStateAction<Line[]>>;
  setRedoStack: React.Dispatch<React.SetStateAction<WhiteboardCanvasState[]>>;
  setUndoStack: React.Dispatch<React.SetStateAction<WhiteboardCanvasState[]>>;
  tool: ToolsType;
  undoStack: WhiteboardCanvasState[];
}

export interface WhiteboardCanvasRef {
  canvasClear: () => void;
  canvasRedo: () => void;
  canvasRedraw: () => void;
  canvasUndo: () => void;
  handleAddImage: (
    img: HTMLImageElement,
    width: number,
    height: number,
  ) => void;
  setupCanvasSize: () => void;
}
interface ToolStyle {
  defaultLineWidth: number;
  globalAlpha: number;
  lineCap: CanvasLineCap;
}

const CANVAS_WRAP_MARGIN = 4;
const DEFAULT_LINE_JOIN = 'round';
export const PENCIL_STYLE: ToolStyle = {
  defaultLineWidth: 2,
  globalAlpha: 1.0,
  lineCap: 'round',
};

const HIGHLIGHTER_STYLE: ToolStyle = {
  defaultLineWidth: 16,
  globalAlpha: 0.3,
  lineCap: 'butt',
};

// const SELECTED_IMAGE_STYLE = {
//   strokeStyle: 'black',
//   lineWidth: 1,
// };

const IMAGE_DEFAULT_STYLE = {
  lineWidth: 2,
  radius: 6,
  strokeStyle: '#A6E8F8',
};

const WhiteboardCanvas = forwardRef<WhiteboardCanvasRef, WhiteboardCanvasProps>(
  (props, ref) => {
    const {
      boxSize,
      canvasRef,
      color,
      images,
      isPaused,
      isRecording,
      lineWidth,
      lines,
      redoStack,
      setImages,
      setLines,
      setRedoStack,
      setUndoStack,
      tool,
      undoStack,
    }: WhiteboardCanvasProps = props;
    const [drawing, setDrawing] = useState(false);
    const [currentLine, setCurrentLine] = useState<Line | null>(null);
    const [selectedImage, setSelectedImage] = useState<ImageObj | null>(null);
    const [resizing, setResizing] = useState(false);
    const [lastPos, setLastPos] = useState<Point | null>(null);
    const [currentHandle, setCurrentHandle] =
      useState<HandlePositionsType | null>(null);
    const [initialRect, setInitialRect] = useState<DOMRect | null>(null);
    const [canvasStartPos, setCanvasStartPos] = useState<Point | null>(null);

    useImperativeHandle(ref, () => ({
      canvasClear() {
        setUndoStack([...undoStack, { images, lines }]);
        setLines([]);
        setImages([images[0]]);
        setRedoStack([]);
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        initCanvas(context, canvas.width, canvas.height);
        redrawAll(context);
      },
      canvasRedo() {
        if (redoStack.length > 0) {
          setSelectedImage(null);
          setUndoStack([...undoStack, { images, lines }]);
          const prevData: WhiteboardCanvasState | undefined = redoStack.pop();
          if (prevData) {
            setLines(prevData.lines);
            setImages(prevData.images);
          }
          const canvas = canvasRef.current;
          if (!canvas) return;
          const context = canvas.getContext('2d');
          if (!context) return;
          initCanvas(context, canvas.width, canvas.height);
          redrawAll(context);
        }
      },
      canvasRedraw() {
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        redrawAll(context);
      },
      canvasUndo() {
        if (undoStack.length > 0) {
          setSelectedImage(null);
          setRedoStack([...redoStack, { images, lines }]);
          const prevData: WhiteboardCanvasState | undefined = undoStack.pop();
          if (prevData) {
            setLines(prevData.lines);
            setImages(prevData.images);
          }

          const canvas = canvasRef.current;
          if (!canvas) return;
          const context = canvas.getContext('2d');
          if (!context) return;
          initCanvas(context, canvas.width, canvas.height);
          redrawAll(context);
        }
      },
      getCanvasRef() {
        return canvasRef;
      },
      handleAddImage(img: HTMLImageElement, width: number, height: number) {
        undoStack.push(JSON.parse(JSON.stringify({ images, lines })));
        const newImage = {
          height: height,
          img: img,
          src: img.src,
          width: width,
          x: IMAGE_DEFAULT_STYLE.lineWidth,
          y: IMAGE_DEFAULT_STYLE.lineWidth,
        };
        setImages(prevImgs => [...prevImgs, newImage]);
        // 나중에 이미지를 수동으로 등록할 때 등록된 이미지 선택되게 처리
        // setSelectedImage(newImage);
        setRedoStack([]);
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        initCanvas(context, canvas.width, canvas.height);
        redrawAll(context);
      },
      setupCanvasSize() {
        const canvas = canvasRef.current;
        if (canvas) {
          canvas.width = boxSize.width - CANVAS_WRAP_MARGIN * 2;
          canvas.height = boxSize.height - CANVAS_WRAP_MARGIN * 2;
        }
      },
    }));

    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext('2d');
        if (context) {
          initCanvas(context, canvas.width, canvas.height);
          redrawAll(context);
        }
      }
    }, [lines, images, selectedImage]);

    useEffect(() => {
      if (tool !== TOOL_LIST.MOVE) {
        setSelectedImage(null);
      }
    }, [tool]);

    const initCanvas = (
      context: CanvasRenderingContext2D,
      canvasWidth: number,
      canvasHeight: number,
    ) => {
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvasWidth, canvasHeight);
    };

    // context가 clear 되고 선들을 다시 그릴 때 사용
    const redrawLines = (context: CanvasRenderingContext2D) => {
      context.lineJoin = DEFAULT_LINE_JOIN;
      lines.forEach(line => {
        context.beginPath();
        if (line.highlight) {
          context.globalAlpha = HIGHLIGHTER_STYLE.globalAlpha;
          context.lineCap = HIGHLIGHTER_STYLE.lineCap;
        } else {
          context.globalAlpha = PENCIL_STYLE.globalAlpha;
          context.lineCap = PENCIL_STYLE.lineCap;
        }
        context.strokeStyle = line.color;
        context.lineWidth = line.width;
        context.moveTo(line.points[0].x, line.points[0].y);
        for (let i = 1; i < line.points.length - 1; i++) {
          const midPoint = {
            x: (line.points[i].x + line.points[i + 1].x) / 2,
            y: (line.points[i].y + line.points[i + 1].y) / 2,
          };
          context.quadraticCurveTo(
            line.points[i].x,
            line.points[i].y,
            midPoint.x,
            midPoint.y,
          );
        }
        context.stroke();
      });
      context.globalAlpha = 1.0;
    };

    // 마우스 및 터치 이벤트에 따라 실시간으로 그릴 때 사용
    const draw = (line: Line) => {
      const canvas = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext('2d');
        if (context) {
          initCanvas(context, canvas.width, canvas.height);
          redrawAll(context);
          context.lineJoin = DEFAULT_LINE_JOIN;
          context.beginPath();
          context.globalAlpha = line.highlight
            ? HIGHLIGHTER_STYLE.globalAlpha
            : PENCIL_STYLE.globalAlpha;
          context.lineCap = line.highlight
            ? HIGHLIGHTER_STYLE.lineCap
            : PENCIL_STYLE.lineCap;
          context.strokeStyle = line.color;
          context.lineWidth = line.width;
          context.moveTo(line.points[0].x, line.points[0].y);

          for (let i = 1; i < line.points.length - 1; i++) {
            const midPoint = {
              x: (line.points[i].x + line.points[i + 1].x) / 2,
              y: (line.points[i].y + line.points[i + 1].y) / 2,
            };
            context.quadraticCurveTo(
              line.points[i].x,
              line.points[i].y,
              midPoint.x,
              midPoint.y,
            );
          }

          context.stroke();
          context.globalAlpha = 1.0;
        }
      }
    };

    // context가 clear 되고 선과 이미지를 다시 그릴 때 사용
    const redrawAll = (context: CanvasRenderingContext2D) => {
      images.forEach(image => {
        if (image.img instanceof HTMLImageElement) {
          context.save();
          context.beginPath();
          roundedRect(
            context,
            image.x,
            image.y,
            image.width,
            image.height,
            IMAGE_DEFAULT_STYLE.radius,
          );
          context.closePath();
          context.clip();
          context.drawImage(
            image.img,
            image.x,
            image.y,
            image.width,
            image.height,
          );
          context.restore();
          context.beginPath();
          roundedRect(
            context,
            image.x,
            image.y,
            image.width,
            image.height,
            IMAGE_DEFAULT_STYLE.radius,
          );
          context.lineWidth = IMAGE_DEFAULT_STYLE.lineWidth;
          context.strokeStyle = IMAGE_DEFAULT_STYLE.strokeStyle;
          context.stroke();
          // 이미지 선택 됐을 때의 border. 이미지의 기본 border가 생겨서 주석 처리
          // if (image === selectedImage) {
          //   context.strokeStyle = SELECTED_IMAGE_STYLE.strokeStyle;
          //   context.lineWidth = SELECTED_IMAGE_STYLE.lineWidth;
          //   context.strokeRect(image.x, image.y, image.width, image.height);
          // }
        }
      });
      redrawLines(context);
    };

    // move mode일 때 x, y 좌표에 있는 이미지 데이터 반환
    const getImageAtPosition = (x: number, y: number): ImageObj | null => {
      for (let i = images.length - 1; i >= 0; i--) {
        const image = images[i];
        if (
          x >= image.x &&
          x <= image.x + image.width &&
          y >= image.y &&
          y <= image.y + image.height
        ) {
          return image;
        }
      }
      return null;
    };

    //선분 p1, p2와 점 px, py 간의 거리 계산
    const distanceToSegment = (
      px: number,
      py: number,
      p1: Point,
      p2: Point,
    ) => {
      let x = p1.x;
      let y = p1.y;
      const dx = p2.x - x;
      const dy = p2.y - y;

      if (dx !== 0 || dy !== 0) {
        const t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);
        if (t > 1) {
          x = p2.x;
          y = p2.y;
        } else if (t > 0) {
          x += t * dx;
          y += t * dy;
        }
      }

      return Math.sqrt((px - x) * (px - x) + (py - y) * (py - y));
    };

    // 점 x, y에서 오차범위 내에 선분이 존재하는지 확인
    const isPointInLine = (x: number, y: number, line: Line) => {
      const tolerance = 20;
      for (let i = 0; i < line.points.length - 1; i++) {
        const p1 = line.points[i];
        const p2 = line.points[i + 1];
        if (distanceToSegment(x, y, p1, p2) < tolerance) {
          return true;
        }
      }
      return false;
    };

    const eraseItem = (x: number, y: number) => {
      let lineErased = false;
      for (let i = lines.length - 1; i >= 0; i--) {
        if (isPointInLine(x, y, lines[i])) {
          setUndoStack([...undoStack, { images, lines }]);
          const newLines = [...lines];
          newLines.splice(i, 1);
          setLines(newLines);
          setRedoStack([]);
          lineErased = true;
          break;
        }
      }
      if (!lineErased) {
        // 첫번째 이미지는 무조건 문제 이미지 이기 때문에 첫번째 배열 제외하고 탐색
        for (let i = images.length - 1; i >= 1; i--) {
          if (
            x >= images[i].x &&
            x <= images[i].x + images[i].width &&
            y >= images[i].y &&
            y <= images[i].y + images[i].height
          ) {
            setUndoStack([...undoStack, { images, lines }]);
            const newImages = [...images];
            newImages.splice(i, 1);
            setImages(newImages);
            setRedoStack([]);
            break;
          }
        }
      }
    };

    const handleCanvasMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      handleCanvasStart(x, y);
    };

    const handleCanvasTouchStart = (e: React.TouchEvent<HTMLCanvasElement>) => {
      const touch = e.touches[0];
      const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      handleCanvasStart(x, y);
    };

    const handleCanvasStart = (x: number, y: number) => {
      if (tool === TOOL_LIST.MOVE) {
        if (selectedImage) {
          const clickedImage = getImageAtPosition(x, y);
          if (clickedImage) {
            setSelectedImage(clickedImage);
          } else {
            setSelectedImage(null);
          }
        }
        setLastPos({ x, y });
        setCanvasStartPos({ x, y });
      }
      if (!isRecording || isPaused) return;
      if (tool === TOOL_LIST.PENCIL || tool === TOOL_LIST.HIGHLIGHTER) {
        const newLine: Line = {
          color: color,
          highlight: tool === TOOL_LIST.HIGHLIGHTER,
          points: [{ x, y }],
          width:
            tool === TOOL_LIST.HIGHLIGHTER
              ? HIGHLIGHTER_STYLE.defaultLineWidth
              : lineWidth,
        };
        setCurrentLine(newLine);
        setDrawing(true);
      } else if (tool === TOOL_LIST.ERASER) {
        eraseItem(x, y);
      }
    };

    const handleCanvasMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      handleCanvasMove(x, y);
    };

    const handleCanvasTouchMove = (e: React.TouchEvent<HTMLCanvasElement>) => {
      const touch = e.touches[0];
      const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      handleCanvasMove(x, y);
    };

    const handleCanvasMove = (x: number, y: number) => {
      if (drawing && currentLine) {
        const newLine = { ...currentLine };
        newLine.points.push({ x, y });
        setCurrentLine(newLine);
        draw(newLine);
      } else if (tool === TOOL_LIST.MOVE && lastPos) {
        if (selectedImage) {
          // moveImage(x, y);
        } else {
          moveCanvas(x, y);
        }
      }
    };

    const moveCanvas = (x: number, y: number) => {
      const canvas = canvasRef.current;
      if (!canvas || !lastPos) return;
      const context = canvas.getContext('2d');
      if (!context) return;

      // 화면 이동 위, 아래로만 가능하게 함. 좌우 허용 시 주석 해제
      // const dx = x - lastPos.x;
      const dx = 0;
      const dy = y - lastPos.y;
      setLastPos({ x, y });
      if (dy === 0) return;

      // images 배열에서 첫 번째 이미지의 y값을 기준으로 이동 제한
      if (images.length > 0) {
        const baseImage = images[0];
        const newY = baseImage.y + dy;

        if (newY <= IMAGE_DEFAULT_STYLE.lineWidth) {
          initCanvas(context, canvas.width, canvas.height);

          const newLines = lines.map(line => ({
            ...line,
            points: line.points.map(point => ({
              x: point.x + dx,
              y: point.y + dy,
            })),
          }));
          const newImages = images.map(image => ({
            ...image,
            x: image.x + dx,
            y: image.y + dy,
          }));

          setLines(newLines);
          setImages(newImages);
          redrawAll(context);
        }
      }
    };

    const moveImage = (x: number, y: number) => {
      const canvas = canvasRef.current;
      if (!canvas || !lastPos) return;
      const context = canvas.getContext('2d');
      if (!context) return;

      const dx = x - lastPos.x;
      const dy = y - lastPos.y;
      setLastPos({ x, y });

      if (selectedImage) {
        const newImage = {
          ...selectedImage,
          x: selectedImage.x + dx,
          y: selectedImage.y + dy,
        };
        setSelectedImage(newImage);
        setImages(images.map(img => (img === selectedImage ? newImage : img)));
        initCanvas(context, canvas.width, canvas.height);
        redrawAll(context);
      }
    };

    const handleCanvasMouseUp = (e: React.MouseEvent<HTMLCanvasElement>) => {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      handleCanvasEnd(x, y);
    };

    const handleCanvasTouchEnd = (e: React.TouchEvent<HTMLCanvasElement>) => {
      const touch = e.changedTouches[0];
      const rect = (e.target as HTMLCanvasElement).getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const y = touch.clientY - rect.top;
      handleCanvasEnd(x, y);
    };

    const handleCanvasEnd = (x: number, y: number) => {
      if (drawing && currentLine) {
        const newLine = { ...currentLine };
        // 점찍기
        newLine.points.push({ x, y }, { x: x + 1, y });
        setLines(prevLines => [...prevLines, newLine]);
        setUndoStack([...undoStack, { images, lines }]);
        setRedoStack([]);
        setCurrentLine(null);
        setDrawing(false);
      } else if (tool === TOOL_LIST.MOVE && canvasStartPos) {
        const range = 10;
        // start 이벤트 후 x, y 기준으로 10px 이내로 움직였으면 click 처리
        if (
          Math.abs(canvasStartPos.x - x) < range &&
          Math.abs(canvasStartPos.y - y) < range
        ) {
          const clickedImage = getImageAtPosition(x, y);
          if (clickedImage) {
            setSelectedImage(clickedImage);
          } else {
            setSelectedImage(null);
          }
        }
      }
      setLastPos(null);
    };

    const startResizing = (
      x: number,
      y: number,
      handle: HandlePositionsType,
      rect?: DOMRect,
    ) => {
      setResizing(true);
      setLastPos({ x, y });
      setCurrentHandle(handle);
      if (rect) {
        setInitialRect(rect);
      }
    };

    const handleResizeImageMouseMove = (e: MouseEvent) => {
      if (resizing && selectedImage && currentHandle) {
        e.preventDefault();
        const x = e.clientX;
        const y = e.clientY;
        handleResize(x, y);
      }
    };

    const handleResizeImageTouchMove = (e: TouchEvent) => {
      if (resizing && selectedImage && currentHandle && initialRect) {
        const touch = e.touches[0];
        const x = touch.clientX - initialRect.left;
        const y = touch.clientY - initialRect.top;
        handleResize(x, y);
      }
    };

    const handleResize = (x: number, y: number) => {
      if (resizing && selectedImage && currentHandle && lastPos) {
        const updatedImage = { ...selectedImage };
        const dx = x - lastPos.x;
        const dy = y - lastPos.y;
        switch (currentHandle) {
          case HANDLE_POSITIONS.TOP_LEFT:
            updatedImage.width -= dx;
            updatedImage.height -= dy;
            updatedImage.x += dx;
            updatedImage.y += dy;
            break;
          case HANDLE_POSITIONS.TOP_RIGHT:
            updatedImage.width += dx;
            updatedImage.height -= dy;
            updatedImage.y += dy;
            break;
          case HANDLE_POSITIONS.BOTTOM_LEFT:
            updatedImage.width -= dx;
            updatedImage.height += dy;
            updatedImage.x += dx;
            break;
          case HANDLE_POSITIONS.BOTTOM_RIGHT:
            updatedImage.width += dx;
            updatedImage.height += dy;
            break;
        }
        setImages(
          images.map(img => (img === selectedImage ? updatedImage : img)),
        );

        setSelectedImage(updatedImage);

        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        initCanvas(context, canvas.width, canvas.height);
        redrawAll(context);
        setLastPos({ x, y });
      }
    };

    const stopResizing = () => {
      setResizing(false);
      setCurrentHandle(null);
      setLastPos(null);
      setInitialRect(null);
    };

    useEffect(() => {
      window.addEventListener('mousemove', handleResizeImageMouseMove);
      window.addEventListener('touchmove', handleResizeImageTouchMove);
      window.addEventListener('mouseup', stopResizing);
      window.addEventListener('touchend', stopResizing);
      return () => {
        window.removeEventListener('mousemove', handleResizeImageMouseMove);
        window.removeEventListener('touchmove', handleResizeImageTouchMove);
        window.removeEventListener('mouseup', stopResizing);
        window.removeEventListener('touchend', stopResizing);
      };
    }, [resizing, selectedImage, currentHandle]);

    const roundedRect = (
      context: CanvasRenderingContext2D,
      x: number,
      y: number,
      width: number,
      height: number,
      radius: number,
    ) => {
      context.beginPath();
      context.moveTo(x + radius, y);
      context.lineTo(x + width - radius, y);
      context.quadraticCurveTo(x + width, y, x + width, y + radius);
      context.lineTo(x + width, y + height - radius);
      context.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height,
      );
      context.lineTo(x + radius, y + height);
      context.quadraticCurveTo(x, y + height, x, y + height - radius);
      context.lineTo(x, y + radius);
      context.quadraticCurveTo(x, y, x + radius, y);
      context.closePath();
    };

    return (
      <div
        style={{
          borderBottom: '1px solid #F8F8F8',
          borderLeft: '1px solid #F8F8F8',
          borderRadius: '0 0 6px 6px',
          borderRight: '1px solid #F8F8F8',
          margin: `0 ${CANVAS_WRAP_MARGIN}px ${CANVAS_WRAP_MARGIN}px ${CANVAS_WRAP_MARGIN}px`,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <canvas
          height={boxSize.height - CANVAS_WRAP_MARGIN * 2}
          onMouseDown={handleCanvasMouseDown}
          onMouseMove={handleCanvasMouseMove}
          onMouseUp={handleCanvasMouseUp}
          onTouchEnd={handleCanvasTouchEnd}
          onTouchMove={handleCanvasTouchMove}
          onTouchStart={handleCanvasTouchStart}
          ref={canvasRef}
          style={{
            cursor: `url(/icons/whiteboard/origin/functional/${tool}.svg) 4 20, auto`,
          }}
          width={boxSize.width - CANVAS_WRAP_MARGIN * 2}
        ></canvas>
        {/* {selectedImage && (
          <ResizeHandles image={selectedImage} startResizing={startResizing} />
        )} */}
      </div>
    );
  },
);

WhiteboardCanvas.displayName = 'WhiteboardCanvas';

export default WhiteboardCanvas;
