import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AnswerInfo,
  ConceptInfo,
  ProblemInfo,
  WhiteboardState,
} from 'types/WhiteboardState';

const initialState: WhiteboardState = {
  answerInfo: {
    boardType: 'answer',
    curriculumId: '',
    isAssessment: false,
    latexData: '',
    learningSysId: 0,
    problemId: 0,
    questionId: 0,
    subtitle: '',
    title: '',
  },
  conceptInfo: {
    boardType: 'concept',
    curriculumId: '',
    isAssessment: false,
    latexData: '',
    learningSysId: 0,
    subtitle: '',
    title: '',
  },
  isCompleteAnswer: false,
  isOpen: false,
  problemInfo: {
    boardType: 'solving',
    curriculumId: '',
    isAssessment: false,
    latexData: '',
    learningSysId: 0,
    problemId: 0,
    subtitle: '',
    title: '',
  },
};

const whiteboardSlice = createSlice({
  initialState,
  name: 'whiteboard',
  reducers: {
    closeModal: state => {
      state.isOpen = false;
      state.problemInfo = initialState.problemInfo;
      state.answerInfo = initialState.answerInfo;
    },
    openModal: state => {
      state.isOpen = true;
    },
    setAnswerData: (
      state: WhiteboardState,
      action: PayloadAction<AnswerInfo>,
    ) => {
      state.isCompleteAnswer = false;
      state.problemInfo = initialState.problemInfo;
      state.conceptInfo = initialState.conceptInfo;
      state.answerInfo = action.payload;
    },
    setCompleteAnswer: state => {
      state.isCompleteAnswer = true;
    },
    setConceptData: (
      state: WhiteboardState,
      action: PayloadAction<ConceptInfo>,
    ) => {
      state.problemInfo = initialState.problemInfo;
      state.answerInfo = initialState.answerInfo;
      state.conceptInfo = action.payload;
    },
    setProblemData: (
      state: WhiteboardState,
      action: PayloadAction<ProblemInfo>,
    ) => {
      state.answerInfo = initialState.answerInfo;
      state.conceptInfo = initialState.conceptInfo;
      state.problemInfo = action.payload;
    },
  },
});

export const {
  closeModal,
  openModal,
  setAnswerData,
  setCompleteAnswer,
  setConceptData,
  setProblemData,
} = whiteboardSlice.actions;

export default whiteboardSlice.reducer;
