/* eslint-disable perfectionist/sort-objects */
import { Box, Stack, Typography } from '@mui/material';
import { useLearningSystem } from 'queries/use-learning-system';
import { useStudent } from 'queries/use-student';
import { useStudentWeakChapters } from 'queries/use-student-weak-chapters';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';
import { calculateCorrectRate } from 'utils/calculate';

export default function StudentWeakChaptersTable() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 학생 전체 정보 조회
  const studentQuery = useStudent({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 취약 단원 난이도별 분석
  const studentWeakChaptersQuery = useStudentWeakChapters({
    headers: {
      // 'Class-Info': `${studentQuery.data?.school_id}-${studentQuery.data?.user_grade}-${studentQuery.data?.user_class}`,
      'Class-Info': user.classInfo,
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 테이블 데이터
  const headers = [
    'common.highest',
    'common.high',
    'common.medium',
    'common.low',
    'common.sum',
  ];

  const subHeaders = [
    'common.problem',
    'common.incorrect',
    'common.accuracy_rate',
  ];

  const rowData = studentWeakChaptersQuery?.data
    ? studentWeakChaptersQuery.data.map(chapter => {
        return {
          award: [
            ...chapter.HIGHEST,
            calculateCorrectRate(chapter.HIGHEST[0], chapter.HIGHEST[1]),
          ],
          best: [
            ...chapter.HIGH,
            calculateCorrectRate(chapter.HIGH[0], chapter.HIGH[1]),
          ],
          middle: [
            ...chapter.MIDDLE,
            calculateCorrectRate(chapter.MIDDLE[0], chapter.MIDDLE[1]),
          ],
          under: [
            ...chapter.LOW,
            calculateCorrectRate(chapter.LOW[0], chapter.LOW[1]),
          ],
          sum: [
            ...chapter.SUM,
            calculateCorrectRate(chapter.SUM[0], chapter.SUM[1]),
          ],
          title: chapter.chapterName,
        };
      })
    : null;

  return (
    <Stack
      aria-label={`${
        learningSystemQuery.data?.type === 'SECTION' ? '' : '대단원 별 '
      }취약단원순 난이도별 분석을 나타내는 테이블입니다. 최대 3개의 취약단원을 순서대로 나타내고, 각 단원의 난이도별로 문제, 오답, 정답률 데이터를 나타냅니다.`}
      sx={{
        backgroundColor: '#EBF2FE',
        borderRadius: '10px',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '.75rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        {learningSystemQuery.data?.type === 'SECTION' ? '' : '대단원 '}
        취약단원순 난이도 별 분석
      </Typography>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".375rem" overflow="hidden">
          {
            <table
              style={{
                backgroundColor: '#BFC7E3',
                borderCollapse: 'collapse',
                height: '100%',
                tableLayout: 'fixed',
                textAlign: 'center',
                width: '100%',
              }}
            >
              <caption style={{ display: 'none' }}>학습 내역 데이터</caption>
              <tbody>
                <tr
                  style={{
                    backgroundColor: '#D0E3FA',
                  }}
                >
                  <Box
                    component="th"
                    rowSpan={2}
                    scope="colgroup"
                    sx={theme => ({
                      fontSize: '.75rem',
                      fontWeight: 600,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                      },
                    })}
                  >
                    취약
                    <br />
                    단원순
                  </Box>
                  <Box
                    colSpan={2}
                    component="th"
                    rowSpan={2}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 600,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                      },
                      width: '12.5rem',
                    })}
                  >
                    {t('common.subunit_name')}
                  </Box>
                  {headers.map((header, index) => (
                    <Box
                      colSpan={3}
                      component="th"
                      key={index}
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 600,
                        height: '1.5rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.25rem',
                        },
                      })}
                    >
                      {t(header)}
                    </Box>
                  ))}
                </tr>

                <tr>
                  {headers.map((data, index) => (
                    <React.Fragment key={index}>
                      {subHeaders.map((subHeader, i) => (
                        <Box
                          component="th"
                          key={i}
                          scope="col"
                          sx={theme => ({
                            backgroundColor: '#EBF2FE',
                            border: '1px solid #BFC7E3',
                            fontSize: '.75rem',
                            fontWeight: index === 4 ? 600 : 400,
                            [theme.breakpoints.down('md')]: {
                              fontSize: '.625rem',
                              height: '1.25rem',
                            },
                          })}
                        >
                          {t(subHeader)}
                        </Box>
                      ))}
                    </React.Fragment>
                  ))}
                </tr>

                {rowData?.map((data, index) => (
                  <tr
                    aria-label={`${index + 1}순위 취약단원명은 ${
                      data.title
                    }입니다.`}
                    key={index}
                    tabIndex={0}
                  >
                    <Box
                      component="td"
                      sx={theme => ({
                        backgroundColor: '#EBF2FE',
                        border: '1px solid #BFC7E3',
                        fontSize: '.75rem',
                        fontWeight: 500,
                        height: '1.75rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.5625rem',
                        },
                      })}
                    >
                      {index + 1}
                    </Box>
                    <td
                      colSpan={2}
                      style={{
                        backgroundColor: 'white',
                        border: '1px solid #BFC7E3',
                        padding: '0 .625rem',
                        textAlign: 'left',
                      }}
                    >
                      <Typography
                        fontSize=".75rem"
                        fontWeight="400"
                        noWrap
                        sx={theme => ({
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                          },
                        })}
                        variant="body2"
                      >
                        {data.title}
                      </Typography>
                    </td>
                    {Object.keys(data).map((key, index) => {
                      if (key === 'title') {
                        return null;
                      }

                      return (
                        <React.Fragment key={index}>
                          {data[key].map((item: number | string, i) => (
                            <Box
                              aria-label={`${
                                index === 0
                                  ? '최상 난이도의'
                                  : index === 1
                                  ? '상 난이도의'
                                  : index === 2
                                  ? '중 난이도의'
                                  : index === 3
                                  ? '하 난이도의'
                                  : '모든 난이도의'
                              } ${
                                i === 0
                                  ? `문제 수는 ${item || 0}개입니다.`
                                  : i === 1
                                  ? `오답 수는 ${item || 0}개입니다.`
                                  : i === 2
                                  ? `정답률은 ${item || 0}%입니다.`
                                  : ''
                              }`}
                              component="td"
                              key={i}
                              sx={theme => ({
                                backgroundColor: 'white',
                                border: '1px solid #BFC7E3',
                                fontSize: '.75rem',
                                fontWeight: 400,
                                [theme.breakpoints.down('md')]: {
                                  fontSize: '.625rem',
                                },
                              })}
                              tabIndex={0}
                            >
                              {item && typeof item === 'number'
                                ? item.toFixed(2)
                                : item || 0}
                            </Box>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          }
        </Box>
      </Box>
    </Stack>
  );
}
