import {
  GetCumulativeDataDto,
  GetCumulativeDataResponse,
  GetLearningHistoryDto,
  GetLearningHistoryResponse,
  GetLearningTimeHistoryDto,
  GetLearningTimeHistoryResponse,
  GetStrategyCommentDataDto,
  GetStrategyCommentDataResponse,
  GetStudentStatisticsComparisonDto,
  GetStudentStatisticsComparisonResponse,
  GetStudentStatisticsDto,
  GetStudentStatisticsResponse,
  GetStudentWeakChaptersDto,
  GetStudentWeakChaptersResponse,
} from 'schemas/student-dashboard';
import { apiService } from 'services/api';

export const studentDashboardService = apiService.injectEndpoints({
  endpoints: build => ({
    // 누적 데이터 조회
    getAccumulatedData: build.query<
      GetCumulativeDataResponse,
      GetCumulativeDataDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/accumulated-data',
      }),
    }),
    // 참여학습별 조회
    getLearningHistory: build.query<
      GetLearningHistoryResponse,
      GetLearningHistoryDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/learning-history',
      }),
    }),
    // 학습참여 상세정보 조회
    getLearningTimeHistory: build.query<
      GetLearningTimeHistoryResponse,
      GetLearningTimeHistoryDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/learning-time-history',
      }),
    }),
    // 학습전략추천문구 데이터 조회
    getStrategyCommentData: build.query<
      GetStrategyCommentDataResponse,
      GetStrategyCommentDataDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/strategy-comment-data',
      }),
    }),
    // 1번 그래프 데이터 조회
    getStudentStatistics: build.query<
      GetStudentStatisticsResponse,
      GetStudentStatisticsDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/student-statistics',
      }),
    }),
    // 2번 그래프 데이터 조회
    getStudentStatisticsRecent: build.query<
      GetStudentStatisticsComparisonResponse,
      GetStudentStatisticsComparisonDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/dashboard/student-statistics-recent',
      }),
    }),
    // 취약 단원 난이도별 분석
    getStudentWeakChapters: build.query<
      GetStudentWeakChaptersResponse,
      GetStudentWeakChaptersDto
    >({
      query: ({ headers }) => ({
        headers,
        method: 'GET',
        url: '/dashboard/student-weak-chapters',
      }),
    }),
  }),
});

export const {
  useGetAccumulatedDataQuery,
  useGetLearningHistoryQuery,
  useGetLearningTimeHistoryQuery,
  useGetStrategyCommentDataQuery,
  useGetStudentStatisticsQuery,
  useGetStudentStatisticsRecentQuery,
  useGetStudentWeakChaptersQuery,
} = studentDashboardService;
