import { BaseHeadersSchema, BaseResponseSchema } from 'schemas/base';
import { answerTypeEnum } from 'schemas/study';
import { z } from 'zod';

import { difficultyTypeEnum } from './common';

// 과제 유무 조회
const AssignmentSchema = z.object({
  assignmentFinish: z.object({
    assignment_gave_id: z.number(),
    created_at: z.string(),
    finished_at: z.string(),
    id: z.number(),
    user_uuid: z.string(),
  }),
  assignmentId: z.number(),
  assignmentType: z.enum(['BASIC', 'CONFIRM', 'FEEDBACK', 'METACOGNITION']),
  learningSysId: z.number(),
  uuid: z.string(),
});

const CheckAssignmentResponseSchema = BaseResponseSchema.extend({
  data: z.array(AssignmentSchema),
});

const CheckAssignmentDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    learningSysId: z.number().optional(),
  }),
});

export type Assignment = z.infer<typeof AssignmentSchema>;
export type CheckAssignmentDto = z.infer<typeof CheckAssignmentDtoSchema>;
export type CheckAssignmentResponse = z.infer<
  typeof CheckAssignmentResponseSchema
>;

// 학생 과제 조회
export const AssignmentPerformSchema = z.object({
  assignment_problem_id: z.number(),
  confidence: z.number(),
  created_at: z.string(),
  id: z.number(),
  is_correct: z.boolean(),
  submission_answer: z.string(),
});

const AssignmentProblemSchema = z.object({
  ai_hint: z.string(),
  answer_type: z.enum(['SHORT', 'SELECT', 'MULTISELECT']),
  assignment_id: z.number(),
  assignment_perform: AssignmentPerformSchema.nullable(),
  cls_id: z.string(),
  difficulty: difficultyTypeEnum,
  latex_data: z.string(),
  problem_id: z.number(),
});

const GetAssignmentSolveDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    // assignmentType이 복수로 선언될 수 있어서 string으로 변환
    assignmentType: z.string(),
    learningSysId: z.number(),
    // assignmentType: z.enum(['BASIC', 'CONFIRM', 'FEEDBACK', 'METACOGNITION']),
  }),
});

const AssignmentSolveResSchema = z
  .object({
    data: z.array(AssignmentProblemSchema),
  })
  .merge(BaseResponseSchema);

export type GetAssignmentSolveDto = z.infer<typeof GetAssignmentSolveDtoSchema>;
export type AssignmentSolveRes = z.infer<typeof AssignmentSolveResSchema>;

// 교사 참여학습 과제 제출
export const AssignmentTypesSchema = z.enum([
  'BASIC',
  'CONFIRM',
  'FEEDBACK',
  'METACOGNITION',
]);

const CreateAssignmentDtoSchema = z.object({
  data: z.object({
    classUuids: z.array(z.string()),
    factor: z.number().min(0).max(20),
    learningSysId: z.number(),
    types: z.array(AssignmentTypesSchema),
  }),
  headers: BaseHeadersSchema.extend({
    teacherUuid: z.string(),
  }),
});

const CreateAssignmentResponseSchema = BaseResponseSchema.extend({
  data: z.string(),
});

export type AssignmentTypes = z.infer<typeof AssignmentTypesSchema>;
export type CreateAssignmentDto = z.infer<typeof CreateAssignmentDtoSchema>;
export type CreateAssignmentResponse = z.infer<
  typeof CreateAssignmentResponseSchema
>;

const PostAssignmentSubmitReqDtoSchema = z.object({
  data: z.object({
    answer: z.string(),
    assignmentId: z.number(),
    confidence: z.number(),
    problemId: z.number(),
  }),
  headers: BaseHeadersSchema,
});

const PostAssignmentSubmitResSchema = z
  .object({
    data: z.object({
      assignment_problem_id: z.number(),
      confidence: z.number(),
      created_at: z.string(),
      id: z.number(),
      is_correct: z.number(),
    }),
  })
  .merge(BaseResponseSchema);

export type PostAssignmentSubmitReqDto = z.infer<
  typeof PostAssignmentSubmitReqDtoSchema
>;
export type PostAssignmentSubmitRes = z.infer<
  typeof PostAssignmentSubmitResSchema
>;

// 과제 결과 조회
const GetAssignmentResultResponseSchema = BaseResponseSchema.extend({
  data: z.array(
    z.object({
      achievementLevel: z.number(),
      assignmentId: z.number(),
      results: z.array(
        z.object({
          difficulty: z.enum(['LOW', 'MIDDLE', 'HIGH']),
          isCorrect: z.number(),
          problemId: z.number(),
        }),
      ),
      score: z.number(),
      uuid: z.string(),
    }),
  ),
});

const GetAssignmentResultDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    learningSysId: z.number(),
  }),
});

export type GetAssignmentResultResponse = z.infer<
  typeof GetAssignmentResultResponseSchema
>;
export type GetAssignmentResultDto = z.infer<
  typeof GetAssignmentResultDtoSchema
>;

// 과제 결과 문제 조회
const GetAssignmentProblemsResultResponseSchema = BaseResponseSchema.extend({
  data: z.array(
    z.object({
      assignment_gave_user_id: z.number(),
      assignment_perform: AssignmentPerformSchema.nullable(),
      created_at: z.string(),
      id: z.number(),
      learning_sys_fullname: z.string(),
      problem: z.object({
        ai_hint: z.string(),
        answer_data: z.string(),
        answer_type: z.enum(['SHORT', 'SELECT', 'MULTISELECT']),
        cls_id: z.string(),
        content_status: z.enum(['ACTIVED', 'INACTIVED']),
        created_at: z.string(),
        deleted_at: z.string().nullable(),
        detail_solution: z.string(),
        difficulty: difficultyTypeEnum,
        id: z.number(),
        is_algeomath: z.boolean(),
        is_ebs: z.boolean(),
        latex_data: z.string(),
        manage_no: z.string(),
        target_grade: z.number(),
        target_semester: z.number(),
        type: z.enum([
          'GENERAL',
          'DIAGNOSTIC',
          'UNIT_PROGRESS',
          'UNIT_END',
          'COMPREHENSIVE',
        ]),
        updated_at: z.string(),
      }),
      problem_id: z.number(),
      status: z.enum(['SUBMIT', 'IDLE']),
    }),
  ),
});

const GetAssignmentProblemsResultDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    assignment_gave_id: z.number(),
  }),
});

export type GetAssignmentProblemsResultResponse = z.infer<
  typeof GetAssignmentProblemsResultResponseSchema
>;
export type GetAssignmentProblemsResultDto = z.infer<
  typeof GetAssignmentProblemsResultDtoSchema
>;

const PostAssignmentFinishReqDtoSchema = z.object({
  data: z.object({
    assignment_gave_id: z.array(z.number()),
    finished_at: z.string(),
  }),
  headers: BaseHeadersSchema,
});

const PostAssignmentFinishResSchema = z
  .object({
    id: z.number(),
  })
  .merge(BaseResponseSchema);

export type PostAssignmentFinishReqDto = z.infer<
  typeof PostAssignmentFinishReqDtoSchema
>;

export type PostAssignmentFinishRes = z.infer<
  typeof PostAssignmentFinishResSchema
>;

// 과제 제출 가능 여부 조회
export const CanCreateAssignmentDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    learningSysId: z.number(),
  }),
});

export const CanCreateAssignmentResponseSchema = BaseResponseSchema.extend({
  data: z.boolean(),
});

export type CanCreateAssignmentDto = z.infer<
  typeof CanCreateAssignmentDtoSchema
>;
export type CanCreateAssignmentResponse = z.infer<
  typeof CanCreateAssignmentResponseSchema
>;

// 과제 문제 조회
export const GetAssignmentsDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    learningSysId: z.number(),
  }),
});

export const ProblemSchema = z.object({
  ai_hint: z.string(),
  answer_type: answerTypeEnum,
  assignment_id: z.number(),
  cls_id: z.string(),
  difficulty: difficultyTypeEnum,
  latex_data: z.string(),
});

export const GetAssignmentsResponseSchema = BaseResponseSchema.extend({
  data: z.array(ProblemSchema),
});

export type GetAssignmentsDto = z.infer<typeof GetAssignmentsDtoSchema>;
export type Problem = z.infer<typeof ProblemSchema>;
export type GetAssignmentsResponse = z.infer<
  typeof GetAssignmentsResponseSchema
>;

// 과제 제출 여부 조회
export const CheckAssignmentSubmissionDtoSchema = z.object({
  headers: BaseHeadersSchema,
  params: z.object({
    classUuids: z.array(z.string()).optional(),
    learningSysId: z.number(),
  }),
});

export const CheckAssignmentSubmissionResponseSchema =
  BaseResponseSchema.extend({
    data: z.array(
      z.object({
        assignment_finish: z.array(
          z.object({
            created_at: z.string(),
            given_at: z.string(),
            id: z.number(),
            learning_map_id: z.number(),
            learning_sys_id: z.number(),
            type: z.enum(['BASIC', 'CONFIRM', 'FEEDBACK', 'METACOGNITION']),
            user_uuid: z.string(),
          }),
        ),
        uuid: z.string(),
      }),
    ),
  });

export type CheckAssignmentSubmissionDto = z.infer<
  typeof CheckAssignmentSubmissionDtoSchema
>;
export type CheckAssignmentSubmissionResponse = z.infer<
  typeof CheckAssignmentSubmissionResponseSchema
>;
