import { Box } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

export function ExpiredPage() {
  return (
    <>
      <Helmet>
        <title>Session Expired</title>
        <meta content="Session expired" name="description" />
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ fontSize: 72, fontWeight: 'bold' }}>
          로그인이 만료되었습니다.
        </Box>
        <Box sx={{ fontSize: 32 }}>
          통합포털 재 로그인이 필요합니다. 통합포털로 이동하여 로그인 해 주세요.
        </Box>
      </Box>
    </>
  );
}
