import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import Typewriter from 'typewriter-effect';

import { formatDate } from '../../../services/dateService';
import SpeechBubble from '../../components/Common/SpeechBubble';
import { Layout } from '../../components/Layout';
import styles from './styles.module.css';

const dummyData = {
  date: '2021-10-10',
};

const ChatMessage = ({ isOwnMessage, message }) => {
  return isOwnMessage ? (
    <Box
      sx={{
        alignSelf: 'flex-end',
        borderRadius: 1,
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
        color: 'black',
        marginBottom: 1,
        maxWidth: '80%',
        padding: 1,
      }}
    >
      <Typography variant="body1">{message}</Typography>
    </Box>
  ) : (
    <SpeechBubble
      customBoxStyle={{
        minHeight: 'auto',
      }}
      customLayoutStyle={{
        alignSelf: 'flex-start',
        height: 'auto',
        maxWidth: '80%',
      }}
    >
      <Typewriter
        onInit={typewriter => {
          typewriter.changeDelay(50).typeString(message).pauseFor(500).start();
        }}
        options={{
          cursorClassName: `${styles.myCustomCursor}`,
        }}
      />
    </SpeechBubble>
  );
};

// This is a mock function. Replace it with your actual AI response function.
const getAiResponse = async message => {
  return `AI response to "${message}"`;
};

export function AiSearchPage() {
  const [messages, setMessages] = React.useState([
    {
      isOwnMessage: false,
      text: '매쓰튜터 AI입니다. 무엇이 궁금한가요? 자유롭게 질문해주세요.',
    },
  ]);
  const [newMessage, setNewMessage] = React.useState('');

  const handleSendMessage = async () => {
    setMessages([...messages, { isOwnMessage: true, text: newMessage }]);

    const aiResponse = await getAiResponse(newMessage);
    setMessages(prevMessages => [
      ...prevMessages,
      { isOwnMessage: false, text: aiResponse },
    ]);
    setNewMessage('');
  };

  return (
    <Layout customContainerStyle={{ padding: '32px 0' }}>
      <Stack
        alignItems={'center'}
        direction={'column'}
        justifyContent={'center'}
        spacing={2}
      >
        <Typography
          component={'h6'}
          sx={{
            border: 1,
            borderColor: '#CCCCCC',
            borderRadius: '20px',
            fontSize: '.75rem',
            margin: '0 auto',
            padding: '4px 12px',
          }}
          variant="subtitle1"
        >
          {formatDate(dummyData.date, 'llll')}
        </Typography>
        {/* chatting */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            maxHeight: 'calc(100vh - 330px)',
            overflowY: 'auto',
            px: 1,
            py: 2,
            width: '100%',
          }}
        >
          {messages.map((message, index) => (
            <ChatMessage
              isOwnMessage={message.isOwnMessage}
              key={index}
              message={message.text}
            />
          ))}
        </Box>

        <Box
          component="form"
          onSubmit={e => {
            e.preventDefault();
            handleSendMessage();
          }}
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: 2,
            justifyContent: 'center',
            position: 'relative',
            width: '100%',
          }}
        >
          <TextField
            autoFocus
            inputProps={{ sx: { height: '10vh' } }}
            multiline
            onChange={e => setNewMessage(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSendMessage();
              }
            }}
            rows={4}
            sx={{ flexGrow: 1, width: '100%' }}
            value={newMessage}
            variant="outlined"
          />
          <Button
            onClick={handleSendMessage}
            sx={{ margin: 1, position: 'absolute', right: 0, top: 0 }}
            variant="contained"
          >
            전송
          </Button>
        </Box>
      </Stack>
    </Layout>
  );
}
