import { Box, Select, Stack, Typography, styled } from '@mui/material';
import { Button } from 'components/ui/button';
import { Pencil } from 'lucide-react';
import { useRecentStatus } from 'queries/user/use-recent-status';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useDrawerStore from 'stores/use-drawer-store';
import { RootState } from 'types';
import { findConsecutiveCount } from 'utils/find-consecutive-count';

import { MENTAL_ICONS } from '../../../constants';

// TODO: 데이터에 따라 aria-label을 다르게 표현해야 함
export default function LearningGoal() {
  const { t } = useTranslation();

  const toggleDrawer = useDrawerStore(state => state.toggleDrawer);

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);

  // 학생 최근 상태 조회
  const recentStatusQuery = useRecentStatus({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
  });

  return (
    <Stack gridColumn="span 2" sx={{ position: 'relative' }}>
      <Button
        aria-label="프로필 설정 버튼입니다. 엔터 키를 누르면 프로필 설정 창이 나타납니다."
        className="absolute rounded-full -right-1.5 -top-1.5 size-8"
        onClick={toggleDrawer('profileSetting', true)}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            toggleDrawer('profileSetting', true)();
          }
        }}
        size="icon"
        variant="outline"
      >
        <Pencil className="w-4 h-4" />
      </Button>
      <Stack flex={1} gap={0.5}>
        <Stack
          borderRadius=".5rem"
          flexGrow={1}
          padding=".25rem"
          sx={{
            backgroundColor: '#CEE2FA',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          }}
        >
          <Stack
            alignItems="center"
            aria-label={`장래희망은 ${
              recentStatusQuery.data?.dreamJobs[0]?.dream_jobs.split('|')[0]
            }입니다.`}
            direction="row"
            gap=".375rem"
            height="2rem"
            paddingBottom={0.5}
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                height: '1.5rem',
              },
            })}
            tabIndex={0}
          >
            <Stack
              alignItems="center"
              direction="row"
              gap=".375rem"
              paddingLeft={0.5}
              sx={theme => ({
                [theme.breakpoints.down('md')]: { gap: '.1563rem' },
              })}
            >
              <Box
                alt="menu"
                component="img"
                height={18}
                src="/icons/ic_profile.svg"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: { height: 14, width: 14 },
                })}
                width={18}
              />
              <Typography
                color="#1A48A9"
                fontSize=".75rem"
                fontWeight="500"
                sx={theme => ({
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
                variant="subtitle1"
              >
                {t('common.future_aspiration')}
              </Typography>
            </Stack>
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={theme => ({
                backgroundColor: '#EBF2FE',
                borderRadius: '.5rem',
                flex: 1,
                height: '1.5rem',
                [theme.breakpoints.down('md')]: { height: '1.25rem' },
              })}
            >
              <Typography
                fontSize=".75rem"
                fontWeight="500"
                noWrap
                sx={theme => ({
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.625rem',
                  },
                })}
                variant="body1"
              >
                {recentStatusQuery.data?.dreamJobs[0]?.dream_jobs.split('|')[0]}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            flex={1}
            gap=".25rem"
            sx={{
              backgroundColor: 'white',
              borderBottomLeftRadius: '.25rem',
              borderBottomRightRadius: '.25rem',
              padding: '.25rem',
            }}
          >
            <Stack
              alignItems="center"
              aria-label="오늘의 피지컬 상태는 매우 나쁨입니다. 이틀 연속으로 매우 나쁨 상태입니다."
              direction="row"
              justifyContent="space-between"
              sx={{
                backgroundColor: '#EBF2FE',
                borderRadius: '.25rem',
                flex: 1,
                textAlign: 'center',
              }}
              tabIndex={0}
            >
              <Stack flex={1}>
                <Typography
                  color="#1A48A9"
                  fontSize=".75rem"
                  fontWeight="500"
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: { fontSize: '.5rem' },
                  })}
                >
                  {t('student_dashboard.today')}
                </Typography>
                <Typography
                  color="#1A48A9"
                  fontSize=".75rem"
                  fontWeight="500"
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: { fontSize: '.5rem' },
                  })}
                >
                  {t('student_dashboard.physical')}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                direction="column"
                flex={1}
                justifyContent="space-around"
              >
                <Box
                  // alt={value?.type}
                  component="img"
                  height={36}
                  src={`/${
                    MENTAL_ICONS.find(
                      item =>
                        item.type === recentStatusQuery.data?.physicalStates[0],
                    )?.selectSrc
                  }`}
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: {
                      height: 30,
                      width: 30,
                    },
                  })}
                  width={36}
                />

                <Stack direction="row" gap={0.5}>
                  <Box
                    borderRadius="50%"
                    height={5}
                    sx={{
                      backgroundColor:
                        findConsecutiveCount(
                          recentStatusQuery.data?.physicalStates || [],
                        ) >= 2
                          ? '#FF0000'
                          : '#00A3FF',
                    }}
                    width={5}
                  />
                  <Box
                    borderRadius="50%"
                    height={5}
                    sx={{
                      backgroundColor:
                        findConsecutiveCount(
                          recentStatusQuery.data?.physicalStates || [],
                        ) === 3
                          ? '#FF0000'
                          : '#00A3FF',
                    }}
                    width={5}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack
              alignItems="center"
              aria-label="오늘의 멘탈 상태는 매우 나쁨입니다. 이틀 연속으로 매우 나쁨 상태입니다."
              direction="row"
              justifyContent="space-between"
              sx={{
                backgroundColor: '#EBF2FE',
                borderRadius: '7px',
                flex: 1,
                textAlign: 'center',
              }}
              tabIndex={0}
            >
              <Stack flex={1}>
                <Typography
                  color="#1A48A9"
                  fontSize=".75rem"
                  fontWeight="500"
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: { fontSize: '.5rem' },
                  })}
                >
                  {t('student_dashboard.today')}
                </Typography>
                <Typography
                  color="#1A48A9"
                  fontSize=".75rem"
                  fontWeight="500"
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: { fontSize: '.5rem' },
                  })}
                >
                  {t('student_dashboard.mental')}
                </Typography>
              </Stack>
              {recentStatusQuery.data?.physicalStates[0] && (
                <Stack
                  alignItems="center"
                  direction="column"
                  flex={1}
                  justifyContent="space-around"
                >
                  <Box
                    // alt={value?.type}
                    component="img"
                    height={36}
                    src={`/${
                      MENTAL_ICONS.find(
                        item =>
                          item.type === recentStatusQuery.data?.mentalStates[0],
                      )?.selectSrc
                    }`}
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: {
                        height: 30,
                        width: 30,
                      },
                    })}
                    width={36}
                  />

                  <Stack direction="row" gap={0.5}>
                    <Box
                      borderRadius="50%"
                      height={5}
                      sx={{
                        backgroundColor:
                          findConsecutiveCount(
                            recentStatusQuery.data?.mentalStates || [],
                          ) >= 2
                            ? '#FF0000'
                            : '#00A3FF',
                      }}
                      width={5}
                    />
                    <Box
                      borderRadius="50%"
                      height={5}
                      sx={{
                        backgroundColor:
                          findConsecutiveCount(
                            recentStatusQuery.data?.mentalStates || [],
                          ) === 3
                            ? '#FF0000'
                            : '#00A3FF',
                      }}
                      width={5}
                    />
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          aria-label={`한줄평: ${recentStatusQuery.data?.statusMessage}`}
          direction="row"
          gap=".375rem"
          height="2rem"
          paddingX={1}
          sx={theme => ({
            backgroundColor: '#EBF2FE',
            borderRadius: '.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            color: '#4F73C0',
            fontWeight: 500,
            [theme.breakpoints.down('md')]: { height: '1.5rem' },
          })}
          tabIndex={0}
        >
          <Box
            alt="말풍선 아이콘"
            component="img"
            height={18}
            src={'/icons/ic_message.svg'}
            sx={theme => ({
              [theme.breakpoints.down('md')]: { height: 14, width: 14 },
            })}
            width={18}
          />

          <Typography
            color="#1A48A9"
            fontSize=".75rem"
            fontWeight="500"
            noWrap
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                fontSize: '.625rem',
              },
            })}
            variant="body1"
          >
            {recentStatusQuery.data?.statusMessage}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

const StyledIconSelect = styled(Select)({
  '& .MuiSelect-icon': {
    display: 'none',
  },
  '& .MuiSelect-select': {
    minWidth: '24px',
    padding: '0 !important',
  },
  '& fieldset': {
    border: '1px solid #EBF2FE',
  },
});
