import { Box, Button, Modal, Typography } from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ScopeType } from 'schemas/common';
import { VIDEO_BUCKETS } from 'schemas/file';
import { PostSharedSolutionVideoReqDto } from 'schemas/study';
import { getPreSignedPostInfo, uploadVideo } from 'services/fileService';
import { usePostQuestionAnswerMutation } from 'services/replyService';
import {
  usePostSharedSolutionVideoMutation,
  usePostSharedVideoMutation,
} from 'services/studyService';
import { closeModal, setCompleteAnswer } from 'store/slices/whiteboardSlice';
import {
  AnswerInfo,
  ConceptInfo,
  DEFAULT_PICKER_COLOR,
  ImageObj,
  Line,
  ProblemData,
  ProblemInfo,
  SubmitState,
  TOAST_MESSAGE,
  TOOL_LIST,
  ToolsType,
  WhiteboardCanvasState,
} from 'types/WhiteboardState';

import { RootState } from '../../../types';
import SuccessModal from '../Common/SuccessModal';
import AudioVideoRecorder, {
  AudioVideoRecorderRef,
  RecordingResultData,
} from './AudioVideoRecorder';
import SubmitComponent, { SubmitFormData } from './SubmitComponent';
import UploadLoading from './UploadLoading';
import VideoPreviewOverlay from './VideoPreviewOverlay';
import styles from './Whiteboard.module.css';
import WhiteboardCanvas, {
  PENCIL_STYLE,
  WhiteboardCanvasRef,
} from './WhiteboardCanvas';
import WhiteboardToolbar from './WhiteboardToolbar';

export const calculateCanvasHeight = (width: number) => {
  return (width * 9) / 16;
};

const TITLE_HEIGHT = 50;
export const SUBTITLE_HEIGHT = 26;
const TOAST_INTERVAL = 2000;

const WhiteboardModal = () => {
  const { t } = useTranslation();

  const [tool, setTool] = useState<ToolsType>(TOOL_LIST.PENCIL);
  const [color, setColor] = useState<string>(DEFAULT_PICKER_COLOR);
  const [lineWidth, setLineWidth] = useState<number>(
    PENCIL_STYLE.defaultLineWidth,
  );
  const [undoStack, setUndoStack] = useState<WhiteboardCanvasState[]>([]);
  const [redoStack, setRedoStack] = useState<WhiteboardCanvasState[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const [images, setImages] = useState<ImageObj[]>([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [endRecording, setEndRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [previewVideoUrl, setPreviewVideoUrl] = useState('');
  const [previewMainThumbUrl, setPreviewMainThumbUrl] = useState('');
  const [previewGridThumbUrl, setPreviewGridThumbUrl] = useState('');
  const [submitState, setSubmitState] = useState<SubmitState>('ready');
  const captureRef = useRef<HTMLDivElement>(null);
  const whiteboardCanvasRef = useRef<WhiteboardCanvasRef>(null);
  const [modalSize, setModalSize] = useState({ height: 0, width: 0 });
  const [boxSize, setBoxSize] = useState({ height: 0, width: 0 });
  const [boardInfo, setBoardInfo] = useState<
    AnswerInfo | ConceptInfo | ProblemInfo | null
  >(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const submitComponentRef = useRef<HTMLDivElement>(null);
  const modalBoxRef = useRef<HTMLDivElement>(null);

  const audioVideoRecorderRef = useRef<AudioVideoRecorderRef>(null);

  // toastmessage 관련
  const [toastMessage, setToastMessage] = useState<string>('');
  const [isVisibleToast, setIsVisibleToast] = useState<boolean>(false);
  const toastTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const autoStartTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [postQuestionAnswer] = usePostQuestionAnswerMutation();
  const [postSharedSolutionVideo] = usePostSharedSolutionVideoMutation();
  const [postSharedVideo] = usePostSharedVideoMutation();

  const userId = useSelector((state: RootState) => state.user.user_id);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const userType = useSelector((state: RootState) => state.user.user_type);

  const handleUndo = () => {
    if (!isRecording || isPaused) return;
    whiteboardCanvasRef.current?.canvasUndo();
  };

  const handleRedo = () => {
    if (!isRecording || isPaused) return;
    whiteboardCanvasRef.current?.canvasRedo();
  };

  const handleClear = () => {
    if (!isRecording || isPaused) return;
    whiteboardCanvasRef.current?.canvasClear();
  };

  const setupCanvasSize = () => {
    whiteboardCanvasRef.current?.setupCanvasSize();
  };

  const canvasRedraw = () => {
    whiteboardCanvasRef.current?.canvasRedraw();
  };

  const handleAddImage = (
    img: HTMLImageElement,
    width: number,
    height: number,
  ) => {
    // 나중에 이미지를 수동으로 등록할 때 move mode 활성화
    // setTool(TOOL_LIST.MOVE);
    whiteboardCanvasRef.current?.handleAddImage(img, width, height);
  };

  const isOpen = useSelector((state: RootState) => state.whiteboard.isOpen);
  const problemInfo = useSelector(
    (state: RootState) => state.whiteboard.problemInfo,
  );
  const answerInfo = useSelector(
    (state: RootState) => state.whiteboard.answerInfo,
  );
  const conceptInfo = useSelector(
    (state: RootState) => state.whiteboard.conceptInfo,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    calculateModalSize();
    window.addEventListener('resize', calculateModalSize);
    return () => {
      window.removeEventListener('resize', calculateModalSize);
    };
  }, []);

  useEffect(() => {
    setBoardInfo(problemInfo);
  }, [problemInfo]);

  useEffect(() => {
    setBoardInfo(answerInfo);
  }, [answerInfo]);

  useEffect(() => {
    setBoardInfo(conceptInfo);
  }, [conceptInfo]);

  useEffect(() => {
    createProblemImage();
  }, [boardInfo]);

  useEffect(() => {
    if (endRecording && submitComponentRef.current) {
      submitComponentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [endRecording]);

  const createProblemImage = async () => {
    if (boardInfo) {
      let widthRatio = 0.8;
      if (boardInfo.boardType === 'concept') {
        widthRatio = 1;
      }
      const imgMaxWidth = modalSize.width * widthRatio;
      if (boardInfo.problemImage) {
        const imageData = await getProblemImage(
          boardInfo.problemImage,
          imgMaxWidth,
        );
        handleAddImage(imageData, imageData.width, imageData.height);
      }
      // else if (boardInfo.problemData.length !== 0) {
      //   const imageData = await createCanvasImage(
      //     boardInfo.problemData,
      //     imgMaxWidth,
      //   );
      //   handleAddImage(imageData, imageData.width, imageData.height);
      // }
    }
  };

  const drawText = (
    ctx: CanvasRenderingContext2D,
    text: string,
    x: number,
    y: number,
    maxWidth: number,
    lineHeight: number,
  ) => {
    const words = text.split(' ');
    let line = '';
    let currentLine = '';
    let lineWidth = 0;
    for (let n = 0; n < words.length; n++) {
      currentLine = line + words[n] + ' ';
      lineWidth = ctx.measureText(currentLine).width;
      if (lineWidth > maxWidth && n > 0) {
        ctx.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      } else {
        line = currentLine;
      }
    }
    ctx.fillText(line, x, y);
    return y + lineHeight;
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

  const createCanvasImage = async (
    inputData: ProblemData[],
    imgMaxWidth: number,
  ): Promise<HTMLImageElement> => {
    const padding = 16;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return Promise.reject('Canvas context not available');

    const fontSize = 16;
    const lineHeight = fontSize * 1.6;
    let currentY = padding;
    canvas.width = imgMaxWidth;

    ctx.font = `${fontSize}px 'Noto Sans KR'`;
    ctx.fillStyle = 'black';

    for (const item of inputData) {
      if (item.type === 'text') {
        currentY = drawText(
          ctx,
          item.data,
          padding,
          currentY + padding,
          imgMaxWidth - 2 * padding,
          lineHeight,
        );
      } else if (item.type === 'image') {
        const img = await loadImage(item.data);
        if (imgMaxWidth > img.width) {
          canvas.width = img.width + padding * 2;
          canvas.height = img.height + padding * 2;
          ctx.drawImage(img, padding, padding);
          currentY += img.height + lineHeight;
        } else {
          const aspectRatio = img.width / img.height;
          const imgWidth = imgMaxWidth - 2 * padding;
          const imgHeight = imgWidth / aspectRatio;
          ctx.drawImage(img, padding, currentY, imgWidth, imgHeight);
          currentY += imgHeight + lineHeight;
        }
      }
    }

    const finalCanvasHeight = currentY + padding;
    canvas.width = imgMaxWidth;
    canvas.height = finalCanvasHeight;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = `${fontSize}px 'Noto Sans KR'`;
    ctx.fillStyle = 'black';

    currentY = padding;
    for (const item of inputData) {
      if (item.type === 'text') {
        currentY = drawText(
          ctx,
          item.data,
          padding,
          currentY + padding,
          imgMaxWidth - 2 * padding,
          lineHeight,
        );
      } else if (item.type === 'image') {
        const img = await loadImage(item.data);
        if (imgMaxWidth > img.width) {
          canvas.width = img.width + padding * 2;
          canvas.height = img.height + padding * 2;
          ctx.drawImage(img, padding, padding);
          currentY += img.height + lineHeight;
        } else {
          const aspectRatio = img.width / img.height;
          const imgWidth = imgMaxWidth - 2 * padding;
          const imgHeight = imgWidth / aspectRatio;
          ctx.drawImage(img, padding, currentY, imgWidth, imgHeight);
          currentY += imgHeight + lineHeight;
        }
      }
    }

    return new Promise<HTMLImageElement>(resolve => {
      const img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = () => resolve(img);
    });
  };

  const getProblemImage = async (imgUrl: string, imgMaxWidth: number) => {
    const padding = 16;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return Promise.reject('Canvas context not available');

    const img = await loadImage(imgUrl);

    // 원본 이미지 그대로 캔버스에 그림
    if (imgMaxWidth > img.width) {
      canvas.width = img.width + padding * 2;
      canvas.height = img.height + padding * 2;
      ctx.drawImage(img, padding, padding);
    } else {
      const aspectRatio = img.width / img.height;
      const imgWidth = imgMaxWidth - 2 * padding;
      const imgHeight = imgWidth / aspectRatio;
      canvas.width = imgWidth + padding * 2;
      canvas.height = imgHeight + padding * 2;
      ctx.drawImage(img, padding, padding, imgWidth, imgHeight);
    }

    return new Promise<HTMLImageElement>(resolve => {
      const img = new Image();
      img.src = canvas.toDataURL('image/png');
      img.onload = () => resolve(img);
    });
  };

  const handleModalClose = () => {
    handleRestartRecording();
    dispatch(closeModal());
  };

  const useDebounce = (callback: () => void, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const debouncedCallback = useCallback(() => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback();
      }, delay);
    }, [callback, delay]);

    useEffect(() => {
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    return debouncedCallback;
  };

  const onRecordingComplete = (result: RecordingResultData) => {
    setEndRecording(true);
    setIsRecording(false);
    setIsPaused(false);
    setVideoBlob(result.videoBlob);
    setPreviewVideoUrl(result.videoUrl);
    setPreviewMainThumbUrl(result.mainThumbnailUrl);
    setPreviewGridThumbUrl(result.gridThumbnailUrl);
  };

  const onRecordingStart = () => {
    setIsRecording(true);
    setEndRecording(false);
    setIsPaused(false);
  };

  const updatePauseState = (paused: boolean) => {
    setIsPaused(paused);
  };

  const handleResize = useDebounce(async () => {
    await setupCanvasSize();
    setTimeout(() => {
      canvasRedraw();
    });
  }, 100);

  useEffect(() => {
    setupCanvasSize();
  }, []);

  useEffect(() => {
    if (isOpen) {
      requestAnimationFrame(setupCanvasSize);
      autoStartTimeoutRef.current = setTimeout(() => {
        if (audioVideoRecorderRef.current) {
          audioVideoRecorderRef.current.startCapture();
        }
      }, 1000);
    } else {
      initWhiteboard();
      setImages([]);
      setBoardInfo(null);
    }
    return () => {
      if (autoStartTimeoutRef.current) {
        clearTimeout(autoStartTimeoutRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (captureRef.current) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [boxSize]);

  const initWhiteboard = () => {
    setTool(TOOL_LIST.PENCIL);
    setColor(DEFAULT_PICKER_COLOR);
    setLineWidth(PENCIL_STYLE.defaultLineWidth);
    setUndoStack([]);
    setRedoStack([]);
    setLines([]);
    setEndRecording(false);
    setVideoBlob(null);
    setPreviewVideoUrl('');
    setPreviewMainThumbUrl('');
    setPreviewGridThumbUrl('');
    setSubmitState('ready');
    setIsRecording(false);
    setIsPaused(false);
    setEndRecording(false);
    setToastMessage('');
  };

  const calculateModalSize = useDebounce(() => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;
    const aspectRatio = 16 / 9;
    // tablet에서 모달 크기를 확보하기 위해 패딩을 최대한 줄임(임시. 보완필요)
    const padding = screenHeight > 800 ? 40 : 4;

    const headerHeight = TITLE_HEIGHT + SUBTITLE_HEIGHT;

    let contentWidth = screenWidth - padding * 2;
    let contentHeight = screenHeight - headerHeight - padding * 2;

    if (screenWidth < screenHeight) {
      if (contentHeight * aspectRatio < contentWidth) {
        contentWidth = contentHeight * aspectRatio;
      }
    } else {
      if (contentWidth / aspectRatio > contentHeight) {
        contentWidth = contentHeight * aspectRatio;
      } else {
        contentHeight = contentWidth / aspectRatio;
      }
    }

    const modalWidth = contentWidth;
    const modalHeight = contentHeight + headerHeight;

    setModalSize({ height: modalHeight, width: modalWidth });
    setBoxSize({ height: contentHeight, width: contentWidth });

    handleResize();
  }, 100);

  const handleRestartRecording = () => {
    setEndRecording(false);
    initWhiteboard();
    setImages(prevImages => {
      if (prevImages.length > 0) {
        return [prevImages[0]];
      } else {
        return [];
      }
    });
    audioVideoRecorderRef.current?.handleRestartRecording();
  };

  const isProblemInfo = (
    boardInfo: AnswerInfo | ConceptInfo | ProblemInfo,
  ): boardInfo is ProblemInfo => {
    return boardInfo.boardType === 'solving';
  };

  const isAnswerInfo = (
    boardInfo: AnswerInfo | ConceptInfo | ProblemInfo,
  ): boardInfo is AnswerInfo => {
    return boardInfo.boardType === 'answer';
  };

  const isConceptInfo = (
    boardInfo: AnswerInfo | ConceptInfo | ProblemInfo,
  ): boardInfo is ConceptInfo => {
    return boardInfo.boardType === 'concept';
  };

  const onSubmit = async (data: SubmitFormData) => {
    if (boardInfo === null) return;

    try {
      setSubmitState('processing');
      // 요청 시 submit form을 가리기 위해 스크롤을 최상단으로 올림
      // 요청 실패가 일어나면 스크롤을 다시 내려야 하는데 스크롤이 올라갔다 내려가는게 보기 좋지 않음
      // if (modalBoxRef.current) {
      //   modalBoxRef.current.scrollTop = 0;
      // }

      let bucketName = '';
      if (isProblemInfo(boardInfo)) {
        bucketName = VIDEO_BUCKETS.CONCEPT_SOLUTION;
      } else if (isAnswerInfo(boardInfo)) {
        bucketName = VIDEO_BUCKETS.ANSWER;
      } else if (isConceptInfo(boardInfo)) {
        bucketName = VIDEO_BUCKETS.SHARED_CONCEPT;
      }
      const presignedPostInfo = await getPreSignedPostInfo({
        bucketName,
        curriculumId: boardInfo.curriculumId,
        fileName: new Date().getTime().toString() + '.webm',
        userId,
        ...(isProblemInfo(boardInfo) || isAnswerInfo(boardInfo)
          ? { problemId: boardInfo.problemId.toString() }
          : {}),
      });

      const uploadedFilePath = await uploadVideo({
        bucketName,
        file: videoBlob as Blob,
        fileName: new Date().getTime().toString() + '.webm',
        presignedPostInfo,
        role: userType,
      });

      if (!uploadedFilePath) {
        throw new Error('Failed to upload file');
      }

      // 문제풀이 일 때 /shared-solution-video
      // 답변하기 일 때 /question/answer
      // 개념학습 일 때 /shared-video
      if (isProblemInfo(boardInfo)) {
        const submitData = {
          body: {
            classInfo,
            problemId: boardInfo.problemId,
            role: userType,
            scope:
              data.shareType === 'all'
                ? ('ALL' as ScopeType)
                : data.shareType === 'class'
                ? ('CLASS' as ScopeType)
                : ('ME' as ScopeType),
            userId,
            videoPath: uploadedFilePath,
          } as PostSharedSolutionVideoReqDto,
        };

        await postSharedSolutionVideo(submitData).unwrap();
      } else if (isAnswerInfo(boardInfo)) {
        const submitData = {
          body: {
            classInfo,
            questionId: boardInfo.questionId,
            role: userType,
            scope:
              data.shareType === 'all'
                ? ('ALL' as ScopeType)
                : data.shareType === 'class'
                ? ('CLASS' as ScopeType)
                : ('ME' as ScopeType),
            userId,
            videoPath: uploadedFilePath,
          },
        };
        await postQuestionAnswer(submitData).unwrap();
      } else if (isConceptInfo(boardInfo)) {
        const submitData = {
          classInfo,
          learningSysId: boardInfo.learningSysId,
          role: userType,
          scope:
            data.shareType === 'all'
              ? ('ALL' as ScopeType)
              : data.shareType === 'class'
              ? ('CLASS' as ScopeType)
              : ('ME' as ScopeType),
          userId,
          videoPath: uploadedFilePath,
        };

        await postSharedVideo(submitData).unwrap();
      } else {
        throw new Error('invalid boardInfo');
      }

      setSubmitState('done');
      dispatch(setCompleteAnswer());
    } catch (error) {
      setSubmitState('ready');
      showToastMessage(TOAST_MESSAGE.SUBMISSION_FAILED);
      // 요청 실패 시 submit form으로 스크롤 내림
      // if (submitComponentRef.current) {
      //   submitComponentRef.current.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  };

  const showToastMessage = (message: string) => {
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }
    setToastMessage(message);
    setIsVisibleToast(true);
    toastTimeoutRef.current = setTimeout(() => {
      setIsVisibleToast(false);
      setTimeout(() => setToastMessage(''), 500);
    }, TOAST_INTERVAL);
  };

  if (submitState === 'done') {
    return (
      <SuccessModal
        onClose={() => {
          dispatch(closeModal());
        }}
        text={t('white_board.upload_completed')}
      />
    );
  }

  return (
    <Modal open={isOpen}>
      <>
        <Box
          ref={modalBoxRef}
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            height: modalSize.height,
            left: '50%',
            msOverflowStyle: 'none' /* IE and Edge */,
            overflow: submitState !== 'processing' ? 'scroll' : 'hidden',
            position: 'absolute',
            scrollbarWidth: 'none' /* Firefox */,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: modalSize.width,
          }}
        >
          {submitState === 'processing' && <UploadLoading />}
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            sx={{
              backgroundColor: 'background.paper',
              borderBottom: '1px solid #DCDCDC',
              height: `${TITLE_HEIGHT}px`,
              position: 'sticky',
              top: 0,
              zIndex: 3,
            }}
          >
            <Button tabIndex={-1}></Button>
            <Typography
              sx={{ fontSize: '18px', fontWeight: 'bold' }}
              tabIndex={0}
              variant="h4"
            >
              {boardInfo?.title}
            </Typography>
            <Button
              aria-label={t('white_board.close_whiteboard')}
              onClick={handleModalClose}
              tabIndex={0}
            >
              <img
                alt="close"
                height={24}
                src="/icons/modal_close.svg"
                width={24}
              />
            </Button>
          </Box>
          <div
            ref={captureRef}
            style={{
              backgroundColor: 'white',
              flex: 1,
              position: 'relative',
            }}
          >
            {endRecording && (
              <VideoPreviewOverlay
                onRestartRecording={handleRestartRecording}
                previewVideoUrl={previewVideoUrl}
                thumbnailUrl={previewMainThumbUrl}
              />
            )}
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              style={{
                backgroundColor: '#F8F8F8',
                height: `${SUBTITLE_HEIGHT}px`,
                padding: '12px',
              }}
              tabIndex={0}
            >
              <Typography
                sx={{
                  color: '#1A48A9',
                  fontSize: '14px',
                }}
                variant="h5"
              >
                {boardInfo?.subtitle}
              </Typography>
            </Box>
            <div
              style={{
                position: 'absolute',
                visibility: endRecording ? 'hidden' : 'visible',
                zIndex: 1,
              }}
            >
              <WhiteboardToolbar
                addImage={handleAddImage}
                clear={handleClear}
                color={color}
                redo={handleRedo}
                redoDisabled={redoStack.length === 0}
                setColor={setColor}
                setTool={setTool}
                tool={tool}
                undo={handleUndo}
                undoDisabled={undoStack.length <= 1}
              />
            </div>
            <div
              style={{
                visibility: endRecording ? 'hidden' : 'visible',
              }}
            >
              <WhiteboardCanvas
                boxSize={boxSize}
                canvasRef={canvasRef}
                color={color}
                images={images}
                isPaused={isPaused}
                isRecording={isRecording}
                lineWidth={lineWidth}
                lines={lines}
                redoStack={redoStack}
                ref={whiteboardCanvasRef}
                setImages={setImages}
                setLines={setLines}
                setRedoStack={setRedoStack}
                setUndoStack={setUndoStack}
                tool={tool}
                undoStack={undoStack}
              />
            </div>
          </div>
          <div
            style={{
              bottom: '40px',
              position: 'absolute',
              right: '10px',
              visibility: endRecording ? 'hidden' : 'visible',
            }}
            tabIndex={0}
          >
            <AudioVideoRecorder
              canvasRef={canvasRef}
              isAssessment={!!boardInfo?.isAssessment}
              onRecordingComplete={onRecordingComplete}
              onRecordingStart={onRecordingStart}
              ref={audioVideoRecorderRef}
              showToastMessage={showToastMessage}
              updatePauseState={updatePauseState}
            />
          </div>
          {endRecording && (
            <Box
              ref={submitComponentRef}
              sx={{ backgroundColor: '#F5F5F5', width: '100%' }}
            >
              <SubmitComponent boardInfo={boardInfo} onSubmit={onSubmit} />
            </Box>
          )}
        </Box>
        {toastMessage && (
          <div
            className={`${styles.toast} ${
              !isVisibleToast && styles.toastHidden
            }`}
          >
            {toastMessage}
          </div>
        )}
      </>
    </Modal>
  );
};

export default WhiteboardModal;
