import { useMutation } from '@tanstack/react-query';
import { updateStatus } from 'apis/user';
import { AxiosError } from 'axios';
import { toast } from 'components/ui/use-toast';
import useDrawerStore from 'stores/use-drawer-store';
import { queryClient } from 'utils/tanstack-query';

interface ErrorResponse {
  message: string;
}

export function useUpdateStatus() {
  const toggleDrawer = useDrawerStore(state => state.toggleDrawer);

  return useMutation({
    mutationFn: updateStatus,
    onError: (error: AxiosError<ErrorResponse>) => {
      toast({
        description: error.response?.data?.message || error.message,
        title: error.message,
        variant: 'destructive',
      });
    },
    onSuccess: () => {
      toggleDrawer('profileSetting', false)();
      toast({
        description: '상태가 변경되었습니다.',
      });
      queryClient.invalidateQueries({
        queryKey: ['recent_status'],
      });
    },
  });
}
