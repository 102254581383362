import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import aidtInstance from 'utils/aditInstance';

const aidtBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError> =>
  async ({ data, headers, method, params, transformResponse, url }) => {
    try {
      const result = await aidtInstance({
        data,
        headers,
        method,
        params,
        transformResponse,
        url,
      });
      return { data: result.data };
    } catch (axiosError) {
      const error = axiosError as AxiosError;
      return {
        error,
      };
    }
  };

export const aidtService = createApi({
  baseQuery: aidtBaseQuery(),
  endpoints: () => ({}),
  reducerPath: 'adit',
});
