import dayjs from 'dayjs';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetLearningTimeHistoryQuery } from 'services/student-dashboard';
import { RootState } from 'types';

export default function LearningTimeHistoryTable() {
  // 유저 정보
  const user = useSelector((state: RootState) => state.user);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 학습참여 상세정보 조회
  const { data: learningTimeHistory } = useGetLearningTimeHistoryQuery(
    {
      headers: {
        role: user.user_type,
        uuid: user.user_id,
      },
      params: {
        curriculumId: subsectionId as string,
      },
    },
    { skip: !subsectionId },
  );

  function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedTime: string[] = [];
    if (hours > 0) {
      formattedTime.push(`${hours}시간`);
    }
    if (minutes > 0) {
      formattedTime.push(`${minutes}분`);
    }
    if (seconds > 0 || formattedTime.length === 0) {
      formattedTime.push(`${seconds}초`);
    }

    return formattedTime.join(' ');
  }

  // 테이블 데이터
  const headers = ['횟수', '로그인 시작', '로그아웃 시작', '학습시간'];
  const rowData = {
    lists: learningTimeHistory?.data.histories.map(history => {
      return {
        login: dayjs(history.loginTime).format('MM.DD.HH:mm'),
        logout: dayjs(history.logoutTime).format('MM.DD.HH:mm'),
        time: formatTime(history.learningTimeInSecond),
      };
    }),
    total: {
      login: '-',
      logout: '-',
      time: formatTime(
        learningTimeHistory?.data.histories.reduce(
          (acc, history) => acc + history.learningTimeInSecond,
          0,
        ),
      ),
    },
  };

  return (
    <div
      style={{
        border: '3px solid #BFC7E3',
        borderRadius: '5px',
        maxHeight: '300px',
        overflow: 'auto',
        position: 'relative',
        width: '100%',
      }}
    >
      <table
        style={{
          backgroundColor: '#BFC7E3',
          borderCollapse: 'collapse',
          height: 'auto',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <caption style={{ display: 'none' }}>학습참여 상세정보 데이터</caption>
        <tbody
          style={{
            borderRadius: '10px',
          }}
        >
          <tr
            style={{
              backgroundColor: '#D0E3FA',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              position: 'sticky',
              top: 0,
            }}
          >
            {headers.map((header, index) => (
              <th
                key={index}
                style={{
                  border: '1px solid #BFC7E3',
                  fontWeight: 700,
                  width: 'auto',
                }}
              >
                {header}
              </th>
            ))}
          </tr>

          {rowData.lists &&
            rowData.lists.map((list, index) => (
              <tr key={index}>
                <td
                  style={{
                    backgroundColor: '#E9EBF5',
                    border: '1px solid #BFC7E3',
                    color: '#333333', // 색상 대비 확인
                    fontSize: '0.9rem',
                    fontWeight: '500',
                  }}
                >
                  {index + 1}
                </td>
                <td
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                  }}
                >
                  {list.login}
                </td>
                <td
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                  }}
                >
                  {list.logout}
                </td>
                <td
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                  }}
                >
                  {list.time}
                </td>
              </tr>
            ))}

          <tr style={{ bottom: 0, position: 'sticky' }}>
            {/* 합계 */}
            <td
              style={{
                backgroundColor: '#E9EBF5',
                border: '1px solid #BFC7E3',
                color: '#333333', // 색상 대비 확인
                fontSize: '0.9rem',
                fontWeight: '500',
              }}
            >
              합계
            </td>
            {Object.keys(rowData.total).map((key, index) => (
              <td
                key={index}
                style={{
                  backgroundColor: '#E9EBF5',
                  border: '1px solid #BFC7E3',
                  color: '#333333', // 색상 대비 확인
                  fontSize: '0.9rem',
                  fontWeight: '500',
                }}
              >
                {rowData.total[key]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
