import Close from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import CustomDialog from 'app/components/Common/CustomDialog';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import ReminderDialog from 'app/components/Dashboard/ReminderDialog';
import { Layout } from 'app/components/Layout';
import { LucideReceiptText } from 'lucide-react';
import { useSendLearningReminder } from 'queries/notification/use-send-learning-reminder';
import { useTeacher } from 'queries/use-teacher';
import { useTeacherClassMembers } from 'queries/use-teacher-class-members';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { AnalysisTableRes } from 'schemas/study';
import {
  useGetTeacherAllQuery,
  useGetTeacherClassMembersQuery,
} from 'services/aidt-teacher';
import { useGetAnalysisTableQuery } from 'services/studyService';
import { RootState } from 'types';
import { DialogData } from 'types/DialogData';
import { findSubsection } from 'utils/studyUtils';

import { CurrentSubSection } from '../../../types/StudyState';

interface Data {
  alert: boolean;
  category: string;
  confidence1?: boolean;
  confidence2?: boolean;
  confidence3?: boolean;
  confidence4?: boolean;
  correct_answer_rate: number;
  difficulty1?: '상' | '중' | '하';
  difficulty2?: '상' | '중' | '하';
  difficulty3?: '상' | '중' | '하';
  difficulty4?: '상' | '중' | '하';
  grading1?: boolean;
  grading2?: boolean;
  grading3?: boolean;
  grading4?: boolean;
  progress_rate: number;
  uuid: string;
}

interface ColumnData {
  boolean?: boolean;
  dataKey: keyof Data;
  label: string;
  width: number;
}

export default function AnalysisResultPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { questionType, subsectionId, unitType } = useParams();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [maxWidth, setMaxWidth] = useState<
    'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
  >('md');
  const [dialogData, setDialogData] = useState<DialogData>({
    contents: '',
    title: '',
  });

  const [subTitle, setSubTitle] = useState('');
  const [subSectionData, setSubSectionData] =
    useState<CurrentSubSection | null>(null);
  const [schoolName, setSchoolName] = useState('');
  const [gradeAndClassStr, setGradeAndClassStr] = useState('');
  const [parsedAnalysisData, setParsedAnalysisData] = useState<Data[]>([]);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const userId = useSelector((state: RootState) => state.user.user_id);
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);
  const lectureCode = useSelector(
    (state: RootState) => state.user.lecture_code,
  );
  const access_token = useSelector(
    (state: RootState) => state.user.access_token,
  );
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );

  const teacherInfo = useSelector((state: RootState) => state.user.teacherInfo);

  const [uuids, setUuids] = useState<string[]>([]);

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 선생님 학급 구성원 조회
  const teacherClassMembersQuery = useTeacherClassMembers({
    data: {
      access_token: user.access_token,
      class_code: teacherQuery.data?.class_info?.[0].class_code,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  const { data: analysisTableRes } = useGetAnalysisTableQuery(
    {
      classInfo,
      learningSysId: subsectionId ? parseInt(subsectionId, 10) : 0,
      role: userType,
      type: questionType ? questionType?.toUpperCase() : '',
      userId,
      uuids: teacherClassMembersQuery.data
        ? teacherClassMembersQuery.data.map(member => member.user_id)
        : [],
    },
    {
      skip: !teacherClassMembersQuery.data,
    },
  );

  // 학습 독촉
  const sendLearningReminderMutation = useSendLearningReminder();

  useEffect(() => {
    if (
      !access_token.token ||
      !access_token.access_id ||
      !userId ||
      !lectureCode
    )
      return;
    // getMemberUuids();
  }, [access_token.token, access_token.access_id, userId, lectureCode]);

  useEffect(() => {
    if (
      !teacherInfo ||
      !teacherInfo.class_info ||
      !teacherInfo.class_info.length
    )
      return;
    setSchoolName(teacherInfo.school_name ?? '');
    setGradeAndClassStr(
      teacherInfo.class_info[0].user_grade +
        '-' +
        parseInt(teacherInfo.class_info[0].user_class.slice(0, 2), 10),
    );
  }, [teacherInfo]);

  const columns: ColumnData[] = [
    {
      dataKey: 'category',
      label: t('common.category'),
      width: 55,
    },
    {
      dataKey: 'grading1',
      label: t('analysis_result.grading'),
      width: 56,
    },
    {
      dataKey: 'confidence1',
      label: t('analysis_result.confidence'),
      width: 56,
    },
    {
      dataKey: 'difficulty1',
      label: t('analysis_result.difficulty'),
      width: 56,
    },
    {
      dataKey: 'grading2',
      label: t('analysis_result.grading'),
      width: 56,
    },
    {
      dataKey: 'confidence2',
      label: t('analysis_result.confidence'),
      width: 56,
    },
    {
      dataKey: 'difficulty2',
      label: t('analysis_result.difficulty'),
      width: 56,
    },
    {
      dataKey: 'grading3',
      label: t('analysis_result.grading'),
      width: 56,
    },
    {
      dataKey: 'confidence3',
      label: t('analysis_result.confidence'),
      width: 56,
    },
    {
      dataKey: 'difficulty3',
      label: t('analysis_result.difficulty'),
      width: 56,
    },
    {
      dataKey: 'grading4',
      label: t('analysis_result.grading'),
      width: 56,
    },
    {
      dataKey: 'confidence4',
      label: t('analysis_result.confidence'),
      width: 56,
    },
    {
      dataKey: 'difficulty4',
      label: t('analysis_result.difficulty'),
      width: 56,
    },

    {
      dataKey: 'correct_answer_rate',
      label: t('analysis_result.correct_answer_rate'),
      width: 56,
    },
    {
      dataKey: 'progress_rate',
      label: t('analysis_result.progress_rate'),
      width: 56,
    },
    {
      boolean: true,
      dataKey: 'alert',
      label: t('analysis_result.alert'),
      width: 100,
    },
  ];

  const VirtuosoTableComponents: TableComponents<Data> = {
    Scroller: React.forwardRef<HTMLDivElement>((props, ref) => (
      <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: props => (
      <Table
        {...props}
        sx={{
          '& .MuiTableCell-root': {
            borderLeft: '1px solid #C7B8F2',
          },
          borderCollapse: 'collapse',
          tableLayout: 'fixed',
        }}
      />
    ),
    TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableBody {...props} ref={ref} />
    )),
    TableHead: React.forwardRef<HTMLTableSectionElement>((props, ref) => (
      <TableHead {...props} ref={ref} />
    )),
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  };

  const getBackgroundColor = (dataKey: keyof Data) => {
    if (dataKey === 'progress_rate' || dataKey === 'correct_answer_rate') {
      return '#E6E2FC';
    }
    if (dataKey === 'alert') {
      return '#7D5CD3';
    }
    return '#F7F5FF';
  };

  const getTextColor = (dataKey: keyof Data) => {
    if (dataKey === 'progress_rate' || dataKey === 'correct_answer_rate') {
      return '#000';
    }
    if (dataKey === 'alert') {
      return '#FFF';
    }
    return '#1A48A9';
  };

  const getFontWeight = (dataKey: keyof Data) => {
    if (
      dataKey === 'progress_rate' ||
      dataKey === 'correct_answer_rate' ||
      dataKey === 'alert'
    ) {
      return 700;
    }
    return 400;
  };

  function fixedHeaderContent() {
    return (
      <>
        <TableRow
          sx={{
            '& .MuiTableCell-root': { p: 0 },
            backgroundColor: '#E6E2FC',

            height: '1.25rem',
          }}
        >
          <TableCell
            align="center"
            sx={{ border: '1px solid #E6E2FC #C7B8F2', fontSize: '.625rem' }}
            tabIndex={0}
          >
            {t('analysis_result.question')}
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ fontSize: '.75rem', fontWeight: 600 }}
            tabIndex={0}
          >
            {t('analysis_result.num_question', {
              num: 1,
            })}
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ fontSize: '.75rem', fontWeight: 600 }}
            tabIndex={0}
          >
            {t('analysis_result.num_question', {
              num: 2,
            })}
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ fontSize: '.75rem', fontWeight: 600 }}
            tabIndex={0}
          >
            {t('analysis_result.num_question', {
              num: 3,
            })}
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ fontSize: '.75rem', fontWeight: 600 }}
            tabIndex={0}
          >
            {t('analysis_result.num_question', {
              num: 4,
            })}
          </TableCell>
          <TableCell
            align="center"
            colSpan={3}
            sx={{ fontSize: '.75rem', fontWeight: 600 }}
            tabIndex={0}
          >
            {t('analysis_result.result_analysis')}
          </TableCell>
        </TableRow>
        <TableRow
          sx={{
            '& .MuiTableCell-root': { p: 0 },
            height: '1.25rem',
          }}
        >
          {columns.map(column => (
            <TableCell
              align="center"
              key={column.dataKey}
              style={{ width: column.width }}
              sx={{
                backgroundColor: getBackgroundColor(column.dataKey),
                color: getTextColor(column.dataKey),
                fontWeight: getFontWeight(column.dataKey),
              }}
              tabIndex={0}
              variant="head"
            >
              {column.label}
            </TableCell>
          ))}
        </TableRow>
      </>
    );
  }

  function rowContent(_index: number, row: Data) {
    return (
      <React.Fragment>
        {columns.map(column => (
          <TableCell
            align="center"
            aria-label={column.label + row[column.dataKey]}
            key={column.dataKey}
            sx={{
              backgroundColor: column.dataKey === 'alert' ? '#F7F5FF' : '',
              p: 0,
            }}
            tabIndex={0}
          >
            {column.dataKey === 'alert' ? (
              <Checkbox
                checked={row[column.dataKey]}
                onChange={() => {
                  setParsedAnalysisData(prev => {
                    const newState = [...prev];
                    newState[_index].alert = !newState[_index].alert;
                    return newState;
                  });
                }}
                sx={{
                  '&.Mui-checked': {
                    color: '#7D5CD3',
                  },
                }}
              />
            ) : (
              row[column.dataKey]
            )}
          </TableCell>
        ))}
      </React.Fragment>
    );
  }

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
    setMaxWidth('sm');
  }, []);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const findMemberName = (uuid: string) => {
    if (!teacherClassMembersQuery.data) return '';
    const member = teacherClassMembersQuery.data.find(m => m.user_id === uuid);
    return member ? member.user_name : '';
  };

  const questionTypeLabel =
    questionType === 'basic'
      ? t('common.basic_problems')
      : questionType === 'confirm'
      ? t('common.review_problems')
      : questionType === 'feedback'
      ? t('common.feedback_problems')
      : '';

  const convertDifficulty = (difficulty: string) => {
    switch (difficulty) {
      case 'LOW':
        return t('common.low');
      case 'MIDDLE':
        return t('common.medium');
      case 'HIGH':
        return t('common.high');
      default:
        return difficulty;
    }
  };
  useEffect(() => {
    if (subsectionId === undefined) return;
    const subSection = findSubsection(contentTables, subsectionId);
    if (subSection) {
      setSubSectionData(subSection);

      setSubTitle(
        `${subSection.unitName} - ${subSection.chapterName} - ${subSection.subSectionName}`,
      );
    }
  }, [contentTables, subsectionId]);

  useEffect(() => {
    if (!analysisTableRes || !analysisTableRes.data) return;
    const analysisData = analysisTableRes.data;
    const parsedData: Data[] = analysisData
      .filter(item => item.uuid)
      .map(item => {
        const transformedItem: Data = {
          alert: false,
          category: findMemberName(item.uuid),
          correct_answer_rate: item.correction_rate,
          progress_rate: item.progress_rate,
          uuid: item.uuid,
        };

        item.problems.forEach((problem, index) => {
          transformedItem[`grading${index + 1}`] =
            problem.is_correct === 1
              ? 'O'
              : problem.is_correct === 0
              ? 'X'
              : '';
          transformedItem[`confidence${index + 1}`] =
            problem.confidence === 1
              ? 'O'
              : problem.confidence === 0
              ? 'X'
              : '';
          transformedItem[`difficulty${index + 1}`] = convertDifficulty(
            problem.difficulty,
          );
        });

        return transformedItem;
      });
    setParsedAnalysisData(parsedData);
  }, [analysisTableRes]);

  React.useEffect(() => {
    if (userType === 'S') {
      window.location.href = '/evaluation';
    }
  }, []);

  return (
    <Layout
      customContainerStyle={{
        backgroundImage: `url(/images/background/bg3.svg)`,
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        gap: '.75rem',
        height: '100%',
        paddingBottom: '.75rem',
      }}
      isDisableGutters
      width={false}
    >
      <SecondaryHeader
        backgroundColor="#7D5CD3"
        handleRightButtonClicked={() => {
          navigate(`/chapter/${subsectionId}`);
        }}
        handleRightButtonKeyPressed={() => {
          navigate(`/chapter/${subsectionId}`);
        }}
        rightButtonLabel="소단원 목차로 돌아갑니다."
        rightButtonText="소단원 목차"
        title={`${schoolName} ${gradeAndClassStr} ${questionTypeLabel} 학습결과 분석`}
        titleColor="#FFFFFF"
        titleIcon={<LucideReceiptText />}
      />
      <Container
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '6px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            gap: '.625rem',
            overflow: 'auto',
            padding: '10px 12px',
          }}
        >
          <Stack
            alignItems="center"
            direction={'row'}
            justifyContent="space-between"
          >
            <Typography
              component={'h2'}
              sx={{
                color: '#4F73C0',
                fontSize: '.9375rem',
                fontWeight: 500,
              }}
              tabIndex={0}
              variant={'h6'}
            >
              {subTitle}
            </Typography>
            <Button
              disabled={
                parsedAnalysisData.filter(item => item.alert).length === 0
              }
              onClick={() => {
                sendLearningReminderMutation.mutate(
                  {
                    data: {
                      action:
                        questionType === 'basic'
                          ? 3
                          : questionType === 'confirm'
                          ? 4
                          : 5,
                      learning_sys_id: Number(subsectionId),
                      taker_uuids: parsedAnalysisData
                        .filter(item => item.alert)
                        .map(item => item.uuid),
                    },
                    headers: {
                      role: user.user_type,
                      uuid: user.user_id,
                    },
                  },
                  {
                    onSuccess: () => {
                      setParsedAnalysisData(prev => {
                        const newState = [...prev];
                        newState.forEach(item => {
                          item.alert = false;
                        });
                        return newState;
                      });
                    },
                  },
                );
              }}
              sx={{ backgroundColor: '#7D5CD3', fontWeight: 500 }}
              variant="contained"
            >
              {t('analysis_result.send')}
            </Button>
          </Stack>
          <Box
            sx={{
              border: '2px solid #C7B8F2',
              borderRadius: '4px',
              flexGrow: 1,
              overflow: 'auto',
            }}
          >
            <TableVirtuoso
              aria-label={
                '문제 학습에서 학생별 각 문제의 결과를 분석하는 테이블 입니다.'
              }
              components={VirtuosoTableComponents}
              data={parsedAnalysisData}
              fixedHeaderContent={fixedHeaderContent}
              itemContent={rowContent}
              tabIndex={0}
            />
          </Box>
        </Box>
      </Container>

      <CustomDialog
        customContentStyle={{
          padding: maxWidth === 'md' ? '0 !important' : 'initial',
        }}
        dialogData={dialogData}
        handleClose={handleDialogClose}
        maxWidth={maxWidth}
        open={dialogOpen}
      />

      <ReminderDialog />
    </Layout>
  );
}
