import { Box, Stack, Typography } from '@mui/material';
import { useStudent } from 'queries/use-student';
import { useTeacher } from 'queries/use-teacher';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { replaceSchoolName } from 'utils/replace-school-name';

export default function Profile() {
  const user = useSelector((state: RootState) => state.user);

  // 학생 정보 조회
  const studentQuery = useStudent({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // 프로필 사진
  const avatarImage = React.useMemo(
    () =>
      `/images/profile/${
        user.user_type === 'T'
          ? 'male'
          : studentQuery.data?.user_gender || 'male'
      }_${user.user_type === 'T' ? 'teacher' : 'student'}.svg`,
    [studentQuery.data?.user_gender],
  );

  return (
    <Stack gridColumn="span 1">
      <Stack flex={1}>
        <Stack alignItems="center" flex={1} justifyContent="center">
          <Stack
            aria-label="프로필 사진"
            borderRadius="50%"
            maxHeight={80}
            maxWidth={80}
            overflow="hidden"
            sx={theme => ({
              aspectRatio: '1 / 1',
              backgroundColor: '#D9D9D9',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              [theme.breakpoints.down('md')]: { maxHeight: 70, maxWidth: 70 },
            })}
            tabIndex={0}
          >
            <Box
              alt="프로필 사진"
              component="img"
              src={avatarImage}
              sx={{ height: '100%', width: '100%' }}
            />
          </Stack>
        </Stack>
        <Stack
          alignItems="center"
          aria-label={
            user.user_type === 'T'
              ? `선생님의 이름은 ${teacherQuery.data?.user_name}입니다.`
              : `학생의 이름은 ${studentQuery.data?.user_name}입니다.`
          }
          flexShrink={0}
          height={user.user_type === 'T' ? '3.375rem' : '2rem'}
          justifyContent="center"
          paddingX={1}
          sx={theme => ({
            backgroundColor: '#EBF2FE',
            borderRadius: '.5rem',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            [theme.breakpoints.down('md')]: { height: '1.5rem' },
          })}
          tabIndex={0}
        >
          {user.user_type === 'T' && (
            <Typography
              fontSize=".75rem"
              fontWeight="500"
              noWrap
              sx={theme => ({
                [theme.breakpoints.down('md')]: {
                  fontSize: '.625rem',
                },
              })}
              textAlign="center"
              variant="h6"
              width="100%"
            >
              {replaceSchoolName(teacherQuery.data?.school_name ?? '')}{' '}
              {teacherQuery.data?.class_info[0].user_grade}-
              {teacherQuery.data?.class_info[0].user_class
                .split(' ')[1]
                .replace(/[^0-9]/g, '')}
            </Typography>
          )}
          <Typography
            fontSize={user.user_type === 'T' ? '.75rem' : '.9375rem'}
            fontWeight="500"
            noWrap
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                fontSize: user.user_type === 'T' ? '.625rem' : '.75rem',
              },
            })}
            textAlign="center"
            variant="h6"
            width="100%"
          >
            {user.user_type === 'T'
              ? teacherQuery.data?.user_name
              : studentQuery.data?.user_name}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
