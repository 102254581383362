import { Box, Stack, Typography } from '@mui/material';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import LearningStageConfig from 'app/pages/Step/LearningStageConfig';
import LearningStageTable from 'app/pages/Step/LearningStageTable';
import { Button } from 'components/ui/button';
import { LucideFolderGit2 } from 'lucide-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';

export function StepPage() {
  const { t } = useTranslation();

  return (
    <Layout
      customContainerStyle={{
        display: 'flex',
        flexDirection: 'column',

        height: '100%',
        paddingBottom: '60px',
      }}
      isDisableGutters={true}
      width={false}
    >
      <main className="flex flex-col gap-2.5">
        <SecondaryHeader
          backgroundColor={'#9BE9BF'}
          title={`${t('step.title')}`}
          titleIcon={<LucideFolderGit2 />}
        />
        <div className="container">
          <Box component="main">
            <Stack
              alignItems="stretch"
              component="section"
              gap=".875rem"
              sx={{ height: '100%' }}
            >
              {/* 학습단계 및 학습단계에 따른 문항의 단원, 난이도, 문항수 */}
              <LearningStageTable />

              {/* 학습단계 설정기준 */}
              <LearningStageConfig />
            </Stack>
          </Box>
        </div>
      </main>
    </Layout>
  );
}
