/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const ChapterResultPage = lazyLoad(
  () => import('./index'),
  module => module.ChapterResultPage,
);
