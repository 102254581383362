import { Box, Stack, Typography } from '@mui/material';
import { useCumulativeAccuracyData } from 'queries/dashboard/use-cumulative-accuracy-data';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types';

export default function CumulativeAccuracyRateDataTable() {
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user);

  const headers = [
    'common.highest',
    'common.high',
    'common.medium',
    'common.low',
  ];

  // 난이도별 문제풀이 및 정답률 누적데이터 조회
  const cumulativeAccuracyDataQuery = useCumulativeAccuracyData({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      classUuids: [user.user_id],
    },
  });

  return (
    <Stack
      aria-label="난이도별 문제풀이 및 정답률 누적데이터를 나타내는 테이블입니다. 최상, 상, 중, 하의 총 4개의 난이도별로 문제풀이수와 정답률에 대한 개인과 반평균 데이터를 나타냅니다."
      borderRadius=".75rem"
      flex={1}
      height="100%"
      padding=".1875rem"
      sx={{
        backgroundColor: '#CEE2FA',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".375rem"
        height="2rem"
        paddingLeft={0.5}
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            height: '1.5rem',
          },
        })}
      >
        <Box
          alt="난이도별 문제풀이 및 정답률 누적 데이터 아이콘"
          component="img"
          height={18}
          src={'/icons/ic_graph.svg'}
          sx={theme => ({
            [theme.breakpoints.down('md')]: { height: 14, width: 14 },
          })}
          width={18}
        />
        <Typography
          fontSize=".9375rem"
          fontWeight="500"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.625rem',
            },
          })}
          variant={'subtitle1'}
        >
          {t('student_dashboard.cumulative_data_of_problem')}
        </Typography>
      </Stack>

      <Box
        border="2px solid #BFC7E3"
        borderRadius=".5rem"
        flexGrow={1}
        overflow="hidden"
      >
        <table
          style={{
            borderCollapse: 'collapse',
            height: '100%',
            tableLayout: 'fixed',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <caption style={{ display: 'none' }}>누적데이터</caption>
          <tbody>
            <tr
              style={{
                backgroundColor: '#CFD5EA',
              }}
            >
              <th style={{ border: '1px solid #BFC7E3' }}></th>
              {headers.map((header, index) => (
                <Box
                  colSpan={2}
                  component="th"
                  key={index}
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 600,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.125rem',
                    },
                  })}
                >
                  {t(header)}
                </Box>
              ))}
            </tr>
            <tr>
              <Box
                component="th"
                sx={theme => ({
                  backgroundColor: '#E9EBF5',
                  border: '1px solid #BFC7E3',
                  fontSize: '.625rem',
                  fontWeight: 600,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
              >
                {t('student_dashboard.compare')}
              </Box>
              {headers.map((header, index) => (
                <React.Fragment key={index}>
                  <Box
                    component="th"
                    scope="col"
                    sx={theme => ({
                      backgroundColor: '#E9EBF5',
                      border: '1px solid #BFC7E3',
                      fontSize: '.625rem',
                      fontWeight: 400,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.5rem',
                      },
                    })}
                  >
                    {t('common.me')}
                  </Box>
                  <Box
                    component="th"
                    scope="col"
                    sx={theme => ({
                      backgroundColor: '#E9EBF5',
                      border: '1px solid #BFC7E3',
                      fontSize: '10px',
                      fontWeight: 400,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.5rem',
                        height: '1.125rem',
                      },
                    })}
                  >
                    {t('student_dashboard.class_average')}
                  </Box>
                </React.Fragment>
              ))}
            </tr>
            <tr
              aria-label="난이도별 문제풀이수 누적데이터를 나타내는 행입니다."
              tabIndex={0}
            >
              <Box
                component="td"
                sx={theme => ({
                  backgroundColor: '#E9EBF5',
                  border: '1px solid #BFC7E3',
                  fontSize: '.625rem',
                  fontWeight: 600,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
              >
                {t('student_dashboard.problem_solved')}
              </Box>
              <Box
                aria-label={`나의 최상 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.highestMy[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highestMy[0]}
              </Box>
              <Box
                aria-label={`반평균 최상 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.highestMean[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highestMean[0]}
              </Box>

              <Box
                aria-label={`나의 상 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.highMy[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highMy[0]}
              </Box>
              <Box
                aria-label={`반평균 상 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.highMean[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highMean[0]}
              </Box>

              <Box
                aria-label={`나의 중 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.middleMy[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.middleMy[0]}
              </Box>
              <Box
                aria-label={`반평균 중 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.middleMean[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.middleMean[0]}
              </Box>

              <Box
                aria-label={`나의 하 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.lowMy[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.lowMy[0]}
              </Box>
              <Box
                aria-label={`반평균 하 난이도 문제풀이수는 ${cumulativeAccuracyDataQuery.data?.lowMean[0]}개입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.lowMean[0]}
              </Box>
            </tr>
            <tr
              aria-label="난이도별 정답률 누적데이터를 나타내는 행입니다."
              tabIndex={0}
            >
              <Box
                component="th"
                sx={theme => ({
                  backgroundColor: '#E9EBF5',
                  border: '1px solid #BFC7E3',
                  fontSize: '.625rem',
                  fontWeight: 600,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
              >
                {t('common.accuracy_rate')}
              </Box>

              <Box
                aria-label={`나의 최상 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.highestMy[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highestMy[1]}
              </Box>
              <Box
                aria-label={`반평균 최상 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.highestMean[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highestMean[1]}
              </Box>

              <Box
                aria-label={`나의 상 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.highMy[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highMy[1]}
              </Box>
              <Box
                aria-label={`반평균 상 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.highMean[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.highMean[1]}
              </Box>

              <Box
                aria-label={`나의 중 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.middleMy[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.middleMy[1]}
              </Box>
              <Box
                aria-label={`반평균 중 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.middleMean[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.middleMean[1]}
              </Box>

              <Box
                aria-label={`나의 하 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.lowMy[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.lowMy[1]}
              </Box>
              <Box
                aria-label={`반평균 하 난이도 정답률은 ${cumulativeAccuracyDataQuery.data?.lowMean[1]}%입니다.`}
                component="td"
                sx={theme => ({
                  backgroundColor: 'white',
                  border: '1px solid #BFC7E3',
                  fontSize: '.75rem',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    fontSize: '.5rem',
                    height: '1.125rem',
                  },
                })}
                tabIndex={0}
              >
                {cumulativeAccuracyDataQuery.data?.lowMean[1]}
              </Box>
            </tr>
          </tbody>
        </table>
      </Box>
    </Stack>
  );
}
