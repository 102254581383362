import { useQuery } from '@tanstack/react-query';
import { getLearningHistory } from 'apis/dashboard';
import {
  GetLearningHistoryDto,
  GetLearningHistoryResponse,
  LearningHistory,
} from 'schemas/student-dashboard';

export function useLearningHistory({ headers, params }: GetLearningHistoryDto) {
  return useQuery<GetLearningHistoryResponse, Error, LearningHistory>({
    enabled: !!params.classUuids && !!params.learningSysId,
    queryFn: () => getLearningHistory({ headers, params }),
    queryKey: ['learning_history', { headers, params }],
    select: data => data.data,
  });
}
