import MarkdownContent from 'app/components/Common/MarkdownContent';
import { Button } from 'components/ui/button';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'components/ui/carousel';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { ChevronRight, X } from 'lucide-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useProblemResultDialogStore from 'stores/use-problem-result-dialog-store';
import { getGradeLabel } from 'utils/get-grade-label';

export default function ProblemResultDialog() {
  const { t } = useTranslation();

  const { number, open, problem, problems, title, toggleDialog } =
    useProblemResultDialogStore(state => ({
      number: state.number,
      open: state.open,
      problem: state.problem,
      problems: state.problems,
      title: state.title,
      toggleDialog: state.toggleDialog,
    }));

  function onOpenChange(open: boolean) {
    toggleDialog(open)();
  }

  function onClick() {
    toggleDialog(false)();
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === 'Enter') {
      toggleDialog(false)();
    }
  }

  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className="p-0 overflow-hidden flex flex-col gap-0 border-none max-w-[50rem] max-h-min min-h-[500px]">
        <DialogHeader className="h-[3.125rem] relative flex items-center border-b flex-row border-[#CCCCCC] space-y-0 justify-center">
          <DialogTitle className="text-xl font-medium" tabIndex={0}>
            {title}
          </DialogTitle>
          <DialogDescription className="sr-only">{title}</DialogDescription>
          <Button
            className="absolute p-0 -translate-y-1/2 size-6 right-3 top-1/2"
            onClick={onClick}
            onKeyDown={onKeyDown}
            variant="ghost"
          >
            <X />
          </Button>
        </DialogHeader>
        {problem && (
          <>
            <div className="h-[1.625rem] bg-[#F8F8F8] gap-1.5 flex items-center px-4 text-xs font-medium text-[#1A48A9]">
              <span>
                {getGradeLabel(
                  Number(problem?.learning_sys?.full_name.split('||')[0]),
                )}
              </span>
              <ChevronRight className="size-3" />
              <span>{problem?.learning_sys?.full_name.split('||')[2]}</span>
              <ChevronRight className="size-3" />
              <span>{problem?.learning_sys?.full_name.split('||')[3]}</span>
              <ChevronRight className="size-3" />
              <span>{problem?.learning_sys?.full_name.split('||')[4]}</span>
              <ChevronRight className="size-3" />
              <span>{t(`common.${problem?.difficulty?.toLowerCase()}`)}</span>
            </div>
            <div className="flex flex-col flex-1 ">
              <div className="relative flex-1 py-5 px-7">
                <img
                  alt="marking"
                  className="absolute left-7 top-2"
                  src={`/icons/marking/mk_${
                    problem?.is_correct ? 'o' : 'wrong'
                  }.svg`}
                />
                <div className="flex items-start gap-2.5">
                  <span className="text-xl font-bold">{number}.</span>
                  <MarkdownContent
                    content={problem?.problem?.latex_data ?? ''}
                  />
                </div>
              </div>
              <div className="border-t border-[#CCCCCC] px-3 py-2 flex items-center gap-2">
                <div className="flex items-center px-2.5 w-min rounded-md border min-w-[7.5rem] justify-between border-[#FFCC32] gap-7 h-9 bg-[#FFFACA]">
                  <span className="text-xs text-nowrap text-[#464646] font-medium">
                    정답
                  </span>
                  <div className="h-[1.625rem] px-2 bg-[#FFCC32] rounded-full">
                    <span className="text-sm font-medium text-[#333]">
                      {problem?.problem?.answer_data}
                    </span>
                  </div>
                </div>
                <div className="flex items-center px-2.5 w-min rounded-md justify-between border min-w-[7.5rem] border-[#4782CA] gap-7 h-9 bg-[#EBF2FE]">
                  <span className="text-xs text-nowrap text-[#464646] font-medium">
                    학생
                  </span>
                  <div className="h-[1.625rem] px-2 bg-[#4782CA] rounded-full flex items-center justify-center">
                    <span className="flex items-center justify-center text-sm font-medium text-white">
                      {problem?.assessment_perform?.submission_answer === '' ? (
                        <X className="size-3" />
                      ) : (
                        problem?.assessment_perform?.submission_answer
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {problems && (
          <Carousel className="flex flex-col flex-1 [&>div]:flex-1 ">
            <CarouselContent className="min-h-[28.125rem]">
              {problems.map((p, index) => (
                <CarouselItem className="flex flex-col" key={index}>
                  <div className="h-[1.625rem] bg-[#F8F8F8] gap-1.5 flex items-center px-4 text-xs font-medium text-[#1A48A9]">
                    <span>
                      {getGradeLabel(
                        Number(p.learning_sys?.full_name.split('||')[0]),
                      )}
                    </span>
                    <ChevronRight className="size-3" />
                    <span>{p.learning_sys?.full_name.split('||')[2]}</span>
                    <ChevronRight className="size-3" />
                    <span>{p.learning_sys?.full_name.split('||')[3]}</span>
                    <ChevronRight className="size-3" />
                    <span>{p.learning_sys?.full_name.split('||')[4]}</span>
                    <ChevronRight className="size-3" />
                    <span>
                      {t(`common.${p.problem?.difficulty?.toLowerCase()}`)}
                    </span>
                  </div>
                  <div className="flex flex-col flex-1 flex-grow">
                    <div className="relative flex-1 py-5 px-7">
                      <img
                        alt="marking"
                        className="absolute left-7 top-2"
                        src={`/icons/marking/mk_${
                          p.perform?.is_correct ? 'o' : 'wrong'
                        }.svg`}
                      />
                      <div className="flex items-start gap-2.5">
                        <span className="text-xl font-bold">{index + 1}.</span>
                        <MarkdownContent
                          content={p?.problem?.latex_data ?? ''}
                        />
                      </div>
                    </div>
                    <div className="border-t border-[#CCCCCC] px-3 py-2 flex items-center gap-2">
                      <div className="flex items-center px-2.5 w-min rounded-md border min-w-[7.5rem] justify-between border-[#FFCC32] gap-7 h-9 bg-[#FFFACA]">
                        <span className="text-xs text-nowrap text-[#464646] font-medium">
                          정답
                        </span>
                        <div className="h-[1.625rem] px-2 bg-[#FFCC32] rounded-full">
                          <span className="text-sm font-medium text-[#333]">
                            {p?.problem?.answer_data}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center px-2.5 w-min rounded-md justify-between border min-w-[7.5rem] border-[#4782CA] gap-7 h-9 bg-[#EBF2FE]">
                        <span className="text-xs text-nowrap text-[#464646] font-medium">
                          학생
                        </span>
                        <div className="h-[1.625rem] px-2 bg-[#4782CA] rounded-full flex items-center justify-center">
                          <span className="flex items-center justify-center text-sm font-medium text-white">
                            {p?.perform?.submission_answer === '' ? (
                              <X className="size-3" />
                            ) : (
                              p?.perform?.submission_answer
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="left-3" />
            <CarouselNext className="right-3" />
          </Carousel>
        )}
      </DialogContent>
    </Dialog>
  );
}
