import { useQuery } from '@tanstack/react-query';
import { getCumulativeData } from 'apis/dashboard';
import {
  CumulativeData,
  GetCumulativeDataDto,
  GetCumulativeDataResponse,
} from 'schemas/student-dashboard';

export function useCumulativeData({ headers, params }: GetCumulativeDataDto) {
  return useQuery<GetCumulativeDataResponse, Error, CumulativeData>({
    enabled: !!params.classUuids,
    queryFn: () => getCumulativeData({ headers, params }),
    queryKey: ['cumulative_data', { headers, params }],
    select: data => data.data,
  });
}
