import { useQuery } from '@tanstack/react-query';
import { getStudentWeakChapters } from 'apis/dashboard';
import {} from 'schemas/assignment';
import {
  GetStudentWeakChaptersDto,
  GetStudentWeakChaptersResponse,
  StudentWeakChapter,
} from 'schemas/student-dashboard';

export function useStudentWeakChapters({
  headers,
  params,
}: GetStudentWeakChaptersDto) {
  return useQuery<GetStudentWeakChaptersResponse, Error, StudentWeakChapter[]>({
    enabled: !!headers['Class-Info'],
    queryFn: () => getStudentWeakChapters({ headers, params }),
    queryKey: ['student_weak_chapters', { headers }],
    select: data => data.data,
  });
}
