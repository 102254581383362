import { useQuery } from '@tanstack/react-query';
import { getCumulativeAccuracyData } from 'apis/dashboard';
import {
  CumulativeAccuracyData,
  GetCumulativeAccuracyDataDto,
  GetCumulativeAccuracyDataResponse,
} from 'schemas/student-dashboard';

export function useCumulativeAccuracyData({
  headers,
  params,
}: GetCumulativeAccuracyDataDto) {
  return useQuery<
    GetCumulativeAccuracyDataResponse,
    Error,
    CumulativeAccuracyData
  >({
    enabled: params.classUuids.length > 0,
    queryFn: () => getCumulativeAccuracyData({ headers, params }),
    queryKey: ['cumulative_accuracy_data'],
    select: data => data.data,
  });
}
