const DRAWER_WIDTH = 240;
const LEFT_NAV_ITEMS = [
  { name: 'index', value: '/' }, // 목차
  { name: 'step', value: '/step' }, // 학습단계
  { name: 'assessment', value: '/assessment/diagnostic' }, // 학력진단평가
  { name: 'reply', value: '/reply' }, // 답변하기
];
const RIGHT_NAV_ITEMS = [
  { name: 'note_alt', value: '/note' }, // 희망노트
  { name: 'alert', value: '/alert' }, // 알람
  { name: 'announcement', value: '/notice' }, // 공지사항
  // { name: 'ai_search', value: '/search' }, // AI검색
  { name: 'option', value: '/option' }, // 추가기능
];
const MULTIPLE_CHOICE = ['1', '2', '3', '4', '5'];
const ANSWER_BUTTON_OPTIONS = [
  'ai_hint',
  'ai_solution',
  'ai_concept',
  'ai_question',
  'peer_learning',
  'concept_video',
  'basic_questions',
  'similar_question',
  'in_depth_question',
];
const MENTAL_ICONS = [
  {
    itemSrc: 'icons/mental/fearful-face-dot.svg',
    selectSrc: 'icons/mental/fearful-face.svg',
    type: 1,
  },
  {
    itemSrc: 'icons/mental/slightly-frowning-face-dot.svg',
    selectSrc: 'icons/mental/slightly-frowning-face.svg',
    type: 2,
  },
  {
    itemSrc: 'icons/mental/flushed-face-dot.svg',
    selectSrc: 'icons/mental/flushed-face.svg',
    type: 3,
  },
  {
    itemSrc: 'icons/mental/grinning-face-dot.svg',
    selectSrc: 'icons/mental/grinning-face.svg',
    type: 4,
  },
  {
    itemSrc: 'icons/mental/smiling-face-with-heart-eyes-dot.svg',
    selectSrc: 'icons/mental/smiling-face-with-heart-eyes.svg',
    type: 5,
  },
];
const QUESTION_LIST = [
  {
    answer: [],
    id: 1,
    isMultipleChoice: false,
    isSolved: false,
    previewAnswer: '3',
    question: `두 다항식 $A, B$ 에 대하여
              $\\left\\{\\begin{array}{l}A=x^{2}+x y-y^{2} \\\\ B=2 x^{2}-3 x y+4 y^{2}\\end{array}\\right.$
              일 때 $A * B=3 A-2 B, A \\diamond B=2 A-7 B$ 로 약속
              할 때, $\\{A *(A-3 B)\\} \\diamond B$ 를 간단히 한 식에서 $y^{2}$ 의
              계수는?
              (1) 12
              (2) 13  
              (3) 15
              (4) 17
              (5) 18`,
  },
  {
    answer: [],
    id: 2,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '5',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 3,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 4,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 5,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 6,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 7,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 8,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `��음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 9,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
  {
    answer: [],
    id: 10,
    isMultipleChoice: true,
    isSolved: false,
    previewAnswer: '3',
    question: `다음 중 $x$ 의 계수가 1인 다항식은?
                (1) $x^{2}+x+1$
                (2) $x^{2}-x+1$
                (3) $x^{2}+1$
                (4) $x^{2}-1$
                (5) $x^{2}+x$`,
  },
];
const DIAGNOSTIC_NOTICE = [
  'diagnostic_notice.item1',
  'diagnostic_notice.item2',
  'diagnostic_notice.item3',
  'diagnostic_notice.item4',
  'diagnostic_notice.item5',
  'diagnostic_notice.item6',
  'diagnostic_notice.item7',
  'diagnostic_notice.item8',
  'diagnostic_notice.item9',
];
const PARTNER_ID = '8eb6268e-f4dc-5d3f-9af7-54a46bad64ea';
const PARTNER_IDS = [
  '8eb6268e-f4dc-5d3f-9af7-54a46bad64ea',
  'ac37353b-1daf-52e8-baa3-ea780ae8cbb9',
  '1dc70846-2ccb-5351-a3b2-b0e321cd9150',
  '8cf9df8f-f086-5db7-9066-e5f0f1195219',
  'eadc8666-eb11-5a87-8d13-0623be7a0ce1',
  '186f060e-4df6-5ae9-9ce0-b1b13940a94a',
  '6d0c2ce0-b136-5892-b471-b2dea069acb4',
  '2f6b70a2-b49c-5ba7-97fa-63f11fb89aae',
  '2c3d91d6-f7a2-5028-9bb2-0482596ce6b2',
  'b7dd668a-16aa-5012-87be-725181263bd9',
  '147c20eb-5617-5c0f-97b3-de6547c0c070',
  '8c6f097e-4778-5e3e-ae58-510fdb5889d4',
  '6c5c9d17-7dac-5b08-9bcb-d65bc2ceb067',
  'bb6a2e6a-2117-56f1-94bb-3642fc111fb4',
  'cc02583f-4843-5015-8a08-df39e9008c07',
  'cd482c14-7a88-5b9f-b885-d245a60f7113',
];
const AGENT_ID = 'gid_portal_dev';
const LOGIN_DOMAIN = process.env.REACT_APP_LOGIN_URL
  ? process.env.REACT_APP_LOGIN_URL
  : (() => {
      throw Error('LOGIN_DOMAIN is not defined');
    })();
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN
  ? process.env.REACT_APP_API_DOMAIN
  : (() => {
      throw Error('API_DOMAIN is not defined');
    })();
const SSO_DOMAIN = process.env.REACT_APP_SSO_DOMAIN
  ? process.env.REACT_APP_SSO_DOMAIN
  : (() => {
      throw Error('SSO_DOMAIN is not defined');
    })();

export {
  AGENT_ID,
  ANSWER_BUTTON_OPTIONS,
  API_DOMAIN,
  DIAGNOSTIC_NOTICE,
  DRAWER_WIDTH,
  LEFT_NAV_ITEMS,
  LOGIN_DOMAIN,
  MENTAL_ICONS,
  MULTIPLE_CHOICE,
  PARTNER_ID,
  PARTNER_IDS,
  QUESTION_LIST,
  RIGHT_NAV_ITEMS,
  SSO_DOMAIN,
};
