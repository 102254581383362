import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types';

export default function StudyOrderInfo() {
  const { t } = useTranslation();
  const curerntUser = useSelector((state: RootState) => state.user);
  const BACKGROUND_IMAGE_URL = `/images/study_order_${curerntUser.user_type}.svg`;
  return (
    <div className="container flex flex-col items-center justify-center p-10">
      <div
        className="mx-auto flex flex-col gap-10 bg-center w-full h-[500px]"
        style={{
          backgroundImage: `url('${BACKGROUND_IMAGE_URL}')`,
          backgroundSize: 'contain',
        }}
      ></div>
    </div>
  );
}
