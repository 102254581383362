import { useQuery } from '@tanstack/react-query';
import { getStudentNames } from 'apis/aidt-student';
import {
  GetStudentNamesDto,
  GetStudentNamesResponse,
  User,
} from 'schemas/aidt-student';

export function useStudentNames({ data, headers }: GetStudentNamesDto) {
  return useQuery<GetStudentNamesResponse, Error, User[] | undefined>({
    enabled: !!data.user_ids,
    queryFn: () => getStudentNames({ data, headers }),
    queryKey: ['student_names', { data, headers }],
    select: data => data.user_list,
  });
}
