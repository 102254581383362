import { Box, Stack, Typography } from '@mui/material';
import ClassToggleSwitch from 'app/components/Common/header/ClassToggleSwitch';
import { LucideTimer } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

export default function HeaderMid({
  currentAssessment,
  subTitle,
  title,
}: {
  currentAssessment;
  subTitle: string;
  title: string;
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '80px',
      }}
      tabIndex={0}
    >
      <div
        className={
          (currentAssessment
            ? 'from-orange-400 to-red-500 bg-gradient-to-b animate-pulse text-white'
            : 'bg-white text-black') +
          ' flex items-center justify-center flex-col leading-3 gap-1 py-3 border relative border-slate-300 border-t-0 rounded-b-xl'
        }
      >
        <Typography
          component="h1"
          sx={{ fontSize: '1.25rem', fontWeight: '800', lineHeight: '1.2' }}
          variant="h6"
        >
          {currentAssessment ? (
            <Link
              className="flex items-center justify-center gap-1"
              to={`/assessment/${
                currentAssessment.learning_sys_id > 0
                  ? `${currentAssessment.type}-${currentAssessment.learning_sys_id}`
                  : currentAssessment.type
              }
            `}
            >
              <LucideTimer size={20} strokeWidth={3} />
              &nbsp;진행중인 평가가 있습니다
            </Link>
          ) : (
            title
          )}
        </Typography>
        <Typography
          component="h5"
          sx={{ fontSize: '0.9rem', lineHeight: '1.2' }}
          variant="subtitle1"
        >
          {currentAssessment ? (
            <div className="flex items-center justify-center gap-1">
              {currentAssessment.type === 'DIAGNOSTIC' &&
                '학력진단평가를 응시해주세요.'}
              {currentAssessment.type === 'UNIT' &&
                '대단원형성평가를 응시해주세요.'}
              {currentAssessment.type === 'COMPREHENSIVE' &&
                '학기총괄평가를 응시해주세요.'}
            </div>
          ) : (
            subTitle
          )}
        </Typography>
        {/* <ClassToggleSwitch /> */}
      </div>
    </Box>
  );
}
