import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  GetDreamJobsDto,
  GetDreamJobsResponse,
  ValidateUuidDto,
  ValidateUuidResponse,
} from 'schemas/user';
import { axiosBaseQuery } from 'services/api';

export const userService = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: '/user',
  }),
  endpoints: build => ({
    // 장래희망 직업 조회
    getDreamJobs: build.query<GetDreamJobsResponse, GetDreamJobsDto>({
      providesTags: ['User'],
      query: ({ headers }) => ({
        headers,
        method: 'GET',
        url: '/dream-jobs',
      }),
    }),
    // UUID 검증
    validateUuid: build.query<ValidateUuidResponse, ValidateUuidDto>({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/authorize',
      }),
    }),
  }),
  reducerPath: 'userApi',
  tagTypes: ['User'],
});

export const { useGetDreamJobsQuery, useValidateUuidQuery } = userService;
