import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { useNavigate } from 'react-router-dom';

interface ErrorResponse {
  message: {
    message: string;
  };
  statusCode: number;
}

const aidtInstance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_DOMAIN}/aidt_userinfo`,
  headers: {
    'Content-Type': 'application/json',
  },
});

const onRequest = (
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  const { method, url } = config;
  // console.log(`🛫 [API - REQUEST] ${method?.toUpperCase()} ${url}`);

  return config;
};

const onResponse = (res: AxiosResponse): AxiosResponse => {
  const { method, url } = res.config;
  const { code, message } = res.data;
  if (code === 'SUCCESS') {
    console
      .log
      // `🛬 [API - RESPONSE] ${method?.toUpperCase()} ${url} | ${code} : ${message}`,
      ();
  } else {
    console
      .log
      // `🚨 [API - ERROR] ${method?.toUpperCase()} ${url} | ${code} : ${message}`,
      ();
  }

  return res;
};

const onError = (error: AxiosError | Error): Promise<AxiosError> => {
  if (axios.isAxiosError(error)) {
    const { method, url } = error.config as InternalAxiosRequestConfig;
    if (error.response) {
      const { message, statusCode } = error.response.data as ErrorResponse;
      // console.log(
      //   `🚨 [API - ERROR] ${method?.toUpperCase()} ${url} | ${statusCode} : ${
      //     message.message
      //   }`,
      // );
      if (error.response.status === 401 || error.response.status === 403) {
        const navigate = useNavigate();
        navigate('/expired');
      }
    }
  } else {
    // console.log(`🚨 [API] | Error ${error.message}`);
  }
  return Promise.reject(error);
};

aidtInstance.interceptors.request.use(onRequest);
aidtInstance.interceptors.response.use(onResponse, onError);

export default aidtInstance;
