import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import {
  AnswerInfo,
  ConceptInfo,
  ProblemInfo,
  ShareType,
} from 'types/WhiteboardState';
const CustomCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: '#1A48A9',
  },
  color: '#1A48A9',
});

export interface SubmitFormData {
  shareType: ShareType;
  title: string;
}

interface SubmitComponentProps {
  boardInfo: AnswerInfo | ConceptInfo | ProblemInfo | null;
  onSubmit: (data: SubmitFormData) => void;
}
const SubmitComponent = (props: SubmitComponentProps) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [isAllShare, setIsAllShare] = useState(true);
  const [isClassShare, setIsClassShare] = useState(false);
  const [isPrivateShare, setIsPrivateShare] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const userType = useSelector((state: RootState) => state.user.user_type);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleAllShareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsAllShare(event.target.checked);
    if (event.target.checked) {
      setIsClassShare(false);
      setIsPrivateShare(false);
    }
  };

  const handleClassShareChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsClassShare(event.target.checked);
    if (event.target.checked) {
      setIsAllShare(false);
      setIsPrivateShare(false);
    }
  };

  const handlePrivateShareChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setIsPrivateShare(event.target.checked);
    if (event.target.checked) {
      setIsAllShare(false);
      setIsClassShare(false);
    }
  };

  const handleSubmit = () => {
    props.onSubmit({
      shareType: isAllShare ? 'all' : isClassShare ? 'class' : 'private',
      title: title ? title : props.boardInfo ? props.boardInfo.title : '',
    });
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  if (props.boardInfo?.boardType === 'answer') {
    return (
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: '#F5F5F5',
          boxSizing: 'border-box',
          display: 'flex',
          height: '70px',
          justifyContent: 'space-between',
          maxWidth: '100%',
          padding: '20px',
          width: '100%',
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography
            sx={{ height: '24px', lineHeight: '24px', marginRight: 2 }}
          >
            {`${t('white_board.share_scope_setting')}:`}
          </Typography>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={isAllShare}
                onChange={handleAllShareChange}
              />
            }
            label={
              <Typography sx={{ height: '24px', lineHeight: '24px' }}>
                {t('white_board.share_all')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={isClassShare}
                onChange={handleClassShareChange}
              />
            }
            label={
              <Typography sx={{ height: '24px', lineHeight: '24px' }}>
                {t('white_board.share_class')}
              </Typography>
            }
          />
        </Box>
        <Button
          onClick={handleSubmit}
          sx={{
            '&:hover': { backgroundColor: '#126652' },
            backgroundColor: '#157C69',
            height: '32px',
            width: '96px',
          }}
        >
          {t('white_board.register')}
        </Button>
      </Box>
    );
  }
  return (
    <Box
      sx={{
        backgroundColor: '#F5F5F5',
        boxSizing: 'border-box',
        maxWidth: '100%',
        padding: '20px 50px 30px 50px',
        width: '100%',
      }}
    >
      <Box alignItems="center" display="flex" mb={2}>
        <Typography
          sx={{
            height: '24px',
            lineHeight: '24px',
            marginRight: 2,
            minWidth: '50px',
          }}
        >
          {`${t('white_board.title')}:`}
        </Typography>
        <TextField
          fullWidth
          inputRef={inputRef}
          onChange={handleTitleChange}
          placeholder={props.boardInfo?.title}
          size="small"
          value={title}
        />
      </Box>
      <Box
        mb={2}
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <Typography
            sx={{ height: '24px', lineHeight: '24px', marginRight: 2 }}
          >
            {`${t('white_board.share_scope_setting')}:`}
          </Typography>
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={isAllShare}
                onChange={handleAllShareChange}
              />
            }
            label={
              <Typography sx={{ height: '24px', lineHeight: '24px' }}>
                {t('white_board.share_all')}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={isClassShare}
                onChange={handleClassShareChange}
              />
            }
            label={
              <Typography sx={{ height: '24px', lineHeight: '24px' }}>
                {t('white_board.share_class')}
              </Typography>
            }
          />
          {userType === 'S' && (
            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={isPrivateShare}
                  onChange={handlePrivateShareChange}
                />
              }
              label={
                <Typography sx={{ height: '24px', lineHeight: '24px' }}>
                  {t('white_board.share_teacher_only')}
                </Typography>
              }
            />
          )}
        </Box>
        <Button
          onClick={handleSubmit}
          sx={{
            '&:hover': { backgroundColor: '#153C91' },
            backgroundColor: '#1A48A9',
            height: '32px',
            width: '96px',
          }}
        >
          {t('white_board.register')}
        </Button>
      </Box>
    </Box>
  );
};

export default SubmitComponent;
