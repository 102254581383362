import axios, { AxiosError } from 'axios';

import { LOGIN_DOMAIN } from '../constants';

const LOCAL_URL = removeAllQueryParametersAndReturn(window.location.href);
const LOG_OUT_URL = `${
  process.env.REACT_APP_SSO_DOMAIN
}/sso/pmi-logout-url.jsp?returl=${encodeURIComponent(LOGIN_DOMAIN)}`;
const returlParam = '?uuid=test';
const customstorage = localStorage;

const apiService = axios.create({
  withCredentials: true,
});

export async function KSign_Server_LiveCheck(): Promise<void> {
  try {
    const response = await apiService.post(
      `https://api.sso.${process.env.REACT_APP_MY_DOMAIN}/sso/sso-server-livecheck`,
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    alert('SSO 서버 연결에 실패했습니다.');
  }
}

export async function KSign_Agent_LiveCheck(): Promise<void> {
  try {
    const response = await apiService.get(
      `https://api.sso.${process.env.REACT_APP_MY_DOMAIN}/liveCheckRequest`,
    );
  } catch (error) {
    const axiosError = error as AxiosError;
    alert('SSO 서버 연결에 실패했습니다.');
  }
}

function parseQueryString(url) {
  // Split the URL into its components
  const [baseUrl, queryString] = url.split('?');

  // Split the query string into individual key-value pairs
  const queryParams = queryString.split('&');

  // Create an empty object to hold the key-value pairs
  const queryObject = {};

  // Iterate over each key-value pair
  queryParams.forEach(param => {
    // Split each pair into its key and value
    const [key, value] = param.split('=');
    // Decode the key and value, then add them to the queryObject
    queryObject[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  // Create the final JSON object with baseUrl and parameters
  const result = {
    base_url: baseUrl,
    parameters: queryObject,
  };

  return { ...result.parameters };
}

const setSSOData = (): void => {
  const url = window.location.href;

  if (!url.includes('access_token.token')) {
    return;
  }

  const parameters: any = parseQueryString(url);

  localStorage.setItem(
    'access_token',
    JSON.stringify({
      access_id: parameters['access_token.access_id'],
      token: parameters['access_token.token'],
    }),
  );
  localStorage.setItem('user_id', parameters['user_id']);
  localStorage.setItem('user_type', parameters['user_type']);
  localStorage.setItem('user_status', parameters['user_status']);
};

export async function makeSSOReq(): Promise<void> {
  const url = `https://api.sso.${process.env.REACT_APP_MY_DOMAIN}/makeTokenRequest`;
  const reqParam = {
    authenMethod: 'form-based',
    returnUrl: LOCAL_URL,
    sessionId: null,
  };

  setSSOData();

  try {
    const response = await apiService.post(url, reqParam);
    const respJson = response.data;

    if (respJson && respJson.code === 200) {
      const pmisso = respJson.object;
      const pmiSSO = `pmi-sso=${encodeURIComponent(pmisso.tokenRequest)}`;
      const from = `from=${encodeURIComponent(pmisso.from)}`;
      const retUrl = `returl=${encodeURIComponent(
        pmisso.returl + returlParam,
      )}`;
      const sinfo = `sinfo=${encodeURIComponent(pmisso.sinfo)}`;
      const challenge = `challenge=${encodeURIComponent(pmisso.challenge)}`;
      const secure = pmisso.securenonce;
      const keyId = pmisso.keyId;
      customstorage.setItem('sn', secure);
      customstorage.setItem('keyId', keyId);

      const SSO_SERVER = `${process.env.REACT_APP_SSO_DOMAIN}/pmi-sso.jsp`;
      const finalUrl = `${SSO_SERVER}?${pmiSSO}&${challenge}&${from}&${sinfo}&${retUrl}`;

      window.location.href = finalUrl;
    }
  } catch (error) {
    console.error('Error: ' + error);
  }
}

export async function verifyToken(pmiSSO: string): Promise<boolean> {
  if (!pmiSSO) {
    await makeSSOReq();
    return false;
  }
  const encryptedToken = pmiSSO;
  const keyId = customstorage.getItem('keyId');

  if (!keyId) {
    await makeSSOReq();
    return false;
  }

  if (!encryptedToken) {
    ssoFail();
    return false;
  }

  const url = `https://api.sso.${process.env.REACT_APP_MY_DOMAIN}/verifyTokenResponse`;
  const sn = customstorage.getItem('sn');
  const reqParam = {
    Authorization: encryptedToken, // 'basic ' + encryptedToken
    KeyId: keyId,
    securenonce: sn,
  };

  try {
    const response = await apiService.post(url, reqParam);
    const responseObject = response.data.object;
    return true;
  } catch (error) {
    const axiosError = error as AxiosError;
    alert('SSO 서버 연결에 실패했습니다.');
    ssoFail();
    return false;
  }
}

export async function invalidateToken(): Promise<void> {
  const url = `https://api.sso.${process.env.REACT_APP_MY_DOMAIN}/sessionTokenInvalidate`;
  try {
    const response = await apiService.post(url);
    if (response.data === 'invalidateSuccess') {
      customstorage.clear();
    }
  } catch (error) {
    removeUrlParameterAndReloadAndMoveLogoutPage();
  }
}

export function getUrlParameter(sParam: string): null | string {
  const params = new URLSearchParams(window.location.search);
  return params.get(sParam);
}

function removeAllQueryParametersAndReturn(locationUrl: string): string {
  const url = new URL(locationUrl);
  url.search = '';
  return url.href;
}

export function removeUrlParameterAndReloadAndMoveLogoutPage(): void {
  window.location.href = LOG_OUT_URL;
}

function ssoFail(): void {
  alert('로그인 상태가 아닙니다.');
  localStorage.clear();
  window.location.href = LOGIN_DOMAIN;
}
