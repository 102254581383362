import moment from 'moment';
import 'moment/locale/ko';

moment.locale('ko');

export function formatDate(dateString: string, format: string) {
  const date = moment(dateString);
  return date.format(format);
}

// format time(00:00:00)
export function formatTime(time: null | number) {
  if (time === null) {
    return '00:00:00';
  }
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return `${hours < 10 ? `0${hours}` : hours}:${
    minutes < 10 ? `0${minutes}` : minutes
  }:${seconds < 10 ? `0${seconds}` : seconds}`;
}
