import {
  GetContentTableReqDto,
  GetContentTableRes,
  GetLearningSystemBookMarkerDto,
  GetLearningSystemByIdDto,
  GetLearningSystemByIdResponse,
  GetLearningSystemByLearingSysIdDto,
  GetLearningSystemDto,
  GetLearningSystemProgessDto,
  GetLearningSystemResponse,
  GetSemesterBookmarkerDto,
  GetTeacherBookMarkerDto,
  LearningSystemBookMarker,
  LearningSystemProgessRes,
  SemesterBookmarkerRes,
  TeacherBookmarkerRes,
  UpdateLearningLevelDto,
  UpdateTeacherBookMarkerDto,
  UpdateTeacherBookMarkerRes,
} from 'schemas/learning';
import { apiService } from 'services/api';

export const learningService = apiService.injectEndpoints({
  endpoints: build => ({
    getContentTable: build.query<GetContentTableRes, GetContentTableReqDto>({
      query: ({ headers, params }) => {
        return {
          headers,
          method: 'GET',
          params,
          url: '/learning/content-table',
        };
      },
    }),
    // 대단원 책갈피 조회
    getLearningBookMarker: build.query<
      LearningSystemBookMarker,
      GetLearningSystemBookMarkerDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/learning/book-marker',
      }),
    }),
    // 단원 정보 조회
    getLearningSystem: build.query<
      GetLearningSystemResponse,
      GetLearningSystemDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/learning/learning-system',
      }),
    }),
    // 유저 단원 정보 조회
    getLearningSystemById: build.query<
      GetLearningSystemByIdResponse,
      GetLearningSystemByIdDto
    >({
      query: ({ headers, query }) => ({
        headers,
        method: 'GET',
        url: `/learning/learning-system/${query.uuid}`,
      }),
    }),
    // 소단원 정보 조회
    getLearningSystemByLearningSysId: build.query<
      GetLearningSystemResponse,
      GetLearningSystemByLearingSysIdDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/learning/learning-system',
      }),
    }),
    // 학습 결과 조회(학생, 교사)
    getLearningSystemProgess: build.query<
      LearningSystemProgessRes,
      GetLearningSystemProgessDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: `/learning/learning-system/progress/${
          headers.role === 'S' ? 'student' : 'teacher'
        }`,
      }),
    }),
    // 학생 전체 목차 책갈피 조회
    getSemesterBookmarker: build.query<
      SemesterBookmarkerRes,
      GetSemesterBookmarkerDto
    >({
      query: ({ headers }) => ({
        headers,
        method: 'GET',
        url: '/learning/book-marker/semester',
      }),
    }),
    // 교사 전체 목차 책갈피 조회
    getTeacherBookMarker: build.query<
      TeacherBookmarkerRes,
      GetTeacherBookMarkerDto
    >({
      query: ({ headers }) => ({
        headers,
        method: 'GET',
        url: '/learning/book-marker/checked',
      }),
    }),
    // 학습 단계 변경
    updateLearningLevel: build.mutation<unknown, UpdateLearningLevelDto>({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'PATCH',
        url: '/learning/level',
      }),
    }),
    // 교사 소단원 북마크 생성
    updateTeacherBookMarker: build.mutation<
      UpdateTeacherBookMarkerRes,
      UpdateTeacherBookMarkerDto
    >({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/learning/book-marker/menual',
      }),
    }),
  }),
});

export const {
  useGetLearningBookMarkerQuery,
  useGetLearningSystemByIdQuery,
  useGetLearningSystemByLearningSysIdQuery,
  useGetLearningSystemProgessQuery,
  useGetLearningSystemQuery,
  useGetSemesterBookmarkerQuery,
  useGetTeacherBookMarkerQuery,
  useLazyGetContentTableQuery,
  useUpdateLearningLevelMutation,
  useUpdateTeacherBookMarkerMutation,
} = learningService;
