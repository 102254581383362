import {
  Badge,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getConceptVideos, getLearningProblems } from 'apis/dashboard';
import ProblemResultDialog from 'app/components/Assessment/ProblemResultDialog';
import ConceptVideoDialog from 'app/components/Dashboard/ConceptVideoDialog';
import CreateAssignmentDialog from 'app/components/Dashboard/CreateAssignmentDialog';
import ReminderDialog from 'app/components/Dashboard/ReminderDialog';
import SubmitAssignmentDialog from 'app/components/Dashboard/SubmitAssignmentDialog';
import { useToast } from 'components/ui/use-toast';
import {
  LucideChevronRight,
  LucideChevronRightCircle,
  LucideMousePointer,
  LucideMousePointer2,
  LucideMousePointerClick,
} from 'lucide-react';
import { send } from 'process';
import { useCanCreateAssignment } from 'queries/assignment/use-can-create-assignment';
import { useCheckAssignmentSubmission } from 'queries/assignment/use-check-assignment-submission';
import { useCreateAssignment } from 'queries/assignment/use-create-assignment';
import { useLearningProblems } from 'queries/dashboard/use-learning-problems';
import { useSendAssignmentReminder } from 'queries/notification/use-send-assignment-reminder';
import { useClassLearningHistory } from 'queries/use-class-learning-history';
import { useLearningSystem } from 'queries/use-learning-system';
import { useStudentNames } from 'queries/use-student-names';
import { useTeacher } from 'queries/use-teacher';
import { useTeacherClassMembers } from 'queries/use-teacher-class-members';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AssignmentTypes } from 'schemas/assignment';
import useConceptVideoDialogStore from 'stores/use-concept-video-dialog-store';
import useDialogStore, { DialogKeys } from 'stores/use-dialog-store';
import useProblemResultDialogStore from 'stores/use-problem-result-dialog-store';
import { RootState } from 'types';

export default function ClassLearningHistoryTable() {
  const { t } = useTranslation();
  const toast = useToast();

  const toggleDialog = useDialogStore(state => state.toggleDialog);

  const { setVideos, toggleConceptVideoDialog } = useConceptVideoDialogStore(
    state => ({
      setVideos: state.setVideos,
      toggleConceptVideoDialog: state.toggleDialog,
    }),
  );

  const { setProblems, setTitle, toggleProblemResultDialog } =
    useProblemResultDialogStore(state => ({
      setProblems: state.setProblems,
      setTitle: state.setTitle,
      toggleProblemResultDialog: state.toggleDialog,
    }));

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 선생님 정보 조회
  const teacherQuery = useTeacher({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학급 구성원 조회
  const teacherClassMembersQuery = useTeacherClassMembers({
    data: {
      access_token: user.access_token,
      class_code: teacherQuery.data?.class_info?.[0].class_code,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 학급 현재 소단원별 학습결과 내역 조회
  const classLearningHistoryQuery = useClassLearningHistory({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      classUuids:
        teacherClassMembersQuery.data?.map(member => member.user_id) || [],
      learningSysId: subsectionId ? parseInt(subsectionId) : 0,
    },
  });

  // 학습결과 학생 성명 리스트 조회
  const studentNamesQuery = useStudentNames({
    data: {
      access_token: user.access_token,
      user_ids: classLearningHistoryQuery.data?.map(member => member.uuid),
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  // 과제 출제 가능 여부 조회
  const canCreateAssignmentQuery = useCanCreateAssignment({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: { learningSysId: Number(subsectionId) },
  });

  // 과제 제출 여부 조회
  const checkAssignmentSubmissionQuery = useCheckAssignmentSubmission({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      classUuids: classLearningHistoryQuery.data?.map(student => student.uuid),

      learningSysId: Number(subsectionId),
    },
  });

  // 과제 독촉
  const sendAssignmentReminderMutation = useSendAssignmentReminder();

  const [types, setTypes] = React.useState<AssignmentTypes[]>([]);
  const [factor, setFactor] = React.useState('');

  // 과제 출제
  const createAssignmentMutation = useCreateAssignment();

  // 테이블 데이터
  const headers = [
    'common.learning_stage',
    'teacher_dashboard.concept_learning',
    'teacher_dashboard.basic_problems',
    'teacher_dashboard.review_problems',
    'teacher_dashboard.feedback',
    'common.metacognition',
    'teacher_dashboard.learning_participation',
    'teacher_dashboard.assignment_result',
  ];
  const subHeaders = [
    ['teacher_dashboard.before', 'teacher_dashboard.after'],
    ['teacher_dashboard.video', 'teacher_dashboard.explanation'],
    ['teacher_dashboard.total', 'teacher_dashboard.incorrect'],
    ['teacher_dashboard.total', 'teacher_dashboard.incorrect'],
    ['teacher_dashboard.total', 'teacher_dashboard.incorrect'],
    ['teacher_dashboard.total', 'teacher_dashboard.incorrect'],
    ['teacher_dashboard.time', 'teacher_dashboard.frequency'],
    ['teacher_dashboard.total', 'common.incorrect'],
  ];

  const meanHistoryItem = classLearningHistoryQuery.data?.[0].meanHistoryItem;

  const customStyle = theme => ({
    backgroundColor: 'white',
    border: '1px solid #BFC7E3',
    color: '#4F73C0',
    fontSize: '.8rem',
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '.625rem',
    },
  });

  function onSubmit() {
    const classUuids = teacherClassMembersQuery.data?.map(
      member => member.user_id,
    ) as string[];
    toggleDialog(DialogKeys.CreateAssignment, false)();
    createAssignmentMutation.mutate(
      {
        data: {
          classUuids,
          factor: Number(factor),
          learningSysId: subsectionId ? Number(subsectionId) : 0,
          types,
        },
        headers: {
          role: user?.user_type,
          teacherUuid: user?.user_id,
          uuid: user?.user_id,
        },
      },
      {
        onSuccess: () => {
          setTypes([]);
          setFactor('');

          toggleDialog(DialogKeys.SubmitAssignment, true)();
        },
      },
    );
  }

  async function fetchConceptVideos({ uuid }: { uuid: string }) {
    const videos = await getConceptVideos({
      data: {
        learning_sys_id: Number(subsectionId),
        uuid,
      },
      headers: {
        role: user?.user_type,
        uuid: user?.user_id,
      },
    });

    setVideos(videos.data);
  }

  async function fetchLearningProblems({
    type,
    uuid,
  }: {
    type: 'ASSIGNMENT' | 'BASIC' | 'CONFIRM' | 'FEEDBACK' | 'METACOGNITION';
    uuid: string;
  }) {
    let newTitle = '';

    switch (type) {
      case 'ASSIGNMENT':
        newTitle = '과제';
        break;
      case 'BASIC':
        newTitle = '기본문제';
        break;
      case 'CONFIRM':
        newTitle = '확인문제';
        break;
      case 'FEEDBACK':
        newTitle = '피드백';
        break;
      case 'METACOGNITION':
        newTitle = '메타인지';
        break;
      default:
        break;
    }

    setTitle(`${newTitle}`);

    const problems = await getLearningProblems({
      data: {
        learning_sys_id: Number(subsectionId),
        type,
        uuid,
      },
      headers: {
        role: user?.user_type,
        uuid: user?.user_id,
      },
    });

    setProblems(problems.data);
  }

  const GenerateStudyCell = ({
    incorrectNum,
    korNotCompleteString = '미학습',
    korString,
    totalNum,
    type,
    uuid,
  }: {
    incorrectNum: number;
    korNotCompleteString?: string | undefined;
    korString: string;
    totalNum: number;
    type: 'ASSIGNMENT' | 'BASIC' | 'CONFIRM' | 'FEEDBACK' | 'METACOGNITION';
    uuid: string;
  }) => {
    if (totalNum === 0) {
      return (
        <Box
          aria-label={`아직 이 학생은 ${korString}를 풀지 않았습니다.`}
          colSpan={2}
          component="td"
          sx={theme => ({
            ...customStyle(theme),
            bgcolor: '#FFD2DD',
            color: '#FF0000',
          })}
          tabIndex={0}
        >
          {korNotCompleteString}
        </Box>
      );
    } else {
      return (
        <>
          <Box
            aria-label={`${korString}의 수는 ${totalNum}개입니다.`}
            component="td"
            sx={theme => ({
              ...customStyle(theme),
              color: '#000',
            })}
            tabIndex={0}
          >
            {totalNum}
          </Box>
          <Box
            aria-label={`${korString}의 오답 수는 ${incorrectNum}개입니다.`}
            component="td"
            onClick={() => {
              if (incorrectNum > 0) {
                fetchLearningProblems({ type, uuid }).then(() => {
                  toggleProblemResultDialog(true)();
                });
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (incorrectNum > 0) {
                  fetchLearningProblems({ type, uuid }).then(() => {
                    toggleProblemResultDialog(true)();
                  });
                }
              }
            }}
            sx={theme => ({
              ...customStyle(theme),
              bgcolor: incorrectNum === 0 ? '#ddf5dd' : '#FFFFFF',
              color: incorrectNum === 0 ? '#04c904' : '#FF0000',
              position: 'relative',
            })}
            tabIndex={0}
          >
            {incorrectNum > 0 ? incorrectNum : '전부 맞춤'}
            {incorrectNum > 0 && (
              <span className="absolute flex w-2 h-2 top-0.5 right-0.5">
                <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                <span className="relative inline-flex w-2 h-2 rounded-full bg-sky-500"></span>
              </span>
            )}
          </Box>
        </>
      );
    }
  };

  const GenerateAssignmentCell = ({ student }) => {
    if (checkAssignmentSubmissionQuery.data) {
      return (
        <>
          <Box
            component="td"
            sx={theme => ({
              ...customStyle(theme),
              color: '#000',
            })}
            tabIndex={0}
          >
            {student.historyItem?.numAssignment}
          </Box>
          <Box
            component="td"
            onClick={() => {
              if (student.historyItem?.numIncorrectAssignment > 0) {
                fetchLearningProblems({
                  type: 'ASSIGNMENT',
                  uuid: student.uuid,
                }).then(() => {
                  toggleProblemResultDialog(true)();
                });
              }
            }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                if (student.historyItem?.numIncorrectAssignment > 0) {
                  fetchLearningProblems({
                    type: 'ASSIGNMENT',
                    uuid: student.uuid,
                  }).then(() => {
                    toggleProblemResultDialog(true)();
                  });
                }
              }
            }}
            sx={theme => ({
              ...customStyle(theme),
              backgroundColor:
                checkAssignmentSubmissionQuery.data?.data.find(
                  s => s.uuid === student.uuid,
                )?.assignment_finish.length === 0
                  ? '#FFD2DD'
                  : student.historyItem?.numIncorrectAssignment === 0
                  ? '#ddf5dd'
                  : 'white',
              border: '1px solid #BFC7E3',
              color:
                checkAssignmentSubmissionQuery.data?.data.find(
                  s => s.uuid === student.uuid,
                )?.assignment_finish.length === 0 ||
                student.historyItem?.numIncorrectAssignment > 0
                  ? '#FF0000'
                  : student.historyItem?.numIncorrectAssignment === 0
                  ? '#04c904'
                  : '#000',
              position: 'relative',
            })}
            tabIndex={0}
          >
            {checkAssignmentSubmissionQuery.data?.data.find(
              s => s.uuid === student.uuid,
            )?.assignment_finish.length === 0
              ? t('teacher_dashboard.not_submitted')
              : student.historyItem?.numIncorrectAssignment === 0
              ? '전부 맞춤'
              : student.historyItem?.numIncorrectAssignment}
            {student.historyItem?.numIncorrectAssignment > 0 && (
              <span className="absolute flex w-2 h-2 top-0.5 right-0.5">
                <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                <span className="relative inline-flex w-2 h-2 rounded-full bg-sky-500"></span>
              </span>
            )}
          </Box>
        </>
      );
    } else {
      return (
        <Box
          colSpan={2}
          component="td"
          sx={theme => ({
            ...customStyle(theme),
            bgcolor: '#EEE',
            color: '#000',
          })}
          tabIndex={0}
        >
          과제 출제 전
        </Box>
      );
    }
  };

  return (
    <Stack
      aria-label="현재 소단원별 학습결과 내역 테이블입니다. 학습결과 내역의 각 항목별로 개인과 반평균 데이터를 나타냅니다. 학습결과 내역에는 학습단계, 개념학습, 기본문제, 확인문제, 피드백, 추가문제, 메타인지, 학습참여 총 8개의 항목이 있습니다. 마지막 행에는 선생님이 제출한 과제관련 문제 페이지로 이동하는 버튼과 과제 결과 보기 버튼이 있습니다."
      sx={{
        backgroundColor: '#F9F3FF',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '.75rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        {learningSystemQuery.data?.type === 'SECTION' ? '현재' : '대단원 내'}{' '}
        소단원별 학습결과 내역
      </Typography>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".375rem" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              현재 소단원별 학습결과 내역 테이블
            </caption>
            <tbody>
              <tr
                style={{
                  backgroundColor: '#E7E3FC',
                }}
              >
                <Box
                  colSpan={2}
                  component="th"
                  rowSpan={2}
                  scope="colgroup"
                  sx={theme => ({
                    fontSize: '.8rem',
                    fontWeight: 600,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  {t('teacher_dashboard.learning_history')}
                </Box>
                {headers.map((header, index) => (
                  <Box
                    colSpan={2}
                    component="th"
                    key={index}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.8rem',
                      fontWeight: 600,
                      height: '1.5rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                    })}
                  >
                    {t(header)}
                  </Box>
                ))}
              </tr>

              <tr>
                {subHeaders.map((subHeader, index) => (
                  <React.Fragment key={index}>
                    {subHeader.map((sub, i) => (
                      <Box
                        component="th"
                        key={i}
                        scope="col"
                        sx={theme => ({
                          backgroundColor: '#F9F3FF',
                          border: '1px solid #BFC7E3',
                          fontSize: '.8rem',
                          fontWeight: 600,
                          height: '1.5rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                      >
                        {t(sub)}
                      </Box>
                    ))}
                  </React.Fragment>
                ))}
              </tr>

              {/* 반평균 */}
              <tr aria-label="반평균 학습결과 내역입니다." tabIndex={0}>
                <Box
                  colSpan={2}
                  component="td"
                  sx={theme => ({
                    backgroundColor: '#F9F3FF',
                    border: '1px solid #BFC7E3',
                    color: '#355272',
                    fontSize: '.8rem',
                    fontWeight: 700,
                    height: '2rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.5625rem',
                    },
                  })}
                >
                  반평균
                </Box>

                <Box
                  aria-label={`이전 단원의 평균 학습단계는 ${meanHistoryItem?.meanPreviousAchievementLevel?.toFixed(
                    1,
                  )}단계입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanPreviousAchievementLevel?.toFixed(1) ??
                    '0'}
                </Box>
                <Box
                  aria-label={`학습 후 조정된 평균 학습단계는 ${meanHistoryItem?.meanAfterAchievementLevel?.toFixed(
                    1,
                  )}단계입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanAfterAchievementLevel?.toFixed(1) ??
                    '0'}
                </Box>
                <Box
                  aria-label={`개념학습 영상을 시청한 총 평균 시간은 ${
                    meanHistoryItem?.meanConcepStudyVideo
                      ? (meanHistoryItem?.meanConcepStudyVideo / 60000).toFixed(
                          1,
                        )
                      : 0
                  }분입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConcepStudyVideo
                    ? (meanHistoryItem?.meanConcepStudyVideo / 60000).toFixed(1)
                    : 0}
                  분
                </Box>
                <Box
                  aria-label={`개념학습 설명을 반 학생들이 평균 ${
                    meanHistoryItem?.meanConcepStudyExplain
                      ? meanHistoryItem?.meanConcepStudyExplain.toFixed(1)
                      : 0
                  }개 등록하셨습니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConcepStudyExplain
                    ? meanHistoryItem?.meanConcepStudyExplain.toFixed(1) +
                      '개 등록'
                    : 0}
                </Box>
                <Box
                  aria-label={`기본문제의 평균 수는 ${
                    meanHistoryItem?.meanBasic?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanBasic?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`기본문제의 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectBasic?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanIncorrectBasic?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`확인문제의 평균 수는 ${
                    meanHistoryItem?.meanConfrim?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConfrim?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`확인문제의 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectConfrim?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanIncorrectConfrim?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`피드백 문제의 평균 수는 ${
                    meanHistoryItem?.meanFeedback?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanFeedback.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`피드백 문제의 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectFeedback?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanIncorrectFeedback?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`추가문제의 평균 수는 ${
                    meanHistoryItem?.meanAdditional?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanAdditional?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`추가문제의 평균 오답 수는 ${
                    meanHistoryItem?.meanInccorectAdditional?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanInccorectAdditional?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`메타인지 문제의 평균 수는 ${
                    meanHistoryItem?.meanMetacognition?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanMetacognition?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`메타인지 문제의 평균 오답 수는 ${
                    meanHistoryItem?.meanMetacognitionMiss?.toFixed(1) ?? 0
                  }개입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanMetacognitionMiss?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 평균 시간은 ${
                    meanHistoryItem?.meanParticipateTimes ?? 0
                  }분입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanParticipateTimes ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 평균 횟수는 ${
                    meanHistoryItem?.meanParticipate ?? 0
                  }번입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanParticipate ?? '0'}
                </Box>
              </tr>

              {/* 학생 */}
              {classLearningHistoryQuery.data &&
                classLearningHistoryQuery.data?.map((student, index) => {
                  return (
                    <tr
                      aria-label={`${
                        studentNamesQuery.data?.find(
                          s => s.user_id === student.uuid,
                        )?.user_name
                      } 학생의 학습결과 내역입니다.`}
                      key={index}
                      tabIndex={0}
                    >
                      <Box
                        colSpan={2}
                        component="td"
                        sx={theme => ({
                          backgroundColor: '#F9F3FF',
                          border: '1px solid #BFC7E3',
                          fontSize: '.8rem',
                          fontWeight: 700,
                          height: '2rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.5625rem',
                          },
                        })}
                      >
                        {
                          studentNamesQuery.data?.find(
                            s => s.user_id === student.uuid,
                          )?.user_name
                        }
                      </Box>

                      <Box
                        aria-label={`이전 단원의 학습단계는 ${student.historyItem?.previousAchievementLevel?.toFixed(
                          1,
                        )}단계입니다.`}
                        component="td"
                        sx={theme => ({
                          ...customStyle(theme),
                          color: '#000',
                        })}
                        tabIndex={0}
                      >
                        {student.historyItem?.previousAchievementLevel?.toFixed(
                          1,
                        )}
                      </Box>
                      <Box
                        aria-label={`학습 후 조정된 학습단계는 ${student.historyItem?.afterAchievementLevel?.toFixed(
                          1,
                        )}단계입니다.`}
                        component="td"
                        sx={theme => ({
                          ...customStyle(theme),
                          color: '#000',
                        })}
                        tabIndex={0}
                      >
                        {student.historyItem?.afterAchievementLevel?.toFixed(
                          1,
                        ) ?? '-'}
                      </Box>
                      <Box
                        aria-label={`개념학습 영상을 시청한 총 시간은 ${
                          student.historyItem?.concepStudyVideo
                            ? (
                                student.historyItem?.concepStudyVideo / 60000
                              ).toFixed(1)
                            : 0
                        }분입니다.`}
                        component="td"
                        sx={theme => ({
                          ...customStyle(theme),
                          color: '#000',
                        })}
                        tabIndex={0}
                      >
                        {student.historyItem?.concepStudyVideo
                          ? (
                              student.historyItem?.concepStudyVideo / 60000
                            ).toFixed(1)
                          : 0}
                        분
                      </Box>
                      <Box
                        aria-label={`개념학습 설명을 ${
                          student.historyItem?.concepStudyExplain
                            ? '읽었습니다.'
                            : '읽지 않았습니다.'
                        }`}
                        component="td"
                        onClick={() => {
                          fetchConceptVideos({ uuid: student.uuid }).then(
                            () => {
                              toggleConceptVideoDialog(true)();
                            },
                          );
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            fetchConceptVideos({ uuid: student.uuid }).then(
                              () => {
                                toggleConceptVideoDialog(true)();
                              },
                            );
                          }
                        }}
                        sx={theme => ({
                          ...customStyle(theme),
                          color:
                            student.historyItem?.concepStudyExplain === 0
                              ? '#FF0000'
                              : '#000',
                          position: 'relative',
                        })}
                        tabIndex={0}
                      >
                        <span className="absolute flex w-2 h-2 top-0.5 right-0.5">
                          <span className="absolute inline-flex w-full h-full rounded-full opacity-75 animate-ping bg-sky-400"></span>
                          <span className="relative inline-flex w-2 h-2 rounded-full bg-sky-500"></span>
                        </span>
                        {student.historyItem?.concepStudyExplain === 0
                          ? `미완료`
                          : `${student.historyItem?.concepStudyExplain}개 등록`}
                      </Box>
                      <GenerateStudyCell
                        incorrectNum={student.historyItem?.numIncorrectBasic}
                        korString="기본문제"
                        totalNum={student.historyItem?.numBasic}
                        type="BASIC"
                        uuid={student.uuid}
                      />
                      <GenerateStudyCell
                        incorrectNum={student.historyItem?.numIncorrectConfirm}
                        korString="확인 문제"
                        totalNum={student.historyItem?.numConfirm}
                        type="CONFIRM"
                        uuid={student.uuid}
                      />
                      <GenerateStudyCell
                        incorrectNum={student.historyItem?.numIncorrectFeedback}
                        korString="피드백 문제"
                        totalNum={student.historyItem?.numFeedback}
                        type="FEEDBACK"
                        uuid={student.uuid}
                      />
                      <GenerateStudyCell
                        incorrectNum={student.historyItem?.metacognitionMiss}
                        korNotCompleteString="데이터 없음"
                        korString="메타인지 문제"
                        totalNum={student.historyItem?.metacognition}
                        type="METACOGNITION"
                        uuid={student.uuid}
                      />
                      <Box
                        aria-label={`학습에 참여한 시간은 ${student.historyItem?.studyParticipate}분입니다.`}
                        component="td"
                        sx={theme => ({
                          ...customStyle(theme),
                          color: '#000',
                        })}
                        tabIndex={0}
                      >
                        {student.historyItem?.studyParticipate}
                      </Box>
                      <Box
                        aria-label={`학습에 참여한 횟수는 ${student.historyItem?.numStudyParticipate}번입니다.`}
                        component="td"
                        sx={theme => ({
                          ...customStyle(theme),
                          color: '#000',
                        })}
                        tabIndex={0}
                      >
                        {student.historyItem?.numStudyParticipate}
                      </Box>
                      <GenerateAssignmentCell student={student} />
                    </tr>
                  );
                })}

              {/* 과제선택 */}
              <tr>
                <Box
                  colSpan={2}
                  component="td"
                  sx={theme => ({
                    backgroundColor: '#E6E2FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.8rem',
                    fontWeight: 700,
                    height: '2rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.5625rem',
                    },
                  })}
                >
                  {t('teacher_dashboard.task_selection')}
                </Box>
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <Box
                      component="td"
                      key={index}
                      sx={theme => ({
                        ...customStyle(theme),
                        backgroundColor: '#F9F3FF',
                      })}
                    >
                      -
                    </Box>
                  ))}
                <Box
                  component="td"
                  paddingX=".3125rem"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  <Button
                    aria-label="기본문제 과제 선택 버튼입니다."
                    disabled={!canCreateAssignmentQuery.data}
                    onClick={() => {
                      setTypes(prev => {
                        if (prev.includes('BASIC')) {
                          return prev.filter(type => type !== 'BASIC');
                        } else {
                          return [...prev, 'BASIC'];
                        }
                      });
                    }}
                    onKeyDown={e => {
                      e.preventDefault();
                      if (e.key === 'Enter') {
                        setTypes(prev => {
                          if (prev.includes('BASIC')) {
                            return prev.filter(type => type !== 'BASIC');
                          } else {
                            return [...prev, 'BASIC'];
                          }
                        });
                      }
                    }}
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: types.includes('BASIC')
                          ? '#1A48A9'
                          : '#CEE2FA',
                      },
                      backgroundColor: types.includes('BASIC')
                        ? '#1A48A9'
                        : '#CEE2FA',
                      border: types.includes('BASIC')
                        ? 'none'
                        : '1px solid #6E92E1',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      color: types.includes('BASIC') ? 'white' : '#1A48A9',
                      fontSize: '.8rem',
                      fontWeight: 700,
                      height: '1.8rem',
                      minWidth: '3.9rem',
                      padding: 0,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                      width: '2.5rem',
                    })}
                    variant={types.includes('BASIC') ? 'contained' : 'outlined'}
                  >
                    선택
                  </Button>
                </Box>
                <Box
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  -
                </Box>
                <Box
                  component="td"
                  paddingX=".3125rem"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  <Button
                    aria-label="확인문제 과제 선택 버튼입니다."
                    disabled={!canCreateAssignmentQuery.data}
                    onClick={() => {
                      setTypes(prev => {
                        if (prev.includes('CONFIRM')) {
                          return prev.filter(type => type !== 'CONFIRM');
                        } else {
                          return [...prev, 'CONFIRM'];
                        }
                      });
                    }}
                    onKeyDown={e => {
                      e.preventDefault();
                      if (e.key === 'Enter') {
                        setTypes(prev => {
                          if (prev.includes('CONFIRM')) {
                            return prev.filter(type => type !== 'CONFIRM');
                          } else {
                            return [...prev, 'CONFIRM'];
                          }
                        });
                      }
                    }}
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: types.includes('CONFIRM')
                          ? '#1A48A9'
                          : '#CEE2FA',
                      },
                      backgroundColor: types.includes('CONFIRM')
                        ? '#1A48A9'
                        : '#CEE2FA',
                      border: types.includes('CONFIRM')
                        ? 'none'
                        : '1px solid #6E92E1',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      color: types.includes('CONFIRM') ? 'white' : '#1A48A9',
                      fontSize: '.8rem',
                      fontWeight: 700,
                      height: '1.8rem',
                      minWidth: '3.9rem',
                      padding: 0,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                      width: '2.5rem',
                    })}
                    variant={
                      types.includes('CONFIRM') ? 'contained' : 'outlined'
                    }
                  >
                    선택
                  </Button>
                </Box>
                <Box
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  -
                </Box>
                <Box
                  component="td"
                  paddingX=".3125rem"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  <Button
                    aria-label="피드백 과제 선택 버튼입니다."
                    disabled={!canCreateAssignmentQuery.data}
                    onClick={() => {
                      setTypes(prev => {
                        if (prev.includes('FEEDBACK')) {
                          return prev.filter(type => type !== 'FEEDBACK');
                        } else {
                          return [...prev, 'FEEDBACK'];
                        }
                      });
                    }}
                    onKeyDown={e => {
                      e.preventDefault();
                      if (e.key === 'Enter') {
                        setTypes(prev => {
                          if (prev.includes('FEEDBACK')) {
                            return prev.filter(type => type !== 'FEEDBACK');
                          } else {
                            return [...prev, 'FEEDBACK'];
                          }
                        });
                      }
                    }}
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: types.includes('FEEDBACK')
                          ? '#1A48A9'
                          : '#CEE2FA',
                      },
                      backgroundColor: types.includes('FEEDBACK')
                        ? '#1A48A9'
                        : '#CEE2FA',
                      border: types.includes('FEEDBACK')
                        ? 'none'
                        : '1px solid #6E92E1',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      color: types.includes('FEEDBACK') ? 'white' : '#1A48A9',
                      fontSize: '.8rem',
                      fontWeight: 700,
                      height: '1.8rem',
                      minWidth: '3.9rem',
                      padding: 0,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                      width: '2.5rem',
                    })}
                    variant={
                      types.includes('FEEDBACK') ? 'contained' : 'outlined'
                    }
                  >
                    선택
                  </Button>
                </Box>
                <Box
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  -
                </Box>
                <Box
                  component="td"
                  paddingX=".3125rem"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  <Button
                    aria-label="메타인지 과제 선택 버튼입니다."
                    disabled={!canCreateAssignmentQuery.data}
                    onClick={() => {
                      setTypes(prev => {
                        if (prev.includes('METACOGNITION')) {
                          return prev.filter(type => type !== 'METACOGNITION');
                        } else {
                          return [...prev, 'METACOGNITION'];
                        }
                      });
                    }}
                    onKeyDown={e => {
                      e.preventDefault();
                      if (e.key === 'Enter') {
                        setTypes(prev => {
                          if (prev.includes('METACOGNITION')) {
                            return prev.filter(
                              type => type !== 'METACOGNITION',
                            );
                          } else {
                            return [...prev, 'METACOGNITION'];
                          }
                        });
                      }
                    }}
                    sx={theme => ({
                      '&:hover': {
                        backgroundColor: types.includes('METACOGNITION')
                          ? '#1A48A9'
                          : '#CEE2FA',
                      },
                      backgroundColor: types.includes('METACOGNITION')
                        ? '#1A48A9'
                        : '#CEE2FA',
                      border: types.includes('METACOGNITION')
                        ? 'none'
                        : '1px solid #6E92E1',
                      borderRadius: '5px',
                      boxShadow: 'none',
                      color: types.includes('METACOGNITION')
                        ? 'white'
                        : '#1A48A9',
                      fontSize: '.8rem',
                      fontWeight: 700,
                      height: '1.8rem',
                      minWidth: '3.9rem',
                      padding: 0,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                      width: '2.5rem',
                    })}
                    variant={
                      types.includes('METACOGNITION') ? 'contained' : 'outlined'
                    }
                  >
                    선택
                  </Button>
                </Box>
                {Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <Box
                      component="td"
                      key={index}
                      sx={theme => ({
                        ...customStyle(theme),
                        backgroundColor: '#F9F3FF',
                      })}
                    ></Box>
                  ))}
                <Box
                  component="td"
                  paddingX=".3125rem"
                  sx={theme => ({
                    ...customStyle(theme),
                    backgroundColor: '#F9F3FF',
                  })}
                >
                  <Tooltip
                    arrow={true}
                    open={true}
                    placement="bottom-end"
                    title={
                      !canCreateAssignmentQuery.data
                        ? '아직 과제를 덜 푼 학생에게 알람을 보내보세요!'
                        : null
                    }
                  >
                    <Button
                      aria-label="알람 버튼입니다."
                      disabled={
                        !checkAssignmentSubmissionQuery.data?.data?.filter(
                          student => student.assignment_finish.length === 0,
                        )
                      }
                      onClick={() => {
                        if (
                          !checkAssignmentSubmissionQuery.data?.data?.filter(
                            student => student.assignment_finish.length === 0,
                          )
                        )
                          return;

                        sendAssignmentReminderMutation.mutate({
                          data: {
                            learning_sys_id: Number(subsectionId),
                            taker_uuids:
                              checkAssignmentSubmissionQuery.data?.data
                                ?.filter(
                                  student =>
                                    student.assignment_finish.length === 0,
                                )
                                .map(student => student.uuid),
                          },
                          headers: {
                            role: user.user_type,
                            uuid: user.user_id,
                          },
                        });
                      }}
                      onKeyDown={e => {
                        if (
                          !checkAssignmentSubmissionQuery.data?.data?.filter(
                            student => student.assignment_finish.length === 0,
                          )
                        )
                          return;

                        if (e.key === 'Enter') {
                          sendAssignmentReminderMutation.mutate({
                            data: {
                              learning_sys_id: Number(subsectionId),
                              taker_uuids:
                                checkAssignmentSubmissionQuery.data?.data
                                  ?.filter(
                                    student =>
                                      student.assignment_finish.length === 0,
                                  )
                                  .map(student => student.uuid),
                            },
                            headers: {
                              role: user.user_type,

                              uuid: user.user_id,
                            },
                          });
                        }
                      }}
                      sx={theme => ({
                        '&:hover': {
                          backgroundColor: '#1A48A9',
                        },
                        backgroundColor: '#1A48A9',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        color: '#FFF',
                        display: canCreateAssignmentQuery.data
                          ? 'none'
                          : 'block',
                        fontSize: '.8rem',
                        fontWeight: 700,
                        height: '1.8rem',
                        minWidth: '3.9rem',
                        padding: 0,
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.25rem',
                        },
                        width: '2.5rem',
                      })}
                      variant="contained"
                    >
                      알람
                    </Button>
                  </Tooltip>
                </Box>
              </tr>

              {/* 과제출제 */}
              <tr>
                <Box
                  colSpan={2}
                  component="td"
                  sx={theme => ({
                    backgroundColor: '#E6E2FC',
                    border: '1px solid #BFC7E3',
                    fontSize: '.8rem',
                    fontWeight: 700,
                    height: '2.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.5625rem',
                    },
                  })}
                >
                  {t('teacher_dashboard.task_assignment')}
                </Box>

                <td
                  colSpan={16}
                  style={{
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                  }}
                >
                  {!canCreateAssignmentQuery.data ? (
                    <>
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="center"
                      >
                        <Typography
                          fontSize=".9375rem"
                          fontWeight={500}
                          marginRight=".75rem"
                        >
                          이미 과제를 출제하셨습니다.
                        </Typography>
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="end"
                      >
                        <Typography
                          fontSize=".9375rem"
                          fontWeight={500}
                          marginRight=".75rem"
                        >
                          {types.length > 0 ? (
                            <span>
                              선택된{' '}
                              <span className="text-red-500 font-bold">
                                {types.length}개 항목에서
                              </span>{' '}
                              학생 본인이 틀린 문제의 유사 문제를
                            </span>
                          ) : (
                            `먼저 위에서 과제를 낼 학습 항목을 선택해주세요.`
                          )}
                        </Typography>
                        <FormControl size="small" sx={{ m: 1, minWidth: 180 }}>
                          <InputLabel id="factor-selector">
                            과제 출제 문항 수
                          </InputLabel>
                          <Select
                            labelId="factor-selector"
                            onChange={(e: SelectChangeEvent) => {
                              setFactor(e.target.value);
                            }}
                            value={factor}
                          >
                            <MenuItem value={1}>각각 1문제씩</MenuItem>
                            <MenuItem value={2}>각각 2문제씩</MenuItem>
                            <MenuItem value={3}>각각 3문제씩</MenuItem>
                            <MenuItem value={4}>각각 4문제씩</MenuItem>
                            <MenuItem value={5}>각각 5문제씩</MenuItem>
                          </Select>
                        </FormControl>

                        <Button
                          disabled={
                            factor === '' ||
                            types.length === 0 ||
                            !canCreateAssignmentQuery.data
                          }
                          onClick={() => {
                            const classUuids =
                              teacherClassMembersQuery.data?.map(
                                member => member.user_id,
                              ) as string[];

                            if ((classUuids?.length || 0) === 0) {
                              alert(
                                '아직 이 소단원 학습을 끝낸 학생이 없습니다.',
                              );
                              return;
                            }
                            toggleDialog(DialogKeys.CreateAssignment, true)();
                          }}
                          sx={theme => ({
                            '&:hover': {
                              backgroundColor: '#1A48A9',
                            },
                            backgroundColor: '#1A48A9',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            color: '#FFF',
                            fontSize: '.8rem',
                            fontWeight: 700,
                            height: '2.2rem',
                            marginRight: '.6rem',
                            minWidth: '2.5rem',
                            padding: 0,
                            paddingX: '.9rem',
                            [theme.breakpoints.down('md')]: {
                              fontSize: '.625rem',
                              height: '1.25rem',
                            },
                          })}
                          variant="contained"
                        >
                          과제출제
                        </Button>
                      </Stack>
                    </>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
      <CreateAssignmentDialog onSubmit={onSubmit} />
      <SubmitAssignmentDialog />
      <ReminderDialog />
      <ConceptVideoDialog />
      <ProblemResultDialog />
    </Stack>
  );
}
