import { Container, Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import UnitContents from 'app/components/Chapter/Main/UnitContents';
import UnitItem from 'app/components/Chapter/Main/UnitItem';
import { LoadingDOM } from 'app/components/Common/LoadingDOM';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import { LucideFolderInput, LucideList } from 'lucide-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContentTable, SemesterBookmarker } from 'schemas/learning';
import {
  useGetSemesterBookmarkerQuery,
  useGetTeacherBookMarkerQuery,
} from 'services/learning';
import { RootState } from 'types';

import { Layout } from '../../components/Layout';
import StudyOrderInfo from './sub/StudyOrderInfo';

export function HomePage() {
  const navigate = useNavigate();
  const [aboutOpen, setAboutOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [indexList, setIndexList] = useState<ContentTable[]>([]);
  const [currentUnitId, setCurrentUnitId] = useState<number>(0);
  const { t } = useTranslation();

  const userId = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );

  const { data: studentBookMarkRes } = useGetSemesterBookmarkerQuery(
    {
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
    },
    {
      skip: !classInfo || !userType || userType === 'T' || !userId,
    },
  );

  const { data: teacherBookMarkRes } = useGetTeacherBookMarkerQuery(
    {
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
    },
    {
      skip: !classInfo || !userType || userType === 'S' || !userId,
    },
  );

  useEffect(() => {
    const isVisited = localStorage.getItem('isVisited');
    if (!isVisited) {
      localStorage.setItem('isVisited', 'true');
      setAboutOpen(true);
    }
  }, []);

  useEffect(() => {
    if (contentTables) {
      setIndexList(contentTables);
    }
  }, [contentTables]);

  useEffect(() => {
    if (!studentBookMarkRes || !studentBookMarkRes.data || !indexList) return;
    const updatedData = updateStudentCheckedStatus(
      indexList,
      studentBookMarkRes.data,
    );
    if (JSON.stringify(updatedData) !== JSON.stringify(indexList)) {
      setIndexList(updatedData);
    }
    setLoading(false);
  }, [studentBookMarkRes, indexList]);

  useEffect(() => {
    if (indexList.length > 0 && teacherBookMarkRes?.data) setLoading(false);
  }, [teacherBookMarkRes, indexList]);

  const updateStudentCheckedStatus = (
    units: ContentTable[],
    statusData: SemesterBookmarker[],
  ): ContentTable[] => {
    const updatedUnits = units.map(unit => ({
      ...unit,
      chapter: unit.chapter.map(chapter => ({
        ...chapter,
        section: chapter.section.map(section => {
          const status = statusData.find(
            s => s.learningSysId === section.learning_sys_id,
          );
          const updatedStatus = {
            checked: status?.status === 'DONE',
            pending: status?.status === 'PENDING',
          };
          // const allChecked =
          //   updatedSubsections.length > 0 &&
          //   updatedSubsections.every(subsection => subsection.checked);
          const updated = {
            ...section,
            ...updatedStatus,
          };
          return updated;
        }),
      })),
    }));

    return updatedUnits;
  };

  const handleClickAboutOpen = () => {
    setAboutOpen(true);
  };

  const handleAboutClose = () => {
    setAboutOpen(false);
  };

  const Transition = React.forwardRef(function Transition(
    props: {
      children: React.ReactElement;
    } & TransitionProps,
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  // component
  const AboutAiDigitalTextbook = () => {
    const { t } = useTranslation();
    return (
      <Dialog
        PaperProps={{
          elevation: 0,
        }}
        TransitionComponent={Transition}
        fullScreen
        onClose={handleAboutClose}
        open={aboutOpen}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'initial',
          },
          marginTop: '80px',
        }}
      >
        <SecondaryHeader
          handleRightButtonClicked={handleAboutClose}
          rightButtonLabel={t('about.close')}
          rightButtonText={t('about.close')}
          title={t('nav.guide_order')}
          titleIcon={<LucideFolderInput size={24} strokeWidth={3} />}
        />
        <StudyOrderInfo />
      </Dialog>
    );
  };

  return (
    <Layout isDisableGutters={true} width={false}>
      <AboutAiDigitalTextbook />
      <SecondaryHeader
        handleRightButtonClicked={() => {
          handleClickAboutOpen();
        }}
        rightButtonLabel={'학습순서 안내'}
        rightButtonText="학습순서 안내"
        title={t('home.table_of_contacts')}
        titleIcon={<LucideList size={24} strokeWidth={3} />}
      />
      <Container
        disableGutters={true}
        sx={{ marginTop: '30px', paddingX: '1.5rem' }}
      >
        {loading ? (
          <LoadingDOM />
        ) : (
          <section className="flex items-start justify-between gap-4">
            <div className="w-[400px] space-y-4">
              {indexList.map((unit, unitIdx) => (
                <UnitItem
                  currentUnitId={currentUnitId}
                  key={`UNIT_ITEM_${unitIdx}`}
                  setCurrentUnitId={setCurrentUnitId}
                  unit={unit}
                  unitIdx={unitIdx}
                />
              ))}
            </div>
            <div className="flex-grow bg-[#EDF5FF] border border-[#CEE2FA] p-3 rounded-xl">
              <div className="border border-dashed border-[#CEE2FA] bg-white pb-7 px-10 pt-5 rounded-xl">
                <UnitContents
                  indexList={indexList}
                  setIndexList={setIndexList}
                  unit={indexList[currentUnitId]}
                  unitIdx={currentUnitId}
                />
              </div>
            </div>
          </section>
        )}
      </Container>
    </Layout>
  );
}
