/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const QuestionsPage = lazyLoad(
  () => import('./index'),
  module => module.QuestionsPage,
);
