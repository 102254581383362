import { Stack, Typography } from '@mui/material';
import React from 'react';
import { cn } from 'utils/cn';

interface Props {
  backgroundColor?: string;
  handleRightButtonClicked?: () => void;
  handleRightButtonKeyPressed?: (e: React.KeyboardEvent) => void;
  leftAlign?: boolean;
  rightButtonLabel?: string;
  rightButtonStyle?: string;
  rightButtonText?: string;
  title: string;
  titleColor?: string;
  titleIcon?: React.ReactNode;
}

export default function SecondaryHeader({
  backgroundColor = '#EBF2FE',
  handleRightButtonClicked,
  handleRightButtonKeyPressed,
  leftAlign = false,
  rightButtonLabel,
  rightButtonStyle = 'border-[#4F73C0] text-[#4F73C0]',
  rightButtonText,
  title,
  titleColor = 'black',
  titleIcon,
}: Props) {
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      justifyContent={'space-between'}
      sx={{
        backgroundColor,
        padding: '12px 0',
        position: 'relative',
        width: '100%',
      }}
    >
      <div
        className={
          (leftAlign ? '' : '') +
          ' container mx-auto flex items-center justify-between'
        }
      >
        {leftAlign !== true && <div className="w-1/5"></div>}
        <div className="flex items-center justify-center gap-4">
          {titleIcon && (
            <div className="flex items-center justify-center p-2 text-xl bg-white rounded-full">
              {titleIcon}
            </div>
          )}
          <Typography
            aria-label={title}
            component={'h1'}
            sx={{
              color: titleColor,
              fontSize: '1.5rem',
              fontWeight: '700',
            }}
            tabIndex={0}
            variant={'h4'}
          >
            {title}
          </Typography>
        </div>
        <div className="flex items-center justify-end w-1/5">
          {(rightButtonLabel || rightButtonText) && (
            <button
              aria-label={rightButtonLabel ?? rightButtonText}
              className={cn(
                'bg-white border rounded-md px-4 py-2 ',
                rightButtonStyle,
              )}
              onClick={handleRightButtonClicked}
              onKeyDown={handleRightButtonKeyPressed}
            >
              {rightButtonText}
            </button>
          )}
        </div>

        {/* 
          handleClickAboutOpen
          {isDiagnostic && (
            <Button
              aria-label={t('home.educational_assessment')}
              onClick={() => {
                dispatch(init());
                dispatch(setType('diagnostic'));
                navigate('/evaluation');
              }}
              sx={customStyleButton}
              tabIndex={0}
              variant="contained"
            >
              {t('home.educational_assessment')}
            </Button>
          )} */}
      </div>
    </Stack>
  );
}
