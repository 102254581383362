import { Box, Stack, Typography } from '@mui/material';
import { useCumulativeData } from 'queries/use-cumulative-data';
import { useStudentClassMembers } from 'queries/use-student-class-members';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types';

export default function CumulativeDataTable() {
  const { t } = useTranslation();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 학급 구성원 조회
  const studentClassMembersQuery = useStudentClassMembers({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 누적 데이터 조회
  const cumulativeDataQuery = useCumulativeData({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      // classUuids: studentClassMembersQuery.data
      //   ? studentClassMembersQuery.data?.map(member => member.user_id)
      //   : [user.user_id],
      classUuids: [user.user_id],
    },
  });

  // 테이블 데이터
  const headers = [
    'student_dashboard.problem_solved',
    'common.accuracy_rate',
    'student_dashboard.qna',
    'student_dashboard.like_and_comments',
  ];

  return (
    <Stack
      aria-label="누적 데이터 테이블: 풀이수, 정답률, 질의응답, 좋아요댓글의 각 항목별로 개인과 평균 데이터를 나타냅니다."
      borderRadius=".5rem"
      component="article"
      gridColumn="span 1"
      padding=".1875rem"
      sx={theme => ({
        backgroundColor: '#CEE2FA',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        [theme.breakpoints.down('md')]: {
          height: '6.5rem',
        },
      })}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".375rem"
        height="2rem"
        paddingLeft={0.5}
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            height: '1.5rem',
          },
        })}
      >
        <Box
          alt="누적데이터 아이콘"
          component="img"
          height={18}
          src="/icons/ic_graph.svg"
          sx={theme => ({
            [theme.breakpoints.down('md')]: { height: 14, width: 14 },
          })}
          width={18}
        />
        <Typography
          fontSize=".9375rem"
          fontWeight="500"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.625rem',
            },
          })}
          variant="subtitle1"
        >
          {t('student_dashboard.cumulation_data')}
        </Typography>
      </Stack>

      <Stack
        border="3px solid #BFC7E3"
        borderRadius=".375rem"
        flex={1}
        overflow="hidden"
        sx={{ backgroundColor: '#BFC7E3' }}
      >
        <Box borderRadius=".3125rem" flex={1} overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              flex: 1,
              height: '100%',
              tableLayout: 'fixed',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              {t('student_dashboard.cumulation_data')}
            </caption>
            <thead style={{ height: '100%' }}>
              <tr
                style={{
                  backgroundColor: '#CFD5EA',
                }}
              >
                {headers.map((header, index) => (
                  <Box
                    colSpan={2}
                    component="th"
                    key={index}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.75rem',
                      fontWeight: 500,
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                      },
                    })}
                  >
                    {t(header)}
                  </Box>
                ))}
              </tr>
            </thead>
            <thead style={{ height: '100%' }}>
              <tr>
                {headers.map((_, index) => (
                  <React.Fragment key={index}>
                    <Box
                      component="th"
                      scope="col"
                      sx={theme => ({
                        backgroundColor: '#E9EBF5',
                        border: '1px solid #BFC7E3',
                        flex: 1,
                        fontSize: '.75rem',
                        fontWeight: '400',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                    >
                      {t('common.me')}
                    </Box>
                    <Box
                      component="th"
                      scope="col"
                      sx={theme => ({
                        backgroundColor: '#E9EBF5',
                        border: '1px solid #BFC7E3',
                        flex: 1,
                        fontSize: '.75rem',
                        fontWeight: 400,
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                        },
                      })}
                    >
                      반평균
                    </Box>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody style={{ height: '100%' }}>
              <tr>
                <Box
                  aria-label={`나의 풀이수는 ${
                    cumulativeDataQuery.data?.userSolved ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.userSolved ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 풀이수는 ${
                    cumulativeDataQuery.data?.classSolved?.toFixed(1) ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.classSolved?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`나의 정답률은 ${
                    cumulativeDataQuery.data?.userCorrectRate?.toFixed(1) ?? '0'
                  }%입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.userCorrectRate?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 정답률은 ${
                    cumulativeDataQuery.data?.classCorrectRate?.toFixed(1) ??
                    '0'
                  }%입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.classCorrectRate?.toFixed(1) ??
                    '0'}
                </Box>
                <Box
                  aria-label={`나의 질의응답수는 ${
                    cumulativeDataQuery.data?.userQnA ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.userQnA ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 질의응답수는 ${
                    cumulativeDataQuery.data?.classQnA?.toFixed(1) ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.classQnA?.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`나의 좋아요댓글수는 ${
                    cumulativeDataQuery.data?.userCommentAndLike ?? '0'
                  }개입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.userCommentAndLike ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 좋아요댓글수는 ${
                    cumulativeDataQuery.data?.userCommentAndLike?.toFixed(1) ??
                    '0'
                  }개입니다.`}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.75rem',
                    fontWeight: 400,
                    textAlign: 'center',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                  tabIndex={0}
                >
                  {cumulativeDataQuery.data?.classCommentAndLike?.toFixed(1) ??
                    '0'}
                </Box>
              </tr>
            </tbody>
          </table>
        </Box>
      </Stack>
    </Stack>
  );
}
