import { Box, Stack, Typography } from '@mui/material';
import { ArrowRightIcon, DotIcon } from 'app/components/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export default function LearningStageConfig() {
  const { t } = useTranslation();

  return (
    <Stack
      aria-label="학습단계 설정기준입니다."
      borderRadius=".5rem"
      sx={{
        backgroundColor: '#E0FAEC',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".3125rem"
        px="1.25rem"
        py=".375rem"
      >
        <Box
          alt="학습단계 설정기준 아이콘"
          component="img"
          height={36}
          src={'/icons/system_icon.svg'}
          width={36}
        />
        <Typography
          component="h3"
          fontSize="1.3125rem"
          fontWeight={500}
          variant="h3"
        >
          {t('step.criteria_for_setting_learning_stage')}
        </Typography>
      </Stack>

      <Stack
        gap=".625rem"
        mb=".375rem"
        mx=".375rem"
        px="1.5rem"
        py=".875rem"
        sx={{
          backgroundColor: '#FFF',
          borderBottomLeftRadius: '.5rem',
          borderBottomRightRadius: '.5rem',
        }}
      >
        <Stack alignItems="center" direction="row">
          <Box
            alt="최초 학습단계 설정 아이콘"
            component="img"
            height={24}
            mr=".5rem"
            src="/icons/folder_managed.svg"
            width={24}
          />
          <Typography fontSize="1.125rem" fontWeight={500}>
            {t('step.initial_learning_stage_setting')}
          </Typography>

          <Stack alignItems="center" direction="row">
            <DotIcon />
            <Typography fontSize=".9375rem" fontWeight={400}>
              {t('step.initial_learning_stage_setting_desc')}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row">
          <Box height={26}>
            <Box
              alt="이후는 소단원 학습결과로 자동설정 아이콘"
              component="img"
              height={24}
              mr=".5rem"
              src="/icons/folder_managed.svg"
              width={24}
            />
          </Box>
          <Stack gap=".5rem">
            <Box height={26}>
              <Typography fontSize="1.125rem" fontWeight={500}>
                {t('step.subsequently')}
              </Typography>
            </Box>
            <Stack alignItems="center" direction="row" gap=".5rem">
              <Stack
                alignItems="center"
                border="1px solid #11AEA1"
                borderRadius=".25rem"
                direction="row"
                gap=".375rem"
                height="1.875rem"
                px=".5rem"
                sx={{
                  backgroundColor: '#DEF6E9',
                }}
              >
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_1st_condition')}
                </Typography>
                <ArrowRightIcon
                  sx={{ color: '#1C1B1F', height: '1.5rem', width: '1.5rem' }}
                />
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_1st_outcome')}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                border="1px solid #11AEA1"
                borderRadius=".25rem"
                direction="row"
                gap=".375rem"
                height="1.875rem"
                px=".5rem"
                sx={{
                  backgroundColor: '#DEF6E9',
                }}
              >
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_2nd_condition')}
                </Typography>
                <ArrowRightIcon
                  sx={{ color: '#1C1B1F', height: '1.5rem', width: '1.5rem' }}
                />
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_2nd_outcome')}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                border="1px solid #11AEA1"
                borderRadius=".25rem"
                direction="row"
                gap=".375rem"
                height="1.875rem"
                px=".5rem"
                sx={{
                  backgroundColor: '#DEF6E9',
                }}
              >
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_3rd_condition')}
                </Typography>
                <ArrowRightIcon
                  sx={{ color: '#1C1B1F', height: '1.5rem', width: '1.5rem' }}
                />
                <Typography fontSize="1.125rem" fontWeight={400}>
                  {t('step.subsequently_3rd_outcome')}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack alignItems="center" direction="row">
          <Box
            alt="최초 학습단계 설정 아이콘"
            component="img"
            height={24}
            mr=".5rem"
            src="/icons/folder_managed.svg"
            width={24}
          />
          <Typography fontSize="1.125rem" fontWeight={500}>
            {t('step.finally')}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
