import { useQuery } from '@tanstack/react-query';
import { getArticle } from 'apis/announcement';
import { ReqGetArticle, RespGetArticle } from 'schemas/announcement';

export function useAnnouncementGetArticle({ headers, params }: ReqGetArticle) {
  return useQuery<RespGetArticle, Error>({
    enabled: params.id !== undefined,
    queryFn: () => getArticle({ headers, params }),
    queryKey: ['announcement_get_article', { headers, params }],
  });
}
