import { useQuery } from '@tanstack/react-query';
import { getAccumulatedDreamJobs } from 'apis/user';
import {
  AccumulatedDreamJobs,
  GetAccumulatedDreamJobsDto,
  GetAccumulatedDreamJobsResponse,
} from 'schemas/user';

export function useAccumulatedDreamJobs({
  headers,
}: GetAccumulatedDreamJobsDto) {
  return useQuery<GetAccumulatedDreamJobsResponse, Error, AccumulatedDreamJobs>(
    {
      queryFn: () => getAccumulatedDreamJobs({ headers }),
      queryKey: ['accumulated_dream_jobs', { headers }],
      select: data => data.data.dream_jobs,
    },
  );
}
