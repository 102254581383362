import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  AssignmentSolveRes,
  CheckAssignmentDto,
  CheckAssignmentResponse,
  CreateAssignmentDto,
  GetAssignmentProblemsResultDto,
  GetAssignmentProblemsResultResponse,
  GetAssignmentResultDto,
  GetAssignmentResultResponse,
  GetAssignmentSolveDto,
  PostAssignmentFinishReqDto,
  PostAssignmentFinishRes,
  PostAssignmentSubmitReqDto,
  PostAssignmentSubmitRes,
} from 'schemas/assignment';
import { axiosBaseQuery } from 'services/api';

export const assignmentService = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: '/assignment',
  }),
  endpoints: build => ({
    // 교사 참여학습 과제 제출
    createAssignmentById: build.mutation<string, CreateAssignmentDto>({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/gave',
      }),
    }),
    // 과제 유무 조회
    getAssignmentExist: build.query<
      CheckAssignmentResponse,
      CheckAssignmentDto
    >({
      providesTags: ['Assignment'],
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/exist',
      }),
    }),
    // 과제 결과 문제 조회
    getAssignmentProblemsResult: build.query<
      GetAssignmentProblemsResultResponse,
      GetAssignmentProblemsResultDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/problems-result',
      }),
    }),
    // 과제 결과 조회
    getAssignmentResult: build.query<
      GetAssignmentResultResponse,
      GetAssignmentResultDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/result',
      }),
    }),
    // 학생 과제 조회
    getAssignmentSolve: build.query<AssignmentSolveRes, GetAssignmentSolveDto>({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/solve',
      }),
    }),
    postAssignmentFinish: build.mutation<
      PostAssignmentFinishRes,
      PostAssignmentFinishReqDto
    >({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/finish',
      }),
    }),
    //학생 과제 문제 풀이 제출
    postAssignmentSubmit: build.mutation<
      PostAssignmentSubmitRes,
      PostAssignmentSubmitReqDto
    >({
      query: ({ data, headers }) => ({
        data,
        headers,
        method: 'POST',
        url: '/submit',
      }),
    }),
  }),
  reducerPath: 'assignmentApi',
  tagTypes: ['Assignment'],
});

export const {
  useCreateAssignmentByIdMutation,
  useGetAssignmentExistQuery,
  useGetAssignmentProblemsResultQuery,
  useGetAssignmentResultQuery,
  useLazyGetAssignmentSolveQuery,
  usePostAssignmentFinishMutation,
  usePostAssignmentSubmitMutation,
} = assignmentService;
