import { Button, Typography } from '@mui/material';
import * as React from 'react';

interface IconButtonProps {
  ariaLabel?: string;
  buttonStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  key?: string;
  name: number | string;
  onClick?: () => void;
  src: string;
  textStyle?: React.CSSProperties;
}

export function TextButton({
  ariaLabel,
  buttonStyle,
  imageStyle,
  key,
  name,
  onClick,
  src,
  textStyle,
}: IconButtonProps) {
  const defaultButtonStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 'initial',
  };
  const defaultImageStyle = {
    height: '29px',
    width: '29px',
  };
  const defaultTextStyle = {
    color: '#333',
    fontSize: '.75rem',
    lineHeight: 'inherit',
  };

  return (
    <Button
      color={'primary'}
      key={key}
      onClick={onClick}
      sx={{ ...defaultButtonStyle, ...buttonStyle }}
      {...(ariaLabel && { 'aria-label': ariaLabel })}
    >
      <img
        alt={typeof name === 'string' ? name : 'icon'} // TODO : ALT 어떻게 처리할 지 고민중
        src={`/icons/${src}.svg`}
        style={{ ...defaultImageStyle, ...imageStyle }}
      />
      <Typography
        component={'span'}
        sx={{ ...defaultTextStyle, ...textStyle }}
        variant={'subtitle2'}
      >
        {name}
      </Typography>
    </Button>
  );
}
