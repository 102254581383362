import { Box, Button, Stack, Typography } from '@mui/material';
import ConceptVideoDialog from 'app/components/Dashboard/ConceptVideoDialog';
import LearningTimeHistoryTable from 'app/components/Dashboard/LearningTimeHistoryTable';
import { setType } from 'app/pages/AssessmentDiagnosis/evaluationSlice';
import {
  LucideArrowDownCircle,
  LucideArrowUpCircle,
  LucidePencilRuler,
} from 'lucide-react';
import { useCheckAssignment } from 'queries/assignment/use-check-assignment';
import { useLearningHistory } from 'queries/use-learning-history';
import { useLearningSystem } from 'queries/use-learning-system';
import { useStudent } from 'queries/use-student';
import { useStudentClassMembers } from 'queries/use-student-class-members';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useConceptVideoDialogStore from 'stores/use-concept-video-dialog-store';
import { RootState } from 'types';
import { DialogData } from 'types/DialogData';

interface Props {
  handleDialogOpen: () => void;
  setDialogData: React.Dispatch<React.SetStateAction<DialogData>>;
}

export default function LearningHistoryTable({
  handleDialogOpen,
  setDialogData,
}: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 유저 정보
  const user = useSelector((state: RootState) => state.user);
  const partnerId = useSelector((state: RootState) => state.user.partnerId);

  // 학생 정보 조회
  const studentQuery = useStudent({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 학급 구성원 조회
  const classMembersQuery = useStudentClassMembers({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': partnerId,
    },
  });

  // 현재 소단원별 학습결과 내역 조회
  const learningHistoryQuery = useLearningHistory({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      // classUuids: classMembersQuery.data
      //   ? classMembersQuery.data?.map(member => member.user_id)
      //   : [user.user_id],
      classUuids: [user.user_id],
      learningSysId: subsectionId ? Number(subsectionId) : undefined,
    },
  });

  // 과제 유무 조회
  const assignmentsQuery = useCheckAssignment({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: subsectionId ? Number(subsectionId) : undefined,
    },
  });

  // 테이블 데이터
  const historyItem = learningHistoryQuery.data?.historyItem;
  const meanHistoryItem = learningHistoryQuery.data?.meanHistoryItem;

  const headers = [
    'common.learning_stage',
    'common.concept_learning',
    'common.basic_problems',
    'common.review_problems',
    'common.feedback',
    'common.additional_problems',
    'common.metacognition',
    'common.learning_participation',
  ];
  const subHeaders = [
    ['common.before', 'common.after'],
    ['common.video', 'common.explanation'],
    ['common.total', 'common.incorrect'],
    ['common.total', 'common.incorrect'],
    ['common.total', 'common.incorrect'],
    ['common.total', 'common.incorrect'],
    ['common.confidence', 'common.incorrect'],
    ['common.time', 'common.frequency'],
  ];

  const customStyle = theme => ({
    backgroundColor: 'white',
    border: '1px solid #BFC7E3',
    fontSize: '.9rem',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      fontSize: '.7rem',
    },
  });

  const DynamicTextCell = ({ num }: { num: number }) => {
    return (
      <Box
        component="td"
        sx={theme => ({
          ...customStyle(theme),
        })}
      >
        <span className={num === 0 ? 'text-green-600' : 'text-red-500'}>
          {num === 0 ? '다맞춤' : num}
        </span>
      </Box>
    );
  };

  return (
    <Stack
      aria-label={`${
        learningSystemQuery.data?.type === 'SECTION' ? '현재' : '대단원 내'
      } 소단원별 학습결과 내역 테이블입니다. 학습결과 내역의 각 항목별로 개인과 반평균 데이터를 나타냅니다. 학습결과 내역에는 학습단계, 개념학습, 기본문제, 확인문제, 피드백, 추가문제, 메타인지, 학습참여 총 8개의 항목이 있습니다.`}
      sx={{
        backgroundColor: '#EBF2FE',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Typography
        fontSize="1.125rem"
        fontWeight="500"
        paddingX="1rem"
        paddingY=".5rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            fontSize: '.9375rem',
            paddingX: '0.1rem',
            paddingY: '.375rem',
          },
        })}
        variant="h6"
      >
        {learningSystemQuery.data?.type === 'SECTION'
          ? t('student_dashboard.current_learning_outcome')
          : '대단원 전체 학습 결과'}
      </Typography>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        <Box borderRadius=".375rem" overflow="hidden">
          <table
            style={{
              backgroundColor: '#BFC7E3',
              borderCollapse: 'collapse',
              height: '100%',
              tableLayout: 'fixed',
              textAlign: 'center',
              width: '100%',
            }}
          >
            <caption style={{ display: 'none' }}>
              {learningSystemQuery.data?.type === 'SECTION'
                ? '현재 소단원별 학습결과 내역 테이블'
                : '대단원 내 소단원별 학습결과 내역 테이블'}
            </caption>
            <tbody>
              <tr
                style={{
                  backgroundColor: '#D0E3FA',
                }}
              >
                <Box
                  colSpan={2}
                  component="th"
                  rowSpan={2}
                  scope="colgroup"
                  sx={theme => ({
                    fontSize: '.9rem',
                    fontWeight: 600,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                    },
                  })}
                >
                  {t('common.learning_history')}
                </Box>
                {headers.map((header, index) => (
                  <Box
                    colSpan={2}
                    component="th"
                    key={index}
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 600,
                      height: '2rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.25rem',
                      },
                    })}
                  >
                    {t(header)}
                  </Box>
                ))}
              </tr>
              <tr>
                {subHeaders.map((subHeader, index) => (
                  <React.Fragment key={index}>
                    {subHeader.map((sub, i) => (
                      <Box
                        component="th"
                        key={i}
                        scope="col"
                        sx={theme => ({
                          backgroundColor: '#EBF2FE',
                          border: '1px solid #BFC7E3',
                          fontSize: '.9rem',
                          fontWeight: 600,
                          height: '2rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                      >
                        {t(sub)}
                      </Box>
                    ))}
                  </React.Fragment>
                ))}
              </tr>

              {/* 결과 */}
              <tr
                aria-label={`${studentQuery.data?.user_name}의 학습결과 내역을 나타내는 행입니다.`}
                tabIndex={0}
              >
                <Box
                  colSpan={2}
                  component="td"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.9rem',
                    fontWeight: 600,
                    height: '2.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.5625rem',
                    },
                  })}
                >
                  {t('student_dashboard.results')}
                </Box>
                <Box
                  aria-label={`학습 전 학습단계는 ${
                    historyItem?.previousAchievementLevel?.toFixed(1) ?? 0
                  }단계입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {historyItem?.previousAchievementLevel?.toFixed(1) ?? '-'}
                </Box>
                <Box
                  aria-label={
                    historyItem?.afterAchievementLevel?.toFixed(1)
                      ? `학습 후 학습단계는 ${
                          historyItem?.afterAchievementLevel?.toFixed(1) ?? 0
                        }입니다.`
                      : '아직 학습을 하지 않아 데이터가 없습니다.'
                  }
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  <span
                    className={
                      (historyItem?.previousAchievementLevel || 0) >
                      (historyItem?.afterAchievementLevel || 0)
                        ? 'text-red-500'
                        : 'text-green-600'
                    }
                  >
                    {historyItem?.afterAchievementLevel?.toFixed(1) ?? '-'}
                    {(historyItem?.previousAchievementLevel || 0) >
                      (historyItem?.afterAchievementLevel || 0) && (
                      <LucideArrowDownCircle
                        className="mb-0.5 pl-1"
                        size={18}
                      />
                    )}
                    {(historyItem?.previousAchievementLevel || 0) <
                      (historyItem?.afterAchievementLevel || 0) && (
                      <LucideArrowUpCircle className="mb-0.5 pl-1" size={18} />
                    )}
                  </span>
                </Box>
                <Box
                  aria-label={`개념학습 영상을 시청한 총 시간은 ${
                    historyItem
                      ? `${(historyItem?.concepStudyVideo / 60000).toFixed(
                          1,
                        )}분`
                      : '0'
                  }분입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {historyItem
                    ? `${(historyItem?.concepStudyVideo / 60000).toFixed(1)}분`
                    : '0'}
                </Box>
                <Box
                  aria-label={`개념학습 설명을 ${historyItem?.concepStudyExplain}개 등록하셨습니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {historyItem?.concepStudyExplain ? (
                    <span className="text-green-600">
                      {historyItem.concepStudyExplain}개
                    </span>
                  ) : (
                    <span className="text-red-500">미완료</span>
                  )}
                </Box>
                <Box
                  aria-label={`전체 기본문제 수 ${
                    historyItem?.numBasic ?? 0
                  }문제중 오답 수는 ${
                    historyItem?.numIncorrectBasic ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.numBasic ?? '-'}
                </Box>
                <DynamicTextCell num={historyItem?.numIncorrectBasic || 0} />
                <Box
                  aria-label={`전체 확인문제 수 ${
                    historyItem?.numConfirm ?? 0
                  }문제중 오답 수는 ${
                    historyItem?.numIncorrectConfirm ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.numConfirm ?? '0'}
                </Box>
                <DynamicTextCell num={historyItem?.numIncorrectConfirm || 0} />
                <Box
                  aria-label={`전체 피드백 문제 수 ${
                    historyItem?.numFeedback ?? 0
                  }문제중 오답 수는 ${
                    historyItem?.numIncorrectFeedback ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.numFeedback ?? '0'}
                </Box>
                <DynamicTextCell num={historyItem?.numIncorrectFeedback || 0} />
                <Box
                  aria-label={`전체 추가문제 수 ${
                    historyItem?.numAdditional ?? 0
                  }문제중 오답 수는 ${
                    historyItem?.numIncorrectAdditional ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.numAdditional ?? '0'}
                </Box>
                <DynamicTextCell
                  num={historyItem?.numIncorrectAdditional || 0}
                />
                <Box
                  aria-label={`전체 메타인지 문제 수 ${
                    historyItem?.metacognition ?? 0
                  }문제중 오답 수는 ${
                    historyItem?.metacognitionMiss ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.metacognition ?? '0'}
                </Box>
                <Box
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                >
                  {historyItem?.metacognitionMiss ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 시간은 총 ${historyItem?.studyParticipate}분입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.studyParticipate ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 횟수는 총 ${historyItem?.numStudyParticipate}번입니다.`}
                  component="td"
                  sx={theme => ({
                    ...customStyle(theme),
                  })}
                  tabIndex={0}
                >
                  {historyItem?.numStudyParticipate ?? ''}
                </Box>
              </tr>

              {/* 반평균 */}
              <tr
                aria-label="반평균 학습결과 내역을 나타내는 행입니다."
                tabIndex={0}
              >
                <Box
                  colSpan={2}
                  component="td"
                  sx={theme => ({
                    backgroundColor: '#EBF2FE',
                    border: '1px solid #BFC7E3',
                    fontSize: '.9rem',
                    fontWeight: 600,
                    height: '2.5rem',
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.5625rem',
                    },
                  })}
                >
                  {t('student_dashboard.class_average')}
                </Box>

                <Box
                  aria-label={`학습 전 평균 학습단계는 ${meanHistoryItem?.meanPreviousAchievementLevel?.toFixed(
                    1,
                  )}단계입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanPreviousAchievementLevel?.toFixed(1) ??
                    '0'}
                </Box>
                <Box
                  aria-label={`학습 후 평균 학습단계는 ${meanHistoryItem?.meanAfterAchievementLevel?.toFixed(
                    1,
                  )}단계입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanAfterAchievementLevel?.toFixed(1) ??
                    '0'}
                </Box>
                <Box
                  aria-label={`개념학습 영상을 시청한 총 평균 시간은 ${
                    meanHistoryItem?.meanConcepStudyVideo
                      ? (meanHistoryItem?.meanConcepStudyVideo / 60000).toFixed(
                          1,
                        )
                      : 0
                  }분입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConcepStudyVideo
                    ? (meanHistoryItem?.meanConcepStudyVideo / 60000).toFixed(1)
                    : 0}
                  분
                </Box>
                <Box
                  aria-label={`개념학습 설명을 반 학생들이 평균 ${
                    meanHistoryItem?.meanConcepStudyExplain
                      ? meanHistoryItem?.meanConcepStudyExplain.toFixed(1)
                      : 0
                  }개 등록하셨습니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConcepStudyExplain
                    ? meanHistoryItem?.meanConcepStudyExplain.toFixed(1) + '개'
                    : 0}
                </Box>
                <Box
                  aria-label={`평균 전체 기본문제 수 ${
                    meanHistoryItem?.meanBasic.toFixed(1) ?? 0
                  }문제중 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectBasic.toFixed(1) ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanBasic.toFixed(1) ?? '0'}
                </Box>
                <Box component="td" sx={theme => customStyle(theme)}>
                  {meanHistoryItem?.meanIncorrectBasic.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 전체 확인문제 수 ${
                    meanHistoryItem?.meanConfrim.toFixed(1) ?? 0
                  }문제중 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectConfrim.toFixed(1) ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanConfrim.toFixed(1) ?? '0'}
                </Box>
                <Box component="td" sx={theme => customStyle(theme)}>
                  {meanHistoryItem?.meanIncorrectConfrim.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 전체 피드백 문제 수 ${
                    meanHistoryItem?.meanFeedback.toFixed(1) ?? 0
                  }문제중 평균 오답 수는 ${
                    meanHistoryItem?.meanIncorrectFeedback.toFixed(1) ?? '0'
                  }문제입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanFeedback.toFixed(1) ?? '0'}
                </Box>
                <Box component="td" sx={theme => customStyle(theme)}>
                  {meanHistoryItem?.meanIncorrectFeedback.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 전체 추가문제 수 ${
                    meanHistoryItem?.meanAdditional.toFixed(1) ?? 0
                  }문제중 평균 오답 수는 ${
                    meanHistoryItem?.meanInccorectAdditional.toFixed(1) ?? '0'
                  }문제입니다..`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanAdditional.toFixed(1) ?? '0'}
                </Box>
                <Box component="td" sx={theme => customStyle(theme)}>
                  {meanHistoryItem?.meanInccorectAdditional.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`평균 전체 메타인지 문제 수 ${
                    meanHistoryItem?.meanMetacognition.toFixed(1) ?? 0
                  }문제중 평균 오답 수는 ${
                    meanHistoryItem?.meanMetacognitionMiss.toFixed(1) ?? 0
                  }문제입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanMetacognition.toFixed(1) ?? '0'}
                </Box>
                <Box component="td" sx={theme => customStyle(theme)}>
                  {meanHistoryItem?.meanMetacognitionMiss.toFixed(1) ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 평균 시간은 ${
                    meanHistoryItem?.meanParticipateTimes ?? 0
                  }분입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanParticipateTimes ?? '0'}
                </Box>
                <Box
                  aria-label={`학습에 참여한 평균 횟수는 ${
                    meanHistoryItem?.meanParticipate ?? 0
                  }번입니다.`}
                  component="td"
                  sx={theme => customStyle(theme)}
                  tabIndex={0}
                >
                  {meanHistoryItem?.meanParticipate ?? '0'}
                </Box>
              </tr>

              {/* 다시풀기 */}
              {learningSystemQuery.data?.type === 'SECTION' && (
                <tr aria-label="다시풀기 행입니다." tabIndex={0}>
                  <Box
                    colSpan={4}
                    component="td"
                    sx={theme => ({
                      backgroundColor: '#EBF2FE',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 600,
                      height: '2.5rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.5625rem',
                      },
                    })}
                  >
                    다시풀기
                  </Box>

                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="개념 재학습 버튼입니다. 엔터 키를 누르면 개념학습 페이지로 이동합니다."
                        onClick={() => {
                          navigate(`/math-concept/${subsectionId}`, {
                            state: 'dashboard',
                          });
                        }}
                        onKeyDown={e => {
                          e.preventDefault();
                          if (e.key === 'Enter') {
                            navigate(`/math-concept/${subsectionId}`, {
                              state: 'dashboard',
                            });
                          }
                        }}
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        개념 재학습
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="기본문제 채점결과 재확인 버튼입니다. 엔터 키를 누르면 기본문제 채점결과 화면으로 이동합니다."
                        onClick={() => {
                          navigate(`/questions/${subsectionId}/BASIC_result`);
                        }}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          navigate(`/questions/${subsectionId}/BASIC_result`)
                        }
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        채점결과 재확인
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="확인문제 채점결과 재확인 버튼입니다. 엔터 키를 누르면 확인문제 채점결과 화면으로 이동합니다."
                        onClick={() => {
                          navigate(`/questions/${subsectionId}/CONFIRM_result`);
                        }}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          navigate(`/questions/${subsectionId}/CONFIRM_result`)
                        }
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        채점결과 재확인
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="피드백 채점결과 재확인 버튼입니다. 엔터 키를 누르면 피드백 채점결과 화면으로 이동합니다."
                        onClick={() => {
                          navigate(
                            `/questions/${subsectionId}/FEEDBACK_result`,
                          );
                        }}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          navigate(`/questions/${subsectionId}/FEEDBACK_result`)
                        }
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        채점결과 재확인
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="추가문제 채점결과 재확인 버튼입니다. 엔터 키를 누르면 추가문제 채점결과 화면으로 이동합니다."
                        onClick={() => {
                          navigate(
                            `/questions/${subsectionId}/ADDITIONAL_result`,
                          );
                        }}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          navigate(
                            `/questions/${subsectionId}/ADDITIONAL_result`,
                          )
                        }
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        채점결과 재확인
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="메타인지 오답보기 버튼입니다. 엔터 키를 누르면 메타인지 오답보기 화면으로 이동합니다."
                        onClick={() => {
                          navigate(
                            `/questions/${subsectionId}/METACOGNITION_result`,
                            {
                              state: 'dashboard',
                            },
                          );
                        }}
                        onKeyDown={e =>
                          e.key === 'Enter' &&
                          navigate(
                            `/questions/${subsectionId}/incorrect/METACOGNITION_result`,
                            { state: 'dashboard' },
                          )
                        }
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        메타인지 오답
                      </Button>
                    </Stack>
                  </Box>
                  <Box
                    colSpan={2}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={1}
                      justifyContent="center"
                    >
                      <Button
                        aria-label="학습참여 통계 버튼입니다. 엔터 키를 누르면 학습참여 상세정보 모달이 나타납니다."
                        onClick={() => {
                          setDialogData({
                            contents: <LearningTimeHistoryTable />,
                            title: '학습참여 상세정보',
                          });
                          handleDialogOpen();
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            setDialogData({
                              contents: <LearningTimeHistoryTable />,
                              title: '학습참여 상세정보',
                            });
                            handleDialogOpen();
                          }
                        }}
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#FFF',
                          },
                          backgroundColor: '#FFF',
                          border: '1px solid #1A48A9',
                          borderRadius: '4px',
                          boxShadow: 'none',
                          color: '#1A48A9',
                          fontSize: '.8rem',
                          fontWeight: 500,
                          height: '2rem',
                          paddingX: '1rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        tabIndex={0}
                        variant="outlined"
                      >
                        학습참여 통계
                      </Button>
                    </Stack>
                  </Box>
                </tr>
              )}

              {/* 과제 */}
              {learningSystemQuery.data?.type === 'SECTION' && (
                <tr aria-label="과제 내역을 나타내는 행입니다." tabIndex={0}>
                  <Box
                    colSpan={4}
                    component="td"
                    sx={theme => ({
                      backgroundColor: '#EBF2FE',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 600,
                      height: '2.5rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.5625rem',
                      },
                    })}
                  >
                    {t('student_dashboard.assignment')}
                  </Box>
                  <Box
                    align="right"
                    colSpan={14}
                    component="td"
                    sx={theme => ({
                      backgroundColor: 'white',
                      border: '1px solid #BFC7E3',
                      fontSize: '.9rem',
                      fontWeight: 700,
                      paddingX: '0.1rem',
                      [theme.breakpoints.down('md')]: {
                        paddingX: '.625rem',
                      },
                    })}
                  >
                    {(assignmentsQuery?.data?.length || 0) > 0 ? (
                      <>
                        <span>
                          {assignmentsQuery.data?.[0].assignmentFinish ===
                          null ? (
                            <span>아직 과제를 완료하지 않았어요.&nbsp;</span>
                          ) : (
                            <span>과제를 완료했습니다!&nbsp;</span>
                          )}
                        </span>
                        <Button
                          aria-label={`${
                            assignmentsQuery.data &&
                            assignmentsQuery.data?.length > 0
                              ? '과제하기 버튼입니다. 엔터 키를 누르면 해당 과제 페이지로 이동합니다.'
                              : '과제가 없습니다.'
                          }`}
                          disabled={
                            assignmentsQuery.data &&
                            assignmentsQuery.data?.length === 0
                          }
                          onClick={() => {
                            if (
                              assignmentsQuery.data &&
                              assignmentsQuery.data?.length < 0
                            )
                              return;
                            navigate(
                              `/questions/${subsectionId}/assignment/${assignmentsQuery.data
                                ?.map(assignment => assignment.assignmentType)
                                .join(',')}`,
                              { state: 'dashboard' },
                            );
                          }}
                          startIcon={<LucidePencilRuler size={20} />}
                          sx={theme => ({
                            '&:disabled': {
                              backgroundColor: '#EBF2FE',
                              border: '1px solid #BFC7E3',
                              color: '#4F73C0',
                            },
                            '&:hover': {
                              backgroundColor: '#4F73C0',
                            },
                            backgroundColor: '#4F73C0',
                            border: 'none',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            color: '#FFF',
                            fontSize: '.8rem',
                            fontWeight: 500,
                            height: '2rem',
                            paddingX: '1rem',
                          })}
                          tabIndex={0}
                          variant="contained"
                        >
                          {assignmentsQuery.data &&
                          assignmentsQuery.data?.length > 0
                            ? assignmentsQuery.data[0].assignmentFinish === null
                              ? t('student_dashboard.do_assignment')
                              : '과제 결과 보기'
                            : t('student_dashboard.no_assignment')}
                        </Button>
                      </>
                    ) : (
                      <>
                        <span>아직 선생님이 과제를 내지 않았습니다.</span>
                      </>
                    )}
                  </Box>
                  {/* <Box
                  colSpan={11}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    fontSize: '.9rem',
                    fontWeight: 700,
                    paddingX: '0.1rem',
                    [theme.breakpoints.down('md')]: {
                      paddingX: '.625rem',
                    },
                  })}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    gap={1}
                    justifyContent="end"
                  >
                    {assignmentsQuery.data &&
                      assignmentsQuery.data?.length < 0 && (
                        <Typography
                          fontSize=".75rem"
                          fontWeight="400"
                          sx={theme => ({
                            [theme.breakpoints.down('md')]: {
                              fontSize: '.625rem',
                            },
                          })}
                        >
                          {t(
                            'student_dashboard.working_on_incorrect_and_similar_problems',
                          )}
                        </Typography>
                      )}
                    <Button
                      aria-label={`${
                        assignmentsQuery.data &&
                        assignmentsQuery.data?.length > 0
                          ? '과제하기 버튼입니다. 엔터 키를 누르면 해당 과제 페이지로 이동합니다.'
                          : '과제가 없습니다.'
                      }`}
                      disabled={
                        assignmentsQuery.data &&
                        assignmentsQuery.data?.length === 0
                      }
                      onClick={() => {
                        if (
                          assignmentsQuery.data &&
                          assignmentsQuery.data?.length < 0
                        )
                          return;
                        navigate(
                          `/questions/${subsectionId}/assignment/${assignmentsQuery.data
                            ?.map(assignment => assignment.assignmentType)
                            .join(',')}`,
                        );
                      }}
                      sx={theme => ({
                        '&:disabled': {
                          backgroundColor: '#EBF2FE',
                          border: '1px solid #BFC7E3',
                          color: '#4F73C0',
                        },
                        '&:hover': {
                          backgroundColor: '#4F73C0',
                        },
                        backgroundColor: '#4F73C0',
                        border: 'none',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        color: '#FFF',
                        fontSize: '.8rem',
                        fontWeight: 500,
                        height: '2rem',
                        paddingX: '1rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.25rem',
                        },
                      })}
                      tabIndex={0}
                      variant="contained"
                    >
                      {assignmentsQuery.data &&
                      assignmentsQuery.data?.length > 0
                        ? t('student_dashboard.do_assignment')
                        : t('student_dashboard.no_assignment')}
                    </Button>
                  </Stack>
                </Box>
                <Box
                  colSpan={3}
                  component="td"
                  sx={theme => ({
                    backgroundColor: 'white',
                    border: '1px solid #BFC7E3',
                    maxWidth: '70px',
                    paddingX: '0.1rem',
                    textAlign: 'end',
                    [theme.breakpoints.down('md')]: {
                      paddingX: '.625rem',
                    },
                  })}
                >
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="end"
                  >
                    <Button
                      aria-label="과제 결과 보기 버튼입니다. 엔터 키를 누르면 해당 과제의 결과 페이지로 이동합니다."
                      onClick={() => {
                        navigate(
                          `/questions/${subsectionId}/ASSIGNMENT_result`,
                        );
                      }}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          navigate(
                            `/questions/${subsectionId}/ASSIGNMENT_result`,
                          );
                        }
                      }}
                      sx={theme => ({
                        '&:hover': {
                          backgroundColor: '#4F73C0',
                        },
                        backgroundColor: '#4F73C0',
                        borderRadius: '5px',
                        boxShadow: 'none',
                        color: 'white',
                        fontSize: '.8rem',
                        fontWeight: 500,
                        height: '2rem',
                        paddingX: '1rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.25rem',
                        },
                      })}
                      variant="contained"
                    >
                      {t('student_dashboard.view_assignment_results')}
                    </Button>
                  </Stack>
                </Box> */}
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Stack>
  );
}
