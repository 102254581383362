import { Box, Stack } from '@mui/material';
import AchievementLevelTable from 'app/components/Dashboard/AchievementLevelTable';
import CumulativeAccuracyRateDataTable from 'app/components/Dashboard/CumulativeAccuracyRateDataTable';
import CumulativeDataTable from 'app/components/Dashboard/CumulativeDataTable';
import DueAssignmentDialog from 'app/components/Dashboard/DueAssignmentDialog';
import LearningGoal from 'app/components/Dashboard/LearningGoal';
import LearningHistoryTable from 'app/components/Dashboard/LearningHistoryTable';
import MVPList from 'app/components/Dashboard/MVPList';
import { MVPListDialog } from 'app/components/Dashboard/MVPListDialog';
import MyDreamJobsTable from 'app/components/Dashboard/MyDreamJobsTable';
import Profile from 'app/components/Dashboard/Profile';
import ProfileSettingDrawer from 'app/components/Dashboard/ProfileSettingDrawer';
import StrategyComment from 'app/components/Dashboard/StrategyComment';
import StudentStatisticsChart from 'app/components/Dashboard/StudentStatisticsChart';
import StudentStatisticsRecentChart from 'app/components/Dashboard/StudentStatisticsRecentChart';
import StudentWeakChaptersTable from 'app/components/Dashboard/StudentWeakChaptersTable';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { useUpdateDashboard } from 'queries/dashboard/use-update-dashboard';
import { useLearningSystem } from 'queries/use-learning-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDrawerStore from 'stores/use-drawer-store';
import { RootState } from 'types';

import { DialogData } from '../../../types/DialogData';
import CustomDialog from '../Common/CustomDialog';
import SpeedDialTooltipOpen from '../Common/SpeedDialTooltipOpen';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// 차트 설정
export const chartOptions = {
  barThickness: 20,
  elements: {
    bar: {
      borderColor: 'white',
      borderWidth: 3,
    },
  },
  indexAxis: 'y' as const,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      max: 10,
      min: 0,
      ticks: {
        stepSize: 1,
      },
    },
  },
};

export default function StudentDashboard() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState<DialogData>({
    contents: '',
    title: '',
  });

  const user = useSelector((state: RootState) => state.user);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  // 유저 학습현황 업데이트
  const updateDashboardMutation = useUpdateDashboard();

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  useEffect(() => {
    if (user) {
      updateDashboardMutation.mutate({
        headers: {
          role: user.user_type,
          uuid: user.user_id,
        },
      });
    }
  }, [user]);

  return (
    <Box component="main">
      <Stack
        alignItems="stretch"
        component="section"
        gap=".875rem"
        sx={{ height: '100%' }}
      >
        <Box
          display="grid"
          gap={1}
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
        >
          <Box
            display="grid"
            gap={1}
            gridColumn="span 1"
            gridTemplateColumns="repeat(3, minmax(0, 1fr))"
            height="8rem"
            sx={theme => ({
              [theme.breakpoints.down('md')]: {
                height: '6.5rem',
              },
            })}
          >
            {/* 프로필 */}
            <Profile />

            {/* 학습 목표 */}
            <LearningGoal />
          </Box>

          {/* 학습전략추천 */}
          <StrategyComment />

          {/* 누적 데이터 */}
          <CumulativeDataTable />
        </Box>

        {/* 현재 소단원별 학습결과 내역 */}
        <LearningHistoryTable
          handleDialogOpen={handleDialogOpen}
          setDialogData={setDialogData}
        />

        {/* 현재 소단원 목표 결과 분석 */}
        <StudentStatisticsChart />

        <Stack
          alignItems="center"
          component="section"
          direction="row"
          gap={1}
          height="8.25rem"
        >
          {/* 나의 장래희망 BEST 3 */}
          <MyDreamJobsTable />

          {/* 각 부분별 MVP */}
          {/* <MVPList /> */}

          {/* 난이도별 문제풀이 및 정답률 누적데이터 */}
          <CumulativeAccuracyRateDataTable />
        </Stack>

        {/* 취약단원순 난이도별 분석 */}
        <StudentWeakChaptersTable />

        {/* 소단원별 학습결과 비교 */}
        <StudentStatisticsRecentChart />

        {/* 성취기준별 성취수준 */}
        {learningSystemQuery.data?.type === 'SECTION' &&
          user.user_school.includes('고등학교') && <AchievementLevelTable />}
      </Stack>

      <CustomDialog
        dialogData={dialogData}
        handleClose={handleDialogClose}
        maxWidth={'sm'}
        open={dialogOpen}
      />

      {/* 프로필 설정 Drawer */}
      <ProfileSettingDrawer />

      {/* 각 부분별 MVP Dialog */}
      <MVPListDialog />
      <DueAssignmentDialog />
    </Box>
  );
}
