import { useQuery } from '@tanstack/react-query';
import { getTeacherClassMembers } from 'apis/aidt-teacher';
import { ClassMember } from 'schemas/aidt';
import {
  GetTeacherClassMembersDto,
  GetTeacherClassMembersResponse,
} from 'schemas/aidt-teacher';

export function useTeacherClassMembers({
  data,
  headers,
}: GetTeacherClassMembersDto) {
  return useQuery<GetTeacherClassMembersResponse, Error, ClassMember[]>({
    enabled: !!data.class_code,
    queryFn: () => getTeacherClassMembers({ data, headers }),
    queryKey: ['teacher_class_members', { data, headers }],
    select: data => data.member_info,
  });
}
