import { useMutation } from '@tanstack/react-query';
import { sendLearningReminder } from 'apis/notification';
import useReminderDialogStore from 'stores/use-assignment-reminder-dialog-store';

export function useSendLearningReminder() {
  const { setMessage, toggleDialog } = useReminderDialogStore(state => ({
    setMessage: state.setMessage,
    toggleDialog: state.toggleDialog,
  }));
  return useMutation({
    mutationFn: sendLearningReminder,
    onSuccess: data => {
      setMessage(data.data.message);
      toggleDialog(true)();
    },
  });
}
