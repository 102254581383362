/**
 * 정답률을 계산하는 함수
 * @param totalQuestions 총 문제 수
 * @param incorrectAnswers 오답 수
 * @returns 정답률 (백분율)
 */
export function calculateCorrectRate(
  totalQuestions: number,
  incorrectAnswers: number,
): number | string {
  if (totalQuestions === 0 && incorrectAnswers === 0) return 0;

  const correctAnswers = totalQuestions - incorrectAnswers;
  const correctRate = (correctAnswers / totalQuestions) * 100;

  return correctRate.toFixed(1);
}

/**
 * 학습 단계를 계산하는 함수
 * @param score 진단평가 점수
 * @returns 학습 단계
 */
export function calculateLearningStage(score: number): number {
  if (score >= 0 && score <= 10) return 1;
  if (score >= 11 && score <= 20) return 2;
  if (score >= 21 && score <= 30) return 3;
  if (score >= 31 && score <= 40) return 4;
  if (score >= 41 && score <= 50) return 5;
  if (score >= 51 && score <= 60) return 6;
  if (score >= 61 && score <= 70) return 7;
  if (score >= 71 && score <= 80) return 8;
  if (score >= 81 && score <= 90) return 9;
  if (score >= 91 && score <= 100) return 10;
  return 0; // 점수가 범위를 벗어난 경우 0을 반환
}
