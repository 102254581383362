import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  setAccessToken,
  setUserId,
  setUserStatus,
  setUserType,
} from '../../../store/slices/userSlice';

const LoginPage = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState('');
  const [accessId, setAccessId] = useState('');
  const [uuid, setUuid] = useState('');
  const [type, setType] = useState<string>('');
  const [status, setStatus] = useState<string>('');

  const handleLogin = () => {
    if (!token || !accessId || !uuid || !type || !status) {
      alert('모든 값을 입력해주세요');
      return;
    }

    // Save to local storage
    localStorage.setItem(
      'access_token',
      JSON.stringify({ access_id: accessId, token }),
    );
    localStorage.setItem('user_id', uuid);
    localStorage.setItem('user_type', type);
    localStorage.setItem('user_status', status);

    dispatch(setAccessToken({ access_id: accessId, token }));
    dispatch(setUserId(uuid));
    dispatch(setUserType(type as 'S' | 'T'));
    dispatch(setUserStatus(status as 'E' | 'I' | 'O'));
    window.location.href = '/';
  };

  return (
    // mui를 이용하여 로그인페이지 예쁘게 UI 수정
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: '#f6f6f6',
          borderRadius: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          padding: '30px 20px',
          width: '500px',
        }}
      >
        <h2>해피에듀테크 디지털교과서 로그인</h2>
        <input
          onChange={e => setToken(e.target.value)}
          placeholder="Token"
          style={{ marginBottom: '5px', padding: '15px', width: '100%' }}
          type="text"
          value={token}
        />
        <input
          onChange={e => setAccessId(e.target.value)}
          placeholder="AccessId"
          style={{ marginBottom: '5px', padding: '15px', width: '100%' }}
          type="text"
          value={accessId}
        />
        <input
          onChange={e => setUuid(e.target.value)}
          placeholder="UserId"
          style={{ marginBottom: '5px', padding: '15px', width: '100%' }}
          type="text"
          value={uuid}
        />
        <input
          onChange={e => setStatus(e.target.value)}
          placeholder="UserStatus(E: 활성화, O: 비활성화, I: 탈퇴)"
          style={{ marginBottom: '5px', padding: '15px', width: '100%' }}
          type="text"
          value={status}
        />
        <input
          onChange={e => setType(e.target.value)}
          placeholder="UserType(S: 학생, T: 선생님)"
          style={{ marginBottom: '5px', padding: '15px', width: '100%' }}
          type="text"
          value={type}
        />
        <Button
          color="primary"
          onClick={handleLogin}
          sx={{
            borderRadius: '3px',
            fontSize: '16px',
            marginTop: '5px',
            padding: '15px',
            width: '100%',
          }}
          variant="contained"
        >
          로그인
        </Button>
      </Box>
    </Box>
  );
};

export default LoginPage;
