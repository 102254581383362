import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  Modal,
  Alert as MuiAlert,
  Typography,
} from '@mui/material';
import { getNotifications } from 'apis/notification';
import { Toaster } from 'components/ui/toaster';
import { LucideX } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNotificationsState } from 'store/slices/notificationSlice';

import { RootState } from '../../../types';
import { Header } from '../Common/header/Header';
import CommonHelpModal from './CommonHelpModal';

const blink = keyframes` 
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

interface LayoutProps {
  children: React.ReactNode;
  customContainerStyle?: React.CSSProperties;
  isDisableGutters?: boolean;
  subHeader?: React.ReactNode;
  width?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false;
}

const Alert = styled(MuiAlert)`
  animation: ${blink} 1s linear 2;
`;

export const Layout = ({
  children,
  customContainerStyle,
  isDisableGutters = false,
  subHeader,
  width,
}: LayoutProps) => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.loading);
  const uuid = useSelector((state: RootState) => state.user.user_id);
  const role = useSelector((state: RootState) => state.user.user_type);
  const notificationInterval = useRef<ReturnType<typeof setInterval> | null>(
    null,
  );
  const notifications = useSelector(
    (state: RootState) => state.user.notifications,
  );
  const defaultContainerStyle = {
    height: `calc(100vh - 86px)`,
    position: 'relative',
  };

  const handleNavigate = event => {
    navigate('/evaluation');
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleNavigate(event);
    }
  };

  //get notifications
  const getNotification = async () => {
    const notifications = await getNotifications({
      headers: {
        role,
        uuid,
      },
    });
    dispatch(setNotificationsState(notifications));
  };
  useEffect(() => {
    notificationInterval.current = setInterval(() => {
      getNotification();
    }, 1000 * 30);
    getNotification();

    return () => {
      if (notificationInterval.current !== null) {
        clearInterval(notificationInterval.current);
      }
    };
  }, [pathname]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <Header />
      {subHeader && subHeader}
      <Container
        disableGutters={isDisableGutters}
        maxWidth={width}
        sx={{
          ...defaultContainerStyle,
          ...customContainerStyle,
        }}
      >
        {children}
      </Container>
      <CommonHelpModal />
      <Backdrop
        open={loading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Toaster />
    </Box>
  );
};
