import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { BaseQueryFn } from '@reduxjs/toolkit/query/react';

import { Dummy, MockData } from './dummyData';

const dummyBaseQuery: BaseQueryFn<
  FetchArgs | string,
  unknown,
  FetchBaseQueryError
> = async args => {
  try {
    const { body, method, url } =
      typeof args === 'string'
        ? { body: undefined, method: 'GET', url: args }
        : args;

    let result;

    const [pathname, queryString] = url.split('?');
    const params = queryString
      ? Object.fromEntries(new URLSearchParams(queryString).entries())
      : {};
    switch (pathname) {
      case '/info?param=other':
        result = { data: Dummy.OTHER_REPLY_DATA };
        break;
      case '/info?param=my':
        result = { data: Dummy.MY_REPLY_DATA };
        break;
      case '/search':
        result = { data: Dummy.SEARCH_RESULT };
        break;
      case '/question':
        if (method === 'GET') {
          const page = params.page ? parseInt(params.page, 10) : 1;
          const onlyMine = params.onlyMine === 'true';
          const questions = Dummy.QUESTIONS;

          const totalPage = questions.length;
          const questionIndex = page - 1;

          if (questionIndex < 0 || questionIndex >= totalPage) {
            result = {
              error: {
                data: { message: 'Question not found' },
                status: 404,
              },
            };
          } else {
            const question = questions[questionIndex];
            result = { data: { question, totalPage } };
          }
        } else if (method === 'POST') {
          const newQuestion = {
            created_at: new Date().toISOString(),
            id: Dummy.QUESTIONS.length + 1,
            learning_sys_id: 1,
            problem_id: body.problemId,
            problem_solving: {
              created_at: new Date().toISOString(),
              id: Dummy.QUESTIONS.length + 1,
              problem_id: body.problemId,
              scope: 'CLASS',
              status: 'IDLE',
              user_uuid: 'generated-user-uuid',
            },
            question_user_uuid: 'generated-user-uuid',
            solving_user_uuid: 'generated-solving-uuid',
            title: body.title,
          };
          Dummy.QUESTIONS.push(newQuestion);
          result = { data: { id: newQuestion.id } };
        } else if (method === 'DELETE') {
          const { id } = body;
          Dummy.QUESTIONS = Dummy.QUESTIONS.filter(q => q.id !== id);
          result = { data: { success: true } };
        } else {
          result = {
            error: {
              data: { message: 'Method Not Allowed' },
              status: 405,
            },
          };
        }
        break;
      case '/problem':
        if (method === 'GET') {
          const { id } = body;
          const problem = Dummy.PROBLEMS.find(p => p.id === id);

          if (problem) {
            result = { data: problem };
          } else {
            result = {
              error: {
                data: { message: 'Problem not found' },
                status: 404,
              },
            };
          }
        } else {
          result = {
            error: {
              data: { message: 'Method Not Allowed' },
              status: 405,
            },
          };
        }
        break;
      case '/question/answer':
        if (method === 'POST') {
          const answerId = 1;
          result = { data: { id: answerId } };
        }
        break;
      case '/study/shared-video':
        result = {
          data: {
            totalPage: Dummy.SHARED_VIDEO.length,
            videos: Dummy.SHARED_VIDEO,
          },
        };
        break;
      case '/learning/content-table':
        result = {
          data: Dummy.CONTENT_TABLES,
        };
        break;
      case '/study/reference-data':
        result = {
          data: getReferenceData(params),
        };
        break;
      case '/concept/common-concept':
        result = {
          data: Dummy.CONCEPT,
        };
        break;
      case '/study/question-bank':
        result = {
          data: Dummy.QUESTION_BANK,
        };
        break;
      case '/study/analysis-table':
        result = {
          data: Dummy.ANALYSIS_TABLE,
        };
        break;
      case '/shared-video':
        result = {
          data: Dummy.SHARED_VIDEO,
        };
        break;
      case '/shared-solution-video':
        result = {
          data: Dummy.SHARED_SOLUTION_VIDEO,
        };
        break;
      case '/semesters':
        result = {
          data: Dummy.SEMESTER_DATA,
        };
        break;
      case '/student/name':
        result = {
          data: MockData.STUDENT_NAME,
        };
        break;
      case '/student/all':
        result = {
          data: MockData.STUDENT_ALL,
        };
        break;
      case '/teacher/all':
        result = {
          data: MockData.TEACHER_ALL,
        };
        break;
      case '/teacher/class_member':
        result = {
          data: MockData.TEACHER_CLASS_MEMBER,
        };
        break;
      default:
        result = {
          error: {
            data: { message: 'Not Found' },
            status: 404,
          },
        };
    }

    if ('data' in result) {
      return { data: result.data };
    } else {
      return { error: result.error };
    }
  } catch (error) {
    return {
      error: {
        data: { message: 'Internal Server Error' },
        status: 500,
      },
    };
  }
};

export default dummyBaseQuery;

function getReferenceData(params) {
  const page = parseInt(params.page, 10);
  const pageSize = parseInt(params.pageSize, 10);

  const startIndex = (page - 1) * pageSize;
  const endIndex = page * pageSize;

  const paginatedData = Dummy.REFERENCE.data.slice(startIndex, endIndex);
  const totalPage = Math.ceil(Dummy.REFERENCE.data.length / pageSize);

  return {
    currentPage: page,
    data: paginatedData,
    totalPage: totalPage,
  };
}
