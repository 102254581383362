/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const AssessmentDiagnosisResultPage = lazyLoad(
  () => import('./index'),
  module => module.AssessmentDiagnosisResultPage,
);
