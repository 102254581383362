import { createSlice } from '@reduxjs/toolkit';
import { NotificationState } from 'types/NotificationState';

const initialState: NotificationState = {
  notifications: [],
};

const notificationSlice = createSlice({
  initialState,
  name: 'notification',
  reducers: {
    deleteNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        notification => notification.id !== action.payload,
      );
    },
    setNotificationsState: (state, action) => {
      state.notifications = action.payload;
    },
  },
});

export const { deleteNotification, setNotificationsState } =
  notificationSlice.actions;

export default notificationSlice.reducer;
