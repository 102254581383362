import { AssessmentProblem } from 'schemas/assessment';
import { LearningProblem } from 'schemas/teacher-dashboard';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ProblemResultDialogState {
  number: number;
  open: boolean;
  problem: AssessmentProblem | null;
  problems: LearningProblem[] | null;
  title: string;
}

interface ProblemResultDialogAction {
  setNumber: (number: number) => void;
  setProblem: (problem: ProblemResultDialogState['problem']) => void;
  setProblems: (problems: ProblemResultDialogState['problems']) => void;
  setTitle: (title: string) => void;
  toggleDialog: (open: boolean) => () => void;
}

const useProblemResultDialogStore = create<
  ProblemResultDialogAction & ProblemResultDialogState
>()(
  devtools(
    persist(
      set => ({
        number: 0,
        open: false,
        problem: null,
        problems: null,
        setNumber: number => set(state => ({ ...state, number })),
        setProblem: problem =>
          set(state => ({ ...state, problem, problems: null })),
        setProblems: problems =>
          set(state => ({ ...state, problem: null, problems })),
        setTitle: title => set(state => ({ ...state, title })),
        title: '학력진단평가',
        toggleDialog: (open: boolean) => () =>
          set(state => ({ ...state, open })),
      }),

      { name: 'problemResultDialog' },
    ),
  ),
);

export default useProblemResultDialogStore;
