import axios from 'axios';

import { API_DOMAIN, LOGIN_DOMAIN } from '../constants';
import { MockData } from './dummyData';

const isTokenExpired = (response: any) => {
  if (response.status === 403 || response.status === 401) {
    window.location.href = LOGIN_DOMAIN;
  }
  return false;
};
// 데이터 수집
// 수업 시작
export async function startClass(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum: 'H1MATA02B08C13',
    timestamp: new Date().toISOString(),
    user_id: userId,
  };
  return true;
}

export async function endClass(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum: 'H1MATA02B08C13',
    timestamp: new Date().toISOString(),
    user_id: userId,
  };
  return true;
}

// 경과메세지
export async function progressClass(
  accessToken: string,
  accessId: string,
  userId: string,
  curriculum,
  partnerId: string,
  percent: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum,
    percent,
    timestamp: new Date().toISOString(),
    user_id: userId,
  };
  return true;
}

// 완료 메세지
export async function completeClass(
  accessToken: string,
  accessId: string,
  userId: string,
  curriculum,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum,
    timestamp: new Date().toISOString(),
    user_id: userId,
  };
  return true;
}

// 점수 메세지
export async function scoreClass(
  accessToken: string,
  accessId: string,
  userId: string,
  curriculum,
  score,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum,
    score,
    timestamp: new Date().toISOString(),
    user_id: userId,
  };

  return true;
}

// 학생 성명 조회
export async function getStudentName(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    user_id: userId,
  };
  if (accessToken === 'MOCK-PENETRATION') {
    return MockData.STUDENT_NAME;
  }

  const response = await axios.post(
    `${API_DOMAIN}/aidt_userinfo/student/class_member`,
    body,
    {
      headers,
    },
  );

  if (isTokenExpired(response)) return;
  return response.data;
}

// 학생 학교 명 조회
// export async function getStudentSchoolName(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/school_name`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 학교 ID 조회
// export async function getStudentSchoolId(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/school_id`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 학교 구분 조회
// export async function getStudentSchoolType(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/division`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 학년 조회
// export async function getStudentGrade(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/grade`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 반 조회
// export async function getStudentClass(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/class`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 번호 조회
// export async function getStudentNumber(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/number`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 시간표 조회
// export async function getStudentSchedule(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/schedule`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 학급 구성원 조회
export async function getStudentClassMembers(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
  lectureCode: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    lecture_code: lectureCode,
    user_id: userId,
  };

  if (accessToken === 'MOCK-PENETRATION') {
    return MockData.TEACHER_CLASS_MEMBER;
  }

  const response = await axios.post(
    `${API_DOMAIN}/aidt_userinfo/student/class_member`,
    body,
    {
      headers,
    },
  );
  if (isTokenExpired(response)) return;
  return response.data;
}

// 학생 성별 조회
// export async function getStudentGender(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/student/gender`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 학생 전체 정보 조회
export async function getStudentInfo(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    user_id: userId,
  };

  if (accessToken === 'MOCK-PENETRATION') {
    return MockData.STUDENT_ALL;
  }

  try {
    const response = await axios.post(
      `${API_DOMAIN}/aidt_userinfo/student/all`,
      body,
      {
        headers,
      },
    );
    if (isTokenExpired(response)) {
      return MockData.STUDENT_ALL;
    }
    return response.data;
  } catch (e) {
    return MockData.STUDENT_ALL;
  }
}

// 교사 성명 조회
// export async function getTeacherName(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/teacher/name`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 교사 학교 명 조회
// export async function getTeacherSchoolName(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/teacher/school_name`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 교사 학교 ID 조회
// export async function getTeacherSchoolId(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/teacher/school_id`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 교사 시간표 조회
// export async function getTeacherSchedule(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/teacher/schedule`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 교사 학급 목록 조회
// export async function getTeacherClassList(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
//   classCode: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     class_code: classCode,
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_userinfo/teacher/class_list`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 교사 학급 구성원 조회
export async function getTeacherClassMembers(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
  lectureCode: string, // 강의 코드
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    lecture_code: lectureCode,
    user_id: userId,
  };

  if (accessToken === 'MOCK-PENETRATION') {
    return MockData.TEACHER_CLASS_MEMBER;
  }

  const response = await axios.post(
    `${API_DOMAIN}/aidt_userinfo/teacher/class_member`,
    body,
    {
      headers,
    },
  );
  if (isTokenExpired(response)) return;
  return response.data;
}

// 교사 전체 정보 조회
export async function getTeacherInfo(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    user_id: userId,
  };

  if (accessToken === 'MOCK-PENETRATION') {
    return MockData.TEACHER_ALL;
  }

  try {
    const response = await axios.post(
      `${API_DOMAIN}/aidt_userinfo/teacher/all`,
      body,
      {
        headers,
      },
    );
    if (isTokenExpired(response)) {
      return MockData.TEACHER_ALL;
    }
    return response.data;
  } catch (e) {
    return MockData.TEACHER_ALL;
  }
}

// AI 디지털교과서 데이터수집 API

// AI 디지털교과서 시작 메세지(디지털 교과서 최초 실행 시 실행)
// export async function sendStartMessage(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
//   curriculum: string,
//   timestamp: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     curriculum,
//     timestamp,
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_dashboard/terminated`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// AI 디지털교과서 경과 메세지
// export async function sendProgressMessage(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
//   curriculum: string,
//   percent: string,
//   timestamp: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     curriculum,
//     percent,
//     timestamp,
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_dashboard/curriculum_progressed`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// AI 디지털교과서 종료 메세지(수업 종료 시 실행)
// export async function sendEndMessage(
//   accessToken: string,
//   accessId: string,
//   userId: string,
//   partnerId: string,
//   curriculum: string,
//   timestamp: string,
// ) {
//   const headers = {
//     'Partner-ID': partnerId,
//   };

//   const body = {
//     access_token: {
//       access_id: accessId,
//       token: accessToken,
//     },
//     curriculum,
//     timestamp,
//     user_id: userId,
//   };
//   const response = await axios.post(
//     `${API_DOMAIN}/aidt_dashboard/terminated`,
//     body,
//     {
//       headers,
//     },
//   );
//   if (isTokenExpired(response)) return;
//   return response.data;
// }

// 표준체계 ID별 학습 완료 메시지 전달
export async function sendProgressMessage(
  accessToken: string,
  accessId: string,
  userId: string,
  partnerId: string,
  curriculum: string,
  timestamp: string,
) {
  const headers = {
    'Partner-ID': partnerId,
  };

  const body = {
    access_token: {
      access_id: accessId,
      token: accessToken,
    },
    curriculum,
    timestamp,
    user_id: userId,
  };
  const response = await axios.post(
    `${API_DOMAIN}/aidt_dashboard/curriculum_completed`,
    body,
    {
      headers,
    },
  );
  if (isTokenExpired(response)) return;
  return response.data;
}
