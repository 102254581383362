import { VIDEO_BUCKETS } from 'schemas/file';
import {
  getPreSignedPostInfo,
  getS3FileDownloadUrl,
  uploadVideo,
} from 'services/fileService';

import { convertUrlToPath } from './convertUrl';

export interface IAidtUploadResp {
  data: string;
  success: boolean;
}

export const aidtUpload = async ({
  bucketName,
  currentUser,
  e,
}: {
  bucketName: string;
  currentUser;
  e: React.ChangeEvent<HTMLInputElement>;
}): Promise<IAidtUploadResp> => {
  const file = e.target.files?.[0];

  if (!file) {
    return {
      data: '파일이 존재하지 않습니다.',
      success: false,
    };
  }
  const presignedResult = await getPreSignedPostInfo({
    bucketName,
    curriculumId: '00000000000000',
    fileName: e.target.files?.[0].name || '',
    userId: currentUser.user_id,
  });
  const uploadResult = await uploadVideo({
    bucketName,
    file: file as Blob,
    fileName: e.target.files?.[0].name || '',
    presignedPostInfo: presignedResult,
    role: 'T',
  });

  return {
    data: uploadResult || '',
    success: true,
  };
};

export const aidtGetPath = async ({ bucketName, currentUser, path }) => {
  if (!path) return '';
  const url = await getS3FileDownloadUrl({
    bucketName,
    filePath: path,
    userId: currentUser.user_id,
  });

  if (process.env.REACT_APP_NODE_ENV === 'development') {
    return convertUrlToPath(url);
  } else {
    return url;
  }
};

export const aidtUploadAndGetPath = async ({
  bucketName,
  currentUser,
  e,
}: {
  bucketName: string;
  currentUser;
  e: React.ChangeEvent<HTMLInputElement>;
}) => {
  const uploadResult = await aidtUpload({ bucketName, currentUser, e });
  const path = await aidtGetPath({
    bucketName,
    currentUser,
    path: uploadResult.data,
  });
  return {
    downlodablePath: path,
    filePath: uploadResult.data,
  };
};
