import {
  GetStudentClassMembersResponse,
  GetStudentNameResponse,
  GetStudentNamesDto,
  GetStudentNamesResponse,
  GetStudentResponse,
} from 'schemas/aidt-student';
import { BaseAidtDto } from 'schemas/base';
import { MockData } from 'services/dummyData';
import aidtInstance from 'utils/aditInstance';

const ENDPOINT = '/student';

// 학생 정보 조회
export const getStudent = async ({
  data,
  headers,
}: BaseAidtDto): Promise<GetStudentResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.STUDENT_ALL;
  }
  return (
    await aidtInstance.post(`${ENDPOINT}/all`, data, {
      headers,
    })
  ).data;
};

// 학생 성명 조회
export const getStudentName = async ({
  data,
  headers,
}: BaseAidtDto): Promise<GetStudentNameResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.STUDENT_NAME_LIST;
  }

  return (
    await aidtInstance.post(`${ENDPOINT}/name`, data, {
      headers,
    })
  ).data;
};

// 학생 성명 리스트 조회
export const getStudentNames = async ({
  data,
  headers,
}: GetStudentNamesDto): Promise<GetStudentNamesResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.STUDENT_NAME_LIST;
  }

  return (
    await aidtInstance.post(`${ENDPOINT}/name`, data, {
      headers,
    })
  ).data;
};

// 학생 학급 구성원 조회
export const getStudentClassMembers = async ({
  data,
  headers,
}: BaseAidtDto): Promise<GetStudentClassMembersResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.TEACHER_CLASS_MEMBER;
  }

  return (
    await aidtInstance.post(`${ENDPOINT}/class_member`, data, {
      headers,
    })
  ).data;
};
