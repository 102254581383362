import { AidtRequestDto, StudentNameDto } from 'schemas/aidt';
import {
  GetStudentClassMembersResponse,
  GetStudentNameResponse,
  GetStudentResponse,
} from 'schemas/aidt-student';
import { aidtService } from 'services/aidt';

import { mockApiService } from './mockApi';

export const AidtStudentService = aidtService.injectEndpoints({
  endpoints: build => ({
    // 학급 구성원 조회
    getClassMembers: build.query<
      GetStudentClassMembersResponse,
      AidtRequestDto
    >({
      query: dto => ({
        data: {
          access_token: dto.access_token,
          user_id: dto.user_id,
        },
        headers: {
          'Partner-ID': dto.partner_id,
        },
        method: 'POST',
        url: '/student/class_member',
      }),
    }),
    // 전체 정보 조회
    getStudentAll: build.query<GetStudentResponse, AidtRequestDto>({
      query: dto => ({
        data: {
          access_token: dto.access_token,
          user_id: dto.user_id,
        },
        headers: {
          'Partner-ID': dto.partner_id,
        },
        method: 'POST',
        url: '/student/all',
      }),
    }),
    // 성명 조회
    getStudentName: build.query<GetStudentNameResponse, StudentNameDto>({
      query: dto => ({
        data: {
          access_token: dto.access_token,
          ...(dto.user_ids
            ? { user_ids: dto.user_ids }
            : { user_id: dto.user_id }),
        },
        headers: {
          'Partner-ID': dto.partner_id,
        },
        method: 'POST',
        url: '/student/name',
      }),
    }),
  }),
});

export const {
  useGetClassMembersQuery,
  useGetStudentAllQuery,
  useGetStudentNameQuery,
  useLazyGetClassMembersQuery,
  useLazyGetStudentNameQuery,
} = AidtStudentService;
