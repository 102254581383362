import { useQuery } from '@tanstack/react-query';
import { getStudentStatisticsComparison } from 'apis/dashboard';
import {} from 'schemas/assignment';
import {
  GetStudentStatisticsComparisonDto,
  GetStudentStatisticsComparisonResponse,
  StudentStatisticData,
} from 'schemas/student-dashboard';

export function useStudentStatisticsComparison({
  headers,
  params,
}: GetStudentStatisticsComparisonDto) {
  return useQuery<
    GetStudentStatisticsComparisonResponse,
    Error,
    StudentStatisticData[]
  >({
    enabled: !!params.learningSysId && !!params.classUuids,
    queryFn: () => getStudentStatisticsComparison({ headers, params }),
    queryKey: ['student_statistics_comparison', { headers, params }],
    retry: false,
    select: data => data.data,
  });
}
