import { ContentTable, ContentTables } from 'schemas/learning';
import { CurrentSection, CurrentSubSection } from 'types/StudyState';

export interface UnitTable extends ContentTable {
  unitOrder: number;
}

export const calculateNextSectionInChapter = (
  contentTables,
  learningSysId: number,
) => {
  const currentSectionPointer = [0, 0, 0];
  let found = false;
  for (let i = 0; i < contentTables.length; i++) {
    const chapters = contentTables[i].chapter;
    for (let j = 0; j < chapters.length; j++) {
      const sections = chapters[j].section;
      for (let k = 0; k < sections.length; k++) {
        if (sections[k].learning_sys_id === learningSysId) {
          currentSectionPointer[0] = i;
          currentSectionPointer[1] = j;
          currentSectionPointer[2] = k;
          found = true;
          break;
        }
      }
    }
  }
  if (!found) return null;
  const nextSection =
    contentTables[currentSectionPointer[0]].chapter[currentSectionPointer[1]]
      .section[currentSectionPointer[2] + 1];
  if (!nextSection) return null;
  return nextSection;
};

export const calculatePrevSectionInChapter = (
  contentTables,
  learningSysId: number,
) => {
  const currentSectionPointer = [0, 0, 0];
  let found = false;
  for (let i = 0; i < contentTables.length; i++) {
    const chapters = contentTables[i].chapter;
    for (let j = 0; j < chapters.length; j++) {
      const sections = chapters[j].section;
      for (let k = 0; k < sections.length; k++) {
        if (sections[k].learning_sys_id === learningSysId) {
          currentSectionPointer[0] = i;
          currentSectionPointer[1] = j;
          currentSectionPointer[2] = k;
          found = true;
          break;
        }
      }
    }
  }
  if (!found) return null;
  const prevSection =
    contentTables[currentSectionPointer[0]].chapter[currentSectionPointer[1]]
      .section[currentSectionPointer[2] - 1];
  if (!prevSection) return null;
  return prevSection;
};

export const calculatePrevSection = (contentTables, learningSysId: number) => {
  const currentSectionPointer = [0, 0, 0];
  let found = false;
  for (let i = 0; i < contentTables.length; i++) {
    const chapters = contentTables[i].chapter;
    for (let j = 0; j < chapters.length; j++) {
      const sections = chapters[j].section;
      for (let k = 0; k < sections.length; k++) {
        if (sections[k].learning_sys_id === learningSysId) {
          currentSectionPointer[0] = i;
          currentSectionPointer[1] = j;
          currentSectionPointer[2] = k;
          found = true;
          break;
        }
      }
    }
  }
  if (!found) return null;
  let prevSection =
    contentTables[currentSectionPointer[0]].chapter[currentSectionPointer[1]]
      .section[currentSectionPointer[2] - 1];
  if (!prevSection) {
    if (
      contentTables[currentSectionPointer[0]].chapter[
        currentSectionPointer[1] - 1
      ] === undefined
    ) {
      if (contentTables[currentSectionPointer[0] - 1] === undefined) {
        return null;
      }
      prevSection =
        contentTables[currentSectionPointer[0] - 1].chapter[
          contentTables[currentSectionPointer[0] - 1].chapter.length - 1
        ].section[
          contentTables[currentSectionPointer[0] - 1].chapter[
            contentTables[currentSectionPointer[0] - 1].chapter.length - 1
          ].section.length - 1
        ];
    } else {
      prevSection =
        contentTables[currentSectionPointer[0]].chapter[
          currentSectionPointer[1] - 1
        ].section[
          contentTables[currentSectionPointer[0]].chapter[
            currentSectionPointer[1] - 1
          ].section.length - 1
        ];
    }
  }
  return prevSection;
};

export const findSubsection = (
  contentTables: ContentTables,
  id: string,
): CurrentSubSection | null => {
  for (let unitIndex = 0; unitIndex < contentTables.length; unitIndex++) {
    const unit = contentTables[unitIndex];
    for (
      let chapterIndex = 0;
      chapterIndex < unit.chapter.length;
      chapterIndex++
    ) {
      const chapter = unit.chapter[chapterIndex];
      let subSectionDocId = 1;
      for (
        let sectionIndex = 0;
        sectionIndex < chapter.section.length;
        sectionIndex++
      ) {
        const section = chapter.section[sectionIndex];
        for (
          let subSectionIndex = 0;
          subSectionIndex < section.subsection.length;
          subSectionIndex++
        ) {
          const foundSubsection = section.subsection[subSectionIndex];
          if (foundSubsection.learning_sys_id === parseInt(id)) {
            const data: CurrentSubSection = {
              chapterDocId: chapterIndex + 1,
              chapterId: chapter.learning_sys_id,
              chapterName: chapter.chapter_name,
              cls_id: foundSubsection.cls_id,
              learningSysId: foundSubsection.learning_sys_id,
              sectionId: section.learning_sys_id,
              subSectionDocId: subSectionDocId,
              subSectionId: foundSubsection.learning_sys_id,
              subSectionName: foundSubsection.sub_section_name,
              unitDocId: unitIndex + 1,
              unitId: unit.learning_sys_id,
              unitName: unit.unit_name,
            };
            return data;
          }
          subSectionDocId++;
        }
      }
    }
  }
  return null;
};

export const findSubsectionByClsId = (
  contentTables: ContentTables,
  id: string,
): CurrentSubSection | null => {
  for (let unitIndex = 0; unitIndex < contentTables.length; unitIndex++) {
    const unit = contentTables[unitIndex];
    for (
      let chapterIndex = 0;
      chapterIndex < unit.chapter.length;
      chapterIndex++
    ) {
      const chapter = unit.chapter[chapterIndex];
      let subSectionDocId = 1;
      for (
        let sectionIndex = 0;
        sectionIndex < chapter.section.length;
        sectionIndex++
      ) {
        const section = chapter.section[sectionIndex];
        for (
          let subSectionIndex = 0;
          subSectionIndex < section.subsection.length;
          subSectionIndex++
        ) {
          const foundSubsection = section.subsection[subSectionIndex];
          if (foundSubsection.cls_id === id) {
            const data: CurrentSubSection = {
              chapterDocId: chapterIndex + 1,
              chapterId: chapter.learning_sys_id,
              chapterName: chapter.chapter_name,
              cls_id: foundSubsection.cls_id,
              learningSysId: foundSubsection.learning_sys_id,
              sectionId: section.learning_sys_id,
              subSectionDocId: subSectionDocId,
              subSectionId: foundSubsection.learning_sys_id,
              subSectionName: foundSubsection.sub_section_name,
              unitDocId: unitIndex + 1,
              unitId: unit.learning_sys_id,
              unitName: unit.unit_name,
            };
            return data;
          }
          subSectionDocId++;
        }
      }
    }
  }
  return null;
};

export const findSection = (
  contentTables: ContentTables,
  id: string,
): CurrentSection | null => {
  for (let unitIndex = 0; unitIndex < contentTables.length; unitIndex++) {
    const unit = contentTables[unitIndex];
    for (
      let chapterIndex = 0;
      chapterIndex < unit.chapter.length;
      chapterIndex++
    ) {
      const chapter = unit.chapter[chapterIndex];
      let subSectionDocId = 1;
      for (
        let sectionIndex = 0;
        sectionIndex < chapter.section.length;
        sectionIndex++
      ) {
        const section = chapter.section[sectionIndex];
        if (section.learning_sys_id === parseInt(id)) {
          const data: CurrentSection = {
            chapterDocId: chapterIndex + 1,
            chapterId: chapter.learning_sys_id,
            chapterName: chapter.chapter_name,
            learningSysId: section.learning_sys_id,
            sectionDocId: sectionIndex + 1,
            sectionId: section.learning_sys_id,
            sectionName: section.section_name,
            subSectionDocId: subSectionDocId,
            subSectionId: section.learning_sys_id,
            subSections: section.subsection,
            unitDocId: unitIndex + 1,
            unitId: unit.learning_sys_id,
            unitName: unit.unit_name,
          };
          return data;
        }
        subSectionDocId++;
      }
    }
  }
  return null;
};

export const findUnitById = (
  contentTables: ContentTables,
  id: string,
): UnitTable | null => {
  for (let unitIndex = 0; unitIndex < contentTables.length; unitIndex++) {
    return { ...contentTables[unitIndex], unitOrder: unitIndex + 1 };
  }
  return null;
};

export const getGradeFromUrl = (
  url: string,
  inputGrade?: number,
): null | number => {
  const regex = /api\.(e|m|h)-(\d+)-\d+/;
  const match = url.match(regex);

  if (!match) {
    return null;
  }

  const schoolType = match[1];

  const grade = inputGrade ? inputGrade : parseInt(match[2], 10);

  let finalGrade: number;

  if (schoolType === 'e') {
    finalGrade = grade;
  } else if (schoolType === 'm') {
    finalGrade = 6 + grade;
  } else if (schoolType === 'h') {
    finalGrade = 9 + grade;
  } else {
    return null;
  }
  return finalGrade;
};

export const getGradeFromSubdomain = (subdomain: string) => {
  const school = subdomain.split('-')[0];
  const grade = subdomain.split('-')[1];
  if (!school || !grade) return 7; //중1로 기본값 설정
  if (school === 'e') {
    return parseInt(grade);
  } else if (school === 'm') {
    return 6 + parseInt(grade);
  } else if (school === 'h') {
    return 9 + parseInt(grade);
  }
};

export const getSchoolCodeFromSubdomain = (subdomain: string) => {
  const school = subdomain.split('-')[0];
  return school;
};

export const getSemesterFromSubdomain = (subdomain: string) => {
  const parts = subdomain.split('-');
  return parseInt(parts[parts.length - 1], 10);
};
