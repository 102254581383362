import { Button, Stack } from '@mui/material';
import { deleteReference } from 'apis/reference';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'types';

export default function ReferenceDetailActionButtons() {
  const [loading, setLoading] = useState(false);
  const { id, sectionId, subsectionId } = useParams();
  const currentUser = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const remove = async () => {
    setLoading(true);
    try {
      const result = await deleteReference({
        headers: {
          'Class-Info': currentUser.classInfo,
          role: currentUser.user_type,
          uuid: currentUser.user_id,
        },
        params: {
          id: Number(id),
        },
      });
      if (result.statusCode < 300) {
        alert('삭제되었습니다.');
        navigate(`/reference/${sectionId}/${subsectionId}`);
      }
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <Stack
      alignItems={'center'}
      direction={'row'}
      gap=".875rem"
      justifyContent={'start'}
      paddingTop={1}
    >
      <Button
        disabled={loading}
        onClick={() => {
          navigate(`/reference/${sectionId}/${subsectionId}/new?id=${id}`);
        }}
        size="large"
        sx={{
          backgroundColor: '#4F73C0',
          borderRadius: '.25rem',
          fontSize: '.9375rem',
        }}
        variant="contained"
      >
        {t('common.edit')}
      </Button>
      <Button
        disabled={loading}
        onClick={() => {
          if (confirm('정말 삭제하시겠습니까?')) {
            remove();
          }
        }}
        size="large"
        sx={{
          backgroundColor: '#4F73C0',
          borderRadius: '.25rem',
          fontSize: '.9375rem',
        }}
        variant="contained"
      >
        {t('common.delete')}
      </Button>
    </Stack>
  );
}
