import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  GetStudiedProblemsDto,
  GetStudiedProblemsResponse,
} from 'schemas/problem';
import { axiosBaseQuery } from 'services/api';

export const problemService = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: '/problem',
  }),
  endpoints: build => ({
    // 학생 대시보드 개념학습 전체 문제별 풀이내역 조회
    getStudiedProblems: build.query<
      GetStudiedProblemsResponse,
      GetStudiedProblemsDto
    >({
      query: ({ headers, params }) => ({
        headers,
        method: 'GET',
        params,
        url: '/studied-problems',
      }),
    }),
  }),
  reducerPath: 'problemApi',
  tagTypes: ['Problem'],
});

export const { useGetStudiedProblemsQuery, useLazyGetStudiedProblemsQuery } =
  problemService;
