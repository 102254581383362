import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';
import { S3FilePair } from 'app/board/board.types';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import ReferenceBreadcrumbs from 'app/components/Reference/ReferenceBreadcrumbs';
import ReferenceDetailActionButtons from 'app/components/Reference/ReferenceDetailActionButtons';
import { LucideArchive } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useRoutes } from 'react-router-dom';
import { VIDEO_BUCKETS } from 'schemas/file';
import {
  PatchReferenceDetailDataLikeReqDto,
  ReferenceDetail,
} from 'schemas/study';
import { getS3FileDownloadUrl } from 'services/fileService';
import {
  useGetReferenceDataByIdQuery,
  usePatchReferenceDataLikeMutation,
  usePatchReferenceDataViewMutation,
} from 'services/studyService';
import { RootState } from 'types';
import { aidtGetPath } from 'utils/aidtUpload';
import { findSection, findSubsectionByClsId } from 'utils/studyUtils';

import { SubHeader } from '../../components/Common/SubHeader';
import { TextButton } from '../../components/Common/TextButton';
import { Layout } from '../../components/Layout';

export function ReferenceDetailPage() {
  const dispatch = useDispatch();
  const { id, sectionId, subsectionId } = useParams();
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );
  const sectionData = findSection(contentTables, sectionId || '');
  const subSectionData = findSubsectionByClsId(
    contentTables,
    subsectionId || '',
  );
  const { t } = useTranslation();
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const currentUser = useSelector((state: RootState) => state.user);
  const userId = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const [referenceData, setReferenceData] = useState<ReferenceDetail | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [isLiked, setIsLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [isClickedLike, setIsClickedLike] = useState(false);
  const [downloadPaths, setDownloadPaths] = useState<Array<S3FilePair>>([]);
  const navigate = useNavigate();

  const { data: referenceRes, refetch } = useGetReferenceDataByIdQuery(
    {
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
      referenceDataId: id ? parseInt(id, 10) : 0,
    },
    {
      skip: !userId || !classInfo || !id,
    },
  );

  const [PatchReferenceDataView] = usePatchReferenceDataViewMutation();
  const [PatchReferenceDataLike] = usePatchReferenceDataLikeMutation();

  const getFileNameByPath = (path: string) => {
    return path.split('/').pop() ?? '';
  };

  const isImageFile = (fileName: string) => {
    const extension = fileName.split('.').pop()?.toLowerCase();
    return extension ? imageExtensions.includes(extension) : false;
  };

  useEffect(() => {
    if (!userId || !classInfo || !userType || !id) return;
    refetch();
    PatchReferenceDataView({
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
      referenceDataId: parseInt(id, 10),
    });
  }, [userId, classInfo, userType, id]);

  useEffect(() => {
    if (!userId || !classInfo || !userType || !id || !isClickedLike) return;
    updateLike({
      data: {
        like: isLiked,
      },
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
      referenceDataId: parseInt(id, 10),
    });
    setLikeCount(prevCnt => (isLiked ? ++prevCnt : --prevCnt));
  }, [isLiked, isClickedLike]);

  useEffect(() => {
    if (!referenceRes || !referenceRes.data) {
      // alert('참고자료 내용을 불러오는데 실패했습니다.');
      // setLoading(false);
      // navigate(`/reference/${sectionId}/${subsectionId}`);
      return;
    }
    setReferenceData(referenceRes.data);
    setIsLiked(referenceRes.data.haveILiked);
    setLikeCount(referenceRes.data.likeCount);
    setCommentCount(referenceRes.data.commentCount);
    setLoading(false);
  }, [referenceRes]);

  const updateLike = async (reqData: PatchReferenceDetailDataLikeReqDto) => {
    setLoading(true);
    await PatchReferenceDataLike(reqData);
    setIsClickedLike(false);
    setLoading(false);
  };

  const downloadFile = async (videoPath: string) => {
    const downloadUrl = await getS3FileDownloadUrl({
      bucketName: VIDEO_BUCKETS.REFERENCE,
      filePath: videoPath,
      userId,
    });
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = videoPath.split('/').pop() ?? '';
    document.body.appendChild(link);
    link.click();
  };

  const toggleLike = () => {
    setIsLiked(prevIsLiked => !prevIsLiked);
    setIsClickedLike(true);
  };

  const getDownloadablePaths = async () => {
    setLoading(true);
    const paths = referenceData?.filePaths || [];
    const tmp: Array<S3FilePair> = [];
    if (!paths) return;
    for await (const path of paths) {
      const tmpPathObj = {
        downlodablePath: '',
        filePath: path,
      };
      const downlodablePath = await aidtGetPath({
        bucketName: VIDEO_BUCKETS.REFERENCE,
        currentUser,
        path,
      });
      tmpPathObj.downlodablePath = downlodablePath;
      tmp.push(tmpPathObj);
    }
    setDownloadPaths(tmp);
    setLoading(false);
  };

  useEffect(() => {
    if (referenceData?.filePaths) {
      getDownloadablePaths();
    }
  }, [referenceData]);

  return (
    <Layout
      customContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100% !important',
      }}
      isDisableGutters={true}
      width={false}
    >
      <Backdrop
        open={loading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SecondaryHeader
        backgroundColor="#4F73C0"
        handleRightButtonClicked={() =>
          navigate(`/reference/${sectionId}/${subsectionId}`)
        }
        rightButtonLabel="참고자료 목록으로 돌아가기"
        rightButtonText="목록으로"
        title={t('reference.reference_material')}
        titleColor="white"
        titleIcon={<LucideArchive />}
      />
      <ReferenceBreadcrumbs section={sectionData} subSection={subSectionData} />
      <Container
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, pb: 2 }}
      >
        <SubHeader
          createdAt={referenceData?.createdAt}
          scope={referenceData?.scope}
          title={referenceData?.title ?? ''}
          userUuid={referenceData?.userUuid}
          viewCount={referenceData?.viewCount || 0}
        />
        <Box
          sx={{
            borderBottom: '1px solid #DCDCDC',
            flex: '1 1 0%',
            pb: 7,
            position: 'relative',
          }}
        >
          <div className="space-y-4 py-4">
            {downloadPaths
              .filter(v =>
                imageExtensions.includes(
                  v.filePath.split('.').pop()?.toLowerCase() || '',
                ),
              )
              .map((downloadable, idx) => (
                <img
                  alt="참고자료 이미지"
                  key={idx}
                  src={downloadable.downlodablePath}
                  style={{ width: '100%' }}
                />
              ))}
          </div>
          <Typography sx={{ my: 2 }} variant="body1">
            {referenceData?.content}
          </Typography>

          <Stack
            alignItems={'center'}
            direction={'row'}
            gap=".875rem"
            justifyContent={'start'}
            paddingTop={1}
            sx={{ bottom: '.625rem', left: 0, position: 'absolute' }}
          >
            {referenceData?.filePaths.map((path: string, idx) => (
              <Button
                key={idx}
                onClick={() => {
                  downloadFile(path);
                }}
                size="large"
                // startIcon={<img src={'/icons/ppt.svg'} alt="ppt" />}
                startIcon={
                  <img
                    alt={
                      isImageFile(getFileNameByPath(path)) ? 'image' : 'file'
                    }
                    src={
                      isImageFile(getFileNameByPath(path))
                        ? '/icons/image.svg'
                        : '/icons/file.svg'
                    }
                  />
                }
                sx={{
                  '& img': {
                    height: '1.25rem',
                    width: '1.25rem',
                  },
                  borderColor: '#9D9C9B',
                  borderRadius: '.25rem',
                  color: 'black',
                  fontSize: '.625rem',
                  py: '.5rem',
                  textTransform: 'none',
                }}
                variant="outlined"
              >
                {getFileNameByPath(path)}
              </Button>
            ))}
          </Stack>
        </Box>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Stack
            alignItems={'center'}
            direction={'row'}
            gap=".9375rem"
            justifyContent={'start'}
            paddingTop={1}
          >
            <TextButton
              name={`좋아요 ${likeCount}`}
              onClick={toggleLike}
              src={isLiked ? 'heart_filled' : 'heart'}
              textStyle={{
                color: '#818181',
                fontSize: '.625rem',
                fontWeight: 'medium',
              }}
            />
          </Stack>
          {userType === 'T' && <ReferenceDetailActionButtons />}
        </Stack>
      </Container>
    </Layout>
  );
}

export default ReferenceDetailPage;
