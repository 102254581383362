import axios from 'axios';
import {
  EndClassDto,
  GetTeacherClassMembersDto,
  GetTeacherClassMembersResponse,
  GetTeacherNamesDto,
  GetTeacherNamesResponse,
  GetTeacherResponse,
  StartClassDto,
} from 'schemas/aidt-teacher';
import { BaseAidtDto } from 'schemas/base';
import { MockData } from 'services/dummyData';
import aidtInstance from 'utils/aditInstance';

import { API_DOMAIN, LOGIN_DOMAIN } from '../constants';

const ENDPOINT = '/teacher';

// 선생님 정보 조회
export const getTeacher = async ({
  data,
  headers,
}: BaseAidtDto): Promise<GetTeacherResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.TEACHER_ALL;
  }
  return (
    await aidtInstance.post(`${ENDPOINT}/all`, data, {
      headers,
    })
  ).data;
};

// 선생님 학급 구성원 조회
export const getTeacherClassMembers = async ({
  data,
  headers,
}: GetTeacherClassMembersDto): Promise<GetTeacherClassMembersResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.TEACHER_CLASS_MEMBER;
  }
  return (
    await aidtInstance.post(`${ENDPOINT}/class_member`, data, { headers })
  ).data;
};

// 선생님 이름 조회
export const getTeacherNames = async ({
  data,
  headers,
}: GetTeacherNamesDto): Promise<GetTeacherNamesResponse> => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return MockData.TEACHER_NAMES;
  }
  return (await aidtInstance.post(`${ENDPOINT}/name`, data, { headers })).data;
};

// 토큰 유효 검사
const isTokenExpired = (response: any) => {
  if (response.status === 403 || response.status === 401) {
    window.location.href = LOGIN_DOMAIN;
  }
  return false;
};

// 수업 시작
export const startClass = async ({ data, headers }: StartClassDto) => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return { code: '00000' };
  }
  const response = await axios.post(
    `${API_DOMAIN}/aidt_dashboard/initialized`,
    data,
    {
      headers,
    },
  );

  if (isTokenExpired(response)) return;

  return response.data;
};

// 수업 종료
export const endClass = async ({ data, headers }: EndClassDto) => {
  if (data.access_token.token === 'MOCK-PENETRATION') {
    return { code: '00000' };
  }
  const response = await axios.post(
    `${API_DOMAIN}/aidt_dashboard/terminated`,
    data,
    {
      headers,
    },
  );

  if (isTokenExpired(response)) return;

  return response.data;
};
