import { GetProblemRes } from 'schemas/problem';

import {
  DeleteQuestionAnswerReqDto,
  GetQuestionAnswersReqDto,
  GetQuestionAnswersRes,
  GetQuestionsReqDto,
  GetQuestionsRes,
  PatchQuestionReqDto,
  PatchQuestionRes,
  PostQuestionAnswerReqDto,
  PostQuestionAnswerRes,
  PostQuestionReqDto,
  PostQuestionRes,
} from '../schemas/question';
import { apiService } from './api';

export const replyService = apiService.injectEndpoints({
  endpoints: build => ({
    deleteQuestionAnswer: build.mutation<
      void,
      { body: DeleteQuestionAnswerReqDto; id: number }
    >({
      query: ({ body, id }) => ({
        headers: {
          Uuid: body.userId,
        },
        method: 'DELETE',
        url: `/shared_solution_video/${id}`,
      }),
    }),
    getProblem: build.query<GetProblemRes, { id: number }>({
      query: body => ({
        body,
        method: 'GET',
        url: '/problem',
      }),
    }),
    getQuestionAnswers: build.query<
      GetQuestionAnswersRes,
      { body: GetQuestionAnswersReqDto; id: number }
    >({
      query: ({ body, id }) => {
        const params = new URLSearchParams({
          onlyMine: body.onlyMine ? 'true' : 'false',
          page: body.page.toString(),
          pageSize: body.pageSize.toString(),
        });

        return {
          headers: {
            'Class-Info': body.classInfo,
            Uuid: body.userId,
          },
          method: 'GET',
          url: `/question/${id}/answer?${params.toString()}`,
        };
      },
    }),
    getQuestions: build.query<GetQuestionsRes, GetQuestionsReqDto>({
      query: ({ headers, params }) => {
        return {
          headers,
          method: 'GET',
          params,
          url: '/question',
        };
      },
    }),
    patchQuestion: build.mutation<PatchQuestionRes, PatchQuestionReqDto>({
      query: ({ classInfo, id, role, userId }) => ({
        headers: {
          'Class-Info': classInfo,
          Role: role,
          Uuid: userId,
        },
        method: 'PATCH',
        url: `/question/${id}`,
      }),
    }),
    postQuestion: build.mutation<PostQuestionRes, { body: PostQuestionReqDto }>(
      {
        query: ({ body }) => ({
          data: {
            problemId: body.problemId,
            scope: body.scope,
            title: body.title,
          },
          headers: {
            Uuid: body.userId,
          },
          method: 'POST',
          url: `/question`,
        }),
      },
    ),
    postQuestionAnswer: build.mutation<
      PostQuestionAnswerRes,
      { body: PostQuestionAnswerReqDto }
    >({
      query: ({ body }) => ({
        data: {
          questionId: body.questionId,
          scope: body.scope,
          videoPath: body.videoPath,
        },
        headers: {
          'Class-Info': body.classInfo,
          Role: body.role,
          Uuid: body.userId,
        },
        method: 'POST',
        url: '/shared-solution-video',
      }),
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useLazyGetProblemQuery,
  usePatchQuestionMutation,
  usePostQuestionAnswerMutation,
  usePostQuestionMutation,
} = replyService;

export default replyService;
