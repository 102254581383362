import { Box, Typography } from '@mui/material';
import React from 'react';

const UploadLoading = () => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '10px',
        display: 'flex',
        height: 'calc(100% + 300px)', // submit form까지 가리기 위해 + 300px
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'fixed',
        width: '100%',
        zIndex: 4,
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          zIndex: 5,
        }}
      >
        <img
          height={100}
          src="/icons/whiteboard/origin/status/upload-processing.svg"
          width={100}
        />
        <Typography
          style={{
            color: 'black',
            fontSize: '24px',
            fontWeight: 'bold',
            marginTop: '20px',
          }}
        >
          전자칠판이 업로드 중입니다...
        </Typography>
      </Box>
    </Box>
  );
};

export default UploadLoading;
