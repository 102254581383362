import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface ReminderDialogState {
  message: string;
  open: boolean;
}

interface ReminderDialogAction {
  setMessage: (message: string) => void;
  toggleDialog: (open: boolean) => () => void;
}

const useReminderDialogStore = create<
  ReminderDialogAction & ReminderDialogState
>()(
  devtools(
    persist(
      set => ({
        message: '',
        open: false,
        setMessage: message => set(state => ({ ...state, message })),

        toggleDialog: (open: boolean) => () =>
          set(state => ({ ...state, open })),
      }),

      { name: 'reminder' },
    ),
  ),
);

export default useReminderDialogStore;
