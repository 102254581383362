import { useMutation } from '@tanstack/react-query';
import { updateDashboard } from 'apis/dashboard';
import { queryClient } from 'utils/tanstack-query';

export function useUpdateDashboard() {
  return useMutation({
    mutationFn: updateDashboard,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['learning_system'],
      });
      queryClient.invalidateQueries({
        queryKey: ['student_statistics'],
      });
      queryClient.invalidateQueries({
        queryKey: ['strategy_comment'],
      });
    },
  });
}
