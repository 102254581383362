import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import ReportIcon from '@mui/icons-material/Report';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import {
  LucideChevronRight,
  LucideTriangle,
  LucideTriangleRight,
} from 'lucide-react';
import * as React from 'react';

const actions = [
  { icon: <CloseIcon />, name: '오류신고' },
  { icon: <HelpIcon />, name: '도움말' },
  { icon: <ReportIcon />, name: '닫기' },
];

export default function SpeedDialTooltipOpen() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      sx={{
        bottom: 15,
        flexGrow: 1,
        left: 15,
        position: 'fixed',
        transform: 'translateZ(0px)',
        zIndex: 150,
      }}
    >
      <Backdrop open={open} />
      <button className="border border-black px-4 shadow py-2 hover:shadow-xl transition-all rounded text-black font-bold text-sm bg-white flex items-center justify-center gap-1">
        <span>HELP</span>
        <LucideChevronRight size={16} />
      </button>
      {/* <SpeedDial
        FabProps={{
          sx: {
            '&:hover': {
              backgroundColor: 'rgba(139,122,122,0.5)',
            },
            backgroundColor: '#EBEBEB',
            border: '2px solid #8b7a7a',
            borderRadius: '4px',
            boxShadow: 'none',
            display: open ? 'none' : 'block',
            height: '30px',
            minHeight: 'inherit',

            width: '55px',
          },
        }}
        ariaLabel="도움 옵션 열기"
        icon={
          <SpeedDialIcon
            icon={
              <span className="text-base font-bold text-black flex items-center justify-center gap-1">
                <span>HELP</span>
                <LucideChevronRight />
              </span>
            }
            openIcon={
              <Typography sx={{ color: '#705F5F' }} variant="subtitle2">
                닫기
              </Typography>
            }
          />
        }
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        sx={{
          '& *': {
            transition: 'none !important',
          },
          '& .MuiSpeedDial-actions': {
            paddingBottom: '35px',
          },
          backgroundColor: open ? '#C9BEBE' : 'inherit',
          borderRadius: '4px',
          bottom: '-30px',
          left: 0,
          position: 'absolute',
        }}
      >
        {actions.map(action => (
          <SpeedDialAction
            delay={0}
            icon={
              <>
                <Typography variant="subtitle2">{action.name}</Typography>
                {action.name === '닫기' && <CloseIcon />}
              </>
            }
            key={action.name}
            onClick={handleClose}
            sx={{
              '&:hover': {
                backgroundColor: 'rgba(139,122,122,0.5)',
              },
              backgroundColor: action.name === '닫기' ? 'inherit' : '#8B7A7A',
              border: action.name === '닫기' ? 'inherit' : '2px solid #8b7a7a',
              borderRadius: '10px',
              boxShadow: 'none',
              color: action.name === '닫기' ? 'black' : 'white',
              height: action.name === '닫기' ? 'inherit' : '50px',
              letterSpacing: '-0.5px',
              marginTop: action.name === '닫기' ? '8px' : '0',
              minHeight: 'inherit',
              paddingLeft: action.name === '닫기' ? '8px' : '0',
              width: action.name === '닫기' ? 'inherit' : '70px',
            }}
          />
        ))}
      </SpeedDial> */}
    </Box>
  );
}
