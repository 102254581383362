import { useQuery } from '@tanstack/react-query';
import { checkAssignmentSubmission } from 'apis/assignment';
import {
  CheckAssignmentSubmissionDto,
  CheckAssignmentSubmissionResponse,
} from 'schemas/assignment';

export function useCheckAssignmentSubmission({
  headers,
  params,
}: CheckAssignmentSubmissionDto) {
  return useQuery<CheckAssignmentSubmissionResponse, Error>({
    enabled: !!params.learningSysId && !!params.classUuids,
    queryFn: () => checkAssignmentSubmission({ headers, params }),
    queryKey: ['check_assignment_submission', { headers, params }],
    retry: false,
  });
}
