import {
  ReqCreateAnnouncement,
  ReqCreateComment,
  ReqDeleteAnnouncement,
  ReqDeleteComment,
  ReqGetArticle,
  ReqGetBoard,
  ReqLikeAnnouncement,
  ReqPatchAnnouncement,
  RespCreateAnnouncement,
  RespDeleteAnnouncement,
  RespDeleteComment,
  RespGetArticle,
  RespGetBoard,
  RespLikeAnnouncement,
} from 'schemas/announcement';
import { GetLearningSystemResponse } from 'schemas/learning';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/announcement';

// 공지사항 목록
export const getBoard = async ({
  headers,
}: ReqGetBoard): Promise<RespGetBoard> => {
  return (await axiosInstance.get(`${ENDPOINT}/board`, { headers })).data;
};

// 공지사항 조회
export const getArticle = async ({
  headers,
  params,
}: ReqGetArticle): Promise<RespGetArticle> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/detail/${params.id}`, { headers })
  ).data;
};

// 댓글 작성
export const createComment = async ({
  data,
  headers,
}: ReqCreateComment): Promise<RespGetArticle> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/comment/create`, data, { headers })
  ).data;
};

// 공지 작성
export const createAnnouncement = async ({
  data,
  headers,
}: ReqCreateAnnouncement): Promise<RespCreateAnnouncement> => {
  return (await axiosInstance.post(`${ENDPOINT}/create`, data, { headers }))
    .data;
};

// 공지 수정
export const patchAnnouncement = async ({
  data,
  headers,
  params,
}: ReqPatchAnnouncement): Promise<RespCreateAnnouncement> => {
  return (
    await axiosInstance.patch(`${ENDPOINT}/patch/${params.id}`, data, {
      headers,
    })
  ).data;
};

// 공지 삭제
export const deleteAnnouncement = async ({
  headers,
  params,
}: ReqDeleteAnnouncement): Promise<RespDeleteAnnouncement> => {
  return (
    await axiosInstance.delete(`${ENDPOINT}/delete/${params.id}`, {
      headers,
    })
  ).data;
};

// 공지 좋아요
export const likeAnnouncement = async ({
  data,
  headers,
}: ReqLikeAnnouncement): Promise<RespLikeAnnouncement> => {
  return (await axiosInstance.post(`${ENDPOINT}/like`, data, { headers })).data;
};

// 댓글 삭제
export const deleteComment = async ({
  headers,
  params,
}: ReqDeleteComment): Promise<RespDeleteComment> => {
  return (
    await axiosInstance.delete(`${ENDPOINT}/comment/delete/${params.id}`, {
      headers,
    })
  ).data;
};
