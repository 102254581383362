import { Modal, Typography } from '@mui/material';
import { LucideHelpCircle, LucideX } from 'lucide-react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types';

import {
  Student_HelpAssessment,
  Student_HelpChapter,
  Student_HelpConcept,
  Student_HelpDashboard,
  Student_HelpNotiAndSettings,
  Student_HelpNotice,
  Student_HelpPlanNote,
  Student_HelpQA,
  Student_HelpStudy,
  Student_HelpTotalChapter,
  Teacher_HelpAssessment,
  Teacher_HelpChapter,
  Teacher_HelpConcept,
  Teacher_HelpDashboard,
  Teacher_HelpNotiAndSettings,
  Teacher_HelpNotice,
  Teacher_HelpPlanNote,
  Teacher_HelpQA,
  Teacher_HelpStudy,
  Teacher_HelpTotalChapter,
} from '../Common/help/HelpPages';

export default function CommonHelpModal() {
  const [openHelpModal, setOpenHelpModal] = useState(false);
  const handleHelpModalOpen = () => setOpenHelpModal(true);
  const handleHelpModalClose = () => setOpenHelpModal(false);
  const leftSectionMenus = [
    '전체목차',
    '소단원목차',
    '학생희망노트',
    '개념학습',
    '풀이학습',
    '대시보드',
    '평가시험',
    '공지사항',
    '질문/답변',
    '알람/설정',
  ];
  const rightSectionContents = [
    [Student_HelpTotalChapter, Teacher_HelpTotalChapter],
    [Student_HelpChapter, Teacher_HelpChapter],
    [Student_HelpPlanNote, Teacher_HelpPlanNote],
    [Student_HelpConcept, Teacher_HelpConcept],
    [Student_HelpStudy, Teacher_HelpStudy],
    [Student_HelpDashboard, Teacher_HelpDashboard],
    [Student_HelpAssessment, Teacher_HelpAssessment],
    [Student_HelpNotice, Teacher_HelpNotice],
    [Student_HelpQA, Teacher_HelpQA],
    [Student_HelpNotiAndSettings, Teacher_HelpNotiAndSettings],
  ];
  const role = useSelector((state: RootState) => state.user.user_type);
  const primaryBgColor =
    role === 'T' ? 'bg-aidt-teacherPrimaryBg' : 'bg-aidt-studentPrimaryBg';
  const primaryBorderColor =
    role === 'T'
      ? 'border-aidt-teacherPrimaryBorder'
      : 'border-aidt-studentPrimaryBorder';
  const accentBgColor =
    role === 'T' ? 'bg-aidt-teacherAccentBg' : 'bg-aidt-studentAccentBg';
  const accentBorderColor =
    role === 'T'
      ? 'border-aidt-teacherAccentBorder'
      : 'border-aidt-studentAccentBorder';
  const [currentMenu, setCurrentMenu] = useState(0);
  return (
    <>
      <button
        className="border z-10 font-bold flex bg-white shadow shadow-slate-600/40 items-center justify-center gap-2 border-slate-800 px-5 py-2 text-base fixed bottom-5 left-5"
        onClick={handleHelpModalOpen}
      >
        <LucideHelpCircle size={18} />
        <span>HELP</span>
      </button>
      <Modal
        aria-describedby="사용자 도움말 컨텐츠"
        aria-labelledby="사용자 도움말"
        disableAutoFocus
        onClose={handleHelpModalClose}
        open={openHelpModal}
      >
        <section className="absolute top-[50%] left-[50%] -translate-x-[50%] bg-white border border-black rounded-xl w-[1000px] h-[500px] -translate-y-[50%]">
          <div className="text-2xl border-b border-slate-500 px-3 py-2 flex items-center justify-between">
            <div className="w-1/5"></div>
            <div className="w-3/5 flex items-center font-bold justify-center">
              도움말
            </div>
            <div className="w-1/5 flex items-center justify-end">
              <button
                className="p-1"
                onClick={() => {
                  handleHelpModalClose();
                }}
              >
                <LucideX size={28} />
              </button>
            </div>
          </div>
          <div className="flex items-start justify-between gap-2 h-[439px]">
            <div className="w-60 flex p-3 gap-3 h-full flex-col items-center justify-start h-[440px] overflow-y-auto">
              {leftSectionMenus.map((menu, index) => (
                <button
                  className={
                    (currentMenu === index
                      ? `${accentBorderColor} ${accentBgColor} text-white font-black `
                      : `${primaryBorderColor} ${primaryBgColor} text-black `) +
                    `py-3 hover:${accentBorderColor} hover:font-bold transition-all w-full border rounded-xl`
                  }
                  key={`MENU-${menu}`}
                  onClick={() => {
                    setCurrentMenu(index);
                  }}
                >
                  {menu}
                </button>
              ))}
            </div>
            <div className="flex-grow p-4 pl-0">
              <div className={`rounded-xl border ${primaryBorderColor}`}>
                <div
                  className={`${primaryBgColor} font-bold text-xl border-b ${primaryBorderColor} text-center w-full p-3 rounded-t-xl`}
                >
                  {leftSectionMenus[currentMenu]}
                </div>
                <div className="p-3 h-[350px] max-w-[730px] rounded-b-xl bg-white overflow-y-auto">
                  {role === 'T'
                    ? rightSectionContents[currentMenu][1]
                    : rightSectionContents[currentMenu][0]}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </>
  );
}
