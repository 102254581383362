import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { EvaluationState } from '../../../types/EvaluationState';

// Define the initial state using that type
const initialState: EvaluationState = {
  done: {
    achievementLevel: 0,
    averageScore: 0,
    score: 0,
  },
  id: 1,
  isEnded: false,
  isOver: false,
  isStart: false,
  problems: [],
  questions: [],
  result: null,
  type: 'diagnostic', // 진단평가, 형성평과, 총괄평가
  unit: '', // 대단원, 중단원, 소단원
};

export const evaluationSlice = createSlice({
  initialState,
  name: 'evaluation',
  reducers: {
    init: state => {
      state.id = 1;
      state.isStart = false;
      state.isOver = false;
      state.isEnded = false;
      state.unit = '';
      state.type = 'diagnostic';
      state.result = null;
      state.problems = [];
      state.questions = [];
      state.done = {
        achievementLevel: 0,
        averageScore: 0,
        score: 0,
      };
    },
    setDone: (state, action: PayloadAction<any>) => {
      state.done = action.payload;
    },
    setId: (state, action: PayloadAction<number>) => {
      state.id = action.payload;
    },
    setIsEnded: (state, action: PayloadAction<boolean>) => {
      state.isEnded = action.payload;
    },
    setIsOver: (state, action: PayloadAction<boolean>) => {
      state.isOver = action.payload;
    },
    setIsStart: (state, action: PayloadAction<boolean>) => {
      state.isStart = action.payload;
    },
    setProblems: (state, action: PayloadAction<any[]>) => {
      state.problems = state.problems.concat(action.payload);
    },
    setQuestions: (state, action: PayloadAction<any[]>) => {
      state.questions = action.payload;
    },
    setResult: (state, action: PayloadAction<any>) => {
      state.result = action.payload;
    },
    setType: (
      state,
      action: PayloadAction<
        'assignment' | 'diagnostic' | 'formative' | 'summative'
      >,
    ) => {
      state.type = action.payload;
    },
    setUnit: (
      state,
      action: PayloadAction<'' | 'large' | 'medium' | 'small' | null>,
    ) => {
      state.unit = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  init,
  setDone,
  setId,
  setIsEnded,
  setIsOver,
  setIsStart,
  setProblems,
  setQuestions,
  setResult,
  setType,
  setUnit,
} = evaluationSlice.actions;

export default evaluationSlice.reducer;
