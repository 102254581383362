import axios from 'axios';
import mimeDb from 'mime-db';
import { VIDEO_BUCKETS } from 'schemas/file';
import { z } from 'zod';

import apiService from './apiService';

export type ValueOfUnion<T extends { [k: string]: unknown }> = T[keyof T];

const PresignedPostInfoSchema = z.object({
  filePath: z.string(),
  url: z.string().url(),
});

const GetPreSignedPostInfoReqSchema = z.object({
  bucketName: z.enum(Object.values(VIDEO_BUCKETS) as [string, ...string[]]),
  curriculumId: z.string(),
  fileName: z.string(),
  problemId: z.string().optional(),
  userId: z.string(),
});

const UploadVideoReqSchema = z.object({
  bucketName: z.string(),
  file: z.instanceof(Blob),
  fileName: z.string(),
  presignedPostInfo: PresignedPostInfoSchema,
  role: z.string(),
});

const UploadVideoResSchema = z.string().url();

export type PresignedPostInfo = z.infer<typeof PresignedPostInfoSchema>;
export type GetPreSignedPostInfoReq = z.infer<
  typeof GetPreSignedPostInfoReqSchema
>;
export type UploadVideoReq = z.infer<typeof UploadVideoReqSchema>;
export type UploadVideoRes = z.infer<typeof UploadVideoResSchema>;

const getMimeType = name => {
  const extension = name.split('.').pop();
  for (const type in mimeDb) {
    if (
      mimeDb[type].extensions &&
      mimeDb[type].extensions.includes(extension)
    ) {
      return type;
    }
  }
  return 'application/octet-stream';
};

const S3FileDownloadSchema = z.object({
  bucketName: z.string(),
  filePath: z.string(),
  userId: z.string(),
});

export type S3FileDownloadReqDto = z.infer<typeof S3FileDownloadSchema>;

export const getPreSignedPostInfo = async ({
  bucketName,
  curriculumId,
  fileName,
  problemId,
  userId,
}: GetPreSignedPostInfoReq): Promise<PresignedPostInfo> => {
  const params = {
    bucketName,
    curriculumId,
    ...(problemId && { problemId }),
    fileName,
  };

  const queryString = new URLSearchParams(params);

  try {
    const res = await apiService.get('/file/upload?' + queryString, {
      headers: {
        uuid: userId,
      },
    });
    return res.data.data as PresignedPostInfo;
  } catch (error) {
    throw new Error('Failed to get presigned post info');
  }
};

export const getS3FileDownloadUrl = async ({
  bucketName,
  filePath,
  userId,
}: S3FileDownloadReqDto): Promise<string> => {
  try {
    const res = await apiService.get('/file/download', {
      headers: {
        uuid: userId,
      },
      params: {
        bucketName,
        filePath,
      },
    });
    return res.data.data as string;
  } catch (error) {
    throw new Error('Failed to get s3 file download url');
  }
};

const getProxyPath = (bucketName: string) => {
  let path = '';
  switch (bucketName) {
    case VIDEO_BUCKETS.SHARED_CONCEPT:
      path = '/shared-concept-videos-upload';
      break;
    case VIDEO_BUCKETS.ANSWER:
      path = '/answers-upload';
      break;
    case VIDEO_BUCKETS.REFERENCE:
      path = '/reference-data-upload';
      break;
    case VIDEO_BUCKETS.COMMON_CONCEPT:
      path = '/concept-videos-upload';
      break;
    case VIDEO_BUCKETS.CONCEPT_SOLUTION:
      path = '/concept-solution-videos-upload';
      break;
    case VIDEO_BUCKETS.ANNOUNCEMENT:
      path = '/announcement-data-upload';
      break;
  }
  return path;
};
export const uploadVideo = async ({
  bucketName,
  file,
  fileName,
  presignedPostInfo,
  role,
}: UploadVideoReq): Promise<UploadVideoRes | null> => {
  if (file) {
    try {
      const url = presignedPostInfo.url;
      const filePath = presignedPostInfo.filePath;

      const blob = new Blob([file], { type: file.type });

      let uploadUrl = '';
      if (process.env.REACT_APP_NODE_ENV === 'development') {
        const uploadPath = url.replace(/^[^:]+:\/\/[^/]+/, '');
        const proxyPath = getProxyPath(bucketName);
        uploadUrl = proxyPath + uploadPath;
      } else {
        uploadUrl = url;
      }

      await axios.put(uploadUrl, blob, {
        headers: {
          'Content-Type': getMimeType(fileName),
          Role: role,
        },
      });

      return filePath;
    } catch (error) {
      //   console.error('Failed to upload video', error);
      return null;
    }
  }
  return null;
};
