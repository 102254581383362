import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiService from '../../services/apiService';
import {
  AnnouncementArticle,
  Notice,
  NoticeState,
} from '../../types/NoticeState';

const initialState: NoticeState = {
  currentPage: 1,
  error: null,
  noticeDetail: null,
  notices: [],
  status: 'idle',
  totalPage: 1,
};

export const fetchNoticeDetail = createAsyncThunk(
  'notice/fetchNoticeDetail',
  async (id: number) => {
    const response = await apiService.get(`/notice/${id}`); // API 서비스를 사용하여 공지사항의 상세 정보를 불러옵니다.
    return response.data;
  },
);

const noticeSlice = createSlice({
  initialState,
  name: 'notice',
  reducers: {
    fetchNoticeDetailError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchNoticeDetailStart: state => {
      state.status = 'loading';
    },
    fetchNoticeDetailSuccess: (state, action: PayloadAction<Notice>) => {
      state.status = 'succeeded';
      state.noticeDetail = action.payload;
    },
    fetchNoticesError: (state, action: PayloadAction<string>) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    fetchNoticesStart: state => {
      state.status = 'loading';
    },
    fetchNoticesSuccess: (state, action: PayloadAction<Notice[]>) => {
      state.status = 'succeeded';
      state.notices = action.payload;
    },
    setNoticeDetail: (state, action: PayloadAction<AnnouncementArticle>) => {
      state.noticeDetail = action.payload;
    },
  },
});

export const {
  fetchNoticeDetailError,
  fetchNoticeDetailStart,
  fetchNoticeDetailSuccess,
  fetchNoticesError,
  fetchNoticesStart,
  fetchNoticesSuccess,
  setNoticeDetail,
} = noticeSlice.actions;

export default noticeSlice.reducer;
