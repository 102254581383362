import { Box, Button, Stack, Typography } from '@mui/material';
import { useAchievementStandard } from 'queries/dashboard/use-achievement-standard';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from 'types';

export default function AchievementLevelTable() {
  const { subsectionId } = useParams();

  const user = useSelector((state: RootState) => state.user);

  // 성취수준 조회
  const achievementStandardQuery = useAchievementStandard({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learning_sys_id: Number(subsectionId),
    },
  });

  return (
    <Stack
      aria-label="현재 소단원별 학습결과 내역 테이블입니다. 학습결과 내역의 각 항목별로 개인과 반평균 데이터를 나타냅니다. 학습결과 내역에는 학습단계, 개념학습, 기본문제, 확인문제, 피드백, 추가문제, 메타인지, 학습참여 총 8개의 항목이 있습니다. 마지막 행에는 선생님이 제출한 과제관련 문제 페이지로 이동하는 버튼과 과제 결과 보기 버튼이 있습니다."
      sx={{
        backgroundColor: '#EBF2FE',
        borderRadius: '.75rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".25rem"
        justifyContent="center"
      >
        <Box component="img" height={32} src="/icons/note_alt.svg" width={32} />
        <Typography
          fontSize="1.125rem"
          fontWeight="500"
          paddingY=".5rem"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.9375rem',
              paddingY: '.375rem',
            },
          })}
          variant="h6"
        >
          성취기준별 성취수준
        </Typography>
      </Stack>
      <Box
        border="5px solid #BFC7E3"
        borderRadius=".5rem"
        component="article"
        overflow="hidden"
        sx={{
          backgroundColor: '#BFC7E3',
        }}
      >
        {achievementStandardQuery.data?.data ? (
          <>
            <Box borderRadius=".5rem .5rem 0 0" overflow="hidden">
              <table
                style={{
                  backgroundColor: '#BFC7E3',
                  borderCollapse: 'collapse',
                  height: '100%',
                  tableLayout: 'fixed',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                <caption style={{ display: 'none' }}>
                  성취기준별 성취수준 테이블
                </caption>
                <tbody>
                  <tr
                    style={{
                      backgroundColor: '#D0E3FA',
                    }}
                  >
                    <Box
                      colSpan={3}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.75rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      학교급 : 고등학교
                    </Box>
                    <Box
                      colSpan={3}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.75rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      과목 : 공통수학1
                    </Box>
                    <Box
                      colSpan={3}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.75rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      학년군 : 10
                    </Box>
                    <Box
                      colSpan={3}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 700,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.75rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      영역 : 다항식
                    </Box>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: '#D0E3FA',
                    }}
                  >
                    <Box
                      colSpan={2}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.875rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '2.25rem',
                        },
                      })}
                    >
                      성취기준
                    </Box>
                    <Box
                      colSpan={10}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        backgroundColor: '#EBF2FE',
                        border: '1px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.875rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '2.25rem',
                        },
                      })}
                    >
                      {
                        achievementStandardQuery.data.data.achievementStandard
                          .achievement_id
                      }{' '}
                      {
                        achievementStandardQuery.data.data.achievementStandard
                          .achievement_desc
                      }
                    </Box>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: '#D0E3FA',
                    }}
                  >
                    <Box
                      colSpan={2}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      평가요소
                    </Box>
                    <Box
                      colSpan={4}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        borderRight: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      다항식의 사칙연산을 수행하기
                    </Box>
                    <Box
                      colSpan={2}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      평가도구 유형
                    </Box>
                    <Box
                      colSpan={4}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        backgroundColor: '#FFF',
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        textAlign: 'left',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      선다형, 단답형
                    </Box>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: '#EBF2FE',
                    }}
                  >
                    <Box
                      colSpan={6}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      평가문항 수 :{' '}
                      {achievementStandardQuery.data.data.problemCount}
                    </Box>
                    <Box
                      colSpan={6}
                      component="th"
                      paddingX=".875rem"
                      scope="colgroup"
                      sx={theme => ({
                        border: '1px solid #BFC7E3',
                        borderBottom: '2px solid #BFC7E3',
                        fontSize: '.875rem',
                        fontWeight: 500,
                        height: '2.25rem',
                        [theme.breakpoints.down('md')]: {
                          fontSize: '.625rem',
                          height: '1.75rem',
                        },
                      })}
                    >
                      평가점수 :{' '}
                      {
                        achievementStandardQuery.data.data
                          .achievementStandardScore
                      }
                      점
                    </Box>
                    {/* <Box
                    colSpan={4}
                    component="th"
                    paddingX=".875rem"
                    scope="colgroup"
                    sx={theme => ({
                      border: '1px solid #BFC7E3',
                      borderBottom: '2px solid #BFC7E3',
                      fontSize: '.875rem',
                      fontWeight: 500,
                      height: '2.25rem',
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.625rem',
                        height: '1.75rem',
                      },
                    })}
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      gap={0.5}
                      justifyContent="center"
                    >
                      피드백학습
                      <Button
                        sx={theme => ({
                          '&:hover': {
                            backgroundColor: '#4F73C0',
                          },
                          backgroundColor: '#4F73C0',
                          borderRadius: '5px',
                          boxShadow: 'none',
                          color: 'white',
                          fontSize: '.75rem',
                          fontWeight: 500,
                          height: '1.375rem',
                          paddingX: '.5rem',
                          [theme.breakpoints.down('md')]: {
                            fontSize: '.625rem',
                            height: '1.25rem',
                          },
                        })}
                        variant="contained"
                      >
                        시작하기
                      </Button>
                    </Stack>
                  </Box> */}
                  </tr>
                </tbody>
              </table>
            </Box>
            <Stack>
              <Box
                border="1px solid #BFC7E3"
                borderBottom="none"
                borderRadius=".375rem .375rem 0 0"
                borderTop="2px solid #BFC7E3"
                sx={{ backgroundColor: '#D2E4FA' }}
              >
                <Typography
                  fontSize="1.125rem"
                  fontWeight="500"
                  paddingX="1.25rem"
                  paddingY=".5rem"
                  sx={theme => ({
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.9375rem',
                      paddingY: '.375rem',
                    },
                  })}
                  variant="h6"
                >
                  성취기준별 성취 수준 결과
                </Typography>
              </Box>
              <Box
                border="1px solid #BFC7E3"
                borderRadius="0 0 .375rem .375rem"
                borderTop="none"
                sx={{ backgroundColor: '#FFF' }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  gap="1.125rem"
                  paddingX="1.25rem"
                  paddingY=".625rem"
                >
                  <Box
                    border="2px solid #D1EFFF"
                    borderRadius=".5rem"
                    flexShrink={0}
                    height={58}
                    padding=".125rem"
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: {
                        height: 48,
                        width: 52,
                      },
                    })}
                    width={64}
                  >
                    <Stack
                      alignItems="center"
                      borderRadius=".375rem"
                      height="100%"
                      justifyContent="center"
                      sx={{ backgroundColor: '#D1EFFF' }}
                      width="100%"
                    >
                      <Typography
                        color="#4F73C0"
                        fontSize="2.25rem"
                        fontWeight="500"
                        sx={theme => ({
                          [theme.breakpoints.down('md')]: {
                            fontSize: '1.75rem',
                          },
                        })}
                      >
                        {
                          achievementStandardQuery.data?.data
                            .achievementStandard.achievement_level
                        }
                      </Typography>
                    </Stack>
                  </Box>
                  <Typography
                    fontSize="1rem"
                    fontWeight="500"
                    sx={theme => ({
                      [theme.breakpoints.down('md')]: {
                        fontSize: '.75rem',
                      },
                    })}
                    variant="h6"
                  >
                    {
                      achievementStandardQuery.data?.data.achievementStandard
                        .level_desc
                    }
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </>
        ) : (
          <p className="text-center">아직 데이터가 없습니다.</p>
        )}
      </Box>
    </Stack>
  );
}
