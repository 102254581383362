import { Box, Button, Container, Typography } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TOOL_LIST, ToolsType } from 'types/WhiteboardState';

import ColorPicker from './ColorPicker';
import CustomSVGIcon from './CustomSVGIcon';
interface ToolbarProps {
  addImage: (img: HTMLImageElement, width: number, height: number) => void;
  clear: () => void;
  color: string;
  redo: () => void;
  redoDisabled: boolean;
  setColor: (color: string) => void;
  setTool: (tool: ToolsType) => void;
  tool: ToolsType;
  undo: () => void;
  undoDisabled: boolean;
}
const WhiteboardToolbar = ({
  addImage,
  clear,
  color,
  redo,
  redoDisabled,
  setColor,
  setTool,
  tool,
  undo,
  undoDisabled,
}: ToolbarProps) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerRef = useRef<HTMLDivElement>(null);
  const colorBtnRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  const handleColorButtonClick = useCallback(() => {
    setShowColorPicker(prev => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setShowColorPicker(false);
  }, []);

  const handleClickOutside = useCallback(
    (event: MouseEvent | TouchEvent) => {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target as Node) &&
        colorBtnRef.current &&
        !colorBtnRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    if (showColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchend', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchend', handleClickOutside);
    };
  }, [showColorPicker, handleClickOutside]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const img = new Image();
        img.onload = function () {
          const maxWidth = 400;
          let width = img.width;
          let height = img.height;

          if (width > maxWidth) {
            const scaleFactor = maxWidth / width;
            width *= scaleFactor;
            height *= scaleFactor;
          }

          addImage(img, width, height);
        };
        if (event.target) {
          img.src = event.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Container
      sx={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: '12px',
        position: 'fixed',
        right: 0,
        width: 'auto',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#E0E0E0',
          borderRadius: '20px',
          display: 'flex',
          height: '54px',
          justifyContent: 'center',
          marginRight: '16px',
          width: 'auto',
        }}
      >
        <Button
          onClick={() => setTool(TOOL_LIST.MOVE)}
          sx={{ ...ButtonStyle, ...(tool === TOOL_LIST.MOVE && pressedStyle) }}
        >
          <CustomSVGIcon id="move" sx={{ height: '22px', width: '20px' }} />
          <Typography style={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.screen_transition')}
          </Typography>
        </Button>
      </Box>

      <Box
        style={{
          backgroundColor: '#E0E0E0',
          borderRadius: '20px',
          display: 'flex',
          height: '54px',
          justifyContent: 'center',
          marginRight: '16px',
        }}
      >
        <Button disabled={undoDisabled} onClick={undo} sx={ButtonStyle}>
          <CustomSVGIcon
            id="undo"
            sx={{ color: 'black', height: '24px', width: '24px' }}
          />
          <Typography sx={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.undo')}
          </Typography>
        </Button>
        <Button
          onClick={handleColorButtonClick}
          ref={colorBtnRef}
          sx={ButtonStyle}
        >
          <Box
            sx={{
              backgroundColor: color,
              border: '2px solid white',
              height: '24px',
              marginBottom: '2px',
              width: '24px',
            }}
          >
            <div
              style={{
                height: '24px',
                width: '24px',
              }}
            ></div>
          </Box>
          <Typography style={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.pen_color')}
          </Typography>
        </Button>
        {showColorPicker && (
          <div
            ref={colorPickerRef}
            style={{
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '8px',
              bottom: '50px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
              padding: '4px',
              position: 'absolute',
              right: '160px',
              zIndex: 1300,
            }}
          >
            <ColorPicker color={color} setColor={setColor} />
          </div>
        )}

        <Button
          onClick={() => setTool(TOOL_LIST.PENCIL)}
          sx={{
            ...ButtonStyle,
            ...(tool === TOOL_LIST.PENCIL && pressedStyle),
          }}
        >
          <CustomSVGIcon id="pencil" sx={{ height: '24px', width: '24px' }} />
          <Typography style={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.write')}
          </Typography>
        </Button>
        <Button
          onClick={() => setTool(TOOL_LIST.ERASER)}
          sx={{
            ...ButtonStyle,
            ...(tool === TOOL_LIST.ERASER && pressedStyle),
          }}
        >
          <CustomSVGIcon
            id="line-eraser"
            sx={{ height: '24px', width: '24px' }}
          />
          <Typography style={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.eraser')}
          </Typography>
        </Button>
        <Button onClick={clear} sx={ButtonStyle}>
          <CustomSVGIcon
            id="all-clear"
            sx={{ color: 'gray', height: '24px', width: '24px' }}
          />
          <Typography style={{ color: 'black', fontSize: '14px' }}>
            {t('white_board.clear_all')}
          </Typography>
        </Button>
      </Box>
    </Container>

    // <div className="button-container">
    //   <input
    //     ref={inputRef}
    //     type="file"
    //     accept="image/*"
    //     style={{ display: 'none' }}
    //     onChange={handleImageUpload}
    //   />
    //   <button onClick={() => inputRef.current?.click()}>이미지 추가</button>
    //   <button onClick={redo} disabled={redoDisabled}>
    //     redo
    //   </button>
    // </div>
  );
};

const ButtonStyle = {
  '&.Mui-disabled': {
    '& > *': {
      color: 'gray',
    },
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
};

const pressedStyle = {
  backgroundColor: '#b0b0b0',
  borderRadius: '20px',
};
export default WhiteboardToolbar;
