import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomSVGIcon from './CustomSVGIcon';
import { SUBTITLE_HEIGHT } from './WhiteboardModal';
interface VideoPreviewOverlayProps {
  onRestartRecording: () => void;
  previewVideoUrl: string;
  thumbnailUrl: string; // 썸네일 URL을 받기 위한 prop 추가
}

const VideoPreviewOverlay = ({
  onRestartRecording,
  previewVideoUrl,
  thumbnailUrl,
}: VideoPreviewOverlayProps) => {
  const { t } = useTranslation();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handlePlay = () => setIsPlaying(true);
      const handleEnded = () => setIsPlaying(false);

      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('ended', handleEnded);

      return () => {
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, []);

  return (
    <Box
      sx={{
        bgcolor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        height: `calc(100% - ${SUBTITLE_HEIGHT}px)`,
        justifyContent: 'center',
        left: 0,
        marginTop: `${SUBTITLE_HEIGHT}px`,
        paddingTop: '20px',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 2,
      }}
    >
      <Button
        onClick={onRestartRecording}
        sx={{
          backgroundColor: 'white',
          border: '2px solid #4782CA',
          borderRadius: '4px',
          height: '30px',
          left: '20px',
          padding: 0,
          position: 'absolute',
          top: '20px',
          width: '72px',
          zIndex: 3,
        }}
      >
        <CustomSVGIcon
          id={'arrow-back'}
          sx={{
            height: '12px',
            marginRight: '2px',
            marginTop: '2px',
            width: '8px',
          }}
        />
        <Typography sx={{ color: 'black', fontSize: '12px' }}>
          {t('white_board.re_record')}
        </Typography>
      </Button>

      <Box
        sx={{
          alignItems: 'center',
          backgroundImage: !isPlaying ? `url(${thumbnailUrl})` : `url('')`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          display: 'flex',
          height: '80%',
          justifyContent: 'center',
          position: 'relative',
          width: 'calc(100% - 200px)',
          zIndex: 1,
        }}
      >
        {!isPlaying && (
          <>
            <Box
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: 2,
              }}
            ></Box>
            <Button
              aria-label={t('white_board.play_recorded_video')}
              onClick={handlePlay}
              sx={{
                position: 'absolute',
                zIndex: 3,
              }}
            >
              <CustomSVGIcon
                id="recording-start"
                sx={{
                  height: '96px',
                  width: '96px',
                }}
              />
            </Button>
          </>
        )}
        <video
          controls
          id="preview-video"
          ref={videoRef}
          style={{
            backgroundColor: 'black',
            border: '1px solid black', // 비디오에 테두리 추가
            display: isPlaying ? 'block' : 'none',
            height: '100%',
            width: '100%',
          }}
        >
          <source src={previewVideoUrl} type="video/webm" />
        </video>
      </Box>
    </Box>
  );
};

export default VideoPreviewOverlay;
