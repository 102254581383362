import { Backdrop, CircularProgress } from '@mui/material';
import AIDTErrorBoundary from 'app/components/Common/AIDTErrorBoundary';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import StudentDashboard from 'app/components/Dashboard/StudentDashboard';
import { LucideLayoutDashboard } from 'lucide-react';
import { useLearningSystem } from 'queries/use-learning-system';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { RootState } from '../../../types';
import TeacherDashboard from '../../components/Dashboard/TeacherDashboard';
import { Layout } from '../../components/Layout';

export function DashboardPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const user = useSelector((state: RootState) => state.user);

  // 단원 아이디
  const { subsectionId } = useParams();

  // 단원 정보 조회
  const learningSystemQuery = useLearningSystem({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
    params: {
      learningSysId: Number(subsectionId),
    },
  });

  const prevStateKorString =
    learningSystemQuery.data?.type === 'SECTION' ? '소단원 목차' : '전체 목차';

  const prevStateUrl =
    learningSystemQuery.data?.type === 'SECTION'
      ? `/chapter/${subsectionId}`
      : '/';

  if (learningSystemQuery.isError)
    return (
      <>
        <AIDTErrorBoundary
          backLink={'/'}
          desc={'단원 정보를 찾을 수 없습니다.'}
          title={'대시보드 불러오기 실패'}
        />
      </>
    );

  return (
    <Layout
      customContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingBottom: '60px',
      }}
      isDisableGutters={true}
      width={false}
    >
      <Backdrop
        open={learningSystemQuery.isLoading}
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!learningSystemQuery.isLoading && (
        <main className="flex flex-col gap-3.5">
          <SecondaryHeader
            backgroundColor={user.user_type === 'T' ? '#7D5CD3' : '#4F73C0'}
            handleRightButtonClicked={() => navigate(prevStateUrl)}
            handleRightButtonKeyPressed={e =>
              e.key === 'Enter' && navigate(prevStateUrl)
            }
            leftAlign={true}
            rightButtonLabel={`엔터키를 누르면 ${prevStateKorString}로 이동합니다.`}
            rightButtonText={prevStateKorString}
            title={`${
              learningSystemQuery.data?.type === 'SECTION' ? '소단원' : '대단원'
            } ${t('dashboard.dashboard')} | ${learningSystemQuery.data?.name} `}
            titleColor="#FFF"
            titleIcon={<LucideLayoutDashboard />}
          />
          <div className="container">
            {user.user_type === 'S' && <StudentDashboard />}
            {user.user_type === 'T' && <TeacherDashboard />}
          </div>
        </main>
      )}
    </Layout>
  );
}
