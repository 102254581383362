export interface WhiteboardState {
  answerInfo: AnswerInfo;
  conceptInfo: ConceptInfo;
  isCompleteAnswer: boolean;
  isOpen: boolean;
  problemInfo: ProblemInfo;
}

export interface Point {
  x: number;
  y: number;
}

export interface Line {
  color: string;
  highlight: boolean;
  points: Point[];
  width: number;
}

export interface ImageObj {
  height: number;
  img: HTMLImageElement;
  src?: string;
  width: number;
  x: number;
  y: number;
}

export interface WhiteboardCanvasState {
  images: ImageObj[];
  lines: Line[];
}

export const TOOL_LIST = {
  ERASER: 'line-eraser',
  HIGHLIGHTER: 'highlighter',
  MOVE: 'move',
  PENCIL: 'pencil',
} as const;

export const DEFAULT_PICKER_COLOR = 'black';

export type ToolsType = ValueOfUnion<typeof TOOL_LIST>;

export type ValueOfUnion<T extends { [k: string]: unknown }> = T[keyof T];

export type BoardType = 'answer' | 'concept' | 'solving';
export type ShareType = 'all' | 'class' | 'private';
export type SubmitState = 'done' | 'processing' | 'ready';

export interface WhiteboardSubmitData {
  boardType: BoardType;
  gridThumb: string;
  mainThumb: string;
  shareType: ShareType;
  title: string;
  video: Blob;
}

export interface ProblemData {
  data: string;
  type: 'image' | 'markdown' | 'text';
}

interface WhiteboardDefaultInfo {
  boardType: BoardType;
  curriculumId: string;
  isAssessment: boolean;
  latexData: string;
  learningSysId: number;
  problemImage?: string;
  subtitle: string;
  title: string;
}

export interface ProblemInfo extends WhiteboardDefaultInfo {
  problemId: number;
}

export interface AnswerInfo extends WhiteboardDefaultInfo {
  problemId: number;
  questionId: number;
}

export type ConceptInfo = WhiteboardDefaultInfo;

export const TOAST_MESSAGE = {
  MIC_NOT_FOUND: '연결된 마이크 장치가 없습니다.',
  MIC_PERMISSION_DENIED: '브라우저 내 마이크 권한을 획득하십시오',
  PAUSE_DISABLED: '녹화 시작 후 30초가 지나면 일시정지 할 수 없습니다.',
  SUBMISSION_FAILED: '요청에 실패하였습니다. 다시 시도해주세요.',
} as const;
export type ToastMessage = ValueOfUnion<typeof TOAST_MESSAGE>;
