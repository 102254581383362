import { Box, Tooltip } from '@mui/material';
import { LucideCheck, LucideLayoutDashboard } from 'lucide-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContentTable, TeacherBookmarker } from 'schemas/learning';
import { useUpdateTeacherBookMarkerMutation } from 'services/learning';
import { RootState } from 'types';

export default function SectionListItem({
  chapterIdx,
  index,
  indexList,
  section,
  setIndexList,
  unitIdx,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClickSubChapter = (id: number) => {
    navigate(`/chapter/${id}`);
  };
  const handleClickDashboard = (id: number) => {
    navigate(`/dashboard/${id}`);
  };
  const userType = useSelector((state: RootState) => state.user.user_type);
  const userId = useSelector((state: RootState) => state.user.user_id);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);

  const [updateTeacherBookMarker, { data: updatedTeacherBookMarkerRes }] =
    useUpdateTeacherBookMarkerMutation();

  const handleClickBookMarker = (id: number, newStatus: number) => {
    updateTeacherBookMarker({
      data: {
        learningSysId: id,
        status: newStatus,
      },
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
    });
  };

  const updateTeacherCheckedStatus = (
    units: ContentTable[],
    statusData: TeacherBookmarker | TeacherBookmarker[],
  ): ContentTable[] => {
    const updatedUnits = units.map(unit => ({
      ...unit,
      chapter: unit.chapter.map(chapter => ({
        ...chapter,
        section: chapter.section.map(section => {
          let sectionChecked = section.checked;

          if (Array.isArray(statusData)) {
            const status = statusData.find(
              s => s.learning_sys_id === section.learning_sys_id,
            );
            if (status) {
              sectionChecked = status.status === 1;
            }
          } else if (
            statusData &&
            statusData.learning_sys_id === section.learning_sys_id
          ) {
            sectionChecked = statusData.status === 1;
          }

          return {
            ...section,
            checked: sectionChecked,
            subsection: section.subsection.map(subsection => ({
              ...subsection,
            })),
          };
        }),
      })),
    }));

    return updatedUnits;
  };

  useEffect(() => {
    if (!updatedTeacherBookMarkerRes || !updatedTeacherBookMarkerRes.data)
      return;
    const updatedData = updateTeacherCheckedStatus(
      indexList,
      updatedTeacherBookMarkerRes.data,
    );
    if (JSON.stringify(updatedData) !== JSON.stringify(indexList)) {
      setIndexList(updatedData);
    }
  }, [updatedTeacherBookMarkerRes]);

  return (
    <div className="pb-1" key={section.learning_sys_id}>
      <div
        aria-label={`소단원, ${section.section_name}`}
        className="pl-10 text-lg pb-2 flex items-center gap-2 justify-start"
        tabIndex={0}
      >
        <div
          className="cursor-pointer"
          onClick={() => {
            handleClickSubChapter(section.learning_sys_id);
          }}
        >{`${unitIdx + 1}-${chapterIdx + 1}-${index + 1} ${
          section.section_name
        } `}</div>
        <div className="border-t border-slate-300 flex-grow"></div>
        <Box
          aria-label={
            section.checked
              ? t('home.learning_completed')
              : t('home.learning_pending')
          }
          onClick={() => {
            handleClickBookMarker(
              section.learning_sys_id,
              section.checked ? 0 : 1,
            );
          }}
          sx={{
            backgroundColor: section.checked ? '#4F73C0' : 'initial',
            backgroundPosition: 'center',
            border: section.checked ? 'none' : '1px solid #CCCCCC',
            borderRadius: '5px',
            cursor: userType === 'T' ? 'pointer' : 'default',
            height: '36px',
            textAlign: 'center',
            width: '36px',
          }}
          tabIndex={0}
        >
          {section.checked && (
            <LucideCheck color="#fff" size={20} strokeWidth={3} />
          )}
        </Box>
        <Tooltip
          arrow
          placement="left"
          title={
            !section.checked && userType === 'S'
              ? '먼저 이 소단원의 학습을 끝내주세요.'
              : null
          }
        >
          <button
            aria-label={
              `엔터를 누르면 ` +
              section.section_name +
              ' ' +
              t('chapter.dashboard') +
              '로 이동합니다.'
            }
            className="disabled:opacity-50 disabled:bg-slate-100 border border-slate-300 px-3 flex items-center justify-center gap-1 h-[36px] ml-4 rounded-md"
            disabled={!section.checked && userType === 'S'}
            onClick={() => {
              handleClickDashboard(section.learning_sys_id);
            }}
            tabIndex={0}
          >
            <LucideLayoutDashboard size={20} strokeWidth={1.3} />
            <span>{t('chapter.dashboard')}</span>
          </button>
        </Tooltip>
      </div>
    </div>
  );
}
