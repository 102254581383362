// src/store/slices/userSlice.ts
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getSubdomain } from 'services/apiService';
import { getSchoolCodeFromSubdomain } from 'utils/studyUtils';

import { UserState } from '../../types/UserState';

const initialState: UserState = {
  access_token: {
    access_id: '',
    token: '',
  },
  api_domain: 'https://adv.aidtbook.kr/test',
  api_send_url: 'http://해피에듀테크',
  apiGradeClass: '',
  assessment_id: 0,
  classInfo: '',
  encryptedToken: '', // pmiSSO
  keyId: '',
  lecture_code: '',
  member_info: [],
  notificationMessage: '',
  notifications: false,
  partnerId: '',
  securenonce: '',
  studentInfo: null,
  teacherInfo: null,
  user_id: '',
  user_name: '',
  user_school: '',
  user_semester: 1,
  user_status: 'E', // E:기존, O:전학(전출), I:전학(전입) ※ 고정값(E:기존) 세팅
  user_type: 'T', // S:학생, T:교사, P:학부모
};

const getClassInfo = (info: any) => {
  if (info.school_id && info.class) {
    const subdomain = getSubdomain();
    const grade = info.class.split('학년')[0];
    const classNum = info.class.split('학년 ')[1].split('반')[0];
    const classInfo = `${info.school_id}-${grade}-${classNum}`;
    return classInfo;
  } else {
    return `K100000001-1-1`;
  }
  // ---
  if (info.school_id && info.user_grade && info.user_class) {
    const match = info.user_class.match(/(\d)반/);
    if (match) {
      return `${info.school_id}-${info.user_grade}-${match[1]}`;
    } else {
      return `${info.school_id}-${info.user_grade}-1`;
    }
  } else if (info.school_id && info.class) {
    //info.class => 1학년 2반 교실
    const grade = info.class.split('학년')[0];
    const classNum = info.class.split('학년 ')[1].split('반')[0];
    return `${info.school_id}-${grade}-${classNum}`;
  } else {
    return `K100000001-1-1`;
  }
};

const getGradeClass = (info: any) => {
  if (info.user_division && info.user_grade && info.user_class) {
    const division =
      info.user_division === '2' ? 'e' : info.user_division === '3' ? 'm' : 'h';

    return `${division}-${info.user_grade}-${info.user_class}`;
  }
  return undefined;
};

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setAccessToken: (state, action) => {
      state.access_token = action.payload;
    },
    setAssessmentId: (state, action) => {
      state.assessment_id = action.payload;
    },
    setEncryptedToken: (state, action) => {
      state.encryptedToken = action.payload;
    },
    setKeyId: (state, action) => {
      state.keyId = action.payload;
    },
    setLectureCode: (state, action) => {
      state.lecture_code = action.payload;
    },
    setMemberInfo: (state, action) => {
      state.member_info = action.payload;
    },
    setNotificationMessage: (state, action) => {
      state.notificationMessage = action.payload;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setPartnerId: (state, action: PayloadAction<string>) => {
      state.partnerId = action.payload;
    },
    setSecurenonce: (state, action) => {
      state.securenonce = action.payload;
    },
    setStudentInfo: (state, action: PayloadAction<any>) => {
      state.studentInfo = action.payload;
      const classInfo = getClassInfo({
        class: action.payload.schedule_info[0].classroom_name,
        school_id: action.payload.school_id,
      });
      if (classInfo) {
        state.classInfo = classInfo;
        state.user_name = action.payload.user_name;
        state.user_school = action.payload.school_name;
        state.apiGradeClass = getSchoolCodeFromSubdomain(getSubdomain());
      }
    },
    setTeacherInfo: (state, action: PayloadAction<any>) => {
      state.teacherInfo = action.payload;
      const classInfo = getClassInfo({
        class: action.payload.schedule_info[0].classroom_name,
        school_id: action.payload.school_id,
      });
      if (classInfo) {
        state.classInfo = classInfo;
        state.user_name = action.payload.user_name;
        state.user_school = action.payload.school_name;
        state.apiGradeClass = getSchoolCodeFromSubdomain(getSubdomain());
      }
    },
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setUserSemester: (state, action) => {
      state.user_semester = action.payload;
    },
    setUserStatus: (state, action: PayloadAction<'E' | 'I' | 'O'>) => {
      state.user_status = action.payload;
    },
    setUserType: (state, action: PayloadAction<'S' | 'T'>) => {
      state.user_type = action.payload;
    },
  },
});

export const {
  setAccessToken,
  setAssessmentId,
  setEncryptedToken,
  setKeyId,
  setLectureCode,
  setMemberInfo,
  setNotificationMessage,
  setNotifications,
  setPartnerId,
  setSecurenonce,
  setStudentInfo,
  setTeacherInfo,
  setUserId,
  setUserSemester,
  setUserStatus,
  setUserType,
} = userSlice.actions;

export default userSlice.reducer;
