import { AssignmentTypesSchema } from 'schemas/assignment';
import { z } from 'zod';

// 나의 장래희망 등록
export const RegisterMyDreamJobsFormSchema = z.object({
  first_job: z
    .string({
      required_error: '첫번째 장래희망을 입력해주세요.',
    })
    .min(2, {
      message: '2글자 이상 입력해주세요.',
    }),
  first_reason: z.string().optional(),
  second_job: z.string().optional(),
  second_reason: z.string().optional(),
  third_job: z.string().optional(),
  third_reason: z.string().optional(),
});

export type RegisterMyDreamJobsFormValues = z.infer<
  typeof RegisterMyDreamJobsFormSchema
>;

// 학력진단평가 설정
export const AssessmentSettingFormSchema = z.object({
  minute: z.coerce.number(),
});

export type AssessmentSettingFormValues = z.infer<
  typeof AssessmentSettingFormSchema
>;

// 학생 상태 변경
export const UpdateStatusFormSchema = z.object({
  mentalState: z.number(),
  physicalState: z.number(),
  statusMessage: z.string(),
});

export type UpdateStatusFormValues = z.infer<typeof UpdateStatusFormSchema>;
