import { ProfileIcon } from 'app/components/icons';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { useStudent } from 'queries/use-student';
import * as React from 'react';
import { useSelector } from 'react-redux';
import useDialogStore, { DialogKeys } from 'stores/use-dialog-store';
import { RootState } from 'types';

export default function DueAssignmentDialog() {
  const { open, toggleDialog } = useDialogStore(state => ({
    open: state[DialogKeys.DueAssignment],
    toggleDialog: state.toggleDialog,
  }));

  const user = useSelector((state: RootState) => state.user);

  // 학생 정보 조회
  const studentQuery = useStudent({
    data: {
      access_token: user.access_token,
      user_id: user.user_id,
    },
    headers: {
      'Partner-ID': user.partnerId,
    },
  });

  return (
    <Dialog
      onOpenChange={(open: boolean) =>
        toggleDialog(DialogKeys.DueAssignment, open)()
      }
      open={open}
    >
      <DialogContent className="p-0 overflow-hidden border-none max-w-[30.625rem] pt-20 pb-28 flex items-center justify-center">
        <DialogHeader>
          <DialogTitle className="flex flex-col items-center justify-center text-xl font-medium gap-1.5">
            <ProfileIcon
              className="text-[#77E665]"
              style={{ height: 64, width: 64 }}
            />
            <h3 className="text-center" tabIndex={0}>
              김땡땡 학생은 과제를 수행하고 있지 않습니다.
              <br /> 바로 과제를 수행해주세요.
            </h3>
          </DialogTitle>
          <DialogDescription className="sr-only">
            <ProfileIcon
              className="text-[#77E665]"
              style={{ height: 64, width: 64 }}
            />
            <h3 className="text-center" tabIndex={0}>
              {studentQuery.data?.user_name} 학생은 과제를 수행하고 있지
              않습니다.
              <br /> 바로 과제를 수행해주세요.
            </h3>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
