import {
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { deleteNotifications } from 'apis/notification';
import { LucideArrowLeft, LucideTrash2 } from 'lucide-react';
import moment from 'moment';
import 'moment/locale/ko';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { deleteNotification } from 'store/slices/notificationSlice';
import { RootState } from 'types/RootState';

import {
  NOTIFICATION_AVATAR_CONSTANTS,
  NOTIFICATION_MESSAGES_KOR_CONSTANTS,
  generateNotificationInfoString,
  generateNotificationUrl,
} from './NotificationConstants';
interface AlertDrawerProps {
  item: {
    name: string;
    value: string;
  };
}

export default function AlertDrawer({ item }: AlertDrawerProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [checked, setChecked] = React.useState<Set<number>>(new Set());
  const uuid = useSelector((state: RootState) => state.user.user_id);
  const role = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const notifications =
    useSelector((state: RootState) => state?.notification?.notifications) || [];

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
    if (!open) {
      setChecked(new Set());
      setIsDelete(false);
    }
  };

  const toggleChecked = (id: number) => () => {
    setChecked(prev => {
      const newChecked = new Set(prev);
      if (newChecked.has(id)) {
        newChecked.delete(id);
      } else {
        newChecked.add(id);
      }
      return newChecked;
    });
  };

  const toggleDelete = () => {
    if (checked.size > 0) {
      checked.forEach(id => {
        deleteNotifications({
          data: { notification_id: id },
          headers: {
            role,
            uuid,
          },
        });
        dispatch(deleteNotification(id));
      });
    }
    if (isDelete === false) {
      setChecked(new Set());
    }
    setIsDelete(prev => !prev);
  };

  const DrawerList = (
    <Box sx={{ flexGrow: 1 }}>
      <List sx={{ padding: 0, width: '100%' }}>
        {notifications?.map((item, index) => (
          <React.Fragment key={item.id}>
            <ListItem
              alignItems="flex-start"
              key={item?.id}
              secondaryAction={
                isDelete ? (
                  <Checkbox
                    checked={checked.has(item.id)}
                    edge="end"
                    inputProps={{ 'aria-labelledby': item.id.toString() }}
                    onChange={toggleChecked(item.id)}
                    sx={{
                      '&.Mui-checked': {
                        color: '#157C69',
                      },
                      borderRadius: '6px',
                    }}
                  />
                ) : (
                  <IconButton
                    size="small"
                    sx={{ height: '1.5rem', width: '1.5rem' }}
                  >
                    <img alt="Chevron Right" src="/icons/chevron-right.svg" />
                  </IconButton>
                )
              }
              sx={{ gap: '.75rem', paddingRight: '3.625rem' }}
            >
              <ListItemAvatar>
                <Avatar
                  src={NOTIFICATION_AVATAR_CONSTANTS[item.action]}
                  sx={{ height: 54, width: 54 }}
                />
              </ListItemAvatar>
              <Link
                to={`${generateNotificationUrl(
                  item.additional_data1,
                  item.additional_data2,
                  item.additional_data3,
                  item.additional_text,
                  item.action,
                )}`}
              >
                <ListItemText
                  primary={
                    <Typography sx={{ fontWeight: 600 }} variant="caption">
                      {/**메인 한국어 메세지 상수 */}
                      {NOTIFICATION_MESSAGES_KOR_CONSTANTS[item.action] ||
                        '새로운 알림이 있습니다.'}
                    </Typography>
                  }
                  secondary={
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography
                        color="black"
                        component="span"
                        sx={{
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          display: '-webkit-box',
                          fontSize: '.625rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant="body2"
                      >
                        {generateNotificationInfoString(
                          item.additional_text,
                          item.action,
                        )}
                      </Typography>
                      <Typography
                        color="#666"
                        sx={{ fontSize: '.625rem', fontWeight: 500 }}
                        variant="caption"
                      >
                        {moment(item.created_at).fromNow()}
                      </Typography>
                    </Box>
                  }
                  secondaryTypographyProps={{ component: 'div' }}
                />
              </Link>
            </ListItem>
            {index < notifications.length - 1 && (
              <Divider sx={{ borderColor: '#DCDCDC' }} />
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  const EmptyDom = (
    <Box
      sx={{
        '& img': {
          marginRight: '10px',
        },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        rowGap: '.375rem',
      }}
    >
      <img alt="Empty Alert" src="/images/empty-alert.svg" />
      <Typography
        sx={{ color: '#555', fontWeight: 'semibold' }}
        variant="caption"
      >
        {t('notification.no_notifications')}
      </Typography>
    </Box>
  );

  const HeaderButtonDOM = (
    <Box
      onClick={toggleDrawer(true)}
      sx={{
        alignItems: 'center',
        color: 'inherit',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '.75rem',
        justifyContent: 'center',
        margin: '0 7px',
        textDecoration: 'none',
      }}
    >
      <Badge
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        badgeContent={notifications.length}
        color="secondary"
        max={99}
      >
        <img
          alt={item.name}
          height={38}
          src={`/icons/${item.name}.svg`}
          width={38}
        />
      </Badge>
      {t(`nav.${item.name}`)}
    </Box>
  );

  return (
    <>
      {HeaderButtonDOM}
      <Drawer
        PaperProps={{
          sx: { display: 'flex', flexDirection: 'column', width: '24.75rem' },
        }}
        anchor="right"
        onClose={toggleDrawer(false)}
        open={open}
      >
        <div className="p-4 flex items-center justify-between border-b border-slate-200">
          <Typography sx={{ fontWeight: 'bold' }} variant="h5">
            {t('notification.header')} ({notifications.length})
          </Typography>
          <div className="flex items-center justify-end gap-4">
            <button
              className={
                (isDelete
                  ? checked.size > 0
                    ? 'text-red-500 bg-red-200'
                    : 'text-black bg-slate-100'
                  : 'text-red-500 bg-red-200') +
                ' flex items-center justify-start gap-2 text-base font-bold py-1 px-2 rounded-md'
              }
              disabled={notifications.length === 0}
              onClick={toggleDelete}
            >
              {isDelete && checked.size === 0 ? (
                <>
                  <LucideArrowLeft size={18} />
                  <span>삭제취소</span>
                </>
              ) : (
                <>
                  <LucideTrash2 size={18} />
                  <span>삭제하기</span>
                </>
              )}
            </button>
            <img alt="close" onClick={toggleDrawer(false)} src="/icons/x.svg" />
          </div>
        </div>
        {notifications.length > 0 ? DrawerList : EmptyDom}
      </Drawer>
    </>
  );
}
