export const findConsecutiveCount = (arr: number[]) => {
  if (arr.length === 0) return 0;

  let maxCount = 1;
  let currentCount = 1;

  for (let i = 1; i < arr.length; i++) {
    if (arr[i] === arr[i - 1]) {
      currentCount++;
    } else {
      maxCount = Math.max(maxCount, currentCount);
      currentCount = 1;
    }
  }

  maxCount = Math.max(maxCount, currentCount);

  return maxCount;
};
