import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { CircleCheck } from 'lucide-react';
import * as React from 'react';
import useDialogStore, { DialogKeys } from 'stores/use-dialog-store';

export default function SubmitAssignmentDialog() {
  const { open, toggleDialog } = useDialogStore(state => ({
    open: state.submitAssignment,
    toggleDialog: state.toggleDialog,
  }));

  return (
    <Dialog
      onOpenChange={(open: boolean) =>
        toggleDialog(DialogKeys.SubmitAssignment, open)()
      }
      open={open}
    >
      <DialogContent className="p-0 overflow-hidden border-none max-w-[30.625rem] pt-20 pb-28 flex items-center justify-center">
        <DialogHeader>
          <DialogTitle className="flex flex-col items-center justify-center text-xl font-medium gap-1.5">
            <CircleCheck className="size-16 text-[#77E665]" />
            <h3 tabIndex={0}>과제가 출제되었습니다.</h3>
          </DialogTitle>
          <DialogDescription className="sr-only">
            <CircleCheck className="size-16 text-[#77E665]" />
            <h3 tabIndex={0}>과제가 출제되었습니다.</h3>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}
