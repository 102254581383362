import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFooterContainer,
  GridOverlay,
  useGridApiContext,
} from '@mui/x-data-grid';
import SecondaryHeader from 'app/components/Common/header/SecondaryHeader';
import ReferenceBreadcrumbs from 'app/components/Reference/ReferenceBreadcrumbs';
import { LucideArchive, LucideTag } from 'lucide-react';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ReferenceMaterial } from 'schemas/study';
import { useLazyGetReferenceDataQuery } from 'services/studyService';
import { RootState } from 'types';
import { CurrentSection, CurrentSubSection } from 'types/StudyState';
import {
  findSection,
  findSubsection,
  findSubsectionByClsId,
} from 'utils/studyUtils';

import { Layout } from '../../components/Layout';

export function CustomPagination({ onPageChange, page, totalPage }) {
  const apiRef = useGridApiContext();

  const handlePageChange = (event, value) => {
    apiRef.current.setPage(value - 1);
    onPageChange(value);
  };

  return (
    <Pagination
      count={totalPage}
      onChange={handlePageChange}
      page={page}
      shape="rounded"
      size="large"
      sx={{
        '& .MuiPaginationItem-root': {
          backgroundColor: 'white',
          color: 'black',
          fontSize: '.875rem',
          fontWeight: 'medium',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: '#CEE2FA !important',
        },
        '& .MuiPaginationItem-root:hover': {
          backgroundColor: '#CEE2FA !important',
        },
      }}
    />
  );
}

function CustomFooter({ onPageChange, page, totalPage }) {
  return (
    <GridFooterContainer
      sx={{ display: 'flex', justifyContent: 'center', padding: 2.5 }}
    >
      <CustomPagination
        onPageChange={onPageChange}
        page={page}
        totalPage={totalPage}
      />
    </GridFooterContainer>
  );
}

export function ReferencePage() {
  const navigate = useNavigate();
  const PAGE_SIZE = 8;
  const { sectionId, subsectionId } = useParams();
  const { t } = useTranslation();
  const [subSectionData, setSubSectionData] =
    useState<CurrentSubSection | null>(null);
  const [sectionData, setSectionData] = useState<CurrentSection | null>(null);
  const [references, setReferences] = useState<ReferenceMaterial[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const userId = useSelector((state: RootState) => state.user.user_id);
  const userType = useSelector((state: RootState) => state.user.user_type);
  const classInfo = useSelector((state: RootState) => state.user.classInfo);
  const contentTables = useSelector(
    (state: RootState) => state.contentTable.contentTables,
  );

  const [triggerGetReferenceData, { data: referenceResponse }] =
    useLazyGetReferenceDataQuery();
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (subsectionId === undefined) return;
    const subsection = findSubsectionByClsId(contentTables, subsectionId);
    const section = findSection(contentTables, (sectionId || '')?.toString());
    if (section && subsection) {
      setSubSectionData(subsection);
      setSectionData(section);
    }
  }, [contentTables, subsectionId]);

  useEffect(() => {
    if (!classInfo || !userId || !subsectionId) return;
    triggerGetReferenceData({
      headers: {
        'Class-Info': classInfo,
        role: userType,
        uuid: userId,
      },
      params: {
        clsId: subsectionId,
        page,
        pageSize: PAGE_SIZE,
      },
    });
  }, [userId, classInfo, subsectionId, page]);

  useEffect(() => {
    if (referenceResponse) {
      setReferences([...referenceResponse.data.data]);
      setTotalPage(Math.ceil(referenceResponse.data.totalPage));
    }
  }, [referenceResponse]);

  const columns: GridColDef[] = [
    {
      align: 'center',
      cellClassName: 'custom-theme--row',
      field: 'id',
      headerAlign: 'center',
      headerClassName: 'custom-theme--header',
      headerName: t('common.number'),
      sortable: false,
      width: 56,
    },
    {
      align: 'center',
      cellClassName: 'custom-theme--row',
      field: 'scope',
      headerAlign: 'center',
      headerClassName: 'custom-theme--header',
      headerName: t('common.category'),
      renderCell: params => (
        <Chip
          label={
            params.value === 'ALL'
              ? t('common.public_to_all')
              : t('common.public_to_class')
          }
          size="small"
          sx={{
            borderColor: '#555',
            color: '#555',
            fontSize: '.625rem',
            fontWeight: 'medium',
          }}
          variant="outlined"
        ></Chip>
      ),
      sortable: false,
      width: 128,
    },
    {
      cellClassName: 'custom-theme--row',
      field: 'title',
      flex: 3,
      headerClassName: 'custom-theme--header',
      headerName: t('common.title'),
      renderCell: params => (
        <div
          aria-label={t('reference.navigate_to_detail_page', {
            name: params.value,
          })}
          tabIndex={0}
        >
          {params.value}
        </div>
      ),
      sortable: false,
    },
    {
      align: 'center',
      cellClassName: 'custom-theme--row',
      field: 'createdAt',
      headerAlign: 'center',
      headerClassName: 'custom-theme--header',
      headerName: t('common.registration_date'),
      sortable: false,
      valueFormatter: (params: any) =>
        moment(params.value as string).format('YYYY-MM-DD'),
      width: 144,
    },
    {
      align: 'center',
      cellClassName: 'custom-theme--row',
      field: 'viewCount',
      headerAlign: 'center',
      headerClassName: 'custom-theme--header',
      headerName: t('common.views'),
      sortable: false,
      width: 96,
    },
  ];

  return (
    <Layout
      customContainerStyle={{
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
      }}
      isDisableGutters={true}
      width={false}
    >
      <SecondaryHeader
        backgroundColor="#4F73C0"
        handleRightButtonClicked={() => navigate(`/math-concept/${sectionId}`)}
        rightButtonLabel="개념학습으로 돌아가기"
        rightButtonText="돌아가기"
        title={t('reference.reference_material')}
        titleColor="white"
        titleIcon={<LucideArchive />}
      />
      <ReferenceBreadcrumbs section={sectionData} subSection={subSectionData} />
      <Box>
        <Container>
          <Box
            sx={{
              '& .custom-theme--header': {
                fontSize: '.875rem',
                fontWeight: 'semibold',
              },
              '& .custom-theme--row': {
                cursor: 'pointer',
                fontSize: '.9375rem',
                fontWeight: 'medium',
              },

              '.custom-theme--header, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within':
                {
                  outline: 'none !important',
                },
              height: 'auto',
              width: '100%',
            }}
          >
            <DataGrid
              autoHeight
              columns={columns}
              disableColumnMenu
              disableColumnResize
              getRowId={row => row.id}
              onRowClick={params =>
                navigate(
                  `/reference/${sectionId}/${subsectionId}/${params.row.id}`,
                )
              }
              paginationModel={{ page: page - 1, pageSize: PAGE_SIZE }}
              rows={references}
              slots={{
                footer: () => (
                  <CustomFooter
                    onPageChange={setPage}
                    page={page}
                    totalPage={totalPage}
                  />
                ),
                noRowsOverlay: () => (
                  <GridOverlay>
                    <Box sx={{ textAlign: 'center' }}>
                      <Typography color="textSecondary" variant="h6">
                        {t('reference.no_reference_material')}
                      </Typography>
                    </Box>
                  </GridOverlay>
                ),
              }}
              sx={{ border: 'none' }}
            />
          </Box>
        </Container>
      </Box>
    </Layout>
  );
}
