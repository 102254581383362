import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: 'white',
          padding: '.3rem .5rem',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#4F73C0',
    },
    secondary: {
      main: '#dc004e',
    },
    text: {
      primary: '#333',
      secondary: '#666',
    },
  },
  typography: {
    fontFamily: '"Noto Sans KR", Roboto, sans-serif',
  },
});

export default theme;
