import { useQuery } from '@tanstack/react-query';
import { getStudentClassMembers } from 'apis/aidt-student';
import { ClassMember } from 'schemas/aidt';
import { GetStudentClassMembersResponse } from 'schemas/aidt-student';
import { BaseAidtDto } from 'schemas/base';

export function useStudentClassMembers({ data, headers }: BaseAidtDto) {
  return useQuery<GetStudentClassMembersResponse, Error, ClassMember[]>({
    queryFn: () => getStudentClassMembers({ data, headers }),
    queryKey: ['student_class_members', { data, headers }],
    select: data => data.member_info,
  });
}
