import LoginPage from 'app/pages/Login';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'types';

import {
  KSign_Agent_LiveCheck,
  KSign_Server_LiveCheck,
  getUrlParameter,
  makeSSOReq,
  verifyToken,
} from '../services/ssoService';
import {
  setEncryptedToken,
  setKeyId,
  setSecurenonce,
} from '../store/slices/userSlice';

const SSORequiredRoute = ({ children }: { children: JSX.Element }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const encryptedToken = useSelector(
    (state: RootState) => state.user.encryptedToken,
  );

  const access_token = JSON.parse(localStorage.getItem('access_token') || '{}');
  const user_id = localStorage.getItem('user_id');
  useEffect(() => {
    async function checkSSOService() {
      try {
        await KSign_Agent_LiveCheck();
        if (process.env.REACT_APP_NODE_ENV === 'production') {
          await KSign_Server_LiveCheck();
        }
        if (
          process.env.REACT_APP_NODE_ENV !== 'development' &&
          !getUrlParameter('pmi-sso-return')
        ) {
          await makeSSOReq();
        } else {
          dispatch(setEncryptedToken(getUrlParameter('pmi-sso-return')));
          dispatch(setKeyId(localStorage.getItem('keyId')));
          dispatch(setSecurenonce(localStorage.getItem('sn')));
        }
      } catch (error) {
        alert('SSO 서비스를 사용할 수 없습니다.');
        verifyToken(encryptedToken);
      } finally {
        setLoading(false);
      }
    }

    if (
      access_token.access_id !== 'MOCK-PENETRATION' &&
      access_token.token !== 'MOCK-PENETRATION' &&
      user_id !== '2c026f6c-5aec-5fdc-8291-7561f0c7b194' &&
      user_id !== '35a489f6-e87f-52ef-a92e-3ce864617986'
    ) {
      if (window.location.pathname !== '/error') {
        // setLoading(false); // sso 활성화 시 제거
        checkSSOService(); // sso 활성화 시 주석 해제
      }
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // sso 활성화 시 제거
  //   if (access_token && user_id) {
  //     return children;
  //   } else {
  //     return <LoginPage />;
  //   }

  //sso 활성화 시 주석 해제
  return children;
};

export default React.memo(SSORequiredRoute);
