import { useQuery } from '@tanstack/react-query';
import { getStudent } from 'apis/aidt-student';
import { useSelector } from 'react-redux';
import { GetStudentResponse } from 'schemas/aidt-student';
import { BaseAidtDto } from 'schemas/base';
import { RootState } from 'types';

export function useStudent({ data, headers }: BaseAidtDto) {
  const user = useSelector((state: RootState) => state.user);

  return useQuery<GetStudentResponse, Error>({
    enabled:
      !!data.user_id &&
      !!data.access_token.access_id &&
      !!data.access_token.token &&
      !!headers['Partner-ID'] &&
      user.user_type === 'S',
    queryFn: () => getStudent({ data, headers }),
    queryKey: ['student', { data, headers }],
  });
}
