import {
  AddDreamJobsDto,
  GetAccumulatedDreamJobsDto,
  GetAccumulatedDreamJobsResponse,
  GetDreamJobsDto,
  GetDreamJobsResponse,
  GetRecentStatusDto,
  GetRecentStatusResponse,
  UpdateStatusDto,
  UpdateStatusResponse,
} from 'schemas/user';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/user';

// 학생 장래희망 직업 조회
export const getDreamJobs = async ({
  headers,
}: GetDreamJobsDto): Promise<GetDreamJobsResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/dream-jobs`, {
      headers,
      params: { take: 1 },
    })
  ).data;
};

// 학생 장래희망 직업 추가
export const addDreamJobs = async ({ data, headers }: AddDreamJobsDto) => {
  return (
    await axiosInstance.post(`${ENDPOINT}/dream-job/bulk`, data, { headers })
  ).data;
};

// 나의 장래희망 BEST 3 조회
export const getAccumulatedDreamJobs = async ({
  headers,
}: GetAccumulatedDreamJobsDto): Promise<GetAccumulatedDreamJobsResponse> => {
  return (
    await axiosInstance.get(`${ENDPOINT}/dream-jobs-accumulated`, {
      headers,
    })
  ).data;
};

// 학생 최근 상태 조회
export const getRecentStatus = async ({
  headers,
}: GetRecentStatusDto): Promise<GetRecentStatusResponse> => {
  return (await axiosInstance.get(`${ENDPOINT}/status/recent`, { headers }))
    .data;
};

// 학생 상태 변경
export const updateStatus = async ({
  data,
  headers,
}: UpdateStatusDto): Promise<UpdateStatusResponse> => {
  return (await axiosInstance.post(`${ENDPOINT}/status`, data, { headers }))
    .data;
};
