import { Box, Stack, Typography } from '@mui/material';
import { SocialLeaderboardIcon } from 'app/components/icons';
import { useAccumulatedDreamJobs } from 'queries/use-accumulated-dream-jobs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'types';

export default function MyDreamJobsTable() {
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user);

  // 나의 장래희망 BEST 3 조회
  const accumulatedDreamJobsQuery = useAccumulatedDreamJobs({
    headers: {
      role: user.user_type,
      uuid: user.user_id,
    },
  });

  const headers = ['', '1순위', '2순위', '3순위'];

  return (
    <Stack
      aria-label="나의 장래희망 BEST 3를 나타내는 테이블입니다. 1순위부터 3순위까지의 장래희망과 각 장래희망이 설정된 횟수를 나타냅니다."
      borderRadius=".5rem"
      direction="column"
      flex={1}
      height="100%"
      paddingBottom=".3125rem"
      paddingX=".3125rem"
      sx={{
        backgroundColor: '#EBF2FE',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      }}
      tabIndex={0}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap=".5rem"
        height="2rem"
        paddingLeft=".9rem"
        sx={theme => ({
          [theme.breakpoints.down('md')]: {
            height: '1.5rem',
          },
        })}
      >
        <SocialLeaderboardIcon
          sx={{ color: '#4F73C0', height: '1.5rem', width: '1.5rem' }}
        />
        <Typography
          fontSize=".9.9rem"
          fontWeight="500"
          sx={theme => ({
            [theme.breakpoints.down('md')]: {
              fontSize: '.9rem',
            },
          })}
          variant={'subtitle1'}
        >
          나의 장래희망 BEST 3
        </Typography>
      </Stack>
      <Box
        border="2px solid #BFC7E3"
        borderRadius=".5rem"
        flexGrow={1}
        overflow="hidden"
      >
        <table
          style={{
            borderCollapse: 'collapse',
            height: '100%',
            tableLayout: 'fixed',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <caption style={{ display: 'none' }}>나의 장래희망 BEST 3</caption>
          <tbody>
            <tr
              style={{
                backgroundColor: '#D0E3FA',
              }}
            >
              {headers.map((header, index) => (
                <Box
                  colSpan={1}
                  component="th"
                  key={index}
                  scope="colgroup"
                  sx={theme => ({
                    border: '1px solid #BFC7E3',
                    fontSize: '.9rem',
                    fontWeight: 500,
                    [theme.breakpoints.down('md')]: {
                      fontSize: '.625rem',
                      height: '1.25rem',
                    },
                  })}
                >
                  {t(header)}
                </Box>
              ))}
            </tr>
            <Box
              component="tr"
              sx={theme => ({
                fontSize: '.9rem',
                fontWeight: 400,
                [theme.breakpoints.down('md')]: {
                  fontSize: '.625rem',
                  height: '1.25rem',
                },
              })}
            >
              <th
                scope="col"
                style={{
                  backgroundColor: '#FFF',
                  border: '1px solid #BFC7E3',
                  fontWeight: 400,
                }}
              >
                장래희망
              </th>
              {accumulatedDreamJobsQuery.data
                ? Object.entries(accumulatedDreamJobsQuery.data).map(
                    ([job, count], index) => (
                      <Box
                        aria-label={`${
                          index + 1
                        }순위는 ${job}입니다. 설정된 횟수는 ${count}번입니다.`}
                        component="td"
                        key={index}
                        px=".25rem"
                        scope="col"
                        style={{
                          backgroundColor: '#FFF',
                          border: '1px solid #BFC7E3',
                        }}
                        tabIndex={0}
                      >
                        <Typography
                          noWrap
                          sx={theme => ({
                            fontSize: '.9rem',
                            fontWeight: 400,
                            [theme.breakpoints.down('md')]: {
                              fontSize: '.625rem',
                              height: '1.25rem',
                            },
                          })}
                        >
                          {job}
                        </Typography>
                      </Box>
                    ),
                  )
                : Array(3)
                    .fill('')
                    .map((_, index) => (
                      <Box
                        component="td"
                        key={index}
                        px=".25rem"
                        scope="col"
                        style={{
                          backgroundColor: '#FFF',
                          border: '1px solid #BFC7E3',
                        }}
                      >
                        <Typography
                          noWrap
                          sx={theme => ({
                            fontSize: '.9rem',
                            fontWeight: 400,
                            [theme.breakpoints.down('md')]: {
                              fontSize: '.625rem',
                              height: '1.25rem',
                            },
                          })}
                        >
                          -
                        </Typography>
                      </Box>
                    ))}
            </Box>
            <Box
              component="tr"
              sx={theme => ({
                fontSize: '.9rem',
                fontWeight: 400,
                [theme.breakpoints.down('md')]: {
                  fontSize: '.625rem',
                  height: '1.25rem',
                },
              })}
            >
              <th
                scope="col"
                style={{
                  backgroundColor: '#FFF',
                  border: '1px solid #BFC7E3',
                  fontWeight: 400,
                }}
              >
                설정횟수
              </th>
              {accumulatedDreamJobsQuery.data
                ? Object.values(accumulatedDreamJobsQuery.data).map(
                    (count, index) => (
                      <td
                        key={index}
                        scope="col"
                        style={{
                          backgroundColor: '#FFF',
                          border: '1px solid #BFC7E3',
                        }}
                      >
                        {count}
                      </td>
                    ),
                  )
                : Array(3)
                    .fill('')
                    .map((_, index) => (
                      <td
                        key={index}
                        scope="col"
                        style={{
                          backgroundColor: '#FFF',
                          border: '1px solid #BFC7E3',
                        }}
                      >
                        -
                      </td>
                    ))}
            </Box>
          </tbody>
        </table>
      </Box>
    </Stack>
  );
}
