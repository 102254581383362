import { useQuery } from '@tanstack/react-query';
import { getDescendingStudents } from 'apis/dashboard';
import {} from 'schemas/assignment';
import {
  DescendingStudents,
  GetDescendingStudentsDto,
  GetDescendingStudentsResponse,
} from 'schemas/teacher-dashboard';

export function useDescendingStudents({
  headers,
  params,
}: GetDescendingStudentsDto) {
  return useQuery<GetDescendingStudentsResponse, Error, DescendingStudents>({
    enabled: !!params.learningSysId && !!params.studentIds,
    queryFn: () => getDescendingStudents({ headers, params }),
    queryKey: ['descending_students', { headers, params }],
    retry: false,
    select: data => data.data,
  });
}
