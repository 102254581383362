/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const AssessmentDiagnosisPage = lazyLoad(
  () => import('./index'),
  module => module.AssessmentDiagnosisPage,
);
