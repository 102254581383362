import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface DrawerState {
  profileSetting: boolean;
}

interface DrawerAction {
  toggleDrawer: (name: string, open: boolean) => () => void;
}

const useDrawerStore = create<DrawerAction & DrawerState>()(
  devtools(
    persist(
      set => ({
        profileSetting: false,
        toggleDrawer: (name, open) => () =>
          set(state => ({ ...state, [name]: open })),
      }),
      { name: 'drawer' },
    ),
  ),
);

export default useDrawerStore;
