/**
 * Asynchronously loads the component for Home
 */

import { lazyLoad } from 'utils/loadable';

export const NoticeDetailPage = lazyLoad(
  () => import('./index'),
  module => module.NoticeDetailPage,
);
