import {
  ReqDeleteNotification,
  ReqGetNotification,
  RespGetNotification,
  SendAssignmentReminderDto,
  SendAssignmentReminderResponse,
  SendLearningReminderDto,
  SendLearningReminderResponse,
} from 'schemas/notification';
import axiosInstance from 'utils/axiosInstance';

const ENDPOINT = '/notification';

//알림 불러오기
export const getNotifications = async ({
  headers,
}: ReqGetNotification): Promise<RespGetNotification> => {
  return (await axiosInstance.get(`${ENDPOINT}`, { headers })).data.data;
};

//알림 하나 삭제
export const deleteNotifications = async ({
  data,
  headers,
}: ReqDeleteNotification): Promise<RespGetNotification> => {
  return (await axiosInstance.delete(`${ENDPOINT}`, { data, headers })).data
    .data;
};

// 과제 독촉
export const sendAssignmentReminder = async ({
  data,
  headers,
}: SendAssignmentReminderDto): Promise<SendAssignmentReminderResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/assignment-reminder`, data, {
      headers,
    })
  ).data;
};

// 학습 독촉
export const sendLearningReminder = async ({
  data,
  headers,
}: SendLearningReminderDto): Promise<SendLearningReminderResponse> => {
  return (
    await axiosInstance.post(`${ENDPOINT}/study-reminder`, data, { headers })
  ).data;
};
