import { Button } from 'components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'components/ui/pagination';
import { ChevronRight, X } from 'lucide-react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { VIDEO_BUCKETS } from 'schemas/file';
import { getS3FileDownloadUrl } from 'services/fileService';
import useConceptVideoDialogStore from 'stores/use-concept-video-dialog-store';
import { RootState } from 'types';
import { convertUrlToPath } from 'utils/convertUrl';

export default function ConceptVideoDialog() {
  const { open, toggleDialog, videos } = useConceptVideoDialogStore(state => ({
    open: state.open,
    toggleDialog: state.toggleDialog,
    videos: state.videos,
  }));

  const [videoPaths, setVideoPaths] = React.useState<string[]>([]);
  const [videoPath, setVideoPath] = React.useState('');
  const [current, setCurrent] = React.useState<number>(1);

  const user = useSelector((state: RootState) => state.user);

  React.useEffect(() => {
    const fetchVideoPaths = async () => {
      const paths = await Promise.all(
        videos.map(video => getUrlByFilePath(video.video_path)),
      );
      setVideoPaths(paths);
    };

    if (open) {
      fetchVideoPaths();
    }
  }, [open, videos]);

  React.useEffect(() => {
    if (videoPaths.length > 0) {
      setVideoPath(videoPaths[current - 1]);
    }
  }, [current, videoPaths]);

  const getUrlByFilePath = async (path: string) => {
    const url = await getS3FileDownloadUrl({
      bucketName: VIDEO_BUCKETS.SHARED_CONCEPT,
      filePath: path,
      userId: user.user_id,
    });

    if (process.env.REACT_APP_NODE_ENV === 'development') {
      return convertUrlToPath(url);
    } else {
      return url;
    }
  };

  return (
    <Dialog onOpenChange={(open: boolean) => toggleDialog(open)()} open={open}>
      <DialogContent className="p-0 overflow-hidden flex flex-col gap-0 border-none max-w-[50rem] max-h-min min-h-[500px]">
        <DialogHeader className="relative flex-row h-[3.125rem] space-y-0 bg-[#CEE2FA]">
          <DialogTitle className="flex flex-1 justify-center gap-2 items-center border-b border-[#DCDCDC] text-lg font-medium">
            <h3>개념학습</h3>
          </DialogTitle>
          <Button
            className="absolute p-0 -translate-y-1/2 right-2 size-6 top-1/2"
            onClick={() => toggleDialog(false)()}
            onKeyDown={e => e.key === 'Enter' && toggleDialog(false)()}
            variant="ghost"
          >
            <X className="size-6" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>

        <div className="flex flex-col items-center justify-center flex-1 text-sm font-normal bg-cover bg-bg4">
          <video className="flex-1" controls key={videoPath}>
            <source src={videoPath} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Pagination className="py-1 bg-transparent">
            <PaginationContent>
              <PaginationItem>
                <PaginationPrevious
                  onClick={() => setCurrent(current === 1 ? 1 : current - 1)}
                />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  isActive={current === 1}
                  onClick={() => setCurrent(1)}
                >
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  isActive={current === 2}
                  onClick={() => setCurrent(2)}
                >
                  2
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink
                  isActive={current === 3}
                  onClick={() => setCurrent(3)}
                >
                  3
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
              {current > 3 && (
                <PaginationItem>
                  <PaginationLink href="#" isActive>
                    {current}
                  </PaginationLink>
                </PaginationItem>
              )}
              <PaginationItem>
                <PaginationNext
                  aria-disabled={current >= videos.length}
                  onClick={() =>
                    setCurrent(
                      current >= videos.length ? videos.length : current + 1,
                    )
                  }
                />
              </PaginationItem>
            </PaginationContent>
          </Pagination>
        </div>
      </DialogContent>
    </Dialog>
  );
}
